import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { CustomModal } from "../../../../components/CustomModal";
import { useState, ChangeEvent, useRef, useEffect } from "react";
import {
  GalleryItem,
  getIllustrationsGallery,
} from "../../../../assets/gallery/gallery-config";

const PAGE_SIZE = 10;

export const IllustrationModal = ({
  onCancel,
  onOk,
}: {
  onCancel: () => void;
  onOk: (value: string) => void;
}) => {
  const [query, setQuery] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [results, setResults] = useState<GalleryItem[]>(
    getIllustrationsGallery().slice(0, PAGE_SIZE * pageNumber)
  );
  const [hasMore, setHasMore] = useState<boolean>(true);
  const timeoutRef = useRef<any>(null);
  const [hoveredImage, setHoveredImage] = useState<GalleryItem | undefined>(
    undefined
  );

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newQueryValue = e.currentTarget.value;
    setQuery(newQueryValue);
    setPageNumber(1);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      const filteredResults = getIllustrationsGallery().filter((illustration) =>
        illustration.tags.some((tag) => tag.includes(query))
      );
      const results = filteredResults.slice(0, PAGE_SIZE * pageNumber);
      if (filteredResults.length > PAGE_SIZE * pageNumber) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setResults(results);
    }, 500);
  };

  useEffect(() => {
    const filteredResults = getIllustrationsGallery().filter((illustration) =>
      illustration.tags.some((tag) => tag.includes(query))
    );
    const results = filteredResults.slice(0, PAGE_SIZE * pageNumber);
    if (filteredResults.length > PAGE_SIZE * pageNumber) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setResults(results);
  }, [pageNumber]);

  const mobileMenu = useMediaQuery("(max-width:600px)");

  return (
    <CustomModal
      open={true}
      cancelIcon
      onCancel={onCancel}
      sx={{ width: "min(500px, 90%)" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          placeholder="Start typing for start searching..."
          sx={{ width: "100%", mb: "15px" }}
          inputProps={{
            style: {
              fontSize: "12px",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
          value={query}
          onChange={handleQueryChange}
        />
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <ImageList cols={mobileMenu ? 1 : 2} sx={{ width: 450, height: 300 }}>
          {results.map((item, index) => (
            <ImageListItem
              sx={{ alignItems: mobileMenu ? "center" : undefined }}
              key={`gallery${index}`}
            >
              <img
                onMouseEnter={() => setHoveredImage(item)}
                onMouseLeave={(e) => {
                  if (
                    e.relatedTarget &&
                    // @ts-ignore
                    e.relatedTarget.id === `select-${index}`
                  ) {
                    return;
                  }
                  setHoveredImage(undefined);
                }}
                src={item.image}
                loading="lazy"
                style={{
                  transition: "0.5s all ease",
                  width: mobileMenu ? "70vw" : undefined,
                }}
                className={`gallery-item${
                  hoveredImage && hoveredImage.image === item.image
                    ? " image-hovered"
                    : ""
                }`}
              />
              <ImageListItemBar subtitle={item.credits} />
              {hoveredImage && hoveredImage.image === item.image ? (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Button
                    id={`select-${index}`}
                    variant="contained"
                    onClick={() => {
                      onOk(hoveredImage.image);
                      setHoveredImage(undefined);
                    }}
                  >
                    Select
                  </Button>
                </Box>
              ) : null}
            </ImageListItem>
          ))}
          {hasMore ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gridColumn: "1 / -1",
              }}
            >
              <Button
                onClick={() => setPageNumber(pageNumber + 1)}
                variant="text"
              >
                Show more
              </Button>
            </Box>
          ) : null}
        </ImageList>
      </Box>
    </CustomModal>
  );
};
