import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { useRef } from 'react';
import { generateRandom } from '../utils/numbers';

interface CustomSelectProps<T> {
    value: T;
    defaultValue?: T;
    options: { value: T, label: string, sx?: SxProps<Theme> }[];
    onChange: (value: T) => void;
    label?: string;
    fullWidth?: boolean;
    selectSx?: SxProps<Theme>;
    selectId?: string;
}

export const CustomSelect = <T,>({ label, defaultValue, onChange, options, value, fullWidth, selectSx, selectId }: CustomSelectProps<T>) => {
    const id = useRef<string>(`label-${generateRandom(0,10000)}`)
    return (
        <FormControl fullWidth={fullWidth}>
        {label ? <InputLabel id={id.current} shrink>{label}</InputLabel> : null}
        <Select
            id={selectId}
            value={value as string}
            labelId={id.current}
            notched
            defaultValue={defaultValue as string}
            label={label}
            onChange={(event: SelectChangeEvent) => {
                const newValue = event.target.value as T;
                onChange(newValue);
            }}
            placeholder={label}
            sx={selectSx}
            MenuProps={{
                sx: {
                    ".MuiPaper-root": {
                        maxHeight: "200px"
                    }
                }
            }}
        >
            {options.map((option) => (<MenuItem sx={{ zIndex: 9999, ...(option.sx ? option.sx : {}) }} key={option.value as string} value={option.value as string}>{option.label}</MenuItem>))}
        </Select>
        </FormControl>
    )
}