import { Box, Button, CircularProgress, Fade, Typography } from "@mui/material";
import subscriptionOkImage from "../assets/subscription-ok.png";
import subscriptionHourglassImage from "../assets/subscription-hourglass.png";
import subscriptionCancelImage from "../assets/subscription-cancel.png";
import useFetch from "../hooks/useFetch";
import { subscriptionApi, SubscriptionStatus } from "../api/SubscriptionApi";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**
 *
 * @param param0 TODO change images, these are taken from Pixabay without attributes
 * @returns
 */
export const SubscriptionStatusPage = ({ success }: { success: boolean }) => {
  const [status, setStatus] = useState<SubscriptionStatus>(
    SubscriptionStatus.INACTIVE
  );
  const [successIcon, setSuccessIcon] = useState<boolean>(false);

  const { callApi } = useFetch();
  const navigate = useNavigate();
  const timer = useRef<any>(null);

  const getStatus = async () => {
    try {
      const response: { status: SubscriptionStatus } = await callApi(
        subscriptionApi.hasActiveSubscription()
      );
      setStatus(response.status);
      if (response.status === SubscriptionStatus.ACTIVE) {
        setTimeout(() => {
          if (timer.current) {
            clearInterval(timer.current);
            navigate("/app/dashboard");
          }
        }, 5000);
      }
    } catch (e) {
      console.log("TODO handle");
    }
  };

  useEffect(() => {
    if (success) {
      timer.current = setInterval(() => getStatus(), 5000);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {success ? (
          <>
            <Fade in={status === SubscriptionStatus.ACTIVE && successIcon} mountOnEnter>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "15px" }}>
                <img
                  src={subscriptionOkImage}
                  style={{ borderRadius: "15px", height: "200px" }}
                />
                <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
                  Thank you for subscribing to VividBoard.app. Now you can enjoy
                  all features of this service. You can manage your Subscription
                  in Application Settings.
                </Typography>
                <Typography>Redirecting to Dashboard...</Typography>
              </Box>
            </Fade>
            <Fade
              onExited={() => setTimeout(() => setSuccessIcon(true), 500)}
              in={status !== SubscriptionStatus.ACTIVE}
              unmountOnExit
            >
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "15px" }}>
                <img
                  src={subscriptionHourglassImage}
                  style={{ borderRadius: "15px", height: "200px" }}
                />
                <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
                  Thank you for subscribing to VividBoard.app. Please give us a
                  few second to confirm your subscription...
                </Typography>
                <CircularProgress />
              </Box>
            </Fade>
          </>
        ) : (
          <img
            src={subscriptionCancelImage}
            style={{ borderRadius: "15px", minWidth: "300px", width: "20%" }}
          />
        )}
      </Box>
      <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
        {!success ?  <>You have canceled your Subscription request</> : null}
      </Typography>

      {!success ? (
        <Button
          component={Link}
          to={"/app/settings#subscription"}
          variant="contained"
        >
          Go to Subscription page
        </Button>
      ) : null}
    </Box>
  );
};
