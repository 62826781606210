import { useState, useEffect, useRef } from "react";
import useMeasure from "react-use-measure";
import { useTransition, a } from "@react-spring/web";
import shuffle from "lodash.shuffle";
import nameBackground0 from "../../assets/3474105.jpg";
import nameBackground1 from "../../assets/abstract-background-design-hd-angel-blue.jpg";
import nameBackground2 from "../../assets/pink-blue-colorful-abstract-background.jpg";
import nameBackground3 from "../../assets/3280347.jpg";
import nameBackground4 from "../../assets/3538932.jpg";

import useMedia from "./useMedia";

import styles from "./styles.module.css";
import { Board } from "../../model/board";
import { Goal, getPhotoUrl } from "../../model/goal";
import useFetch from "../../hooks/useFetch";
import { Box, IconButton } from "@mui/material";
import { generateRandom } from "../../utils/numbers";
import { enterFullscreen } from "../../utils/fullscreen";
import { isPwaApplication } from "../../utils/pwa";
import CloseIcon from "@mui/icons-material/Close";

type Item = {
  css: string;
  height: number;
  styles?: any;
  title: string;
  type: "goal" | "board_name";
};

const NAME_BACKGROUNDS = [
  nameBackground0,
  nameBackground1,
  nameBackground2,
  nameBackground3,
  nameBackground4,
];

export const BACKGROUND = [
  {
    backgroundColor: "#0093E9",
    backgroundImage: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
  },
  {
    backgroundColor: "#4158D0",
    backgroundImage:
      "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
  },
  {
    backgroundColor: "#D9AFD9",
    backgroundImage: "linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%)",
  },
  {
    backgroundColor: "#8EC5FC",
    backgroundImage: "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
  },
  {
    backgroundColor: "#8BC6EC",
    backgroundImage: "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
  },
];

export const PwaCloseFullscreenButton = ({ callback }: { callback: () => void }) => {
  return (
    <Box
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            zIndex: 1300,
          }}
        >
          <IconButton
            onClick={callback}
          >
            <CloseIcon />
          </IconButton>
        </Box>
  )
}

const DEFAULT_HEIGHT = 350;

const calculateGridItems = (items: Item[], columns: number, width: number) => {
  let heights = new Array(columns).fill(0); // Each column gets a height starting with zero
  let gridItems = items.map((child, i) => {
    const column = heights.indexOf(Math.min(...heights)); // Basic masonry-grid placing, puts tile into the smallest column using Math.min
    const x = (width / columns) * column; // x = container width / number of columns * column index,
    const y = (heights[column] += child.height) - child.height; // y = it's just the height of the current column
    return {
      ...child,
      x,
      y,
      width: width / columns,
      height: child.height,
    };
  });
  return [heights, gridItems];
};

const calculateItemHeight = (goals: Goal[], columns: number) =>
  window.screen.height / Math.ceil(goals.length / columns);

export const Masonry = ({
  board,
  fullScreen,
  exitFullScreenCallback,
}: {
  board: Board;
  fullScreen: boolean;
  exitFullScreenCallback: () => void;
}) => {
  const { callApi } = useFetch();
  const isPwaApp = isPwaApplication();

  const [items, setItems] = useState<Item[]>([]);
  const itemsRef = useRef<Item[]>([]);
  const shuffleIntervalRef = useRef<any>(null);
  const wakeLockRef = useRef<any>(null);
  const nameBackground = useRef<string>(
    NAME_BACKGROUNDS[generateRandom(0, NAME_BACKGROUNDS.length - 1)]
  );
  const screenBackground = useRef<any>(
    BACKGROUND[generateRandom(0, BACKGROUND.length - 1)]
  );

  const mapGoals = async (goals: Goal[]): Promise<Item[]> => {
    const items: Item[] = [];
    for (const goal of goals) {
      const url = await getPhotoUrl(goal, "regular", callApi);
      items.push({
        css: url,
        height: DEFAULT_HEIGHT,
        title: goal.name,
        type: "goal",
      });
    }
    return items;
  };

  useEffect(() => {
    mapGoals(board.goals).then((mappedItems) => {
      const titleIndex = Math.floor(mappedItems.length / 2);
      const itemsWithTitle: Item[] = [
        ...mappedItems.slice(0, titleIndex),
        {
          height: mappedItems[0].height,
          type: "board_name",
          title: board.name,
          css: "",
        },
        ...mappedItems.slice(titleIndex),
      ];
      setItems(itemsWithTitle);
      itemsRef.current = itemsWithTitle;
    });
  }, [board]);

  useEffect(() => {
    if (shuffleIntervalRef.current) {
      clearInterval(shuffleIntervalRef.current);
    }
    shuffleIntervalRef.current = setInterval(async () => {
      const shuffled = shuffle(items);
      setItems(shuffled);
      itemsRef.current = shuffled;
    }, 5000);
  }, [items]);

  const columns = useMedia(
    [
      "(min-width: 1500px)",
      "(min-width: 1000px)",
      "(min-width: 600px)",
      "(min-width: 300px)",
    ],
    [3, 3, 1, 1],
    2
  );
  const [ref, { width }] = useMeasure();

  const exitedFullScreenCallback = () => {
    if (!document.fullscreenElement) {
      exitFullScreenCallback();
      const updatedItems = itemsRef.current.map((item) => ({
        ...item,
        height: DEFAULT_HEIGHT,
      }));
      setItems(updatedItems);
      itemsRef.current = updatedItems;
      if (wakeLockRef.current) {
        wakeLockRef.current.release().then(() => {
          wakeLockRef.current = null;
        });
      }
    }
  };

  const pwaExitFullScreenCallback = () => {
    exitFullScreenCallback();
    const updatedItems = itemsRef.current.map((item) => ({
      ...item,
      height: DEFAULT_HEIGHT,
    }));
    setItems(updatedItems);
    itemsRef.current = updatedItems;
    if (wakeLockRef.current) {
      wakeLockRef.current.release().then(() => {
        wakeLockRef.current = null;
      });
    }
  };

  const enterFullScreenCallback = async () => {
    if (fullScreen) {
      const element = document.getElementById("screen-saver");
      const updatedItems = items.map((item) => ({
        ...item,
        height: calculateItemHeight(board.goals, columns),
      }));
      setItems(updatedItems);
      itemsRef.current = updatedItems;
      if (element && !isPwaApp) {
        enterFullscreen(element);
      }
      // Create a reference for the Wake Lock.
      let wakeLock = null;

      // create an async function to request a wake lock
      try {
        const anyNav: any = navigator;
        if ("wakeLock" in navigator) {
          wakeLock = await anyNav["wakeLock"].request("screen");
          wakeLockRef.current = wakeLock;
        }
      } catch (err: any) {
        // The Wake Lock request has failed - usually system related, such as battery.
        console.log(`${err.name}, ${err.message}`);
      }
    }
  };

  useEffect(() => {
    enterFullScreenCallback();
  }, [fullScreen]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", exitedFullScreenCallback);

    return () => {
      document.removeEventListener(
        "fullscreenchange",
        exitedFullScreenCallback
      );
      if (shuffleIntervalRef.current) {
        clearInterval(shuffleIntervalRef.current);
      }
    };
  }, []);
  // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
  const [heights, gridItems] = calculateGridItems(items, columns, width);

  // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(gridItems, {
    key: (item: { css: string; height: number }) => item.css,
    from: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 0 }),
    enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
    update: ({ x, y, width, height }) => ({ x, y, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });

  return (
    <div
      ref={ref}
      id="screen-saver"
      className={styles.list}
      style={{
        ...(fullScreen ? screenBackground.current : {}),
        height: Math.max(...heights),
        ...(isPwaApp && fullScreen
          ? {
              height: gridItems.length <= 6 ? "100vh" : gridItems.length > 6 ? "150vh" : "200vh",
              width: "100vw",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1200,
            }
          : {}),
      }}
    >
      {isPwaApp && fullScreen ? (
        <PwaCloseFullscreenButton callback={pwaExitFullScreenCallback} />
      ) : null}
      {transitions((style, item) => {
        const i = item as Item;
        return (
          <a.div style={{ ...style }}>
            <div
              style={{
                backgroundImage:
                  i.type === "goal"
                    ? `url(${item.css})`
                    : `url(${nameBackground.current})`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  opacity: 0.7,
                  p: columns === 1 ? "8px" : "20px",
                  mt:
                    i.type === "goal" ||
                    (i.type === "board_name" && !board.statement)
                      ? columns === 1
                        ? "0px"
                        : "150px"
                      : "0px",
                  fontSize: columns === 1 ? "12px" : "26px",
                }}
              >
                {i.title}
              </Box>
              {i.type === "board_name" && board.statement ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    opacity: 0.7,
                    p: columns === 1 ? "8px" : "30px",
                    lineHeight: columns === 1 ? "10px" : "14px",
                    fontSize: columns === 1 ? "10px" : "14px",
                    fontStyle: columns === 1 ? "10px" : "italic",
                  }}
                >
                  {board.statement}
                </Box>
              ) : null}
            </div>
          </a.div>
        );
      })}
    </div>
  );
};
