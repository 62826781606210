export type EventType = "menu_size_change";

interface EventListener {
    type: EventType;
    callback: () => void;
};

const eventListeners: EventListener[] = [];

export const registerListener = (listener: EventListener): void => {
    if (eventListeners.some((l) => l.type === listener.type)) {
        return;
    } else {
        eventListeners.push(listener);
    }
}

export const dispatchEvent = (event: EventType) => {
    const listener = eventListeners.find((l) => l.type === event);
    if (listener) {
        listener.callback();
    }
}