import { Box, TextField, Typography } from "@mui/material"
import { CustomModal } from "../../../../components/CustomModal"
import { useState } from "react"
import { CustomSelect } from "../../../../components/CustomSelect";
import { Label, getVariants } from "../labels/Label";
import { DEFAULT_TEXT_STYLES, Label as LabelItem, LabelStyle } from "./model";
import { useTranslation } from "react-i18next";

const DEFAULT_STYLES = { ...DEFAULT_TEXT_STYLES, size: 40, bold: true };
const MAX_LENGTH = 100;

export const LabelModal = ({ onCancel, onOk, initialLabel }: { onCancel: () => void; onOk: (label: LabelItem) => void; initialLabel?: LabelItem }) => {

    const [label, setLabel] = useState<string>(initialLabel ? initialLabel.text : "");
    const [error, setError] = useState<boolean>(false);
    const [style, setStyle] = useState<LabelStyle>(initialLabel ? initialLabel.style : LabelStyle.NONE);
    const [styleVariant, setStyleVariant] = useState<any>(initialLabel ? initialLabel.variant : null);

    const { t } = useTranslation();

    const variants = getVariants(style);

    const validate = (): boolean => label.length > 0 && label.length < MAX_LENGTH;

    return (
        <CustomModal sx={{ width: "min(500px, 90%)" }} open={true} cancelIcon onCancel={onCancel} onOk={() => { 
            if (validate()) {
                onOk({ style, text: label, variant: styleVariant });
            } else {
                setError(true);
            }
        }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", mt: "15px" }}>
                <TextField
                    label={"Label text"}
                    sx={{ width: "100%" }}
                    helperText={error ? "Missing label value" : null}
                    error={error}
                    onChange={(e) => {
                        setLabel(e.currentTarget.value || "");
                        setError(false);
                    }}
                    value={label} />
                <CustomSelect label="Style" value={style} options={Object.keys(LabelStyle).map(label => ({
                    label: t(`label.style.${label}`), value: label
                }))} onChange={(val) => { 
                    setStyle(val as LabelStyle);
                    const variants = getVariants(val as LabelStyle);
                    if (variants.length) {
                        setStyleVariant(variants[0]);
                    }
                }} />
                {variants.length ?
                    <CustomSelect label="Style variant" value={styleVariant ?? variants[0]} options={variants.map(label => ({
                        label, value: label
                    }))} onChange={(val) => setStyleVariant(val)} /> : null}
                <Box sx={{}}>
                    <Typography>Preview</Typography>
                    <Box sx={{ p: "5px", border: "1px dashed #aabbcc", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {label.length ?
                            <Label text={label} style={style} textStyles={DEFAULT_STYLES} variant={styleVariant} /> : <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px", fontFamily: "Lato, sans-serif", fontStyle: "italic" }}>No label to preview</Box>}
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    )
}