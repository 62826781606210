import { Box, Button, Typography } from "@mui/material"
import { TutorialContent } from "./TutorialContent"
import { useNavigate } from "react-router-dom"

export const Tutorial = ({ tutorial }: { tutorial: TutorialContent }) => {
    const navigate = useNavigate();
    return (<Box sx={{ display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" }}>
        <Box component={"img"} src={tutorial.image} sx={{ maxWidth: "500px", width: "100%" }} />
        <Typography component={"h1"} sx={{ fontSize: "24px", fontWeight: 700, fontStyle: "italic" }}>{tutorial.title}</Typography>
        {tutorial.content.map((desc, index) => (<Typography key={`desc${index}`}>{desc}</Typography>))}
        <Button variant="outlined" onClick={() => navigate("/app/tutorials")}>Go back to tutorials</Button>
    </Box>)
}