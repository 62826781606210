import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AIAssistantAction } from "../../api/AIAssistantApi";
import { AssistantMessage } from "./AssistantMessage";
import { useTranslation } from "react-i18next";

export const FinalScreen = ({ onClose, startAgainCallback, performedAction }: { onClose: () => void; startAgainCallback: () => void; performedAction: AIAssistantAction }) => {
    const [messageFinished, setMessageFinished] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <AssistantMessage
          key={"introMessage"}
          message={`Ok! Thank you for waiting. I'm done. ${t(`ai.action.success.${performedAction}`)}`}
          onFinish={() => setMessageFinished(true)}
        />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            p: "10px 20px",
            opacity: messageFinished ? 1 : 0,
            transition: "0.5s all ease",
          }}
        >
          <Button onClick={startAgainCallback} variant="outlined">
            start again
          </Button>
          <Button onClick={() => navigate("/app/tutorials")} variant="outlined">
            Show tutorials
          </Button>
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
        </Box>
      </Box>
    );
  };