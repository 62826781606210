import { styled } from "@mui/material/styles";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { CustomTabPanel } from "../../components/CustomTabPanel";
import { useLocation, useNavigate } from "react-router-dom";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    flexWrap: "wrap"
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#544961",
  },
});

interface StyledTabProps {
  label: string;
  icon: React.ReactElement;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple iconPosition="start" {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  padding: "12px 6px",
  color: "#243040",
  "&.Mui-selected": {
    color: "#544961",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
  "&.MuiTab-root": {
    minHeight: "unset",
  },
}));

interface SettingTabProps {
  label: string;
  icon: React.ReactElement;
  Element: ReactNode;
  hash: string;
}

export const SettingTabs = ({ tabs }: { tabs: SettingTabProps[] }) => {
  const [value, setValue] = useState(0);
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hashValue = hash.replace("#", "");
    for (let i = 0; i < tabs.length; ++i) {
      if (tabs[i].hash === hashValue) {
        setValue(i);
      }
    }
  }, [hash]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/app/settings#${tabs[newValue].hash}`)
    // setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabs value={value} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={`tabLabel${index}`}
            icon={tab.icon}
            label={tab.label}
          />
        ))}
      </StyledTabs>
      <Paper elevation={2} sx={{ width: "100%", padding: "20px", mt: "10px" }}>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={`tabPanel${index}`} index={index} value={value}>
            {tab.Element}
          </CustomTabPanel>
        ))}
      </Paper>
    </Box>
  );
};
