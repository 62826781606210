export interface TutorialContent {
  title: string;
  image: string;
  content: string[];
  path: string;
}
export const TutorialContents: TutorialContent[] = [
  {
    path: "smart-goals",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/smart-goals.jpg`,
    title: "SMART goals",
    content: [
      "Setting goals is a powerful practice that can provide you with clarity, focus, and the motivation needed to achieve your desired outcomes. However, not all goals are created equal. To enhance the likelihood of success, it is beneficial to set SMART goals. SMART is an acronym that stands for Specific, Measurable, Achievable, Relevant, and Time-bound. This framework can be applied to personal, professional, educational, and health-related goals to ensure they are clearly defined and attainable within a certain timeframe.",
      'A SMART goal begins with specificity. Rather than setting a vague goal like "I want to be healthier," a specific goal would clearly define what health means to you. For example, "I want to reduce my cholesterol level by 10%." This specificity allows you to focus your efforts and fully understand what you are aiming to achieve. Without a specific target, it\'s difficult to measure progress or know when you\'ve reached your goal.',
      'Measurability is the second aspect of SMART goal setting. If you can\'t measure it, you can\'t manage it. Continuing with the example above, knowing your current cholesterol levels and tracking changes over time provides quantifiable data. This data not only helps you stay on track but also provides motivation as you see incremental improvements. Measurable goals answer questions like "How much?" or "How many?" providing a way to objectively determine when the goal has been met.',
      "The third criterion ensures that your goal is Achievable. It's important to set goals that are challenging yet attainable given your current resources, capabilities, and constraints. An unrealistic goal can demotivate you, while an achievable goal can boost your confidence and encourage further progress. If the goal to reduce cholesterol by 10% in three months is not realistic due to your starting point, adjust it to a more achievable figure based on medical advice.",
      'Finally, Time-bound refers to setting a specific deadline for the goal. A goal without a timeline is just a dream. By setting a deadline, such as "I will reduce my cholesterol level by 10% within six months," you create a sense of urgency and can plan accordingly. Time constraints can prompt action and help you organize your resources to make consistent progress toward your goal.',
      "In conclusion, SMART goal setting transforms vague aspirations into actionable steps with clear metrics and timelines. By making goals Specific, Measurable, Achievable, Relevant, and Time-bound, you can create a structured approach to your ambitions, making it easier to manage your progress and stay motivated. Whether you're looking to advance your career, improve your health, or achieve a personal milestone, SMART goals provide a practical framework to guide your journey toward success.",
    ],
  },
  {
    path: "vision-board-and-visualisation-techniques",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/vision-board-visualisation-techniques.jpg`,
    title: "Vision Boards and other visualization techniques",
    content: [
      "Vision boards and visualization techniques are powerful tools used to manifest one's goals and dreams. A vision board is a visual representation of your goals, dreams, and aspirations created by collaging images, words, and symbols onto a board or poster. By creating a vision board, you are essentially bringing your desires to life and reinforcing them in your mind. Visualization techniques, on the other hand, involve mentally picturing yourself achieving your goals in vivid detail. This practice helps to program your subconscious mind to work towards making your dreams a reality.",
      "One of the key benefits of vision boards is that they serve as a constant reminder of what you are working towards. By placing your vision board in a prominent location where you will see it every day, you are reinforcing your goals and keeping yourself motivated. Visualization techniques complement this practice by allowing you to mentally rehearse achieving your goals, which can increase your belief in your ability to succeed and attract opportunities to help you reach your goals.",
      "Creating a vision board can be a fun and creative process that allows you to tap into your imagination and clarify your aspirations. Whether you prefer to cut out images and words from magazines or create a digital vision board using online tools, the process of curating your board can help you gain clarity on your goals and priorities. Visualization techniques can then be used to enhance the effectiveness of your vision board by engaging your senses and emotions in the process of manifesting your desires.",
      "Studies have shown that visualization techniques can have a positive impact on performance and goal achievement. By regularly visualizing yourself achieving your goals, you are programming your mind to focus on the actions and behaviors that will move you closer to your desired outcome. When combined with a visually stimulating vision board, the power of visualization is amplified, making it easier to stay focused and motivated on your path to success.",
      "In conclusion, vision boards and visualization techniques are valuable tools for setting and achieving goals. Whether you are looking to improve your health, career, relationships, or any other area of your life, incorporating these practices into your daily routine can help you clarify your aspirations, stay motivated, and attract opportunities to help you succeed. By harnessing the power of visualization and creating a visual representation of your dreams, you can take proactive steps towards transforming your visions into reality.",
    ],
  },
  {
    path: "why-use-vision-boards",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/why-u-should-use-vision-board.jpg`,
    title: "Why you should use Vision Boards?",
    content: [
      "Using a vision board is a powerful tool for visualizing your goals and dreams, turning them into tangible realities. A vision board serves as a visual representation of your aspirations, helping you clarify your desires and focus your intentions. By creating a vision board and regularly engaging with it, you can harness the power of visualization to manifest your goals and bring them to life.",
      "One of the main benefits of using a vision board is that it helps you stay motivated and inspired. By surrounding yourself with images, words, and symbols that represent your goals and dreams, you create a constant reminder of what you are working towards. This visual reinforcement can reignite your passion and determination, especially during challenging times when motivation may wane. A vision board serves as a visual anchor, keeping your goals at the forefront of your mind and fueling your drive to succeed.",
      "Furthermore, a vision board can help you clarify your goals and set clear intentions. When you take the time to curate images and words that resonate with your desires, you are forced to articulate and define what you truly want. This process of visualizing your goals can bring clarity and focus to your aspirations, helping you prioritize what is most important to you. By creating a visual representation of your dreams, you can gain a deeper understanding of your values and aspirations, guiding your actions and decisions towards their fulfillment.",
      "In addition, using a vision board can enhance your creativity and problem-solving skills. As you compile images and ideas onto your vision board, you are engaging in a creative process that stimulates your imagination and innovative thinking. This creative exercise can spark new ideas, insights, and solutions that can propel you towards your goals. By tapping into your creativity through your vision board, you can explore different possibilities, brainstorm strategies, and approach challenges from a fresh perspective.",
      "Moreover, a vision board can serve as a powerful manifestation tool, helping you attract the opportunities and resources needed to achieve your goals. By visualizing your desires and embodying the feelings associated with their fulfillment, you are aligning yourself with the energy of abundance and success. This positive mindset can attract synchronicities, serendipities, and opportunities that support your journey towards your goals. By regularly engaging with your vision board and embodying the emotions of success, you can accelerate the manifestation of your dreams and bring them into reality.",
      "In conclusion, using a vision board is a valuable practice for visualizing your goals, staying motivated, clarifying your intentions, enhancing creativity, and manifesting your desires. By creating a visual representation of your aspirations and regularly engaging with it, you can harness the power of visualization to transform your dreams into achievable goals. Whether you seek personal growth, career advancement, or improved relationships, a vision board can serve as a powerful tool for manifesting your desires and living a more fulfilling and purposeful life",
    ],
  },
  {
    path: "motivational-quotes",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/motivational-quotes.jpg`,
    title: "Motivational quotes",
    content: [
      "Motivational quotes play a significant role in self-development by offering inspiration, encouragement, and perspective to individuals seeking personal growth and improvement. These quotes, often distilled wisdom from successful and respected figures, serve as powerful reminders of the potential within each person to overcome challenges, pursue goals, and strive for greatness. By internalizing and reflecting on motivational quotes, individuals can shift their mindset, boost their morale, and ignite their passion for self-improvement.",
      "Moreover, motivational quotes have the ability to provide clarity and guidance during moments of uncertainty or self-doubt. When faced with obstacles or setbacks, a well-chosen quote can offer a fresh perspective, reframe limiting beliefs, and remind individuals of their inherent strength and resilience. By incorporating motivational quotes into their daily routine, individuals can recenter themselves, regain focus, and stay committed to their personal development journey.",
      "Additionally, motivational quotes serve as catalysts for positive change, nudging individuals out of their comfort zones and encouraging them to take risks in pursuit of their goals. These quotes often convey powerful messages about perseverance, courage, and determination, inspiring individuals to push past their limits, embrace challenges, and seize opportunities for growth. Through exposure to motivational quotes, individuals can cultivate a mindset of continuous improvement, adaptability, and openness to new experiences.",
      "Furthermore, motivational quotes have the capacity to foster a sense of community and connection among individuals on their self-development journeys. Shared and discussed in personal growth forums, social media platforms, and workshops, motivational quotes create a common language of inspiration and support that unites like-minded individuals striving for self-improvement. This sense of community can provide encouragement, accountability, and motivation to individuals as they navigate their personal growth paths.",
      "In conclusion, motivational quotes are essential tools for self-development as they offer inspiration, guidance, encouragement, and a sense of community to individuals seeking personal growth. By incorporating motivational quotes into their daily routines and mindset, individuals can cultivate resilience, motivation, and a positive outlook on their journey towards self-improvement. These quotes serve as powerful reminders of the potential within each individual to overcome challenges, pursue goals, and lead a fulfilling and purposeful life.",
    ],
  },
  {
    path: "power-of-motivation",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/power-of-motivation.jpg`,
    title: "Power of motivation",
    content: [
      "Motivation is the driving force behind our actions, behaviors, and decisions. It is what propels us to set goals, overcome challenges, and pursue our dreams. Motivation is essential for achieving success and fulfillment in life, as it provides us with the energy and focus needed to take action towards our desired outcomes. There are various factors that contribute to motivation, including internal desires, external rewards, and personal values. When we are motivated, we are more likely to persevere in the face of obstacles, stay committed to our goals, and maintain a positive attitude even during difficult times.",
      "One of the key reasons why motivation works is that it helps us maintain momentum towards our goals. When we are motivated, we are more likely to take consistent action towards achieving our objectives, rather than getting discouraged or giving up when faced with challenges. Motivation provides us with a sense of purpose and direction, guiding our efforts towards meaningful outcomes. By staying motivated, we are able to stay focused on our goals and make progress even when the going gets tough.",
      "Motivation also plays a critical role in boosting our confidence and self-esteem. When we are motivated to pursue our goals and dreams, we are more likely to believe in our abilities and capabilities. This self-belief can help us overcome self-doubt and fear of failure, empowering us to take risks and step outside of our comfort zones. As we achieve small victories and milestones along the way, our confidence grows, reinforcing our motivation and fueling our drive to continue moving forward.",
      "Moreover, motivation can enhance our overall well-being and sense of fulfillment. When we are motivated to pursue goals that align with our values and aspirations, we experience a greater sense of purpose and satisfaction in our lives. Motivation can help us tap into our passions, strengths, and talents, leading to a deeper sense of fulfillment and happiness. By staying motivated and actively working towards our goals, we can create a sense of accomplishment and pride in our achievements, which in turn boosts our overall well-being.",
      "In conclusion, motivation is a powerful force that drives us to take action, overcome challenges, and achieve our goals. It works by providing us with the energy, focus, and determination needed to pursue our aspirations and dreams. Motivation helps us maintain momentum, boost our confidence, enhance our well-being, and ultimately lead more fulfilling lives. By understanding the importance of motivation and harnessing its power, we can unlock our full potential, overcome obstacles, and create a life filled with purpose and success.",
    ],
  },
  {
    path: "definitions",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/definitions.jpg`,
    title: "Definitions",
    content: [
      "Vision Board - A vision board is a visual representation of one's goals, dreams, aspirations, and desires, typically created by collaging images, words, and symbols onto a physical or digital board. The purpose of a vision board is to serve as a powerful visualization tool that helps individuals clarify their intentions, stay motivated, and manifest their goals through the law of attraction. By regularly engaging with their vision board, individuals can focus their energy and attention on their desired outcomes, reinforcing positive beliefs and emotions to bring their dreams into reality. Vision boards are commonly used in personal development, goal-setting, and manifestation practices to inspire and encourage individuals to pursue their ambitions and create the life they envision.",
      "Personal goal - A personal goal is a specific, achievable, and measurable objective that an individual sets for themselves to work towards in order to improve their life, achieve personal growth, or fulfill a desired outcome. Personal goals are based on individual aspirations, values, and priorities, and they often reflect a person's hopes, dreams, and ambitions. These goals can encompass various areas of life, such as health, relationships, career, education, finance, hobbies, and personal development. By setting personal goals, individuals can create a roadmap for success, stay motivated, track their progress, and ultimately enhance their well-being and satisfaction in life. Personal goals serve as guiding principles that help individuals focus their efforts, make meaningful choices, and strive towards a more fulfilling and purposeful existence.",
      "Visualization technique - Visualization technique is a mental practice that involves creating vivid mental images or scenarios to simulate specific experiences, outcomes, or goals in one's mind. This technique is used to enhance performance, improve skills, reduce anxiety, and manifest desired results by mentally rehearsing successful outcomes. Visualization often incorporates multiple senses, such as sight, sound, touch, and emotions, to make the mental imagery as realistic and detailed as possible. By engaging in visualization exercises regularly, individuals can increase their confidence, focus, motivation, and belief in their ability to achieve their goals. Visualization techniques are commonly employed in sports, personal development, therapy, and goal-setting practices as a powerful tool for mental rehearsal, self-improvement, and positive reinforcement.",
      "Affirmations - Affirmations are positive statements or phrases that individuals repeat to themselves regularly with the intention of instilling a specific belief, mindset, or outcome in their subconscious mind. These statements are typically personalized to reflect desired traits, goals, or attitudes and are often expressed in the present tense as if they are already true. Affirmations are used as a self-help or self-improvement technique to challenge negative thoughts, reprogram limiting beliefs, boost self-confidence, and cultivate a more positive and empowering mindset. By consistently affirming positive statements, individuals aim to shift their focus, thoughts, and energy towards aligning with their goals, promoting self-empowerment, and enhancing their overall well-being. Affirmations are a popular tool in personal development, mindfulness practices, and mental health strategies for promoting self-love, resilience, and personal growth.",
      "Self-motivation - Self-motivation is the inner drive, determination, and initiative that individuals harness to propel themselves towards achieving their goals, overcoming challenges, and fulfilling their aspirations without external pressure or influence. It is the ability to maintain a positive attitude, set meaningful objectives, and persistently pursue them even in the face of obstacles or setbacks. Self-motivated individuals exhibit a strong sense of purpose, autonomy, and intrinsic motivation, drawing upon their internal resources, values, and beliefs to stay focused, energized, and committed to their endeavors. Self-motivation involves taking responsibility for one's actions, fostering a growth mindset, and cultivating resilience to stay motivated and inspired in pursuing personal and professional success.",
    ],
  },
  {
    path: "setting-goals",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/setting-goals.jpg`,
    title: "Why settings goals is important",
    content: [
      "Setting goals is crucial for providing direction and purpose in both personal and professional life. Goals act as a roadmap, guiding individuals towards their desired destination. Without clear goals, one may feel lost or aimless, lacking motivation and a sense of accomplishment. By setting specific, measurable, achievable, relevant, and time-bound (SMART) goals, individuals can break down their aspirations into manageable steps, making them more attainable and realistic.",
      "Moreover, setting goals helps individuals prioritize their tasks and focus their efforts on what truly matters. With a clear goal in mind, one can allocate their time, resources, and energy efficiently, avoiding distractions and procrastination. Goals create a sense of urgency and drive, pushing individuals to take action and make progress towards their objectives. Whether it's advancing in one's career, improving personal relationships, or pursuing a passion, goals provide a sense of purpose and motivation to keep moving forward.",
      "Additionally, setting goals promotes personal growth and development. Through the process of setting and striving towards goals, individuals challenge themselves, learn new skills, and overcome obstacles. This continuous cycle of improvement fosters resilience, adaptability, and self-confidence. By setting ambitious yet achievable goals, individuals can push beyond their comfort zones, expanding their capabilities and unlocking their full potential.",
      "Furthermore, goals serve as a measure of success and a source of motivation. When individuals set specific and measurable goals, they can track their progress and celebrate their achievements along the way. This sense of accomplishment boosts self-esteem and reinforces positive behavior, encouraging individuals to set even more challenging goals in the future. By setting milestones and celebrating small victories, individuals can stay motivated and engaged in their pursuits.",
      "In conclusion, setting goals is essential for creating a clear vision, staying focused, fostering personal growth, measuring success, and maintaining motivation. Whether short-term or long-term, personal or professional, goals provide a sense of direction and purpose in life. By setting SMART goals and committing to their achievement, individuals can unlock their full potential, overcome challenges, and lead a more fulfilling and purposeful life.",
    ],
  },
  {
    path: "ai-assistant-wizard",
    image: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/tutorials/ai-assistant-wizard.jpg`,
    title: "AI Assistant FAQ",
    content: ["In this short tutorial, we will show you sample user descriptions that will help our assistant generate the best possible offers for you. The below paragraphs will contain sample text",
    "Working as software architect in Bay Area. I would like to create my own small saas and have an additional source of income. Be famous in Open Source community is my ultimate goal",
    "I am 30 years old and have a sedentary lifestyle. I would like to get in better shape, have better test results and be happier",
    "I am overweight and 40 years old, I work a lot in front of the computer but I would like to be in better shape to play football with my sons and be healthier.",
    "I am 35 years old and single. I have traveled all over Europe and both Americas and I would like to start exploring another continent",
    "I am 45 years old and have three children. I work a lot but I would like to make up for lost time with my children. I have two sons in primary school and a daughter in college"
    ],
  },
];
