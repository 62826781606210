import { Box, Button, Skeleton, TextField } from "@mui/material";
import { Photo } from "../api/UnsplashApi";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import useUnsplash from "../hooks/useUnsplash";

const PhotoCreditLabel = ({ photo }: { photo: Photo }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#88a2f9",
        opacity: "0.8",
        position: "absolute",
        bottom: 0,
        textAlign: "center",
        fontStyle: "Lato",
        fontWeight: 500,
        color: "#fff",
        flex: 1,
      }}
    >
      by
      <a
        style={{
          color: "#fff",
          textDecoration: "outline",
          paddingLeft: "3px",
        }}
        target="_blank"
        href={`https://unsplash.com/@${photo.user.username}`}
      >
        {photo.user.name}
      </a>
    </Box>
  );
};

const PhotoElement = ({
  photo,
  onSelect,
}: {
  photo: Photo;
  onSelect: (photo: Photo) => void;
}) => {
  const [hoveredImageId, setHoveredImageId] = useState<string | undefined>(
    undefined
  );
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <Box
      sx={{
        position: "relative",
        background: "#f1efef",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "300px",
      }}
      key={photo.id}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: "300px",
          maxWidth: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          transition: "0.5s ease all",
          opacity: loaded ? 0 : 1,
          zIndex: loaded ? -100 : 100
        }}
      >
        <Skeleton width={200} height={200} />
      </Box>
      <Box
        component={"img"}
        src={photo.urls.regular}
        alt={photo.user.name}
        // loading="lazy"
        onLoad={() => setLoaded(true)}
        sx={{
          maxHeight: "300px",
          maxWidth: "calc(100%)",
          transition: "0.5s all ease",
          cursor: "pointer",
          "&:hover": {
            opacity: "0.5",
          },
        }}
        onClick={() => onSelect(photo)}
        onMouseEnter={() => setHoveredImageId(photo.id)}
        onMouseLeave={(e) => {
          // @ts-ignore
          if (e.relatedTarget && e.relatedTarget.id === `select-${photo.id}`) {
            return;
          }
          setHoveredImageId(undefined);
        }}
      />
      {hoveredImageId === photo.id ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <Button
            id={`select-${photo.id}`}
            variant="contained"
            onClick={() => {
              onSelect(photo);
            }}
          >
            Select
          </Button>
        </Box>
      ) : null}
      <PhotoCreditLabel photo={photo} />
    </Box>
  );
};

export const UnsplashSelectComponent = ({
  selectedPhoto,
  columns = 1,
  onSelect,
  orientation,
}: {
  selectedPhoto?: Photo;
  columns?: number;
  onSelect: (photo: Photo) => void;
  orientation?: "landscape" | "portrait";
}) => {
  const timeoutRef = useRef<any>(null);
  const [query, setQuery] = useState<string>("");
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(1);
  const [hoveredImageId, setHoveredImageId] = useState<string | undefined>(
    undefined
  );
  const [selectedImage, setSelectedImage] = useState<Photo | undefined>(
    selectedPhoto
  );

  useEffect(() => {
    if (selectedPhoto) {
      setSelectedImage(selectedPhoto);
    }
  }, [selectedPhoto]);

  const mapPhotos = useCallback((result: any[]): Photo[] => {
    return result.map(
      (r) =>
        ({
          id: r.id,
          urls: {
            large: r.urls.large,
            regular: r.urls.regular,
            small: r.urls.small,
            full: r.urls.full,
          },
          user: {
            name: r.user.name,
            username: r.user.username,
          },
        } as Photo)
    );
  }, []);

  const { callUnsplash } = useUnsplash();

  const queryImages = async (query: string) => {
    if (query.length) {
      const result = await callUnsplash(query, {
        page: pageNumber,
        orientation,
      });
      if (result.status === 200) {
        // @ts-ignore
        if (photos.length && pageNumber > 1) {
          // @ts-ignore
          setPhotos([...photos, ...mapPhotos(result.response?.results)]);
        } else {
          // @ts-ignore
          setPhotos(mapPhotos(result.response?.results));
        }
        setTotalPageCount(result.response?.total_pages || 1);
      }
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    queryImages(query);
  }, [pageNumber]);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newQueryValue = e.currentTarget.value;
    setQuery(newQueryValue);
    setPageNumber(1);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      await queryImages(newQueryValue);
    }, 500);
  };

  return (
    <Box>
      {!selectedImage ? (
        <Box>
          <TextField
            placeholder="Start typing for start searching..."
            sx={{ width: "100%", mb: "15px" }}
            inputProps={{
              style: {
                fontSize: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
            value={query}
            onChange={handleQueryChange}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              height: "300px",
              overflowY: "scroll",
            }}
          >
            {photos.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  pt: "20px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No results
              </Box>
            ) : null}
            {photos.length ? (
              <Box
                sx={{
                  display: "grid",
                  gap: "10px",
                  width: "100%",
                  gridTemplateColumns: `${Array.from(Array(columns))
                    .map(() => "1fr")
                    .join(" ")}`,
                }}
              >
                {photos.map((photo) => (
                  <PhotoElement
                    key={`photo-${photo.id}`}
                    photo={photo}
                    onSelect={(photo) => {
                      setSelectedImage(photo);
                      onSelect(photo);
                    }}
                  />
                ))}
              </Box>
            ) : null}
            {totalPageCount > pageNumber ? (
              <Box
                sx={{
                  width: "100%",
                  pt: "20px",
                  pb: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{}}
                  onClick={() => {
                    if (totalPageCount > pageNumber) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                  variant="text"
                >
                  Load more
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : null}
      {selectedImage ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            src={selectedImage.urls.regular}
            alt={selectedImage.user.name}
            loading="lazy"
            sx={{
              // width: "calc(100%)",
              maxHeight: "300px",
              transition: "0.5s all ease",
              "&:hover": {
                opacity: "0.5",
              },
            }}
            onMouseEnter={() => setHoveredImageId(selectedImage.id)}
            onMouseLeave={(e) => {
              // @ts-ignore
              if (
                e.relatedTarget &&
                // @ts-ignore
                e.relatedTarget.id === `select-${selectedImage.id}`
              ) {
                return;
              }
              setHoveredImageId(undefined);
            }}
          />
          {hoveredImageId === selectedImage.id ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Button
                id={`select-${selectedImage.id}`}
                variant="contained"
                onClick={() => setSelectedImage(undefined)}
              >
                Remove image
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};
