import { Api } from "./Api";

export interface NewsletterResponse {
    result: boolean
  }

class NewsletterApi extends Api {
    signUp() {
        return this.post(`newsletter`, {});
    }
    hasSignedUp() {
        return this.get(`newsletter/status`);
    }
}

export const newsletterApi = new NewsletterApi();