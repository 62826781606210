import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export type BorderStyle = "normal" | "dashed" | "dotted" | "double" | "outset" | "special-dashed-1"


const resources = {
  en: {
    translation: {
      "label.style.NONE": "None",
      "label.style.LABEL1": "Style 1",
      "label.style.WAVE": "Underline wave",
      "label.style.ANIMATED1": "Animated",
      "label.style.ANIMATED_GRADIENT": "Animated gradient",
      "label.style.TEXT_SHADOW": "Text shadow",
      "label.style.GRADIENT_UNDERLINE": "Gradient underline",
      "border.style.normal": "Normal",
      "border.style.dashed": "Dashed",
      "border.style.dotted": "Dotted",
      "border.style.double": "Double",
      "border.style.outset": "Outset",
      "border.style.special-dashed-1": "Styled Dashed 1",
      "border.style.special-dotted-1": "Styled Dotted 1",
      "border.style.special-normal-1": "Styled 1",
      "border.style.animated-rainbow-1": "Animated Rainbow 1",
      "border.style.animated-rainbow-2": "Animated Rainbow 2",
      "animation.CONNECT": "Connect",
      "animation.NONE": "None",
      "animation.SNOW": "Snow",
      "animation.BLACK_HOLE": "Black hole",
      "animation.STARS": "Stars",
      "animation.PARALLAX": "Parallax",
      "animation.HEAVY_SNOW": "Heavy snow",

      "boards.confirmation.delete": "Are you sure you want to delete this board? This operation cannot be undone and this board will be no longer available.",
      "boards.confirmation.archive": "Are you sure we want to archive this board? Archived boards are still persisted but not marked as active ones. Your daily workflows will not process them and you will not receive any notification from this board anymore.",
      "boards.confirmation.unarchive": "Are you sure we want to restore this board? It will be moved again to Draft status so you can start working with it again.",
      "boards.show.archived": "You are about to show archived vision board. Please not that this vision board is not active so any actions done with this board will not affect your daily workflows or routines. Would you still like to see this vision board?",
      "board.filter.status.DRAFT": "Draft",
      "board.filter.status.READY": "Ready",
      "board.filter.status.ARCHIVED": "Archived",
      "board.filter.status.ALL": "All",
      "board.filter.status.ACTIVE": "Active",
      "board.filter.status.COMPLETED": "Completed",
      "days": "Days",
      "day.MONDAY": "Monday",
      "day.TUESDAY": "Tuesday",
      "day.WEDNESDAY": "Wednesday",
      "day.THURSDAY": "Thursday",
      "day.FRIDAY": "Friday",
      "day.SATURDAY": "Saturday",
      "day.SUNDAY": "Sunday",
      "EVERYDAY": "Everyday", 
      "WEEKDAYS": "Weekdays",
      "WEEKEND": "Weekend",
      "CUSTOM": "Custom",

      "settings.account": "Account",
      "settings.account.delete": "Delete account",
      "settings.notifications": "Notifications",
      "settings.notifications.types.label": "Notification types",
      "settings.notifications.time.label": "Reminder time",
      "settings.notifications.WEB.enabled": "Web PUSH notifications",
      "settings.notifications.EMAIL.enabled": "E-mail notifications",
      "settings.notifications.visualisation.label": "Visualisations",
      "settings.notifications.visualisation.enabled": "Reminder enabled",
      "settings.notifications.updates.label": "Updates",
      "settings.notifications.updates.releases": "Product releases",
      "settings.subscription": "Subscription",
      "settings.changelog": "Changelog",

      "save": "Save",

      "dashboard": "Dashboard",
      "settings": "Settings",
      "boards": "Boards",
      "backlog": "Backlog",
      "tutorials": "Tutorials",
      "community": "Community",
      "journal": "Journal",
      "workflows": "Workflows",
      "subscriptions_success": "Subscription confirmation",
      "subscriptions_cancel": "Subscription checkout cancelled",
      "screensaver": "Screen Saver",

      "trial.ended": "Your Free Trial access has ended. In order to continue using this application please subscribe with one of the below options",
      "trial.message": "Free Trial access. Remaining days {{remainingDays}}",
      "subscription.renewal": "In order to renew your subscription please select one of available plans",

      "visualization.settings.with-audio": "With audio",
      "visualization.settings.with-loop": "Infinite loop",
      "visualization.settings.make-default": "Make default visualization",
      "visualization.BOARD_BACKGROUND": "Board background",
      "visualization.ZOOM_IN_ZOOM_OUT": "Zoom in - Zoom out",
      "visualization.CELLS": "Cells",
      "visualization.CLOUDS": "Clouds",
      "visualization.HALO": "Halo",
      "visualization": "Visualization",
      "ai.action.GENERATE_GOALS": "Propose goals for your Vision Board",
      "ai.action.GENERATE_GOAL": "Propose additional goal",
      "ai.action.GENERATE_GOALS_AND_STATEMENT": "Create Vision Board for you",
      "ai.action.user-input.GENERATE_GOALS_AND_STATEMENT": "Create Vision Board",
      "ai.action.GENERATE_STATEMENT": "Propose a Vision Board statement",

      "ai.action.generated.GENERATE_GOALS_AND_STATEMENT": "Thank you for waiting. Here is an example Vision Board I proposed for you. You can select all goals or only those that suit you and work on their final version, like changing photo or description, in the next steps of the editor. If you would like me to try to regenerate them, click the button at the bottom.",
      "ai.action.generated.GENERATE_GOALS": "Thank you for waiting. Here are some additional goals generated for your Vision Board. You can select all goals or only those that suit you",
      "ai.action.generated.GENERATE_GOAL": "Thank you for waiting. Here is an additional goal proposed for your Vision Board.",
      "ai.action.generated.GENERATE_STATEMENT": "Thank you for waiting. Here is an example Vision Board statement generated for you",

      "ai.action.success.GENERATE_GOALS_AND_STATEMENT": "I have created a draft version of your Vision Board. Please advance with the next steps in wizard and create a beautiful representation of your board.",
      "ai.action.success.GENERATE_GOALS": "I have added new goals to your Vision Board. You can still edit them or keep adding a new ones. If you are good with the current goals you can move forward to build visual representation of your board.",
      "ai.action.success.GENERATE_GOAL": "I have added new goal to your Vision Board. You can still edit it to make it as perfect as possible for you",
      "ai.action.success.GENERATE_STATEMENT": "I have added new statement to your Vision Board. You can still edit it to make it as perfect as possible for you",
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", 
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;