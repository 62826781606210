import { Placement } from "react-joyride";

export type GUIDE_TYPE = "DASHBOARD" | "BOARDS" | "VISUALIZATIONS" | "SCREENSAVER" | "VISUALIZATOR_SETTINGS" | "GRID_DESIGNER"

export const GUIDES: { [key in GUIDE_TYPE]: { content: string | string[]; target: string; placement?: "center" | "auto" | Placement | undefined }[] } = {
    "DASHBOARD": [
        {
            target: "#menu-dashboard",
            content: "Use this menu to navigate through application"
        },
        {
            target: "#view-boards-btn",
            content: "Click this button to view your Vision Boards"
        },
        {
            target: "#statistics",
            content: "Track your visualization statistics"
        },
        {
            target: "#affirmations-widget",
            content: "Here you can find your daily affirmations"
        }
    ],
    "BOARDS": [
        {
            target: "#filter-alt-icon",
            content: "You can filter Vision Boards here"
        },
        {
            target: "#new-board-card",
            content: "Click here to open wizzard to create new Vision Board"
        },
    ],
    "VISUALIZATIONS": [
        {
            target: "body",
            content: ["Visualization mode is a key component when working with Vision Boards.", "It is not enough to just create a beautiful Vision Board, but you need to check it regularly.", "Visualization mode will help with this. Choose a specific Vision Board, sit back and start visualizing!", "", "Just remember. It's important to do it regularly :)"],
            placement: "center"
        },
        {
            target: "#visualization-select",
            content: "Select an active Vision Board"
        }
    ],
    "VISUALIZATOR_SETTINGS": [
        {
            target: "#visualization-type",
            content: "There are different types of visualizations. Please select the one you like the most"
        }
    ],
    "SCREENSAVER": [
        {
            target: "body",
            content: ["Screensaver mode is a unique feature that allows you to turn your browser window into a beautiful screensaver.", "By clicking \"Enter full screen\", the application will enter full screen mode and block the system screen saver.", "Change your system screensaver to your Vision Board!"],
            placement: "center"
        },
        {
            target: "#visualization-select",
            content: "Select an active Vision Board"
        }
    ],
    "GRID_DESIGNER": [
        {
            target: "#new-elements-group",
            content: ["Expand this option to add new elements to your Vision Board", "You can add a Powerful statements, illustrations, stickers and label elements"]
        },
        {
            target: "#customize-board-group",
            content: ["Expand this option to customize your Vision Board", "You can shuffle your board layout, configure borders, set background and make it animated one.", "Finally you can enable elements overlapping to make your Vision Board even more dynamic"]
        },
        {
            target: "#preview-group",
            content: ["Click here to see your Vision Board preview"]
        },
        {
            target: "body",
            content: ["If you need a break, don't worry. Your session will be remembered and you can continue as soon as you clear your head"],
            placement: "center"
        },
    ]
}