import { MessagePayload, onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";
import { NotificationPayloadProps, messaging, getFirebaseToken } from "./init";
import { Alert, Box, Typography, useMediaQuery } from "@mui/material";
import { notificationApi } from "../api/NotificationApi";
import useFetch from "../hooks/useFetch";
import { isiPhone } from "../utils/mobile";
import { CustomModal } from "../components/CustomModal";
import { isPwaApplication } from "../utils/pwa";

/**
 * https://stackoverflow.com/questions/46690124/fcm-push-notifications-arrive-twice-if-the-browser-is-in-background
 * @returns
 */

export const NotificationContainer = () => {
  const [notificationPayload, setNotificationPayload] = useState<
    (NotificationPayloadProps | undefined)[]
  >([]);
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);

  const { callApi } = useFetch();

  const onMessageListener = (async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        setNotificationPayload([{ data: payload, open: true }]);
        setTimeout(() => setNotificationPayload([{ open: false }]), 6000);
      });
    }
  })();

  // FIXME
  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken: string | undefined) => {
        if (firebaseToken) {
          callApi(notificationApi.register(firebaseToken))
            .then(() => setPermissionGranted(true))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // TODO - check it with PWA on iOS, iPadOS, probably needs to be changes to requestPermission() API
    if (
      "Notification" in window &&
      window.Notification?.permission === "granted"
    ) {
      handleGetFirebaseToken();
    }
  }, []);

  const isMobile = useMediaQuery("(max-width:630px)");
  const iPhoneNotPwa = isiPhone() && !isPwaApplication();
  const text =
    iPhoneNotPwa
      ? "As we are still working on mobile application, we recommend adding our application to Home Screen in order to enable notifications. Please click this link to get more information"
      : `Our application works best with enabled browser notifications. In order to experience full power of visualisations please${
          isMobile ? " click enable button" : ""
        }`;
  const shouldShowAskForNotification =
    "Notification" in window &&
    Notification.permission !== "granted" &&
    !permissionGranted &&
    !closed;

  return (
    <Box
      sx={{ position: "fixed", left: 0, width: "100vw", top: 0, zIndex: 2000 }}
    >
      {shouldShowAskForNotification ? (
        isMobile ? (
          <Box sx={{ position: "absolute", top: 0, left: 0 }}>
            <CustomModal
              open={true}
              okLabel={iPhoneNotPwa ? undefined : "Enable"}
              onOk={iPhoneNotPwa ? () => setClosed(true) : handleGetFirebaseToken}
              onCancel={iPhoneNotPwa ? undefined : () => setClosed(true)}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>{text}</Typography>
                {isiPhone() && !isPwaApplication() ? (
                  <a
                    target="_blank"
                    style={{ marginTop: "5px" }}
                    href="https://support.apple.com/guide/iphone/bookmark-favorite-webpages-iph42ab2f3a7/ios"
                  >
                    Apple tutorial
                  </a>
                ) : null}
              </Box>
            </CustomModal>
          </Box>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              p: "10px",
              color: "#88a2f9",
              backgroundColor: "#fff",
              border: "1px solid #88a2f9",
              width: "100vw",
              top: 0,
            }}
          >
            <span style={{ marginRight: "5px" }}>{text}</span>
            <a
              href="#"
              className="notification-banner-link"
              onClick={handleGetFirebaseToken}
            >
              enable push notifications.
            </a>
          </Box>
        )
      ) : null}
      {notificationPayload.map((notification, index) => {
        return (
          <Box key={`notification${index}`}>
            {notification?.open && (
              <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
                <Alert severity="info" sx={{ width: "250px" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box>{notification?.data?.notification?.title}</Box>
                    <Box>{notification?.data?.notification?.body}</Box>
                  </Box>
                </Alert>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
