import { Api } from "./Api";
import { Photo } from "./UnsplashApi";

export enum AIAssistantAction {
  GENERATE_GOALS = "GENERATE_GOALS",
  GENERATE_GOAL = "GENERATE_GOAL",
  GENERATE_GOALS_AND_STATEMENT = "GENERATE_GOALS_AND_STATEMENT",
  GENERATE_STATEMENT = "GENERATE_STATEMENT"
}

export interface GenerateParams {
  action: AIAssistantAction;
  boardName?: string;
  userDescription?: string;
  goals?: string[];
}

export interface GeneratedGoalResponse {
  photo: Photo;
  goal: string;
  description: string;
}

export interface AIGenerationResponse {
  action: AIAssistantAction;
  goals: GeneratedGoalResponse[];
  statement?: string;
}

class AIAssistantApi extends Api {
  generate(params: GenerateParams) {
    return this.post("assistant/generate", params);
  }
}

export const aiAssistantApi = new AIAssistantApi();
