import { Box, Stepper, StepLabel, Button, Step, useMediaQuery } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

export const WizardSideMenu = ({
  steps,
  activeStep,
  handleStep,
  handleNextStep,
  handlePreviousStep,
  nextStepAvailable,
  loadingNextStep
}: {
  steps: string[];
  activeStep: number;
  handleStep: (step: number) => () => void;
  handlePreviousStep: () => void;
  handleNextStep: () => Promise<void>;
  nextStepAvailable: boolean;
  loadingNextStep?: boolean;
}) => {
  const isWideScreen = useMediaQuery("(min-width:1600px)");

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        width: isWideScreen ? "350px" : "250px",
        justifyContent: "space-evenly",
        alignItems: "center",
        gap: "50px",
        backgroundColor: "#f5f5f7",
        position: "absolute",
      }}
    >
      <Stepper
        orientation="vertical"
        activeStep={activeStep}
        sx={{ p: "20px", width: isWideScreen ? "350px" : "250px" }}
      >
        {steps.map((label, index) => (
          <Step
            sx={{
              "& .MuiStepLabel-label": {
                fontSize: isWideScreen ? "20px" : "14px"
              },
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#6c9468", // circle color (COMPLETED)
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "#243040", // Just text label (COMPLETED)
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: "#243040", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-root .Mui-disabled": {
                color: "#9d9fa1 !important", // circle color (ACTIVE)
                ".MuiSvgIcon-root.MuiStepIcon-root": {
                  color: "#cdcdce !important", // circle color (ACTIVE)
                },
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "#243040", // Just text label (ACTIVE)
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "white", // circle's number (ACTIVE)
              },
            }}
            key={label}
          >
            <StepLabel onClick={handleStep(index)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        {activeStep > 0 ? (
          <Button
            sx={{ color: "#243040", height: "40px", width: isWideScreen ? "150px" : "100px", fontSize: isWideScreen ? "22px" : undefined }}
            onClick={handlePreviousStep}
            variant={"text"}
            startIcon={<NavigateBeforeIcon />}
          >
            Previous
          </Button>
        ) : (
          <Box sx={{ width: isWideScreen ? "150px" : "100px" }}></Box>
        )}
        {nextStepAvailable ? (
          <Button
            disabled={loadingNextStep}
            sx={{ color: "#243040", height: "40px", width: isWideScreen ? "150px" : "100px", fontSize: isWideScreen ? "22px" : undefined }}
            onClick={handleNextStep}
            variant={"text"}
            endIcon={<NavigateNextIcon />}
          >
            Next
          </Button>
        ) : (
          <Box sx={{ width: isWideScreen ? "150px" : "100px" }}></Box>
        )}
      </Box>
    </Box>
  );
};
