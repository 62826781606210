export interface GalleryConfig {
  baseUrl: string;
  stickersUrl: string;
  textBackgroundsUrl: string;
  wallpapersUrl: string;
  illustrationsUrl: string;
}

export interface GalleryItem {
  image: any;
  credits: string;
  tags: string[];
}

export enum GalleryType {
  STICKERS = "STICKERS",
  ILLUSTRATIONS = "ILLUSTRATIONS",
  TEXT_BACKGROUNDS = "TEXT_BACKGROUNDS",
  WALLPAPERS = "WALLPAPERS",
}

let config: GalleryConfig | null = null;
const stickers: GalleryItem[] = [];
const illustrations: GalleryItem[] = [];
const textBackground: GalleryItem[] = [];
const wallpapers: GalleryItem[] = [];

/**
 * Local serve command from assets/gallery/json `serve -l 3500 --cors -s .`
 * @returns
 */
export const initGallery = async (): Promise<void> => {
  try {
    const result = await fetch(`${process.env.REACT_APP_GALLERY_CONFIG}`, {
      method: "GET",
    });
    if (result.ok) {
      config = await result.json();
      if (config) {
        const stickersResponse = await fetchGalleryItems(
          `${config.baseUrl}/${config.stickersUrl}`
        );
        stickers.push(
          ...stickersResponse.map((sticker) => ({
            ...sticker,
            image: `${config?.baseUrl}/${sticker.image}`,
          }))
        );
        const textBackgroundsResponse = await fetchGalleryItems(
          `${config.baseUrl}/${config.textBackgroundsUrl}`
        );
        textBackground.push(
          ...textBackgroundsResponse.map((background) => ({
            ...background,
            image: `${config?.baseUrl}/${background.image}`,
          }))
        );
        const wallpapersResponse = await fetchGalleryItems(
          `${config.baseUrl}/${config.wallpapersUrl}`
        );
        wallpapers.push(
          ...wallpapersResponse.map((wallpaper) => ({
            ...wallpaper,
            image: `${config?.baseUrl}/${wallpaper.image}`,
          }))
        );
        const illustrationsResponse = await fetchGalleryItems(
          `${config.baseUrl}/${config.illustrationsUrl}`
        );
        illustrations.push(
          ...illustrationsResponse.map((illustration) => ({
            ...illustration,
            image: `${config?.baseUrl}/${illustration.image}`,
          }))
        );
      }
    } else {
      config = null;
    }
  } catch (e) {
    //
  }
};

const fetchGalleryItems = async (url: string): Promise<GalleryItem[]> => {
  try {
    const result = await fetch(url, {
      method: "GET",
    });
    if (result.ok) {
      return result.json();
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getIllustrationsGallery = () => illustrations;
export const getStickersGallery = () => stickers;
export const getWallpapersGallery = () => wallpapers;
export const getTextBackgroundsGallery = () => textBackground;