import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { LogoText } from "./components/LogoText";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  const isSmallDevice = useMediaQuery("(max-width:400px)");

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        backgroundColor: "#243040"
      }}
    >
      <LogoText small={isSmallDevice} />
      <Typography sx={{ fontSize: "18px", color: "#ffe" }}>
        Requested page does not exist ;-(
      </Typography>
      <Button
        component={Link}
        to={"/"}
        sx={{ width: "250px" }}
        variant="outlined"
      >
        Go to main page
      </Button>
    </Box>
  );
};
