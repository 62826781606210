import { Box, Typography, useMediaQuery } from "@mui/material"
import wizardWelcome from "../../../assets/wizzard-welcome.png";
import { withFadeIn } from "../../../hoc/withFadeIn";
import { useEffect } from "react";
import { getWizardState, WizardStep } from "../state/wizzardState";
import { observer } from "mobx-react-lite";

export const WelcomeStep = observer(withFadeIn(() => {

    const mobileMenu = useMediaQuery("(max-width:800px)");
    const isWideScreen = useMediaQuery("(min-width:1600px)");    
    const isUltraWideScreen = useMediaQuery("(min-width:2000px)");

    const state = getWizardState();
    useEffect(() => {
        state.step = WizardStep.WELCOME;
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: mobileMenu ? "center" : undefined, fontFamily: "Lato, sans-serif", width: "100%", flex: 1, height: mobileMenu ? "100%" : undefined }}>
            <Box component={"img"} src={wizardWelcome} sx={{ maxWidth: isWideScreen ? "550px" : isUltraWideScreen ? "700px" : "400px", width: "80%", pb: isWideScreen ? "40px" : "20px" }} />
            <Typography sx={{ fontFamily: "Lato, sans-serif", fontSize: mobileMenu ? "22px" : isWideScreen ? "30px" : "24px", pb: mobileMenu ? "20px" : undefined, textAlign: "center" }}>Welcome in your Vision Board creator</Typography>
            <Typography sx={{ fontFamily: "Lato, sans-serif", fontSize: mobileMenu ? "18px" : isWideScreen ? "30px" : "24px", textAlign: "center" }}>Vision board is a collage of images that you use to visualize your goals</Typography>
        </Box>
    )
}, {
    fullWidth: true,
    center: true,
    overflowY: true
}));
