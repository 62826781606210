export const FONTS = [
  "Lato",
  "Snidely",
  "HipHoray",
  "ArshineUnfocus",
  "DimsumWeek",
  "Nicolast",
  "Ohyou",
  "GingerCat",
  "Dinofiles",
  "SnackBox",
  "QuickyStory",
  "DodolSabang",
  "RusticStory",
  "DinoCare",
  "Porcinilead",
  "MangabeyRegular",
  "Grillin"
];
