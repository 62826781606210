export const getValue = (key: string, userId: string): string | null => {
    try {
        return localStorage.getItem(`${key}|${userId}`);
    } catch (e) {
        return null;
    }
}

export const setValue = (key: string, userId: string, value: string): void => {
    localStorage.setItem(`${key}|${userId}`, value);
}