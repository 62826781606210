import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { GoalGalleryAttachment } from "../../model/goal";
import { useEffect, useState } from "react";
import { attachmentApi } from "../../api/AttachmentApi";
import { Loader } from "../../components/Loader";
import { UploadImage } from "../../components/UploadImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { YesNoModal } from "../../components/YesNoModal";
import Lightbox from "react-18-image-lightbox";
import useFetch from "../../hooks/useFetch";

const AttachmentGalleryItem = ({
  attachment,
  refreshListCallback,
  onClick,
}: {
  attachment: GoalGalleryAttachment;
  refreshListCallback: () => Promise<void>;
  onClick: () => void;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const { loading, callApi } = useFetch();
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const mobileMenu = useMediaQuery("(max-width:760px)");

  return (
    <ImageListItem
      sx={{
        cursor: "pointer",
        ...(mobileMenu
          ? { justifyContent: "center", alignItems: "center", gap: "10px" }
          : {}),
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        sx={{
          opacity: hovered ? 1 : 0,
          transition: "0.3s all ease",
          position: "absolute",
          display: "flex",
          transform: "translate(128px, 0px)",
          backgroundColor: "#ecf1ff",
          zIndex: 10,
        }}
      >
        <Tooltip title="Delete" arrow placement="top">
          <IconButton onClick={() => setConfirmationModal(true)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" width={164} height={164} />
      ) : (
        <Box
          component={"img"}
          sx={{ width: "164px", height: "164px", opacity: hovered ? 0.8 : 1 }}
          src={attachment.url}
          alt={attachment.name}
          loading="lazy"
          onClick={onClick}
        />
      )}
      {confirmationModal ? (
        <YesNoModal
          label={"Are you sure you want to delete this attachment?"}
          open={true}
          onNo={() => setConfirmationModal(false)}
          onYes={async () => {
            try {
              setHovered(false);
              await callApi(attachmentApi.deleteAttachment(attachment.id));
              await refreshListCallback();
            } catch (e) {
              console.log("TODO handle error");
            }
          }}
        />
      ) : null}
    </ImageListItem>
  );
};

const AttachmentGallery = ({
  attachments,
  refreshListCallback,
}: {
  attachments: GoalGalleryAttachment[];
  refreshListCallback: () => Promise<void>;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [images] = useState<string[]>(
    attachments.map((attachment) => attachment.url)
  );
  const mobileMenu = useMediaQuery("(max-width:760px)");

  return (
    <Box>
      {isOpen && (
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            setIndex((index + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setIndex((index + images.length + 1) % images.length);
          }}
        />
      )}
      <ImageList
        sx={{ width: mobileMenu ? "100%" : 500, minHeight: 170, maxHeight: 450, gap: mobileMenu ? "10px !important" : "4px" }}
        cols={mobileMenu ? 1 : 3}
        rowHeight={164}
      >
        {attachments.map((attachment, index) => (
          <AttachmentGalleryItem
            onClick={() => {
              setIndex(index);
              setIsOpen(true);
            }}
            key={`attachment-${attachment.id}`}
            attachment={attachment}
            refreshListCallback={async () => {
              await refreshListCallback();
              setIsOpen(false);
            }}
          />
        ))}
      </ImageList>
    </Box>
  );
};

export const GoalGallery = ({
  boardId,
  goalId,
}: {
  boardId: string;
  goalId: string;
}) => {
  const [attachments, setAttachments] = useState<GoalGalleryAttachment[]>([]);
  const [firstImage, addFirstImage] = useState<boolean>(false);
  const { callApi } = useFetch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const queryGallery = async () => {
      setLoading(false);
      const results: GoalGalleryAttachment[] = await callApi(
        attachmentApi.getGoalGallery(boardId, goalId)
      );
      setAttachments(results);
    };
    if (loading) {
      queryGallery();
    }
  }, [loading]);

  if (loading) {
    return <Loader />;
  } else if (firstImage) {
    // TODO - add blocking buttons
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <UploadImage
          uploadCompleted={() => {
            addFirstImage(false);
            setLoading(true);
          }}
          goalId={goalId}
        />
        <Button
          sx={{ mt: "15px" }}
          onClick={() => {
            addFirstImage(false);
            setLoading(true);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </Box>
    );
  }

  return attachments.length ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AttachmentGallery
        attachments={attachments}
        refreshListCallback={async () => setLoading(true)}
      />
      <Button
        sx={{ width: "150px" }}
        onClick={() => addFirstImage(true)}
        variant="outlined"
      >
        Add image
      </Button>
    </Box>
  ) : (
    <Box
      sx={{
        fontFamily: "Lato, sans-serif",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 300,
        fontStyle: "italic",
        height: "100%",
      }}
    >
      Your goal gallery is empty. Goal gallery can help you better documenting
      the journey to accomplish your goal. Click button below to add your first
      image!
      <Button
        sx={{ width: "150px" }}
        onClick={() => addFirstImage(true)}
        variant="outlined"
      >
        Add image
      </Button>
    </Box>
  );
};
