import { useAuth } from "@clerk/clerk-react";
import { createApi } from "unsplash-js";

const serverApi = createApi({
  apiUrl: process.env.REACT_APP_API_ENDPOINT as string,
});

const PAGE_SIZE = 10;

export type CallUnsplash = (query: string,
  params?: {
    page?: number;
    orientation?: "landscape" | "portrait";
  }) => Promise<any>

export default function useUnsplash(): { callUnsplash: CallUnsplash } {
  const { getToken } = useAuth();

  const authenticatedFetch = async (query: string,
    params?: {
      page?: number;
      orientation?: "landscape" | "portrait";
    }) => {
    return serverApi.search.getPhotos(
        {
          query,
          page: params && params.page ? params.page : 1,
          perPage: PAGE_SIZE,
          orientation:
            params && params.orientation ? params.orientation : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      );
  };

  return { callUnsplash: authenticatedFetch };
}
