import { ChangeEvent } from "react";

export const getInputValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, minValue: number, maxValue: number) => {
    const val = parseInt(e.currentTarget.value);
    if (val < minValue) {
        return minValue;
    } else if (val > maxValue) {
        return maxValue;
    } else {
        return val;
    }
}