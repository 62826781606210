import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "./CustomSelect";
import { RepeatType, DAYS, NotificationSettingsDto } from "../api/NotificationApi";
import { convertNotificationUtcTimeToDate, getUtcNotificationTime } from "../utils/dates";

interface NotificationSelectionProps {
    settings: NotificationSettingsDto;
    onChange: (settings: NotificationSettingsDto) => void;
}

export const NotificationSelection = ({ settings, onChange }: NotificationSelectionProps) => {

    const { t } = useTranslation();

    const [ampm, setAmpm] = useState<boolean>(true);

    const handleChange = (event: SelectChangeEvent<typeof DAYS>) => {
        const target = event.target
        if (typeof target.value === "string") {
            onChange({ ...settings, repeatCustom: target.value.split(",")});
        } else {
            onChange({ ...settings, repeatCustom: target.value});
        }
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <TimePicker label={t("settings.notifications.time.label")} sx={{ width: "200px" }} ampm={ampm} value={convertNotificationUtcTimeToDate(settings.executionTime)} onChange={(val) => {
                    if (val) {
                        const executionTime = getUtcNotificationTime(val);
                        onChange({
                            ...settings,
                            executionTime
                        })
                    }
                }} />
                <FormControlLabel control={<Checkbox checked={!ampm} onChange={() => setAmpm(!ampm)} />} label="24 hours" />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <CustomSelect
                    label="Repeat"
                    selectSx={{ width: "200px", fontFamily: "Lato, sans-serif", }}
                    value={settings.repeatType}
                    onChange={(value) => onChange({
                        ...settings,
                        repeatType: value as RepeatType,
                    })}
                    options={Object.keys(RepeatType).map((repeat) => ({
                        label: t(repeat),
                        value: repeat
                    }))} />
                {settings.repeatType === RepeatType.CUSTOM ?
                // TODO Move to CustomMultiSelect
                <FormControl sx={{ flex: 1 }}>
                    <InputLabel>{t("days")}</InputLabel>
                    <Select
                        multiple
                        label={t("days")}
                        value={settings.repeatCustom || []}
                        onChange={handleChange}
                        renderValue={(selected: string[]) => selected.map((day) => t(`day.${day}`)).join(', ')}
                    >
                        {DAYS.map((day) => (
                            <MenuItem key={day} value={day}>
                                <Checkbox checked={settings.repeatCustom ? settings.repeatCustom.includes(day) : false} />
                                <ListItemText primary={t(`day.${day}`)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                 : null}
            </Box>
        </Box>
    )
}