import { GUIDE_TYPE } from "../model/guides";
import { Api } from "./Api";

class GuideApi extends Api {
    record(type: GUIDE_TYPE) {
        return this.post(`guides/${type}`, {});
    }
    getAll() {
        return this.get(`guides`);
    }
}

export const guideApi = new GuideApi();