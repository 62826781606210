import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../state/RootStore";
import { observer } from "mobx-react-lite";

export const NavigationTracker = observer(({ children }: PropsWithChildren) => {

    const location = useLocation();
    const store = useStore();

    useEffect(() => {
        if (location.pathname !== store.selectedPage) {
            store.selectedPage = location.pathname;
        }
    }, [location.pathname, store]);

    return (<>{children}</>)

});