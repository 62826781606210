import { Goal } from "./goal";
import { Background, GridBoard } from "../pages/wizzard/layouts/grid/model";

export enum BoardStatus {
    DRAFT = "DRAFT",
    READY = "READY",
    ARCHIVED = "ARCHIVED",
    COMPLETED = "COMPLETED",
}

export enum BoardType {
    GRID = "GRID",
    FULL = "FULL",
}

export interface Board {
    id: string;
    name: string;
    statement: string;
    timestamp: number;
    goals: Goal[];
    status: BoardStatus;
    // type: BoardType;
    model: any; //vison board elements, positions, etc
    background?: Background
}

export interface BoardModel {
    type: "grid" | "full"
    model: GridBoard | "TODO"
}