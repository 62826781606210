import { DEFINITIONS, LayoutDefinition, LayoutItem } from "./model";

interface TemplateItem {
    x: number;
    y: number;
    w: number;
    h: number;
    name: boolean;
}

/**
 * TODO - post-MVP add templates for every LayoutDefinition, it would be more accurate
 * 3 - LG
 * 2 - MD
 */
const PREDEFINED_TEMPLATES: {
    [key: number]: {
        [columns: number]: TemplateItem[][]
    }
} = {
    "3": {
        "2": [
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": true }],
            [{ "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 5, "name": true }],
            [{ "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": true }],
            [{ "x": 5, "y": 2, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 1, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 5, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 12, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 3, "w": 8, "name": false }, { "x": 4, "y": 3, "h": 1, "w": 8, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 3, "w": 7, "name": false }, { "x": 5, "y": 3, "h": 1, "w": 7, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 3, "w": 7, "name": false }, { "x": 5, "y": 0, "h": 1, "w": 7, "name": true }]
        ]
    },
    "4": {
        "2": [
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 3, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 4, "name": true }],
            [{ "x": 0, "y": 3, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 4, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 3, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 3, "w": 3, "name": false }, { "x": 6, "y": 2, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 12, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 8, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 8, "name": true }]
        ]
    },
    "5": {
        "2": [
            [{ "x": 0, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 4, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 2, "w": 5, "name": true }],
            [{ "x": 0, "y": 2, "h": 3, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 5, "name": true }],
            [{ "x": 0, "y": 2, "h": 3, "w": 5, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 5, "y": 2, "h": 1, "w": 5, "name": false }, { "x": 6, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 6, "name": true }],
            [{ "x": 0, "y": 1, "h": 3, "w": 5, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 5, "y": 2, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 6, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 5, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 6, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 2, "h": 3, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 8, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 6, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 2, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 6, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 2, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 3, "w": 6, "name": false }, { "x": 6, "y": 2, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 6, "name": true }, { "x": 0, "y": 1, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 3, "w": 6, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }]
        ]
    },
    "6": {
        "2": [
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 6, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 1, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 5, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 2, "h": 3, "w": 6, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 6, "name": false }, { "x": 8, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 2, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 7, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 4, "h": 3, "w": 6, "name": false }, { "x": 6, "y": 4, "h": 3, "w": 6, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 9, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 3, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 5, "name": true }]
        ]
    },
    "7": {
        "2": [
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }, { "x": 0, "y": 7, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 7, "h": 2, "w": 5, "name": false }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 7, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 6, "name": true }, { "x": 0, "y": 3, "h": 3, "w": 6, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 4, "name": false }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 7, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 6, "y": 2, "h": 3, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 6, "name": true }, { "x": 0, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 3, "name": false }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 7, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 6, "name": true }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 3, "name": false }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 7, "name": true }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 3, "name": false }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 8, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 8, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 1, "h": 2, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 8, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 8, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 3, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": true }],
            [{ "x": 8, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 3, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 8, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": true }]
        ]
    },
    "8": {
        "2": [
            [{ "x": 0, "y": 1, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 7, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 7, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 6, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 8, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 6, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 5, "name": true }],
            [{ "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 6, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 6, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 6, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 2, "w": 6, "name": true }],
            [{ "x": 0, "y": 1, "h": 2, "w": 5, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 4, "h": 1, "w": 5, "name": false }, { "x": 5, "y": 5, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 2, "h": 2, "w": 5, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 6, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 4, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 3, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 5, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 9, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 5, "h": 2, "w": 6, "name": false }, { "x": 5, "y": 2, "h": 1, "w": 7, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 5, "h": 2, "w": 6, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 8, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 4, "y": 3, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 3, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 4, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 5, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 8, "name": true }]
        ]
    },
    "9": {
        "2": [
            [{ "x": 0, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 1, "w": 4, "name": false }, { "x": 5, "y": 6, "h": 2, "w": 5, "name": false }, { "x": 7, "y": 3, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 6, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 7, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 5, "y": 7, "h": 2, "w": 5, "name": false }, { "x": 7, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 5, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 7, "h": 2, "w": 5, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 7, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 5, "h": 1, "w": 4, "name": false }, { "x": 7, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 5, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 6, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 3, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 3, "h": 1, "w": 4, "name": false }, { "x": 6, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 4, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 10, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 3, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 3, "h": 3, "w": 3, "name": false }, { "x": 3, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 3, "y": 4, "h": 2, "w": 7, "name": false }, { "x": 3, "y": 1, "h": 1, "w": 7, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 3, "h": 1, "w": 4, "name": false }, { "x": 9, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 6, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 8, "y": 2, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 8, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 1, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 9, "y": 1, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 5, "name": false }, { "x": 4, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 5, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 7, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 5, "y": 1, "h": 2, "w": 7, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 8, "y": 1, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 8, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 4, "name": false }, { "x": 4, "y": 4, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 3, "h": 2, "w": 5, "name": false }, { "x": 7, "y": 2, "h": 1, "w": 5, "name": true }],
            [{ "x": 0, "y": 0, "h": 3, "w": 4, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 8, "y": 0, "h": 1, "w": 4, "name": false }, { "x": 4, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 8, "y": 1, "h": 2, "w": 4, "name": false }, { "x": 0, "y": 3, "h": 3, "w": 4, "name": false }, { "x": 9, "y": 3, "h": 1, "w": 3, "name": false }, { "x": 7, "y": 4, "h": 2, "w": 5, "name": false }, { "x": 4, "y": 3, "h": 1, "w": 5, "name": true }]
        ]
    },
    "10": {
        "2": [
            [{ "x": 0, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 2, "w": 3, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 2, "name": false }, { "x": 2, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 0, "h": 2, "w": 2, "name": false }, { "x": 4, "y": 4, "h": 3, "w": 2, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 2, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 4, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 6, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 5, "h": 2, "w": 2, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 2, "name": false }, { "x": 3, "y": 2, "h": 2, "w": 3, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 5, "h": 2, "w": 3, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 3, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 7, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 9, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 5, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 3, "name": true }],
            [{ "x": 0, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 3, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 3, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 0, "h": 1, "w": 2, "name": false }, { "x": 4, "y": 2, "h": 1, "w": 2, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 2, "y": 3, "h": 1, "w": 2, "name": false }, { "x": 2, "y": 0, "h": 2, "w": 4, "name": true }]
        ],
        "3": [
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 9, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 1, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 6, "y": 3, "h": 1, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 9, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 1, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 2, "w": 5, "name": false }, { "x": 3, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 9, "y": 3, "h": 3, "w": 3, "name": false }, { "x": 8, "y": 0, "h": 2, "w": 4, "name": false }, { "x": 6, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 2, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 4, "h": 2, "w": 6, "name": false }, { "x": 9, "y": 2, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 4, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 1, "w": 6, "name": true }],
            [{ "x": 0, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 3, "y": 1, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 2, "h": 3, "w": 3, "name": false }, { "x": 6, "y": 1, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 0, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 1, "h": 2, "w": 3, "name": false }, { "x": 6, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 9, "y": 1, "h": 1, "w": 3, "name": false }, { "x": 0, "y": 3, "h": 2, "w": 3, "name": false }, { "x": 3, "y": 0, "h": 1, "w": 6, "name": true }]
        ]
    }
}

export const getMatchingDefinition = (): LayoutDefinition => {
    const currentWidth = document.body.clientWidth;
    if (currentWidth > 1200) {
        return DEFINITIONS.filter((def) => def.name === "lg")[0]
    } else {
        return DEFINITIONS.filter((def) => def.name !== 'lg').filter((def) => currentWidth > def.width)[0];
    }
}

export const shuffle = (inputLayout: LayoutItem[]): LayoutItem[] => {
    const goalsCount = inputLayout.filter((l) => l.type === "goal").length;
    const orderChangedLayout = inputLayout.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

    const definition = getMatchingDefinition();
    const columns = definition.columns === 12 ? 3 : 2;
    const templates = PREDEFINED_TEMPLATES[goalsCount][columns]
    const template = templates[generateRandom(0, templates.length - 1)];
    const nameTemplate = template.find((t) => t.name);
    const goalTemplates = template.filter((t) => !t.name);

    const shuffledStaticElementsLayout = orderChangedLayout.filter((item) => item.type === "goal" || item.type === "name").map((item) => {
        if (item.type === "name") {
            if (nameTemplate) {
                return {
                    ...item,
                    layout: {
                        ...item.layout,
                        h: nameTemplate.h,
                        w: nameTemplate.w,
                        x: nameTemplate.x,
                        y: nameTemplate.y,
                    }
                }
            } else {
                return item;
            }
        } else {
            const goalTemplate = goalTemplates.shift();
            if (goalTemplate) {
                return {
                    ...item,
                    layout: {
                        ...item.layout,
                        h: goalTemplate.h,
                        w: goalTemplate.w,
                        x: goalTemplate.x,
                        y: goalTemplate.y,
                    }
                }
            } else {
                return item;
            }
        }
    });

    const width = definition.columns > 10 ? 4 : definition.name === "xxs" ? 1 : definition.columns / 2;
    let prevElement: LayoutItem = shuffledStaticElementsLayout[shuffledStaticElementsLayout.length - 1];
    const shuffledCustomElements = orderChangedLayout.filter((item) => item.type !== "goal" && item.type !== "name").map((item, index) => {
        const sameRow = definition.columns - prevElement.layout.w - prevElement.layout.x >= width;
        const updatedItem = {
            ...item,
            layout: {
                ...item.layout,
                h: 2,
                w: width,
                y: Infinity,
                x: sameRow ? prevElement.layout.w + prevElement.layout.x : 0
            }
        }
        prevElement = updatedItem;
        return updatedItem;
    })

    return [...shuffledStaticElementsLayout, ...shuffledCustomElements];
}

const generateRandom = (min = 0, max = 10) => {
    const difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + min;
    return rand;
}