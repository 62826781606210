import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
import { AccountStatus } from "../api/UserApi";
import { GUIDE_TYPE } from "../model/guides";

export class RootStore {
  private _selectedPage: string | null = null;
  private _userId: string | null = null;
  private _accountStatus: AccountStatus | null = null;
  private _isInitialRoute: boolean = true;
  private _viewedGuides: GUIDE_TYPE[] | null = null;

  get viewedGuides(): GUIDE_TYPE[] | null {
    return this._viewedGuides;
  }

  set viewedGuides(value: GUIDE_TYPE[] | null) {
    this._viewedGuides = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  get userId() {
    return this._userId;
  }

  set userId(value: string | null) {
    this._userId = value;
  }

  get selectedPage() {
    return this._selectedPage;
  }

  set selectedPage(value: string | null) {
    if (this._selectedPage !== null && this._selectedPage !== value && this._isInitialRoute) {
      this._isInitialRoute = false;
    }
    this._selectedPage = value;
  }

  get accountStatus() {
    return this._accountStatus;
  }

  set accountStatus(value: AccountStatus | null) {
    this._accountStatus = value;
  }

  get isInitialRoute() {
    return this._isInitialRoute;
  }
}

export const store = new RootStore();
export const RootContext = createContext<RootStore>(store);
export const useStore = () => useContext(RootContext);
