import { BoardStatus } from "../model/board";
import { Goal } from "../model/goal";
import { VisualizationType } from "../pages/visualize/VisualizatorSettings";
import { GeneratedGoalResponse } from "./AIAssistantApi";
import { Api } from "./Api";

export interface BoardRequestDto {
  name: string;
  statement?: string;
  endDate: Date | null;
  model?: any;
  background?: any;
}

export interface AssistantNewBoardDto {
  name: string;
  statement?: string;
  goals: GeneratedGoalResponse[];
}

export interface NewGoalDto {
  name: string;
  description?: string;
  photo: any;
  tags: string[];
}

export interface NewGoalJournalEntryDto {
  title: string;
  body?: string;
}

export interface AccomplishmentDto {
  accomplished: boolean;
}

export interface MilestoneDto {
  id: string;
  name: string;
  accomplished: boolean;
}

export interface NewMilestoneDto {
  name: string;
}

export interface RecordVisualizationDto {
  type: VisualizationType;
}

export interface BulkGoalsDto {
  goals: NewGoalDto[]
}

class BoardApi extends Api {
  getBoards(statuses: BoardStatus[]) {
    return this.get(`boards?statuses=${statuses.join(",")}`);
  }
  getBoard(id: string) {
    return this.get(`boards/${id}`);
  }
  createBoard(board: BoardRequestDto) {
    return this.post("boards", board);
  }
  assistantCreateBoard(board: AssistantNewBoardDto) {
    return this.post("assistant/boards", board);
  }
  updateBoard(boardId: string, board: BoardRequestDto) {
    return this.put(`boards/${boardId}`, board);
  }
  deleteBoard(boardId: string) {
    return this.delete(`boards/${boardId}`);
  }
  progressWithStatus(boardId: string, status: BoardStatus) {
    return this.post(`boards/${boardId}/status/${status}`, undefined);
  }
  createGoal(boardId: string, goal: NewGoalDto) {
    return this.post(`boards/${boardId}/goals`, goal);
  }
  createGoals(boardId: string, request: BulkGoalsDto) {
    return this.post(`boards/${boardId}/bulk/goals`, request);
  }
  updateGoal(boardId: string, goalId: string, goal: Goal) {
    return this.put(`boards/${boardId}/goals/${goalId}`, goal);
  }
  toggleGoalAccomplishment(boardId: string, goalId: string) {
    return this.post(`boards/${boardId}/goals/${goalId}/accomplish`, {});
  }
  deleteGoal(boardId: string, goalId: string) {
    return this.delete(`boards/${boardId}/goals/${goalId}`);
  }
  getGoals(boardId: string) {
    return this.get(`boards/${boardId}/goals`);
  }
  getGoalJournal(boardId: string, goalId: string) {
    return this.get(`boards/${boardId}/goals/${goalId}/journal`);
  }
  createGoalJournal(
    boardId: string,
    goalId: string,
    entry: NewGoalJournalEntryDto
  ) {
    return this.post(`boards/${boardId}/goals/${goalId}/journal`, entry);
  }
  deleteGoalJournalEntry(boardId: string, goalId: string, entryId: string) {
    return this.delete(`boards/${boardId}/goals/${goalId}/journal/${entryId}`);
  }
  getGoalMilestones(boardId: string, goalId: string) {
    return this.get(`boards/${boardId}/goals/${goalId}/milestones`);
  }
  createGoalMilestone(
    boardId: string,
    goalId: string,
    milestone: NewMilestoneDto
  ) {
    return this.post(`boards/${boardId}/goals/${goalId}/milestones`, milestone);
  }
  toggleGoalMilestone(boardId: string, goalId: string, milestoneId: string) {
    return this.post(
      `boards/${boardId}/goals/${goalId}/milestones/${milestoneId}/accomplish`,
      {}
    );
  }
  deleteGoalMilestone(boardId: string, goalId: string, milestoneId: string) {
    return this.delete(
      `boards/${boardId}/goals/${goalId}/milestones/${milestoneId}`
    );
  }
  recordVisualization(boardId: string, type: VisualizationType) {
    return this.post(`boards/${boardId}/visualizations/record`, {
      type
    });
  }
}

export const boardApi = new BoardApi();
