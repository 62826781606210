import { Photo } from "../../../../api/UnsplashApi";
import { GalleryItem } from "../../../../assets/gallery/gallery-config";
import { Goal } from "../../../../model/goal";
import { DesignerProps } from "../FullScreenDesigner";
import { getMatchingDefinition } from "./gridTemplates";
import { Background, BorderStyle, DEFAULT_BACKGROUND, DEFAULT_TEXT_STYLES, FilterType, GridBoard, GridBoardProperties, LayoutItem, LayoutItemStyles, LayoutItemType } from "./model";

export const FILTER_STYLES: { [key in FilterType]: string } = {
    grayscale: "grayscale(100%)",
    none: "unset",
    sepia: "sepia(100%)",
    contrast: "contrast(200%)",
    inverted: "invert(100%)"
}

export const mapBoardBackgroundImage = (background: Background) => {
    if (background.type === "image") {
        return background.source === "internal" ? `url(${(background.value as GalleryItem).image})` : `url(${(background.value as Photo).urls.full})`;
    } else {
        return undefined;
    }
}

export const borderStyles = (style: BorderStyle) => {
    if (style === "special-dashed-1") {
        return {
            borderStyle: "dashed",
            borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px"
        }
    } else if (style === "special-dotted-1") {
        return {
            borderStyle: "dotted",
            borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px"
        }
    } else if (style === "special-normal-1") {
        return {
            borderStyle: "normal",
            borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px"
        }
    } else if (style === "animated-rainbow-1") {
        return {
            className: "rainbow-border-1"
        }
    } else if (style === "animated-rainbow-2") {
        return {
            className: "rainbow-border-2"
        }
    } else {
        return {
            borderStyle: style === "normal" ? "solid" : style
        }
    }
}

export const mapBorderStyles = (boardStyles: GridBoardProperties, styles?: LayoutItemStyles) => {
    if (styles && styles.borderStyle) {
        return borderStyles(styles.borderStyle);
    } else {
        return borderStyles(boardStyles.borderStyle);
    }
}

export const isTextItem = (type: LayoutItemType): boolean => type === "name" || type === "powerful-statement" || type === "label" || type === "statement";
export const canDeleteItem = (item: LayoutItem): boolean => item.type === "powerful-statement" || item.type === "sticker" || item.type === "illustration" || item.type === "label";

const RESIZE_HANDLERS = ["e", "n", "s", "w", "ne", "nw", "se", "sw"];

export const buildNewInitialLayout = (goals: Goal[], name: string, statement?: string): LayoutItem[] => {
    const layout: LayoutItem[] = [];
    const definition = getMatchingDefinition();

    const width = Math.ceil(definition.columns > 10 ? 4 : definition.columns / 2);
    const maxInRow = Math.ceil(definition.columns / width);

    layout.push(...goals.map((goal, index) => ({
        layout: {
            i: goal.id,
            h: 2,
            w: width,
            x: width * (index % maxInRow),
            y: Math.floor(index / maxInRow),
            resizeHandles: RESIZE_HANDLERS
        },
        type: "goal",
        value: goal.id
    } as LayoutItem)))

    const lastItem = layout[layout.length - 1];
    const nameSameRow = definition.columns - (lastItem.layout.x + lastItem.layout.w) >= width * 2;

    layout.push({
        layout: {
            i: `name-key`,
            h: 1,
            w: width * 2,
            x: nameSameRow ? lastItem.layout.x + lastItem.layout.w : 0,
            y: nameSameRow ? lastItem.layout.y : lastItem.layout.y + 1,
            // @ts-ignore
            resizeHandles: RESIZE_HANDLERS
        },
        type: "name",
        value: name,
        styles: {
            background: DEFAULT_BACKGROUND,
            textStyles: {
                ...DEFAULT_TEXT_STYLES,
                textAlign: "center",
                size: 48,
            },

        }
    })
    return layout;
}

export const buildNewItem = (type: LayoutItemType, value: any, layout: LayoutItem[]): LayoutItem => {
    const definition = getMatchingDefinition();

    const width = definition.columns > 10 ? 4 : definition.columns / 2;
    const lastItem = layout[layout.length - 1];
    const nameSameRow = definition.columns - (lastItem.layout.x + lastItem.layout.w) >= width * 2;
    const item: LayoutItem = {
        value,
        type,
        layout: {
            i: `${type}-${layout.filter((l) => l.type === type).length}`,
            h: definition.name === "xxs" ? 2 : isTextItem(type) ? 1 : width / 2,
            w: definition.name === "xxs" ? 1 : width,
            x: nameSameRow ? lastItem.layout.x + lastItem.layout.w : 0,
            y: nameSameRow ? lastItem.layout.y : lastItem.layout.y + 1,
            // @ts-ignore
            resizeHandles: RESIZE_HANDLERS
        },
        styles: {
            background: isTextItem(type) ? DEFAULT_BACKGROUND : undefined,
            textStyles: isTextItem(type) ? DEFAULT_TEXT_STYLES : undefined,
        }
    }
    return item;
}

export const buildInitialLayout = (props: DesignerProps): LayoutItem[] => {
    const { goals, name, statement, initialModel } = props;
    if (initialModel) {
        const gridBoard = initialModel.model as GridBoard;
        const updatedLayout = gridBoard.layout.map((item) => {
            if (item.type === "name") {
                item.value = name;
                return item;
            } else if (item.type === "statement" && statement) {
                item.value = statement;
                return item;
            } 
            else if (item.type === "goal") {
                const goal = goals.find((goal) => goal.id === (item.value as string));
                if (goal) {
                    return item;
                } else {
                    return null;
                }
            } 
            else {
                return item;
            }
        }).filter((item) => item !== null) as LayoutItem[];
        const goalIds = updatedLayout.filter((item) => item?.type === "goal").map((item) => (item?.value as string));
        goals.forEach((goal) => {
            if (!goalIds.includes(goal.id)) {
                updatedLayout.push(buildNewItem("goal", goal.id, updatedLayout as LayoutItem[]));
            }
        });
        return updatedLayout;
    } else {
        return buildNewInitialLayout(goals, name, statement);
    }
}