import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { withFadeIn } from "../../../hoc/withFadeIn";
import { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { GoalIntroModal } from "./GoalIntroModal";
import { NewGoalModal, NewGoalProps } from "./NewGoalModal";
import { Goal, getPhotoUrl } from "../../../model/goal";
import { observer } from "mobx-react-lite";
import { WizardStep, getWizardState } from "../state/wizzardState";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { YesNoModal } from "../../../components/YesNoModal";
import { boardApi } from "../../../api/BoardApi";
import useFetch from "../../../hooks/useFetch";
import { PersistedResult } from "../../../api/Api";

const NewGoalCard = ({ onClick }: { onClick: () => void }) => {
  const isWideScreen = useMediaQuery("(min-width:1600px)");

  return (
    <Card
      variant="outlined"
      sx={{
        flexBasis: "17%",
        ".MuiCardActionArea-root": { height: "auto" },
        height: "fit-content",
        minWidth: "200px",
        m: "10px",
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            onClick={onClick}
            sx={{
              minWidth: "150px",
              minHeight: isWideScreen ? "150px" : "100px",
              maxWidth: isWideScreen ? "200px" : "150px",
              maxHeight: isWideScreen ? "200px" : "100px",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background:
                "linear-gradient(162deg, rgb(238 242 255) 0%, rgb(178 194 249) 47%, rgb(133 158 239) 100%)",
              transition: "0.5s all ease",
              cursor: "pointer",
              transform: "scale(1.0)",
              "&:hover": {
                transform: "scale(0.95)",
              },
            }}
          >
            <AddCircleOutlineIcon />
          </Box>
          <Typography
            sx={{
              pt: "10px",
              textAlign: "center",
              fontSize: isWideScreen ? "20px" : undefined,
            }}
            component="div"
          >
            Add goal
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const DefinedGoalCard = ({
  goal,
  onDelete,
  onEdit,
}: {
  goal: Goal;
  onDelete: () => void;
  onEdit: () => void;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { callApi } = useFetch();

  useEffect(() => {
    const getImageUrl = async () => {
      const url = await getPhotoUrl(goal, "small", callApi);
      setImageUrl(url);
    };
    getImageUrl();
  }, []);

  const isWideScreen = useMediaQuery("(min-width:1600px)");

  return (
    <Card
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      variant="outlined"
      sx={{
        flexBasis: "17%",
        ".MuiCardActionArea-root": { height: "auto" },
        height: "fit-content",
        maxWidth: "250px",
        minWidth: "200px",
        m: "10px",
      }}
    >
      {confirmModal ? (
        <YesNoModal
          open={true}
          label="Are you sure we want to delete this goal?"
          onNo={() => setConfirmModal(false)}
          onYes={() => {
            onDelete();
            setConfirmModal(false);
          }}
        />
      ) : null}
      <Box
        sx={{
          borderRadius: "5px",
          opacity: hovered ? 1 : 0,
          transition: "0.3s all ease",
          position: "absolute",
          display: "flex",
          transform: "translate(-16px, -16px)",
          backgroundColor: "#ecf1ff",
          zIndex: 10,
        }}
      >
        <Tooltip title="Edit" arrow placement="top">
          <IconButton
            onClick={() => {
              setHovered(false);
              onEdit();
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow placement="top">
          <IconButton
            onClick={() => {
              setConfirmModal(true);
              setHovered(false);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {imageUrl ? (
            <Box
              key={goal.id}
              component={"img"}
              src={imageUrl}
              alt={goal.name}
              loading="lazy"
              sx={{
                objectFit: "cover",
                minWidth: "100px",
                minHeight: "100px",
                maxWidth: isWideScreen ? "200px" : "150px",
                maxHeight: isWideScreen ? "200px" : "150px",
                transition: "0.5s all ease",
                cursor: "pointer",
                transform: "scale(1.0)",
                "&:hover": {
                  transform: "scale(0.95)",
                },
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={150} height={150} />
          )}
          <Typography
            sx={{
              pt: "10px",
              textAlign: "center",
              fontSize: isWideScreen ? "20px" : undefined,
            }}
            component="div"
          >
            {goal.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const DefineGoalsStep = withFadeIn(
  observer(() => {
    const [introModal, setIntroModal] = useState<boolean>(false);
    const [newGoalModal, setNewGoalModal] = useState<boolean>(false);
    const [editingGoal, setEditingGoal] = useState<Goal | null>(null);

    const { callApi } = useFetch();

    const state = getWizardState();

    useEffect(() => {
      setTimeout(() => setIntroModal(true), 400);
      state.step = WizardStep.GOALS;
    }, []);

    const persistCallback = async (props: NewGoalProps) => {
      const updatedGoals = [...state.goals];
      if (editingGoal) {
        updatedGoals.forEach(async (g) => {
          if (g.id === editingGoal.id) {
            g.description = props.description;
            g.name = props.name;
            g.photo = props.photo;
            g.tags = props.tags;
            await callApi(
              boardApi.updateGoal(state.boardId as string, g.id, g)
            );
          }
        });
        setEditingGoal(null);
      } else {
        const goal = {
          ...props,
          attachments: [],
          journal: {
            id: `todo-${state.goals.length}-journal`,
            entries: [],
          },
        };
        const persistedGoal: PersistedResult = await callApi(
          boardApi.createGoal(state.boardId as string, goal)
        );
        updatedGoals.push({
          ...goal,
          accomplished: false,
          id: persistedGoal.id,
        });
      }
      state.goals = updatedGoals;
      setNewGoalModal(false);
    };

    const mobileMenu = useMediaQuery("(max-width:800px)");
    const isWideScreen = useMediaQuery("(min-width:1600px)");

    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: mobileMenu ? "80%" : undefined,
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Lato, sans-serif",
            pt: "20px",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "block",
              fontSize: isWideScreen ? "26px" : "18px",
              fontFamily: "Lato, sans-serif",
              textAlign: mobileMenu ? "justify" : undefined,
              fontWeight: 400,
            }}
          >
            Now let's define your goals. In order to make a vision board more
            effective it's good to define no more than 10 goals
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {state.goals.map((goal, index) => (
              <DefinedGoalCard
                key={`goal${index}`}
                goal={goal}
                onDelete={async () => {
                  await callApi(
                    boardApi.deleteGoal(state.boardId as string, goal.id)
                  );
                  const updatedGoals = state.goals.filter(
                    (g) => g.id !== goal.id
                  );
                  state.goals = updatedGoals;
                }}
                onEdit={() => {
                  setEditingGoal({ ...goal });
                  setNewGoalModal(true);
                }}
              />
            ))}
            {state.goals.length < 10 ? (
              <NewGoalCard onClick={() => setNewGoalModal(true)} />
            ) : null}
          </Box>
        </Box>
        {state.goals.length === 0 ? (
          <GoalIntroModal
            open={introModal}
            onCancel={() => setIntroModal(false)}
          />
        ) : null}
        {newGoalModal ? (
          <NewGoalModal
            editingGoal={
              editingGoal
                ? {
                    description: editingGoal.description,
                    photo: editingGoal.photo,
                    name: editingGoal.name,
                    tags: editingGoal.tags,
                  }
                : undefined
            }
            onCancel={() => {
              setNewGoalModal(false);
              setEditingGoal(null);
            }}
            onSave={persistCallback}
          />
        ) : null}
      </Box>
    );
  }),
  {
    fullWidth: true,
    center: true,
    overflowY: true,
  }
);
