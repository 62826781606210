import { Box } from "@mui/material"
import { useState, ChangeEvent } from "react";
import { PresignedUrl, attachmentApi } from "../api/AttachmentApi";
import { AttachmentType } from "../model/goal";
import LoadingButton from "@mui/lab/LoadingButton";
import useFetch from "../hooks/useFetch";

export const UploadImage = ({ uploadCompleted, goalId }: { uploadCompleted: (attachmentId: string) => void; goalId?: string }) => {
    const [file, setFile] = useState<{ url: string; name: string; file: File } | null>(null);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];
            setFile({
                name: file.name,
                url: URL.createObjectURL(file),
                file: file,
            });
        }
    }

    const { loading, callApi } = useFetch();

    return (
        <Box sx={{
            maxWidth: "350px",
            flex: 1,
            // maxHeight: "350px", TODO to be verified
            display: "flex", flexDirection: "column", justifyContent: "space-between"
        }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <form style={{ paddingBottom: "10px" }}>
                    <input name="file" type="file" onChange={handleChange} accept="image/png, image/jpeg, image/bmp" />
                </form>
                {file ? <img style={{ objectFit: "contain", maxHeight: "300px" }} src={file.url} /> : null}
            </Box>
            {file ?
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <LoadingButton loading={loading} sx={{ mt: "20px", width: "100px" }} variant="outlined" onClick={async () => {
                        const presignedUrl: PresignedUrl = await callApi(attachmentApi.getUploadAttachmentParameters({
                            name: file.name,
                            type: AttachmentType.IMAGE,
                            goalId
                        }));
                        const uploadResult = await attachmentApi.upload(presignedUrl.params.url, file.file, presignedUrl.params.fields);
                        if (uploadResult) {
                            uploadCompleted(presignedUrl.id);
                        } else {
                            // TODO show notification
                        }
                    }}>Upload</LoadingButton>
                </Box> : null}
        </Box>
    )
}