import { Api } from "./Api";

export enum SubscriptionPeriod {
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
}

export enum SubscriptionStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export type StripeStatus =
| 'active'
| 'canceled'
| 'incomplete'
| 'incomplete_expired'
| 'past_due'
| 'paused'
| 'trialing'
| 'unpaid';

export interface Payment {
    id: string;
    amount: number;
    currency: string;
    creationDate: Date
}

export interface SubscriptionDetails {
    period: SubscriptionPeriod,
    internalStatus: SubscriptionStatus,
    currentPeriodStart: Date;
    currentPeriodEnd: Date;
    currency: string;
    status: StripeStatus;
    payments: Payment[];
}

class SubscriptionApi extends Api {
    subscribe(period: SubscriptionPeriod) {
        return this.post("subscriptions/checkout", {
            period
        });
    }
    getSubscription() {
        return this.get(`subscriptions`);
    }
    hasActiveSubscription() {
        return this.get("subscriptions/status");
    }
    getPaymentInvoiceLink(paymentId: string) {
        return this.get(`subscriptions/payments/${paymentId}/invoice`);
    }
    cancelSubscription() {
        return this.delete("subscriptions");
    }
}

export const subscriptionApi = new SubscriptionApi();