import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { generateRandom } from "../../utils/numbers";
import { BACKGROUND } from "../screensaver/Masonry";
import { BasicVisualizatorProps } from "./BasicVisualizator";

export const ZoomInZoomOutVisualizator = ({
  fullscreen,
  loop,
  slides,
  onFinishCallback,
}: BasicVisualizatorProps) => {
  const [visibleIndex, setVisibleIndex] = useState<number>(0);
  const [recorded, setRecorded] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:630px)");

  useEffect(() => {
    if (visibleIndex === slides.length - 1 && !recorded) {
      onFinishCallback().then(() => setRecorded(true));
    }
  }, [visibleIndex, recorded]);

  const screenBackground = useRef<any>(
    BACKGROUND[generateRandom(0, BACKGROUND.length - 1)]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        ...screenBackground.current,
      }}
    >
      <Splide
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onAutoplayPause={(splide) => {
          const { Autoplay } = splide.Components;
          Autoplay.play();
        }}
        onMoved={(splide, index) => setVisibleIndex(index)}
        options={{
          start: 0,
          autoWidth: true,
          type: "fade",
          rewind: loop,
          autoplay: true,
          arrows: false,
          interval: 5000,
          pagination: false,
        }}
      >
        {slides.map((slide, index) => (
          <SplideSlide
            key={`slide-${index}-${fullscreen}`}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Box
              className={
                slide.type === "goal" && visibleIndex === index
                  ? "zoom-in-out-box"
                  : undefined
              }
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                height: fullscreen
                  ? isMobile
                    ? "70vh"
                    : `${window.screen.height}px`
                  : isMobile
                  ? "40vh"
                  : "500px",
                maxWidth: isMobile && slide.type === "goal" ? fullscreen ? "70vw" : "50vw" : "unset",
                fontSize:
                  slide.type === "name"
                    ? fullscreen
                      ? isMobile
                        ? "18px"
                        : "46px"
                      : isMobile
                      ? "15px"
                      : "30px"
                    : fullscreen
                    ? isMobile
                      ? "16px"
                      : "40px"
                    : isMobile
                    ? "14px"
                    : "26px",
                fontWeight: slide.type === "name" ? "bold" : undefined,
                fontStyle: slide.type === "statement" ? "italic" : undefined,
                fontFamily: '"Titillium Web", sans-serif',
                backgroundImage:
                  slide.type === "goal"
                    ? `url(${slide.background})`
                    : undefined,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.9,
              }}
            >
              {slide.type !== "goal" ? (
                <Box
                  sx={{
                    p: "20px 40px",
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderRadius: "15px",
                    width: undefined,
                    position: undefined,
                    bottom: undefined,
                    textAlign: "center",
                  }}
                >
                  {slide.name}
                </Box>
              ) : null}
            </Box>
            {slide.type === "goal" ? (
              <Box
                sx={{
                  fontSize: fullscreen
                    ? isMobile
                      ? "18px"
                      : "40px"
                    : isMobile
                    ? "14px"
                    : "18px",
                  fontStyle: "italic",
                  fontWeight: "400",
                  fontFamily: '"Titillium Web", sans-serif',
                  position: "absolute",
                  bottom: 0,
                  p: "20px 60px",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  backgroundColor: "rgba(255,255,255,0.7)",
                }}
              >
                {slide.name}
              </Box>
            ) : null}
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};
