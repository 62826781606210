import { Box } from "@mui/material";
import { CustomModal } from "./CustomModal";
import { useEffect, useState } from "react";
import { isiPhone } from "../utils/mobile";
import { isPwaApplication } from "../utils/pwa";
import { useStore } from "../state/RootStore";
import { getValue, setValue } from "../utils/localStorage";
import dayjs from "dayjs";

export type ScreenName = "screensaver" | "visualization";
const INFO_MODAL_KEY = "__ainfo"; // keeps date when modal was last shown

const shouldPresentModal = (name: ScreenName, userId: string): boolean => {
  const keyName = `${INFO_MODAL_KEY}_${name}`;

  const lastModalShownDays = getValue(keyName, userId);
  if (lastModalShownDays) {
    const result = dayjs(lastModalShownDays).add(1, "day").isBefore(dayjs());
    if (result) {
        setValue(keyName, userId, new Date().toString());
    }
    return result;
} else {
      setValue(keyName, userId, new Date().toString());
  }
  return true;
};

export const IPhoneModals = ({ name }: { name: ScreenName }) => {
  const [pwaModal, setPwaModal] = useState<boolean>(false);
  const [iphoneModal, setIphoneModal] = useState<boolean>(false);
  const store = useStore();

  useEffect(() => {
    if (
      isPwaApplication() &&
      store.userId &&
      shouldPresentModal(name, store.userId)
    ) {
      setPwaModal(true);
    } else if (
      isiPhone() &&
      store.userId &&
      shouldPresentModal(name, store.userId)
    ) {
      setIphoneModal(true);
    }
  }, []);

  return (
    <>
      {pwaModal ? (
        <Box sx={{ position: "absolute", bottom: 0 }}>
          <CustomModal onOk={() => setPwaModal(false)} open={true}>
            You are using PWA application. You can experience this feature fully
            in desktop browser
          </CustomModal>
        </Box>
      ) : null}
      {iphoneModal ? (
        <Box sx={{ position: "absolute", bottom: 0 }}>
          <CustomModal onOk={() => setIphoneModal(false)} open={true}>
            You are using VividBoard.app on iPhone. In order to fully experience
            this feature please enable Fullscreen API in Safari flags (Settings
            \ Safari \ Advanced \ Feature Flags \ Fullscreen API)
          </CustomModal>
        </Box>
      ) : null}
    </>
  );
};
