import Card from "@mui/material/Card";
import { Board, BoardStatus } from "../../model/board";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import emptyBoardImage from "../../assets/empty-board.jpg";
import { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { boardApi } from "../../api/BoardApi";
import { Background } from "../wizzard/layouts/grid/model";
import { Photo } from "../../api/UnsplashApi";
import { YesNoModal } from "../../components/YesNoModal";
import DeleteIcon from "@mui/icons-material/Delete";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import TheatersIcon from "@mui/icons-material/Theaters";
import { Loader } from "../../components/Loader";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import { CustomModal } from "../../components/CustomModal";
import {
  VisualizatorSettingsContainer,
  mapVisualizationPath,
} from "../visualize/VisualizatorSettings";
import { useNavigate } from "react-router-dom";
import { HtmlTooltip } from "../../components/HtmlTooltip";
import { GalleryItem } from "../../assets/gallery/gallery-config";

export const NewBoardCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <Card
      id="new-board-card"
      variant="outlined"
      sx={{
        maxHeight: "250px",
        width: "250px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
      onClick={onClick}
    >
      <CardActionArea>
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              color: "#fff",
              height: "140px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background:
                "linear-gradient(162deg, rgb(238 242 255) 0%, rgb(178 194 249) 47%, rgb(133 158 239) 100%);",
            }}
          >
            <AddCircleOutlineIcon />
          </Box>
          <Typography
            sx={{
              p: "16px",
              fontFamily: "Lato, sans-serif",
              fontSize: "18px",
              fontStyle: "italic",
              fontWeight: 400,
              textAlign: "center",
            }}
            gutterBottom
            variant="h6"
            component="div"
          >
            New Board
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const getBoardImage = (background?: Background) => {
  if (background) {
    if (background.type === "color") {
      // TODO
    } else if (background.type === "image") {
      if (background.source === "external") {
        const unsplashPhoto: Photo = background.value as Photo;
        return unsplashPhoto.urls.small;
      } else {
        const internalGalleryItem: GalleryItem =
          background.value as GalleryItem;
        return internalGalleryItem.image;
      }
    }
  }
  return emptyBoardImage;
};

const BoardStatusLabel = ({ status }: { status: BoardStatus }) => {
  const label = status === BoardStatus.DRAFT ? "Draft" : "Archived";

  return status === BoardStatus.READY ? null : (
    <Box
      sx={{
        position: "absolute",
        ml: "-30px",
        mt: "-10px",
        zIndex: 9,
        transform: "rotate(-15deg)",
      }}
    >
      <label
        className="layout-draft"
        style={{ fontSize: "12px", padding: "5px 16px" }}
      >
        {label}
      </label>
    </Box>
  );
};

export const BoardCard = ({
  board,
  showBoard,
  archiveBoard,
  deleteBoard,
}: {
  board: Board;
  deleteBoard: () => Promise<void>;
  archiveBoard: () => Promise<void>;
  showBoard: (board: Board) => void;
}) => {
  const { t } = useTranslation();

  const [hovered, setHovered] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<
    "delete" | "archive" | "unarchive" | null
  >(null);
  const [visualizationModal, setVisualizationModal] = useState<boolean>(false);
  const { loading, callApi } = useFetch();
  const navigate = useNavigate();

  const handleClick = async () => {
    setHovered(false);
    try {
      const persistedBoard: Board = await callApi(boardApi.getBoard(board.id));
      showBoard(persistedBoard);
    } catch (e) {
      // TODO how to handle it properly?
    }
  };

  return (
    <Card
      sx={{
        width: 250,
        maxHeight: 250,
        height: "fit-content",
        cursor: "pointer",
        position: "relative",
        overflow: "inherit",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <BoardStatusLabel status={board.status} />
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            top: 0,
            left: 0,
            zIndex: 20,
            backgroundColor: "rgba(0,0,0,0)",
          }}
        ></Box>
      ) : null}
      {confirmModal ? (
        <YesNoModal
          alert={confirmModal === "delete"}
          open={true}
          label={t(`boards.confirmation.${confirmModal}`) ?? ""}
          onNo={() => setConfirmModal(null)}
          onYes={async () => {
            if (confirmModal === "archive" || confirmModal === "unarchive") {
              await archiveBoard();
            } else if (confirmModal === "delete") {
              await deleteBoard();
            }
            setConfirmModal(null);
          }}
        />
      ) : null}
      {visualizationModal ? (
        <CustomModal
          open={true}
          cancelIcon
          onCancel={() => setVisualizationModal(false)}
        >
          <VisualizatorSettingsContainer
            onOK={(type) => {
              navigate(mapVisualizationPath(type, board.id));
            }}
          />
        </CustomModal>
      ) : null}
      <HtmlTooltip
        arrow
        placement="top"
        title={
          <Box sx={{ display: "flex" }}>
            {board.status === BoardStatus.READY ? (
              <>
                <Tooltip title="Visualize" arrow placement="top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setVisualizationModal(true);
                      setHovered(false);
                    }}
                  >
                    <TheatersIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Screen Saver" arrow placement="top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setHovered(false);
                      navigate(`/app/screensaver/${board.id}`);
                    }}
                  >
                    <NightsStayIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Archive" arrow placement="top">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setConfirmModal("archive");
                      setHovered(false);
                    }}
                  >
                    <ArchiveIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
            {board.status === BoardStatus.ARCHIVED ? (
              <Tooltip title="Unarchive" arrow placement="top">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmModal("unarchive");
                    setHovered(false);
                  }}
                >
                  <UnarchiveIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmModal("delete");
                  setHovered(false);
                }}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
        }
      >
        <CardActionArea onClick={handleClick}>
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </Box>
          ) : null}
          <CardMedia
            component="img"
            height="140"
            image={getBoardImage(board.background)}
            alt={board.name}
            sx={{
              opacity: hovered ? "1.0" : "0.5",
              transition: "0.5s all ease",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              height: "140px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              zIndex: 200,
              opacity: hovered ? 0.8 : 0,
              transition: "0.5s all ease",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                fontStyle: "italic",
                backgroundColor: "rgba(255,255,255,0.8)",
                p: "10px",
                borderRadius: "15px",
              }}
            >
              Open
            </Typography>
          </Box>
          <CardContent>
            <Typography
              sx={{
                fontFamily: "Lato, sans-serif",
                fontSize: "18px",
                fontStyle: "italic",
                fontWeight: 300,
                textAlign: "center",
              }}
              gutterBottom
              variant="h6"
              component="div"
            >
              {board.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </HtmlTooltip>
    </Card>
  );
};
