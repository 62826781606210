import { AttachmentType } from "../model/goal";
import { Api } from "./Api";

export interface PresignedUrl {
    params: {
        url: string;
        fields: any;
    };
    id: string;
}

export interface UploadAttachmentDto {
    name: string;
    type: AttachmentType;
    goalId?: string;
}

class AttachmentApi extends Api {
    getDownloadAttachmentParameters(attachmentId: string) {
        return this.get(`attachments/${attachmentId}`);
    }
    getUploadAttachmentParameters(dto: UploadAttachmentDto) {
        return this.post("attachments", dto);
    }
    async upload(url: string, file: File, params: any): Promise<boolean> {
        const formData = new FormData();
        Object.keys(params).forEach((param) => formData.append(param, params[param]));
        formData.append("file", file);
        const response = await fetch (url, {
            method: "POST",
            body: formData,
        });
        return response.ok;
    }
    getGoalGallery(boardId: string, goalId: string) {
        return this.get(`boards/${boardId}/goals/${goalId}/gallery`);
    }

    deleteAttachment(attachmentId: string) {
        return this.delete(`attachments/${attachmentId}`);
    }
}

export const attachmentApi = new AttachmentApi();