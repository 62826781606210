import { Box, Button } from "@mui/material"
import workingHardImage from "../assets/working-hard.jpg";

export const ComingSoon = () => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", flexDirection: "column", gap: "40px" }}>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={workingHardImage} style={{ borderRadius: "15px", width: "40%" }} />
            </Box>
            <Box>We are working very hard to bring this feature to our service. It should be available soon :)</Box>
            <Button variant="contained">Sign up to newsletter</Button>
        </Box>
    )
}