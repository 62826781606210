import {
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const WizardBottomMenu = ({
  steps,
  activeStep,
  handleStep,
  handleNextStep,
  handlePreviousStep,
  nextStepAvailable,
  loadingNextStep,
}: {
  steps: string[];
  activeStep: number;
  handleStep: (step: number) => () => void;
  handlePreviousStep: () => void;
  handleNextStep: () => Promise<void>;
  nextStepAvailable: boolean;
  loadingNextStep?: boolean;
}) => {
  return (
    <>
      <Box
        sx={{
          top: 0,
          position: "absolute",
          zIndex: 20,
          justifyContent: "center",
          width: "100%",
          left: 0,
          display: "flex",
          alignItems: "center",
          height: "60px",
          borderBottom: "1px solid grey",
          backgroundColor: "#f5f5f7",
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
          {steps[activeStep]}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "75px",
          alignItems: "center",
          borderTop: "1px solid grey",
          zIndex: 16000,
          backgroundColor: "#f5f5f7",
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          position: "absolute",
          bottom: 0,
        }}
      >
        {activeStep > 0 ? (
          <IconButton onClick={handlePreviousStep}>
            <NavigateBeforeIcon />
          </IconButton>
        ) : (
          <Box sx={{ width: "40px" }}></Box>
        )}
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
          {steps.map((step, index) => (
            <FiberManualRecordIcon
              key={`stepIcon${index}`}
              sx={{
                color: activeStep > index ? "#6c9468" : activeStep === index ? "#243040" : "#9d9fa1" ,
              }}
              fontSize="small"
            />
          ))}
        </Box>
        {nextStepAvailable ? (
          <IconButton disabled={loadingNextStep} onClick={handleNextStep}>
            <NavigateNextIcon />
          </IconButton>
        ) : (
          <Box sx={{ width: "40px" }}></Box>
        )}
      </Box>
    </>
  );
};
