import { useAuth } from "@clerk/clerk-react";
import { useState } from "react";
import { ApiParams } from "../api/Api";
import { getErrorState } from "../state/ErrorState";

export type ErrorResponse = {
  status: number,
  payload?: any,
}

export type CallApi = <T>(params: ApiParams) => Promise<T>;

export type UseFetchProps = { loading: boolean; callApi: CallApi, error: ErrorResponse | null };

export type ValidationStatus = "MISSING_PAYMENT" | "TRIAL_EXPIRED";

export const useFetch = (): UseFetchProps => {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const errorStore = getErrorState();

  const authenticatedFetch = async <T,>(params: ApiParams): Promise<T> => {
    setLoading(true);
    return fetch(params.path, {
      method: params.method,
      mode: "cors",
      body: params.body ? JSON.stringify(params.body) : undefined,
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          setLoading(false);
          return res.json();
        } else {
          return Promise.reject(res);
        }
      })
      .then((json) => json)
      .catch((response) => {
        setLoading(false);
        setError({
          status: response.statue,
        })
        if (response.status === 403) {
          response.json().then((payload: any) => {
            if (payload.status === "TRIAL_EXPIRED") {
              errorStore.trialExpiredModal = true;
            } else  if (payload.status === "CANCELED") {
              errorStore.subscriptionCanceledModal = true;
            }
          });
        } else if (response.status === 401) {
          response.json().then((payload: any) => {
            if (payload.account_status === "creation_in_progress") {
              errorStore.accountUnderCreationModal = true;
            } else {
              window.location.href = "/";
              console.log("Not authorized");
            }
          })
        } else {
          console.log("Handle generic error");
        }
      });
  };

  return {
    callApi: authenticatedFetch,
    loading,
    error
  };
};

export default useFetch;
