import { Typography } from "@mui/material"
import { TextStyles } from "../toolbox/TextToolbox";
import { mapStylesToSx } from "./Label";

export enum TextShadowStyle {
    "Regular" = "Regular",
    "Retro" = "Retro",
    "Vintage" = "Vintage",
    "Neon" = "Neon"
}

const COLOR_MAPPINGS: { [key in TextShadowStyle]: string } = {
    [TextShadowStyle.Regular]: "2px 2px 0px #FFB650, 4px 4px 0px #FFD662, 6px 6px 0px  #FF80BF, 8px 8px 0px #EF5097, 10px 10px 0px #6868AC, 12px 12px 0px #90B1E0",
    [TextShadowStyle.Retro]: "2px 2px 0px #00db96, 4px 4px 0px #49297e, 6px 6px 0px #90dcff, 8px 8px 0px #e10086, 10px 10px 0px #fdfb76",
    [TextShadowStyle.Vintage]: "2px 2px 0px #f4d4e3, 4px 4px 0px #bcd2dd, 6px 6px 0px #e1c48d, 8px 8px 0px #ccc3a7, 10px 10px 0px #e7e7e7",
    [TextShadowStyle.Neon]: "2px 2px 0px #3b00fd, 4px 4px 0px #00f92a, 6px 6px 0px #04caf4, 8px 8px 0px #f906d6, 10px 10px 0px #d1f60a",
}

export const Label1 = ({ text, styles, variant }: { text: string; styles: TextStyles, variant: TextShadowStyle  }) => {
    return (
        <Typography sx={{ 
            ...mapStylesToSx(styles),
            textShadow: COLOR_MAPPINGS[variant]
        }}>{text}</Typography>
    )
}