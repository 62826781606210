import { Box, Button, Card, CardActionArea, CardContent, Typography, useMediaQuery } from "@mui/material"
import { withFadeIn } from "../../../hoc/withFadeIn";
import { useEffect, useState } from "react";
import { getWizardState, WizardStep } from "../state/wizzardState";
import { observer } from "mobx-react-lite";
import templateLayout from "../../../assets/grid-layout.svg";
import editorLayout from "../../../assets/layout-editor.svg";
import { FullScreenDesigner, LayoutType } from "../layouts/FullScreenDesigner";
import happyMan from "../../../assets/man-1459246_1280.png";
import { boardApi } from "../../../api/BoardApi";
import { YesNoModal } from "../../../components/YesNoModal";
import { clearUnsavedChanges, hasUnsavedChanges } from "../layouts/grid/GridLayoutDesigner";
import { BoardStatus } from "../../../model/board";
import useFetch from "../../../hooks/useFetch";
import { Goal } from "../../../model/goal";

const CardOption = ({ name, image, onClick, comingSoon: commingSoon }: { name: string, image: any, onClick: () => void; comingSoon?: boolean }) => {
    return (
        <Card variant="outlined" sx={{ ".MuiCardActionArea-root": { height: "auto" }, m: "10px" }} onClick={!commingSoon ? onClick : undefined}>
            <CardActionArea>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                        component={"img"}
                        src={image}
                        alt={name}
                        loading="lazy"
                        sx={{
                            objectFit: "cover",
                            width: "300px",
                            height: "300px",
                            transition: "0.5s all ease",
                            cursor: "pointer",
                            opacity: commingSoon ? "0.5" : 1,
                            transform: "scale(1.0)",
                            "&:hover": {
                                transform: "scale(0.95)"
                            }
                        }}
                    />
                    <Typography sx={{ pt: "10px", textAlign: "center" }} component="div">
                        {`${name} ${commingSoon ? "(Coming soon)" : ""}`}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export const DesignerStep = withFadeIn(observer(() => {

    const [designer, setDesigner] = useState<"new" | "continue" | false>(false);
    const [designerType, setDesignerType] = useState<LayoutType>(LayoutType.GRID);
    const [preview, setPreview] = useState<boolean>(false);
    const [unsavedChangesModal, setUnsavedChangesModal] = useState<boolean>(false);
    const { callApi } = useFetch();

    const state = getWizardState();
    useEffect(() => {
        state.step = WizardStep.DESIGNER;
    }, []);

    const openDesigner = (type: LayoutType) => {
        setDesignerType(type);
        setDesigner("new");
    }

    const mobileMenu = useMediaQuery("(max-width:800px)");
    const isWideScreen = useMediaQuery("(min-width:1600px)");    
    const isUltraWideScreen = useMediaQuery("(min-width:2000px)");

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", fontFamily: "Lato, sans-serif", height: "100%" }}>
            {state.boardModel ?
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "20px", justifyContent: "center", alignItems: "center" }}>
                    <Box component="img" src={happyMan} sx={{ width: mobileMenu ? "70%" : isWideScreen ? "550px" : isUltraWideScreen ? "700px" : "400px" } } />
                    <Box><Typography  sx={{ fontFamily: "Lato, sans-serif", fontWeight: 300, fontStyle: "italic", color: "#000", fontSize: mobileMenu ? "18px" : isWideScreen ? "30px" : "24px", textAlign: "center" }}>You have already created a vision board. Would you like to continue working on it or process to the final step?</Typography></Box>
                    <Box sx={{ display: "flex", flexDirection: mobileMenu ? "column" : "row", alignItems: "center", justifyContent: "center", gap: mobileMenu ? "10px" : "50px", pb: "40px" }}>
                        <Button onClick={() => setDesigner("continue")} variant="contained">Open editor</Button>
                        <Button onClick={() => setDesigner("new")} variant="text">Start editor from scratch</Button>
                        <Button onClick={() => {
                            setPreview(true);
                            setDesigner("continue");
                        }} variant="text">Preview</Button>
                    </Box>
                </Box> :
                <><Box sx={{ display: "inline-flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                    <CardOption name="Grid template" image={templateLayout} onClick={() => openDesigner(LayoutType.GRID)} />
                    <CardOption name="Designer" image={editorLayout} onClick={() => openDesigner(LayoutType.EDITOR)} comingSoon />
                </Box>
                    <Typography sx={{ fontFamily: "Lato, sans-serif", fontSize: "24px", pt: "15px" }}>Please select type of Vision Board designer</Typography></>
            }
            {unsavedChangesModal ? 
            <YesNoModal 
            onYes={() => {
                setDesigner(false);
                setPreview(false);
                clearUnsavedChanges();
                setUnsavedChangesModal(false);
            }}
            onNo={() => {
                setUnsavedChangesModal(false);
            }}
            label="There are some unsaved changes? Are you sure you want to discard them and leave the editor?" open={true} /> : null}
            {designer ? <FullScreenDesigner open={true} type={designerType} DesignerProps={{
                boardId: state.boardId as string,
                boardStatus: BoardStatus.DRAFT, //FIXME, probably for edit mode should be fixed
                goals: state.goals,
                name: state.boardName,
                statement: state.boardStatement,
                preview,
                onClose: () => {
                    if (hasUnsavedChanges()) {
                        setUnsavedChangesModal(true);
                    } else {
                        setDesigner(false);
                        setPreview(false);
                    }
                },
                onSave: async (model, background) => { 
                    setDesigner(false);
                    setPreview(false);
                    state.boardModel = model;
                    await callApi(boardApi.updateBoard(state.boardId as string, state.toBoardRequest(background)));
                },
                refreshGoals: async () => {
                    const goals: Goal[] = await callApi(boardApi.getGoals(state.boardId as string));
                    state.goals = goals;
                },
                initialModel: designer === "continue" ? state.boardModel : null
            }} /> : null}
        </Box>
    )
}), {
    fullWidth: true,
    center: true,
    overflowY: true
});
