import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AIAssistantAction } from "../../api/AIAssistantApi";
import { AssistantMessage } from "./AssistantMessage";
import { useTranslation } from "react-i18next";
import { GenerateCallbackParams } from "./AIAssistantWindow";
import { observer } from "mobx-react-lite";
import { getWizardState } from "../../pages/wizzard/state/wizzardState";
import { Link } from "react-router-dom";

export const UserInputScreen = observer(({
  handleAction,
  userInputStepAction,
  onBack,
}: {
  handleAction: (
    action: AIAssistantAction,
    params?: GenerateCallbackParams
  ) => Promise<void>;
  userInputStepAction: AIAssistantAction;
  onBack: () => void;
}) => {
  const [userInputMessageFinished, setUserInputMessageFinished] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const [userInput, setUserInput] = useState<string>("");
  const [userInputError, setUserInputError] = useState<boolean>(false);
  const [boardName, setBoardName] = useState<string>("");
  const [boardNameError, setBoardNameError] = useState<boolean>(false);
  const state = getWizardState();

  const callback = async () => {
    if (userInput.length === 0 || userInput.split(" ").length < 10) {
      setUserInputError(true);
      return;
    }
    if (boardName.length === 0) {
      setBoardNameError(true);
      return;
    }
    try {
      await handleAction(userInputStepAction, {
        boardName,
        userInput,
      });
      setBoardName("");
      setUserInput("");

    } catch (e) {
      console.log("Handle error");
    }
  };

  useEffect(() => {
    if (state.aiAssistantInput) {
      setBoardName(state.aiAssistantInput.boardName);
      if (state.aiAssistantInput.userInput) {
        setUserInput(state.aiAssistantInput.userInput);
      }
    }
  }, [state.aiAssistantInput]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AssistantMessage
        key={`userInputStep`}
        message="Ok great! I will help you but please let me know you a bit more in order to propose something you might love."
        secondMessage="In the text fields below please write a Vision Board name and a few short sentences about you. Example sentences you can find at the bottom"
        onFinish={() => setUserInputMessageFinished(true)}
      />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          p: "10px 20px",
          opacity: userInputMessageFinished ? 1 : 0,
          transition: "0.5s all ease",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField
            placeholder="Vision Board name"
            sx={{
              "& .MuiInputBase-root, & .MuiInputLabel-root": {
                fontSize: "14px",
              },
              ".MuiInputBase-input": {
                padding: "3px 10px 4px 10px",
              },
            }}
            onChange={(e) => {
              setBoardName(e.currentTarget.value);
              setBoardNameError(false);
            }}
            helperText={
              boardNameError ? "This field cannot be empty" : undefined
            }
            value={boardName}
            error={boardNameError}
          />
          <TextField
            error={userInputError}
            rows={3}
            placeholder={"A few words about me"}
            multiline
            value={userInput}
            onChange={(e) => {
              setUserInput(e.currentTarget.value);
              setUserInputError(false);
            }}
            helperText={
              userInputError
                ? "This field cannot be empty and must have at least 10 words"
                : undefined
            }
            sx={{
              "& .MuiInputBase-root, & .MuiInputLabel-root": {
                fontSize: "14px",
              },
              ".MuiInputBase-root": {
                padding: "3px 10px 4px 10px",
              },
            }}
          />
          <Typography
            sx={{ fontStyle: "italic", fontSize: "12px", color: "grey" }}
          >
            Example: I’m 28. Live in Poland. Working in Banking sector as junior
            accountant. I’m married and have two kids. Earn 200k PLN annually.
            Love cold plunge and sauna
          </Typography>
          <Typography sx={{ fontStyle: "italic", fontSize: "10px", color: "grey" }}>For more examples read this <Link target="_blank" to="/app/tutorials/view/ai-assistant-wizard">Tutorial</Link></Typography>
          <Button
            onClick={async () => await callback()}
            variant="outlined"
          >
            {t(`ai.action.user-input.${userInputStepAction}`)}
          </Button>
          <Button
            onClick={() => {
              onBack();
              setUserInputMessageFinished(false);
            }}
            variant="text"
          >
            Go Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
