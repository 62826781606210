import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { Board } from "../model/board"
import { boardApi } from "../api/BoardApi"
import { Loader } from "../components/Loader"
import { LayoutType, ViewModeFullScreenDesigner } from "./wizzard/layouts/FullScreenDesigner"
import { useNavigate, useParams } from "react-router-dom"
import useFetch from "../hooks/useFetch"

export const ViewBoard = () => {
    const { boardId } = useParams();
    const navigate = useNavigate();
    const [board, setBoard] = useState<Board | null>(null);
    const { loading, callApi } = useFetch();

    useEffect(() => {
        const queryBoard = async () => {
            if (boardId) {
                try {
                    const board: Board = await callApi(boardApi.getBoard(boardId));
                    setBoard(board);
                } catch (e) {
                    console.log("notification");
                }
            }
        }
        queryBoard();
    }, [boardId]);

    return boardId && board && !loading ? (
        <Box sx={{ display: "flex", gap: "50px", justifyContent: "center", flexWrap: "wrap" }} >
            <ViewModeFullScreenDesigner type={LayoutType.GRID} 
            refreshBoard={async () => {
                const board: Board = await callApi(boardApi.getBoard(boardId));
                setBoard(board);
            }}
            DesignerProps={{
                boardStatus: board.status,
                boardId: board.id,
                goals: board.goals,
                name: board.name,
                statement: board.statement,
                initialModel: board.model,
                onClose: () =>  {
                    setBoard(null);
                    navigate("/app/boards")
                },
            }}  /> 
        </Box>
    ) : <Loader fullScreen />
}