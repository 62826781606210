import { Box, Typography, useMediaQuery } from "@mui/material";
import { BoardCard, NewBoardCard } from "./board/BoardCard";
import { AddBoardModal } from "./wizzard/AddBoardModal";
import { useEffect, useState } from "react";
import { Board, BoardStatus } from "../model/board";
import { boardApi } from "../api/BoardApi";
import {
  PreviousSessionBoard,
  clearPreviousSessionBoardId,
  getPreviousSessionBoardId,
  getWizardState,
} from "./wizzard/state/wizzardState";
import { CustomModal } from "../components/CustomModal";
import { Loader } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { CustomSelect } from "../components/CustomSelect";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { YesNoModal } from "../components/YesNoModal";
import useFetch from "../hooks/useFetch";
import { Guide } from "../components/Guide";

const PreviousSessionDraftModal = ({
  boardName,
  onCancel,
  onOk,
}: {
  boardName: string;
  onCancel: () => void;
  onOk: () => void;
}) => {
  return (
    <CustomModal
      open={true}
      cancelIcon
      okLabel="Let's continue this board"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Typography
        sx={{
          fontFamily: "Lato, sans-serif",
          fontSize: "22px",
          fontStyle: "italic",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        You have unfinished work on <strong>{boardName}</strong> vision board
        from the previous session. Would you like to continue working on it?
      </Typography>
    </CustomModal>
  );
};

enum BoardFilterStatus {
  DRAFT = "DRAFT",
  READY = "READY",
  ARCHIVED = "ARCHIVED",
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
}

const mapFilterStatusToBoardStatus = (
  filterStatus: BoardFilterStatus
): BoardStatus[] => {
  if (filterStatus === BoardFilterStatus.DRAFT) {
    return [BoardStatus.DRAFT];
  } else if (filterStatus === BoardFilterStatus.READY) {
    return [BoardStatus.READY];
  } else if (filterStatus === BoardFilterStatus.ACTIVE) {
    return [BoardStatus.DRAFT, BoardStatus.READY];
  } else if (filterStatus === BoardFilterStatus.ARCHIVED) {
    return [BoardStatus.ARCHIVED];
  } else if (filterStatus === BoardFilterStatus.COMPLETED) {
    return [BoardStatus.COMPLETED];
  } else {
    return [];
  }
};

export const Boards = () => {
  const [newBoardModal, setNewBoardModal] = useState<boolean>(false);
  const [boards, setBoards] = useState<Board[]>([]);
  const [previousSessionDraft, setPreviousSessionDraft] =
    useState<PreviousSessionBoard | null>(getPreviousSessionBoardId());
  const [keepState, setKeepState] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<BoardFilterStatus>(
    BoardFilterStatus.ACTIVE
  );
  const [archivedBoardId, setArchivedBoardId] = useState<string | null>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const wizardState = getWizardState();

  const { loading, callApi } = useFetch();

  const queryBoards = async (statuses: BoardStatus[]) => {
    const result: Board[] = await callApi(boardApi.getBoards(statuses));
    setBoards(result);
  };

  useEffect(() => {
    queryBoards(mapFilterStatusToBoardStatus(filterStatus));
  }, []);

  const handleShowBoard = (board: Board) => {
    if (board.status === BoardStatus.READY) {
      navigate(`/app/boards/${board.id}`);
    } else if (board.status === BoardStatus.ARCHIVED) {
      setArchivedBoardId(board.id);
    } else {
      // TODO query here
      wizardState.restoreBoard(board);
      setKeepState(true);
      setNewBoardModal(true);
    }
  };

  const handleArchiveBoard = async (board: Board) => {
    await callApi(
      boardApi.progressWithStatus(
        board.id,
        board.status === BoardStatus.ARCHIVED
          ? BoardStatus.DRAFT
          : BoardStatus.ARCHIVED
      )
    );
    await queryBoards(mapFilterStatusToBoardStatus(filterStatus));
  };

  const handleDeleteBoard = async (board: Board) => {
    await callApi(boardApi.deleteBoard(board.id));
    await queryBoards(mapFilterStatusToBoardStatus(filterStatus));
  };

  const handleContinuePreviousSession = async () => {
    if (previousSessionDraft) {
      try {
        const board: Board = await callApi(
          boardApi.getBoard(previousSessionDraft.boardId)
        );
        wizardState.restoreBoard(board);
        setPreviousSessionDraft(null);
        setKeepState(true);
        setNewBoardModal(true);
      } catch (e) {
        console.log("Unable to restore board");
        setPreviousSessionDraft(null);
      }
    }
  };

  const handleFilterStatusChange = async (value: string) => {
    const filterStatus: BoardFilterStatus = value as BoardFilterStatus;
    setFilterStatus(filterStatus);
    await queryBoards(mapFilterStatusToBoardStatus(filterStatus));
  };

  const mobileMenu = useMediaQuery("(max-width:630px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        gap: "20px",
      }}
    >
      <Guide type="BOARDS" />
      <Box
        sx={{
          justifyContent: mobileMenu ? "center" : "flex-end",
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <FilterAltIcon id="filter-alt-icon" sx={{ color: "#243040" }} />
        <CustomSelect
          fullWidth={mobileMenu}
          selectSx={{
            width: mobileMenu ? "100%" : "200px",
            height: "30px",
            fontFamily: "Lato, sans-serif",
          }}
          value={filterStatus}
          onChange={handleFilterStatusChange}
          options={Object.keys(BoardFilterStatus).map((status) => ({
            label: t(`board.filter.status.${status}`),
            value: status,
          }))}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {previousSessionDraft ? (
          <Box sx={{ position: "absolute" }}><PreviousSessionDraftModal
            boardName={previousSessionDraft.name}
            onCancel={() => {
              setPreviousSessionDraft(null);
              clearPreviousSessionBoardId();
            }}
            onOk={handleContinuePreviousSession}
          /></Box>
        ) : null}
        {!loading ? (
          boards.map((board) => (
            <BoardCard
              showBoard={handleShowBoard}
              deleteBoard={async () => await handleDeleteBoard(board)}
              archiveBoard={async () => await handleArchiveBoard(board)}
              key={board.id}
              board={board}
            />
          ))
        ) : (
          <Loader />
        )}
        {!loading ? (
          <NewBoardCard
            onClick={() => {
              setNewBoardModal(true);
            }}
          />
        ) : null}
        {newBoardModal ? (
          <AddBoardModal
            onFinish={async () => {
              setNewBoardModal(false);
              setKeepState(false);
              await queryBoards(mapFilterStatusToBoardStatus(filterStatus));
            }}
            onCancel={async () => {
              setNewBoardModal(false);
              setKeepState(false);
              setPreviousSessionDraft(null);
              await queryBoards(mapFilterStatusToBoardStatus(filterStatus));
            }}
            keepState={keepState}
          />
        ) : null}
      </Box>
      {archivedBoardId ? (
        <YesNoModal
          open={true}
          label={t("boards.show.archived") ?? ""}
          onNo={() => setArchivedBoardId(null)}
          onYes={() => navigate(`/app/boards/${archivedBoardId}`)}
        />
      ) : null}
    </Box>
  );
};
