import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AIAssistantAction,
  AIGenerationResponse,
  GeneratedGoalResponse,
} from "../../api/AIAssistantApi";
import { AssistantMessage } from "./AssistantMessage";
import DeleteIcon from "@mui/icons-material/Delete";
import { HtmlTooltip } from "../HtmlTooltip";
import { observer } from "mobx-react-lite";
import {
  WizardStep,
  getWizardState,
} from "../../pages/wizzard/state/wizzardState";
import { boardApi } from "../../api/BoardApi";
import useFetch from "../../hooks/useFetch";
import { AIAssistantLoadingScreen } from "./AIAssistantLoadingScreen";
import { Board } from "../../model/board";
import StaggerText from "react-stagger-text";
import { useTranslation } from "react-i18next";
import { PersistedResult } from "../../api/Api";
import { Goal, GoalPhoto } from "../../model/goal";

const GeneratedGoalCard = ({
  goal,
  deleteCallback,
}: {
  goal: GeneratedGoalResponse;
  deleteCallback: () => void;
}) => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Card
      sx={{ maxWidth: 300, position: "relative" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <HtmlTooltip title="Delete">
        <IconButton
          onMouseEnter={() => setHover(true)}
          sx={{
            opacity: hover ? 0.7 : 0,
            position: "absolute",
            right: "5px",
            top: "5px",
            backgroundColor: "#ffe",
            color: "#243040",
            transition: "0.5s ease all",
            "&:hover": {
              backgroundColor: "#ffe",
              opacity: 1,
            },
          }}
          onClick={deleteCallback}
        >
          <DeleteIcon />
        </IconButton>
      </HtmlTooltip>
      <CardMedia
        sx={{ height: 100 }}
        image={goal.photo.urls.regular}
        title={goal.goal}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          sx={{ fontSize: "14px", fontWeight: 700 }}
          component="div"
        >
          {goal.goal}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: "12px" }}
          color="text.secondary"
        >
          {goal.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const GeneratedResponseScreen = observer(
  ({
    response,
    onBack,
    onRegenerate,
    onActionPerformed,
  }: {
    response: AIGenerationResponse;
    onBack: () => void;
    onRegenerate: () => Promise<void>;
    onActionPerformed: () => void;
  }) => {
    const [messageFinished, setMessageFinished] = useState<boolean>(false);
    const [goals, setGoals] = useState<GeneratedGoalResponse[]>([]);
    const state = getWizardState();

    const { callApi, loading } = useFetch();
    const { t } = useTranslation();

    useEffect(() => {
      setGoals(response.goals);
    }, [response]);

    const okCallback = async () => {
      try {
        if (
          state.aiAssistantInput &&
          response.action === AIAssistantAction.GENERATE_GOALS_AND_STATEMENT
        ) {
          const newBoardResponse: { id: string } = await callApi(
            boardApi.assistantCreateBoard({
              goals,
              name: state.aiAssistantInput.boardName,
            })
          );
          const board: Board = await callApi(
            boardApi.getBoard(newBoardResponse.id)
          );
          state.restoreBoard(board);
          state.step = WizardStep.GOALS;
        } else if (response.action === AIAssistantAction.GENERATE_GOAL) {
          const goal = {
            name: response.goals[0].goal,
            photo: {
              type: "unsplash",
              value: response.goals[0].photo,
            } as GoalPhoto,
            tags: [],
            description: response.goals[0].description,
            attachments: [],
          };
          const persistedGoal: PersistedResult = await callApi(
            boardApi.createGoal(state.boardId as string, goal)
          );
          state.goals.push({
            ...goal,
            accomplished: false,
            id: persistedGoal.id,
            attachments: [],
          });
        } else if (response.action === AIAssistantAction.GENERATE_GOALS) {
          const goals = response.goals.map((goal) => ({
            name: goal.goal,
            photo: {
              type: "unsplash",
              value: goal.photo,
            } as GoalPhoto,
            tags: [],
            description: goal.description,
            attachments: [],
          }));
          const persistedGoals: Goal[] = await callApi(
            boardApi.createGoals(state.boardId as string, { goals })
          );
          state.goals.push(...persistedGoals);
        } else if (response.action === AIAssistantAction.GENERATE_STATEMENT) {
          await callApi(
            boardApi.updateBoard(state.boardId as string, {
              name: state.boardName,
              statement: response.statement,
              endDate: state.endDate,
              model: state.boardModel,
            })
          );
          state.boardStatement = response.statement as string;
        }
      } catch (e) {}
      state.aiAssistantRefresh = true;
      onActionPerformed();
    };

    const canRegenerate =
      response.action === AIAssistantAction.GENERATE_STATEMENT ||
      response.action === AIAssistantAction.GENERATE_GOAL;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
        {loading ? (
          <AIAssistantLoadingScreen message="Please wait a few moment. I'm building your new Vision Board..." />
        ) : null}
        <AssistantMessage
          key={"introMessage"}
          message={t(`ai.action.generated.${response.action}`)}
          onFinish={() => setMessageFinished(true)}
        />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            p: "10px 20px",
            opacity: messageFinished ? 1 : 0,
            transition: "0.5s all ease",
          }}
        >
          {response.statement ? (
            <Box
              sx={{
                fontSize: "26px",
                fontWeight: 700,
                fontFamily: "Handlee, cursive",
                textAlign: "center",
              }}
            >
              <StaggerText key={response.statement.replaceAll(" ", "-")} shouldStart={messageFinished} staggerDuration={1}>
                {response.statement}
              </StaggerText>
            </Box>
          ) : null}
          {goals.length ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              {goals.map((goal, index) => (
                <Box key={`goal${index}`}>
                  <GeneratedGoalCard
                    goal={goal}
                    deleteCallback={() => {
                      const filteredGoals = goals.filter(
                        (g) => g.goal !== goal.goal
                      );
                      setGoals(filteredGoals);
                    }}
                  />
                </Box>
              ))}
            </Box>
          ) : null}
          <Button onClick={okCallback} variant="outlined">
            {canRegenerate
              ? "I love it! Let's use it"
              : "I love it! Let's use it"}
          </Button>
          <Button
            onClick={async () => {
              if (canRegenerate) {
                await onRegenerate();
              } else {
                onBack();
              }
            }}
            variant="outlined"
          >
            Regenerate
          </Button>
          <Button onClick={onBack} variant="outlined">
            Go Back
          </Button>
        </Box>
      </Box>
    );
  }
);
