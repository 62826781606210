import { Box, Chip, TextField, Typography, useMediaQuery } from "@mui/material"
import { CustomModal } from "../../../../components/CustomModal"
import { useState } from "react"
import { TypeAnimation } from 'react-type-animation';
import { PowerfulStatement } from "./model";

const MAX_PARTS_LENGTH = 6;

const EXAMPLE_STATEMENT = [
    "I want to eat more vegetables",
    "I want to sleep more",
    "I want to drink no alcohol",
]

export const PowerfulStatementModal = ({ onCancel, onOk }: { onCancel: () => void; onOk: (value: PowerfulStatement) => void }) => {

    const [parts, setParts] = useState<string[]>([]);
    const [inputPart, setInputPart] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const validate = () => parts.length > 0 && parts.length <= MAX_PARTS_LENGTH;
    const mobileMenu = useMediaQuery("(max-width:800px)");

    return (
        <CustomModal open={true} cancelIcon onCancel={onCancel} onOk={() => {
            if (validate()) {
                onOk({
                    parts
                });
            } else {
                setError(true);
            }
        }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", mb: "20px" }}>
                <Box sx={{ fontFamily: "Lato, sans-serif", fontSize: mobileMenu ? "14px" : "18px", fontStyle: "italic", textAlign: "justify", gap: "10px" }}>
                    Powerful statement is a special kind of element of your Vision Board to keep motivate you to work.
                    It's a dynamic sentence that will keep changing while you will be looking at your board.
                    You can define up to 6 statements.
                    <br /><br />
                    For more info please check our Tutorials section or apply an example <a href="" onClick={(e) => { e.preventDefault(); setParts(EXAMPLE_STATEMENT) }}>Powerful statement</a>
                </Box>
                <Box>
                    <TextField
                        disabled={parts.length === MAX_PARTS_LENGTH}
                        label={"Powerful statement line"}
                        sx={{ width: "100%" }}
                        onChange={(e) => {
                            setInputPart(e.currentTarget.value || "");
                            setError(false);
                        }}
                        error={error}
                        helperText={error ? "Missing powerful statement value" : null}
                        value={inputPart} onKeyDown={(e) => {
                            if (e.code === "Enter" && inputPart.length) {
                                setParts([...parts, inputPart]);
                                setInputPart("");
                            }
                        }} />
                </Box>
                <Box>
                    {
                        parts.map((part) => (<Chip key={`chip${part}`} label={part} variant="outlined" onDelete={() => setParts([...parts.filter(p => p !== part)])} sx={{ m: "5px" }} />))
                    }
                </Box>
                <Box>
                    <Typography>Preview</Typography>
                    <Box sx={{ p: "5px", border: "1px dashed #aabbcc" }}>
                        {parts.length ?
                            <TypeAnimation
                                key={`preview${parts.length}`}
                                sequence={parts.flatMap((part => [part, 1000]))}
                                speed={50}
                                style={{ fontSize: mobileMenu ? "16px" : '22px', fontFamily: "Lato, sans-serif", fontWeight: "bold" }}
                                repeat={Infinity}
                            /> : <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: mobileMenu ? "16px" : "18px", fontFamily: "Lato, sans-serif", fontStyle: "italic" }}>No powerful statements defined</Box>}
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    )
}