import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Board, BoardStatus } from "../model/board";
import { boardApi } from "../api/BoardApi";
import useFetch from "../hooks/useFetch";
import { Masonry } from "./screensaver/Masonry";
import { CustomSelect } from "../components/CustomSelect";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Guide } from "../components/Guide";
import { IPhoneModals } from "../components/IPhponeModals";
import { useNavigate } from "react-router-dom";

export const NoBoardsAvailable = () => {
  const navigate = useNavigate();
  const mobileMenu = useMediaQuery("(max-width:630px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography sx={{ fontStyle: "italic", textAlign: "center", fontSize: mobileMenu ? "18px" : "22px", pb: "20px" }}>
        Ups, it looks like there are no boards available. Click the button below
        to navigate to Boards page
      </Typography>
      <Button variant="contained" onClick={() => navigate("/app/boards")}>
        Boards
      </Button>
    </Box>
  );
};

export const Screensaver = () => {
  const [boards, setBoards] = useState<Board[]>([]);
  const [boardId, setBoardId] = useState<string | null>(null);
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const { callApi, loading } = useFetch();

  const queryBoards = async (statuses: BoardStatus[]) => {
    const result: Board[] = await callApi(boardApi.getBoards(statuses));
    setBoards(result);
  };

  useEffect(() => {
    queryBoards([BoardStatus.READY]);
  }, []);

  useEffect(() => {
    const queryBoard = async () => {
      if (boardId) {
        try {
          const board: Board = await callApi(boardApi.getBoard(boardId));
          setSelectedBoard(board);
        } catch (e) {
          console.log("notification");
        }
      }
    };
    queryBoard();
  }, [boardId]);

  const mobileMenu = useMediaQuery("(max-width:630px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        gap: "20px",
      }}
    >
      <IPhoneModals name="screensaver" />
      <Box
        sx={{
          display: "flex",
          gap: mobileMenu ? "10px" : "40px",
          justifyContent: mobileMenu ? "center" : "start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ maxWidth: "500px", width: "100%" }}>
          <Guide type="SCREENSAVER" />
          <CustomSelect
            selectId="visualization-select"
            label="Active Vision Board"
            value={boardId || ""}
            fullWidth
            options={boards.map((board) => ({
              label: board.name,
              value: board.id,
            }))}
            onChange={(value) => {
              setBoardId(value);
            }}
            selectSx={{
              minWidth: mobileMenu ? "300px" : undefined,
              width: mobileMenu ? "100%" : undefined,
            }}
          />
        </Box>
        {!boardId && boards.length === 0 && !loading ? (
          <NoBoardsAvailable />
        ) : null}
        {boardId ? (
          <Button
            disabled={boardId === null}
            variant="text"
            onClick={() => {
              setFullScreen(!fullScreen);
            }}
          >
            <FullscreenIcon />
            {!fullScreen ? "Enter full screen" : "Exit full screen"}
          </Button>
        ) : null}
      </Box>
      <Box>
        {selectedBoard ? (
          <Masonry
            exitFullScreenCallback={() => setFullScreen(false)}
            fullScreen={fullScreen}
            board={selectedBoard}
          />
        ) : null}
      </Box>
    </Box>
  );
};
