import { generateRandom } from "../utils/numbers";

interface Quote {
  value: string;
  author: string;
}

const QUOTES: Quote[] = [
  {
    value: "If your dreams don't scare you, they are too small.",
    author: "Richard Branson",
  },
  {
    value: "The purpose of our lives is to be happy.",
    author: "Dalai Lama",
  },
  {
    value: "The best way to get started is to quit talking and begin doing.",
    author: "Walt Disney",
  },
  {
    value: "Today is your opportunity to build the tomorrow you want.",
    author: "Ken Poirot"
  },
  {
    value: "Don't wait. The time will never be just right.",
    author: "Napoleon Hill"
  },
  {
    value: "Don't wait. The time will never be just right.",
    author: "Napoleon Hill"
  },
  {
    value: "The best way to predict the future is to create it.",
    author: "Abraham Lincoln",
  },
  {
    value: "A winner is a dreamer who never gives up.",
    author: "Nelson Mandela"
  }
];

export const getQuote = (): Quote => QUOTES[generateRandom(0, QUOTES.length - 1)];