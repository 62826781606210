import { Board } from "../model/board";
import { Api } from "./Api";

export enum RepeatType {
    EVERYDAY = "EVERYDAY",
    WEEKDAYS = "WEEKDAYS",
    WEEKEND = "WEEKEND",
    CUSTOM = "CUSTOM"
};

export const DAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

export interface RequestNotificationSettingsDto {
    repeatType: RepeatType;
    repeatCustom?: string[];
    executionTime: number;
    pushEnabled: boolean;
    emailEnabled: boolean;
    reminderEnabled: boolean;
    productUpdatesEnabled: boolean;
    timezone: string;
}

export type NotificationSettingsDto = Omit<RequestNotificationSettingsDto, "timezone">;


class NotificationApi extends Api {
    register(token: string) {
        return this.post("notifications/token", { token });
    }
    getSettings() {
        return this.get("notifications/settings");
    }
    updateSettings(dto: RequestNotificationSettingsDto) {
        return this.post("notifications/settings", dto);
    }
}

export const notificationApi = new NotificationApi();