import { Box, IconButton, useMediaQuery } from "@mui/material";
import { CustomModal } from "../../components/CustomModal";
import { useCallback, useEffect, useState } from "react";
import { WelcomeStep } from "./steps/WelcomeStep";
import { NameYourVisionBoardStep } from "./steps/NameYourVisionBoardStep";
import { DefineGoalsStep } from "./steps/DefineGoalsStep";
import { observer } from "mobx-react-lite";
import { WizardStep, getWizardState } from "./state/wizzardState";
import { DesignerStep } from "./steps/DesignerStep";
import { SummaryStep } from "./steps/SummaryStep";
import { boardApi } from "../../api/BoardApi";
import useFetch from "../../hooks/useFetch";
import { PersistedResult } from "../../api/Api";
import { WizardSideMenu } from "./WizardSideMenu";
import { WizardBottomMenu } from "./WizardBottomMenu";
import { AIAssistantIcon } from "../../components/aiAssistant/AIAssistantIcon";
import { HtmlTooltip } from "../../components/HtmlTooltip";
import { AIAssistantWindow } from "../../components/aiAssistant/AIAssistantWindow";

const STEPS = [
  "Welcome",
  "Name your Vision board",
  "Define your goals",
  "Visualise it",
  "Summary",
];

const StepComponent = ({
  index,
  onClose,
}: {
  index: number;
  onClose: () => void;
}) => {
  switch (index) {
    case 0:
      return <WelcomeStep />;
    case 1:
      return <NameYourVisionBoardStep />;
    case 2:
      return <DefineGoalsStep />;
    case 3:
      return <DesignerStep />;
    default:
      return <SummaryStep onClose={onClose} />;
  }
};

export const AddBoardModal = observer(
  ({
    onCancel,
    onFinish,
    keepState = false,
  }: {
    onCancel: () => void;
    onFinish: () => Promise<void>;
    keepState?: boolean;
  }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [nextStepAvailable, setNextStepAvailable] = useState<boolean>(false);
    const { loading, callApi } = useFetch();

    const handleStep = useCallback(
      (step: number) => () => {
        if ((step > activeStep && nextStepAvailable) || step <= activeStep) {
          setActiveStep(step);
        }
      },
      []
    );

    const state = getWizardState();

    useEffect(() => {
      if (!keepState) {
        state.reset();
      } else {
        setActiveStep(state.step === WizardStep.SET_UP_NAME ? 1 : 2);
      }
    }, []);

    useEffect(() => {
      if (state.aiAssistantRefresh) {
        setActiveStep(state.step === WizardStep.SET_UP_NAME ? 1 : 2);
        state.aiAssistantRefresh = false;
      }
    }, [state.aiAssistantRefresh]);

    useEffect(() => {
      setNextStepAvailable(state.nextStepAvailable);
    }, [state, state.nextStepAvailable]);

    const handleNextStep = async () => {
      if (activeStep !== 0) {
        try {
          if (!state.boardId) {
            const createdBoard: PersistedResult = await callApi(
              boardApi.createBoard({
                name: state.boardName,
                statement: state.boardStatement,
                endDate: state.endDate,
              })
            );
            state.boardId = createdBoard.id;
          } else {
            await callApi(
              boardApi.updateBoard(state.boardId, {
                name: state.boardName,
                statement: state.boardStatement,
                endDate: state.endDate,
                model: state.boardModel,
              })
            );
          }
          setActiveStep(activeStep + 1);
        } catch (e) {
          console.log("error");
          console.error(e);
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    };

    const mobileMenu = useMediaQuery("(max-width:800px)");
    const isWideScreen = useMediaQuery("(min-width:1600px)");

    return (
      <CustomModal
        id="addBoardModal"
        open={true}
        onCancel={onCancel}
        cancelIcon={true}
        noPadding
        sx={{
          width: `calc(100% - ${mobileMenu ? 10 : 30}px)`,
          height: `calc(100% - ${mobileMenu ? 10 : 30}px)`,
          border: "undefined",
          boxShadow: "0px 0px 15px 0px rgba(66, 68, 90, 1)",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: mobileMenu ? "column" : "row",
            gap: "20px",
            alignItems: "center",
            pt: mobileMenu ? "30px" : "0px",
            pb: mobileMenu ? "90px" : "0px",
            pl: mobileMenu ? "10px" : "0px",
            pr: mobileMenu ? "10px" : "0px",
          }}
        >
          {mobileMenu ? null : (
            <WizardSideMenu
              activeStep={activeStep}
              handleNextStep={handleNextStep}
              handleStep={handleStep}
              handlePreviousStep={() => setActiveStep(activeStep - 1)}
              nextStepAvailable={nextStepAvailable}
              steps={STEPS}
              loadingNextStep={loading}
            />
          )}
          {mobileMenu ? (
            <WizardBottomMenu
              activeStep={activeStep}
              handleNextStep={handleNextStep}
              handleStep={handleStep}
              handlePreviousStep={() => setActiveStep(activeStep - 1)}
              nextStepAvailable={nextStepAvailable}
              steps={STEPS}
              loadingNextStep={loading}
            />
          ) : (
            <Box
              sx={{
                height: "100%",
                minWidth: isWideScreen ? "350px" : "250px",
              }}
            ></Box>
          )}
          <StepComponent index={activeStep} onClose={() => onFinish()} />
          <AIAssistantWindow />
          <Box
            sx={{
              position: "absolute",
              bottom: mobileMenu ? "85px" : "30px",
              backgroundColor: "#fff",
              boxShadow: mobileMenu ? "0px 0px 2px 0px rgba(66, 68, 90, 1)" : undefined,
              borderRadius: "10px",
              right: mobileMenu ? "10px" : "30px",
              display: "flex",
            }}
          >
            <HtmlTooltip title={"AI Assistant"}>
              <IconButton
                onClick={() => {
                  state.aiAssistant = !state.aiAssistant;
                }}
              >
                <AIAssistantIcon />
              </IconButton>
            </HtmlTooltip>
          </Box>
        </Box>
      </CustomModal>
    );
  }
);
