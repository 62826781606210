import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AIAssistantAction } from "../../api/AIAssistantApi";
import { AssistantMessage } from "./AssistantMessage";
import { useTranslation } from "react-i18next";

export const InitialScreen = ({ actions, handleAction }: { actions: AIAssistantAction[]; handleAction: (action: AIAssistantAction) => Promise<void> }) => {
    const [messageFinished, setMessageFinished] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <AssistantMessage
          key={"introMessage"}
          message="Hello, I'm your AI Assistant and I will try to help your create your perfect Vision Board. Would you like me to: "
          onFinish={() => setMessageFinished(true)}
        />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            p: "10px 20px",
            opacity: messageFinished ? 1 : 0,
            transition: "0.5s all ease",
          }}
        >
          {actions.map((action) => (
            <Button key={action} onClick={() => handleAction(action)} variant="outlined">
              {t(`ai.action.${action}`)}
            </Button>
          ))}
          <Button onClick={() => navigate("/app/tutorials")} variant="outlined">
            Show tutorials
          </Button>
        </Box>
      </Box>
    );
  };