import { Api } from "./Api";

export interface DashboardVisualizationStatistics {
    visualizationDaysInRow: number;
    lastVisualization: null | {
        boardName: string;
        date: string;
    };
    mostVisualizedBoard: string | null;
    accomplishedGoals: number;
  }

class DashboardApi extends Api {
    getStatistics() {
        return this.get(`dashboard/statistics`);
    }
}

export const dashboardApi = new DashboardApi();