import { Box } from "@mui/material";
import { CustomModal } from "../../../../components/CustomModal";
import { Background } from "./model";
import Particles from "react-particles";
import { useCallback, useState } from "react";
import { snow } from "../../../../assets/particles/snow";
import { connect } from "../../../../assets/particles/connect";
import { CustomSelect } from "../../../../components/CustomSelect";
import type { ISourceOptions } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim"; 
import { blackHole } from "../../../../assets/particles/blackHole";
import { stars } from "../../../../assets/particles/stars";
import { parallax } from "../../../../assets/particles/parallax";
import { heavySnow } from "../../../../assets/particles/heavySnow";
import { mapBoardBackgroundImage } from "./utils";
import { useTranslation } from "react-i18next";

export enum BackgroundAnimation {
    NONE = "NONE",
    SNOW = "SNOW",
    HEAVY_SNOW = "HEAVY_SNOW",
    CONNECT = "CONNECT",
    BLACK_HOLE = "BLACK_HOLE",
    STARS = "STARS",
    PARALLAX = "PARALLAX",
}

export const MAPPINGS: { [key in BackgroundAnimation]: ISourceOptions | undefined } = {
    CONNECT: connect,
    NONE: undefined,
    SNOW: snow,
    BLACK_HOLE: blackHole,
    STARS: stars,
    PARALLAX: parallax,
    HEAVY_SNOW: heavySnow,
}

export const BackgroundAnimationModal = ({ initialAnimation, background, onCancel, onOk }: { initialAnimation: BackgroundAnimation, background: Background; onCancel: () => void; onOk: (animation: BackgroundAnimation) => void }) => {

    const [animation, setAnimation] = useState<BackgroundAnimation>(initialAnimation);

    const { t } = useTranslation();

    const particlesInit = useCallback(async (engine: any) => {
        await loadSlim(engine);
    }, []);

    return (
        <CustomModal open={true} cancelIcon okLabel='Save' onCancel={onCancel} onOk={() => onOk(animation)}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ width: "100%", fontSize: "20px", pb: "20px" }}>Background animations</Box>
                <CustomSelect selectSx={{ backgroundColor: "#fff" }} label="Animation" options={Object.keys(BackgroundAnimation).map((val) => ({ label: t(`animation.${val}`), value: val }))} fullWidth value={animation} onChange={(val) => setAnimation(val as BackgroundAnimation)} />
                <Box sx={{ height: "300px", width: "100%", position: "relative", mt: "20px", 
                    backgroundColor: background.type === "color" ? background.value as string : undefined,
                    backgroundImage: mapBoardBackgroundImage(background),
                    backgroundRepeat: background.type === "image" ? "no-repeat" : undefined,
                    backgroundSize: background.type === "image" ? "cover" : undefined,
                    border: "1px dashed #9999FF" }}>
                    <Particles id="tsparticles-preview" init={particlesInit} height="300px" width="100%" options={{...MAPPINGS[animation], fullScreen: { enable: false, zIndex: 0 }}} />
                </Box>
            </Box>
        </CustomModal>
    )
}

