import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { CustomSelect } from "../../components/CustomSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomSwitch } from "../../components/CustomSwitch";
import { Guide } from "../../components/Guide";

export enum VisualizationType {
  BOARD_BACKGROUND = "BOARD_BACKGROUND",
  ZOOM_IN_ZOOM_OUT = "ZOOM_IN_ZOOM_OUT",
  CLOUDS = "CLOUDS",
  CELLS = "CELLS",
  HALO = "HALO",
}

export interface VisualizatorSettings {
  type: VisualizationType;
  withAudio: boolean;
  withLoop: boolean;
}

const SERIALIZED_SETTINGS_KEY = "__vsettings";
const DEFAULT_SETTINGS: VisualizatorSettings = {
  type: VisualizationType.BOARD_BACKGROUND,
  withAudio: true,
  withLoop: true,
};

export const getSerializedVisualizatorSettings = (): VisualizatorSettings => {
  try {
    const json = localStorage.getItem(SERIALIZED_SETTINGS_KEY);
    if (json) {
      return JSON.parse(json) as VisualizatorSettings;
    } else {
      return DEFAULT_SETTINGS;
    }
  } catch (e) {
    return DEFAULT_SETTINGS;
  }
};

const serializeSettings = (settings: VisualizatorSettings) => {
  try {
    localStorage.setItem(SERIALIZED_SETTINGS_KEY, JSON.stringify(settings));
  } catch (e) {
    //
  }
};

const VISUALIZATION_MAPPINGS: { [key in VisualizationType]: number } = {
  BOARD_BACKGROUND: 1,
  ZOOM_IN_ZOOM_OUT: 2,
  CLOUDS: 3,
  HALO: 4,
  CELLS: 5
}

export const mapVisualizationPath = (type: VisualizationType, boardId: string) => `/app/visualization/${VISUALIZATION_MAPPINGS[type]}/${boardId}`;

export const VisualizatorSettingsContainer = ({
  withLabel = true,
  onOK
}: {
  withLabel?: boolean;
  onOK: (type: VisualizationType) => void;
}) => {
  const [type, setType] = useState<VisualizationType>(
    VisualizationType.BOARD_BACKGROUND
  );
  const [withAudio, setAudio] = useState<boolean>(true);
  const [withLoop, setLoop] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    const serializedSettings = getSerializedVisualizatorSettings();
    setType(serializedSettings.type);
    setAudio(serializedSettings.withAudio);
    setLoop(serializedSettings.withLoop);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Guide type="VISUALIZATOR_SETTINGS" />
      {withLabel ? (
        <Typography sx={{ textAlign: "center", fontSize: "20px" }}>
          Visualization settings
        </Typography>
      ) : null}
      <CustomSelect
        label="Visualization Type"
        value={type}
        fullWidth
        selectId={"visualization-type"}
        options={Object.keys(VisualizationType).map((type) => ({
          label: t(`visualization.${type}`),
          value: type,
        }))}
        onChange={(value) => {
          setType(value as VisualizationType);
        }}
      />
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={() => setAudio(!withAudio)}
                checked={withAudio}
              />
            }
            label={t("visualization.settings.with-audio")}
          />
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={() => setLoop(!withLoop)}
                checked={withLoop}
              />
            }
            label={t("visualization.settings.with-loop")}
          />
        </FormGroup>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            serializeSettings({
              type,
              withAudio,
              withLoop,
            });
            onOK(type)
          }}
        >
          Visualize
        </Button>
      </Box>
    </Box>
  );
};
