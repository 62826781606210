import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, TextField, Tooltip } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useRef, useState } from "react";
import { RichTextEditor } from "../../components/RichTextEditor";
import { GoalRelatedElement, JournalEntry } from "../../model/goal";
import AddBoxIcon from '@mui/icons-material/AddBox';
import dayjs from "dayjs";
import SortIcon from '@mui/icons-material/Sort';
import { boardApi } from "../../api/BoardApi";
import { Loader } from "../../components/Loader";
import { YesNoModal } from "../../components/YesNoModal";
import DeleteIcon from '@mui/icons-material/Delete';
import useFetch from "../../hooks/useFetch";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Entry = ({ journalEntry, refreshCallback, boardId, goalId }: { journalEntry: JournalEntry; refreshCallback: () => Promise<void> } & GoalRelatedElement) => {

    const [expanded, setExpanded] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { callApi } = useFetch();

    return (
        <>
            <Box
                onPointerEnter={() => setHovered(true)}
                onPointerLeave={() => setHovered(false)}
                sx={{ transition: "0.5s all ease", display: "flex", gap: "50px", justifyContent: "space-between", alignItems: "center", backgroundColor: hovered ? "#ECECEC" : "#FAFAFA", fontFamily: "Lato, sans-serif", p: "15px", borderRadius: "10px", boxShadow: hovered ? "0px 0px 2px 0px rgba(66, 68, 90, 1)" : "0px 0px 1px 0px rgba(66, 68, 90, 1)" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", width: "100%" }}>
                    <Box sx={{ borderRadius: "5px", opacity: hovered ? 1 : 0, right: 0, transition: "0.3s all ease", position: "absolute", display: "flex", transform: "translate(-10px, -30px)", backgroundColor: "#ecf1ff", zIndex: 10 }}>
                        <Tooltip title="Delete" arrow placement='top'>
                            <IconButton onClick={() => {
                                setConfirmModal(true);
                                setHovered(false);
                            }}><DeleteIcon fontSize='small' /></IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ fontSize: "12px" }}>{dayjs(journalEntry.creationDate).format("dddd, MMMM D, YYYY h:mm A")}</Box>
                    <Box sx={{ fontSize: "18px", fontWeight: "bold", cursor: "pointer", width: "100%" }} onClick={() => {
                        if (journalEntry.body && journalEntry.body.length) {
                            setExpanded(!expanded);
                        }
                    }}>{journalEntry.title}</Box>
                    {expanded ?
                        <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{
                            __html: journalEntry.body ?? ""
                        }} /> : null}
                </Box>
                {/* TODO - post-MVP images added to journal entry */}
                {/* <Box>
                    <Box component={"img"} src="https://hips.hearstapps.com/hmg-prod/images/ama-dablam-mountain-peak-view-from-chola-pass-royalty-free-image-1623254695.jpg" sx={{ borderRadius: "10px", width: "80px" }} />
                </Box> */}
            </Box>
            {confirmModal ? <YesNoModal open={true} label="Are you sure we want to delete this entry?" onNo={() => setConfirmModal(false)} onYes={async () => {
                await callApi(boardApi.deleteGoalJournalEntry(boardId, goalId, journalEntry.id));
                await refreshCallback();
                setConfirmModal(false);
            }} /> : null}
        </>
    )
}

type SortOrder = "asc" | "desc";

const Entries = ({ entries, refreshCallback, boardId, goalId, sort }: { entries: JournalEntry[]; refreshCallback: () => Promise<void>; sort: SortOrder } & GoalRelatedElement) => {

    const [sorterdEntries, setSortedEntries] = useState<JournalEntry[]>(entries);

    useEffect(() => {
        // TODO - fix dates and sorting, wrongly presented now
        setSortedEntries([...entries.sort((e1, e2) => sort === "asc" ? dayjs(e1.creationDate).unix() - dayjs(e2.creationDate).unix() : dayjs(e2.creationDate).unix() - dayjs(e1.creationDate).unix())]);
    }, [sort, entries]);

    if (sorterdEntries.length) {
        return (
            <Box sx={{ p: "20px 0px", display: "flex", flexDirection: "column", gap: "10px" }}>
                {sorterdEntries.map((entry) => (<Entry boardId={boardId} goalId={goalId} refreshCallback={refreshCallback} journalEntry={entry} key={entry.id} />))}
            </Box>
        )
    } else {
        return (
            <Box sx={{ fontFamily: "Lato, sans-serif", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 300, fontStyle: "italic", height: "100%", fontSize: "26px" }}>
                Your goal journal is empty. Click plus icon in top right corner to add your first entry!
            </Box>
        )
    }
}

export const GoalJournalModal = ({ boardId, goalId }: { boardId: string; goalId: string }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [newEntry, setNewEntry] = useState<boolean>(false);
    const editorRef = useRef(null);
    const [entries, setEntries] = useState<JournalEntry[]>([]);
    const [title, setTitle] = useState<string>("");
    const [sort, setSort] = useState<SortOrder>("desc");
    const [addLoading, setAddLoading] = useState<boolean>(false);
    const { loading, callApi } = useFetch();

    // const [addImage, setAddImage] = useState<boolean>(false);

    // TODO useCallback
    const queryEntries = async () => {
        // FIXME
        const entries: JournalEntry[] = await callApi(boardApi.getGoalJournal(boardId, goalId));
        setEntries(entries);
    }

    useEffect(() => {
        queryEntries();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const addEntry = async () => {
        if (editorRef.current) {
            // @ts-ignore
            const description: string = editorRef.current ? editorRef.current.getContent() : "";
            if (title.length) {
                setAddLoading(true);
                await callApi(boardApi.createGoalJournal(boardId, goalId, {
                    title,
                    body: description
                }));
                const entries: JournalEntry[] = await callApi(boardApi.getGoalJournal(boardId, goalId));
                setEntries(entries);
                setNewEntry(false);
                setTitle("");
                setAddLoading(false);
            }
        }
    }

    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            fontStyle: "italic",
            flexDirection: "column",
            textAlign: "center",
            fontFamily: "Lato, sans-serif",
            gap: "30px"
        }}> Your personal goal journal. You can track all your activities related to this goal. When you complete a goal, it is good to sit down for a moment and reflect on the entire path you have went through to achieve this goal.
            <Button onClick={() => setOpen(true)} variant="outlined">Open journal</Button>
            <Dialog
                disableEnforceFocus
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                sx={{
                    ".MuiDialog-paper": {
                        width: "80vw", maxWidth: "80vw",
                        background: "#fff"
                    }
                }}
            >
                {newEntry ? null : <Box sx={{ position: "absolute", right: "10px", top: "15px", display: "flex", alignItems: "center" }}>
                    <Tooltip placement="top" arrow title={`Sort ${sort === "desc" ? "Ascending" : "Descending"}`}>
                        <IconButton onClick={() => setSort(sort === "asc" ? "desc" : "asc")}><SortIcon /></IconButton>
                    </Tooltip>
                    <Tooltip placement="top" arrow title="Add new entry">
                        <IconButton onClick={() => setNewEntry(true)}><AddBoxIcon /></IconButton>
                    </Tooltip>
                </Box>}
                <DialogTitle sx={{ width: "100%", textAlign: "center", fontFamily: "Lato, sans-serif", fontWeight: 400, fontStyle: "italic", fontSize: "24px" }}>{newEntry ? "Add new entry" : "Goal journal"}</DialogTitle>
                <DialogContent sx={{ width: "80vw", height: "90vh" }}>
                    {newEntry ?
                        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Box sx={{ display: "flex", width: "100%", gap: "20px" }}>
                                <TextField
                                    disabled={addLoading}
                                    value={title}
                                    onChange={(e) => setTitle(e.currentTarget.value)}
                                    placeholder="Title"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: {
                                            fontFamily: "Lato, sans-serif", fontSize: "24px"
                                        }
                                    }} sx={{ width: "100%", border: "0px" }} />
                                    {/* TODO add this feature a bit later */}
                                {/* <Tooltip arrow placement="top" title="Add image"><IconButton onClick={() => setAddImage(true)} disabled={addLoading}><ImageIcon /></IconButton></Tooltip> */}
                            </Box>
                            <RichTextEditor initialValue="" ref={editorRef} />
                        </Box>
                        : loading ? <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}><Loader /></Box> : <Entries boardId={boardId} goalId={goalId} refreshCallback={queryEntries} sort={sort} entries={entries} />}
                </DialogContent>
                <DialogActions sx={{ p: "20px 24px" }}>
                    <Button disabled={addLoading && newEntry} variant="contained" onClick={newEntry ? addEntry : handleClose}>{newEntry ? "Add entry" : "Close journal"}</Button>
                    {newEntry ? <Button disabled={addLoading} variant="outlined" onClick={() => { setNewEntry(false); setTitle("") }}>Cancel</Button> : null}
                </DialogActions>
            </Dialog>
        </Box>
    )
}