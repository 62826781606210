import { Api } from "./Api";

export interface VersionResponse {
  uiVersion: string,
}

class VersionApi extends Api {
  version() {
    return this.get("version");
  }
}

export const versionApi = new VersionApi();
