import { Typography } from "@mui/material";
import { TextStyles } from "../toolbox/TextToolbox";
import { mapStylesToSx } from "./Label";

export enum GradientUnderlineColor {
  "Blue" = "Blue",
  "Orange" = "Orange",
  "Green" = "Green",
  "Vivid" = "Vivid",
}

const MAPPINGS: {[variant in GradientUnderlineColor]: string} = {
    Blue: "rgb(0, 210, 255), rgb(58, 123, 213)",
    Green: "rgb(91, 255, 24), rgb(73, 120, 65)",
    Orange: "rgba(253, 173, 90, 0.5), rgb(255, 136, 63)",
    Vivid: "rgb(240, 240, 63), rgb(240, 42, 213)"
}

export const GradientUnderlineLabel = ({
  text,
  styles,
  variant,
}: {
  text: string;
  styles: TextStyles;
  variant: GradientUnderlineColor;
}) => {
  return (
    <Typography
      sx={{
        ...mapStylesToSx(styles),
        border: "0px",
        borderBottom: "4px solid",
        borderImage:
          `linear-gradient(to right, ${MAPPINGS[variant]})`,
        borderImageSlice: 1,
        textDecoration: "none",
      }}
    >
      {text}
    </Typography>
  );
};
