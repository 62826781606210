import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PagesIcon from "@mui/icons-material/Pages";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import YardIcon from "@mui/icons-material/Yard";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import TheatersIcon from "@mui/icons-material/Theaters";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { LogoText } from "./components/LogoText";
import { SignOutButton, UserButton } from "@clerk/clerk-react";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { scrollToTopApp } from "./utils/common";
import { dispatchEvent } from "./events/eventBus";

interface MenuItem {
  name: string;
  icon: ReactElement;
  path: string;
  comingSoon?: boolean;
}

const DASHBOARD_MENU_ITEMS: MenuItem[] = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "dashboard",
  },
  {
    name: "Vision Boards",
    icon: <PagesIcon />,
    path: "boards",
  },
  {
    name: "Visualization",
    icon: <TheatersIcon />,
    path: "visualization",
  },
  {
    name: "Screensaver",
    icon: <NightsStayIcon />,
    path: "screensaver",
  },
  {
    name: "Tutorials",
    icon: <SchoolIcon />,
    path: "tutorials",
  },
  {
    name: "Journal",
    icon: <MenuBookIcon />,
    path: "journal",
    comingSoon: true,
  },
  // let's not expose it in MVP
  // {
  //   name: "Daily workflows",
  //   icon: <YardIcon />,
  //   path: "workflows",
  //   comingSoon: true,
  // },
  // {
  //   name: "Goals backlog",
  //   icon: <LibraryAddCheckIcon />,
  //   path: "backlog",
  //   comingSoon: true,
  // },
  {
    name: "Community",
    icon: <PeopleIcon />,
    path: "community",
    comingSoon: true,
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    path: "settings",
  },
];

export const DashboardMenuItem = ({
  item,
  smallSize,
  onClickCallback,
}: {
  item: MenuItem;
  smallSize: boolean;
  onClickCallback?: () => void;
}) => {
  const [selected, setSelected] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname.startsWith(`/app/${item.path}`)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [item.path, location]);

  return (
    <Button
      id={`menu-${item.path}`}
      component={Link}
      to={item.path}
      onClick={() => {
        if (onClickCallback) {
          onClickCallback();
        }
        scrollToTopApp();
      }}
      sx={{
        p: "0px",
        m: "5px 15px",
        justifyContent: "start",
        backgroundColor: selected ? "#ffe" : undefined,
        minWidth: smallSize ? "24px" : undefined,
        "&.MuiButton-root:hover": {
          backgroundColor: "#ffe",
          "& > div": {
            color: "#243040",
          },
        },
        "&:hover": { color: "#243040" },
        opacity: item.comingSoon ? "0.7" : undefined,
      }}
    >
      <Box
        sx={{
          display: "flex",
          pt: "5px",
          pb: "5px",
          alignItems: "center",
          // color: selected ? "#5B7EF2" : "#778AC7",
          color: selected ? "#243040" : "#ffe",
          pl: smallSize ? "0px" : "8px",
          justifyContent: smallSize ? "center" : undefined,
          width: smallSize ? "100%" : undefined,
          "&:hover": { color: "#243040" },
        }}
      >
        <Tooltip arrow placement="right" title={smallSize ? item.name : null}>
          {item.icon}
        </Tooltip>
        {!smallSize ? (
          <Box
            sx={{
              pl: "15px",
              fontSize: "13px",
              opacity: item.comingSoon ? "0.7" : undefined,
            }}
          >
            {item.name}
          </Box>
        ) : null}
      </Box>
      {item.comingSoon && !smallSize ? (
        <Badge
          badgeContent={"Coming soon"}
          sx={{
            backgroundColor: "#73b9ee",
            color: "#fff",
            "&.MuiBadge-root": {
              // opacity: "0.8",
              position: "absolute",
              ml: "195px",
            },
            ".MuiBadge-badge": {
              fontSize: "8px",
              textAlign: "center",
              backgroundColor: "#73b9ee",
              color: "#fff",
            },
          }}
        />
      ) : null}
    </Button>
  );
};

const MobileMenu = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          width: "100vw",
          backgroundColor: "#243040",
          height: "56px",
          display: "flex",
          flex: 1,
        }}
      >
        <Box sx={{ pl: "20px", pr: "20px" }}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
              minHeight: "56px !important",
            }}
            disableGutters
          >
            <LogoText small size={20} />
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <IconButton>
                <NotificationsIcon sx={{ color: "#ffe" }} />
              </IconButton>

              <UserButton afterSignOutUrl="/" />
              <IconButton
                onClick={() =>
                  mobileMenuOpen
                    ? setMobileMenuOpen(false)
                    : setMobileMenuOpen(true)
                }
              >
                {mobileMenuOpen ? (
                  <CloseIcon sx={{ color: "#ffe" }} />
                ) : (
                  <MenuIcon sx={{ color: "#ffe" }} />
                )}
              </IconButton>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Slide direction="right" in={mobileMenuOpen}>
        <Box
          sx={{
            zIndex: 400,
            width: "70vw",
            height: "100vh",
            position: "absolute",
            backgroundColor: "#243040",
            display: "flex",
            flexDirection: "column",
            pt: "80px",
          }}
        >
          {DASHBOARD_MENU_ITEMS.map((item, index) => (
            <DashboardMenuItem
              onClickCallback={() => setMobileMenuOpen(false)}
              item={item}
              key={`menuItem${index}`}
              smallSize={false}
            />
          ))}
          <Box
            sx={{
              display: "flex",
              m: "20px",
              color: "#D0D7EF",
              alignItems: "end",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <SignOutButton signOutCallback={() => navigate("/")}>
              <Button sx={{ color: "#ffe" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LogoutIcon />
                  <Box sx={{ pl: "15px", fontSize: "12px" }}>Logout</Box>
                </Box>
              </Button>
            </SignOutButton>
          </Box>
        </Box>
      </Slide>
    </>
  );
};

export const AppMenu = () => {
  const [smallSize, setSmallSize] = useState<boolean>(false);
  const forceSmallMenu = useMediaQuery("(max-width:900px)");
  const mobileMenu = useMediaQuery("(max-width:760px)");

  const navigate = useNavigate();

  return (
    <>
      {mobileMenu ? <MobileMenu /> : null}
      {!mobileMenu ? (
        <>
          <Box
            sx={{
              height: "100%",
              width: smallSize || forceSmallMenu ? "70px" : "250px",
              display: "flex",
              flexDirection: "column",
              zIndex: 190,
            }}
          >
            {/* THIS IS PLACEHOLDER */}
          </Box>
          <Box
            sx={{
              height: "100%",
              width: smallSize || forceSmallMenu ? "70px" : "250px",
              backgroundColor: "#243040",
              display: "flex",
              flexDirection: "column",
              position: "fixed",
              zIndex: 200,
            }}
          >
            <Box
              sx={{
                pt: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                color: "#fff",
                cursor: "pointer",
              }}
              onClick={() => {
                setSmallSize(!smallSize);
                dispatchEvent("menu_size_change")
              }}
            >
              <LogoText
                outlined
                size={20}
                small={smallSize || forceSmallMenu}
              />
            </Box>
            <Divider
              sx={{
                m: "20px",
                color: "#D0D7EF",
                opacity: 0.5,
                borderColor: "unset",
              }}
            />
            {DASHBOARD_MENU_ITEMS.map((item, index) => (
              <DashboardMenuItem
                item={item}
                key={`menuItem${index}`}
                smallSize={smallSize || forceSmallMenu}
              />
            ))}
            <Box
              sx={{
                display: "flex",
                m: "20px",
                color: "#D0D7EF",
                alignItems: "end",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <SignOutButton signOutCallback={() => navigate("/")}>
                <Button sx={{ color: "#ffe" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LogoutIcon />
                    {!(smallSize || forceSmallMenu) ? (
                      <Box sx={{ pl: "15px", fontSize: "12px" }}>Logout</Box>
                    ) : null}
                  </Box>
                </Button>
              </SignOutButton>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};
