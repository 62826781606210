import '@splidejs/react-splide/css';
import 'react-grid-layout/css/styles.css'
import { Responsive, WidthProvider } from "react-grid-layout";
import { DesignerProps } from '../FullScreenDesigner';
import { Box, Button, Fade, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { HtmlTooltip } from '../../../../components/HtmlTooltip';
import { DEFINITIONS, LayoutItem, GridBoardProperties, DEFAULT_BOARD_PROPERTIES, LayoutItemType, LayoutItemStyles, GridBoard } from './model';
import { WelcomeModal } from './WelcomeModal';
import { NavigationButtons } from './NavigationButtons';
import { mapBoardBackgroundImage, mapBorderStyles, isTextItem, canDeleteItem, buildNewItem, buildInitialLayout } from './utils';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import { MAPPINGS } from './BackgroundAnimationModal';
import { getMatchingDefinition, shuffle } from './gridTemplates';
import { GridItemToolbox } from './GridItemToolbox';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { BoardModel, BoardStatus } from '../../../../model/board';
import { StatementPreview } from './StatementPreview';
import { Item } from './Items';
import { Guide } from '../../../../components/Guide';

const ResponsiveGridLayout = WidthProvider(Responsive);

const UNSAVED_CHANGES_KEY = "__editor_changes";

export const hasUnsavedChanges = () => localStorage.getItem(UNSAVED_CHANGES_KEY) !== null;
export const clearUnsavedChanges = () => localStorage.removeItem(UNSAVED_CHANGES_KEY);
const setUnsavedChanges = () => localStorage.setItem(UNSAVED_CHANGES_KEY, "true");

export const GridLayoutDesigner = (props: DesignerProps) => {
    const { initialModel, onSave, preview, statement, viewMode, onClose, boardId, refreshGoals, boardStatus } = props;
    const [toolbarAnchorEl, setToolbarAnchorEl] = useState<null | HTMLDivElement>(null);
    const [layout, setLayout] = useState<LayoutItem[]>(buildInitialLayout(props));
    const [boardProperties, setBoardProperties] = useState<GridBoardProperties>(initialModel ? (initialModel.model as GridBoard).props : DEFAULT_BOARD_PROPERTIES);
    const [welcomeModal, setWelcomeModal] = useState<boolean>(true);
    const [toolbarItem, setToolbarItem] = useState<LayoutItem | null>(null);
    const [noBackground, setNoBackground] = useState<boolean>(false);
    const [statementSeen, setStatementSeen] = useState<boolean>(false);
    const [editorPreview, setEditorPreview] = useState<boolean>(false);
    const initializedRef = useRef<boolean>(false);
    const doubleTouchRef = useRef<number>(new Date().getTime());

    const definitions = DEFINITIONS.map((def) => ({ name: def.name, definition: def }));
    const mobileMenu = useMediaQuery("(max-width:800px)");

    const addNewItem = (type: LayoutItemType, value: any) => {
        const itemToAdd = buildNewItem(type, value, layout);
        const updatedLayout = [...layout, itemToAdd];
        if (boardProperties.backgroundAnimation) {
            document.querySelector(".react-grid-layout.layout #tsparticles-editor")?.remove();
        }
        setNoBackground(true);
        setLayout(updatedLayout);
        setNoBackground(false);
        setUnsavedChanges();
    }

    const moveDown = (item: LayoutItem) => {
        const currentPosition = layout.findIndex((l) => l.layout.i === item.layout.i);
        if (currentPosition > 0) {
            const el = layout[currentPosition];
            layout[currentPosition] = layout[currentPosition - 1];
            layout[currentPosition - 1] = el;
            const updatedLayout = [...layout];
            setLayout(updatedLayout);
            setUnsavedChanges();
        }
    }

    const moveUp = (item: LayoutItem) => {
        const currentPosition = layout.findIndex((l) => l.layout.i === item.layout.i);
        if (currentPosition !== -1 && currentPosition < layout.length - 1) {
            const el = layout[currentPosition];
            layout[currentPosition] = layout[currentPosition + 1];
            layout[currentPosition + 1] = el;
            const updatedLayout = [...layout];
            setLayout(updatedLayout);
            setUnsavedChanges();
        }
    }

    const updateLayoutItemStyles = (item: LayoutItem, updatedStyles: LayoutItemStyles) => {
        const updatedLayout = [...layout];
        updatedLayout.forEach((i) => {
            if (i.layout.i === item.layout.i) {
                i.styles = {
                    ...i.styles,
                    ...updatedStyles
                }
            }
        })
        setLayout(updatedLayout);
        setUnsavedChanges();
    }

    const updateItem = (item: LayoutItem) => {
        const updatedLayout = [...layout];
        updatedLayout.forEach((i) => {
            if (i.layout.i === item.layout.i) {
                i.value = item.value;
            }
        })
        setLayout(updatedLayout);
        setUnsavedChanges();
    }

    const particlesInit = async (engine: any) => {
        await loadSlim(engine);
    };

    const particlesLoaded = async () => {
        const particlesElement: HTMLDivElement | null = document.querySelector("#tsparticles-editor");
        if (particlesElement) {
            const fragment = document.createDocumentFragment();
            particlesElement.style.height = "100%";
            fragment.appendChild(particlesElement);
            const gridContainer: HTMLElement | null = document.querySelector(".react-grid-layout.layout");
            if (gridContainer) {
                gridContainer.appendChild(fragment);
            }
        }
    };

    const updateOverflow = (overflow: "hidden" | "scroll") => {
        document.body.style.overflowY = overflow;
        if (props.parentId) {
            const parent: HTMLElement | null = document.querySelector(`#${props.parentId}`);
            if (parent) {
                parent.style.overflowY = overflow;
            }
        }
    }

    const isReadonly = viewMode || preview || editorPreview;

    useEffect(() => {
        if (isReadonly && statement) {
            updateOverflow("hidden");
        }
    }, [preview, viewMode, editorPreview])

    const toBoardModel = (): BoardModel => ({
        type: "grid",
        model: {
            layout,
            definition: getMatchingDefinition(),
            props: boardProperties
        } as GridBoard
    });

    return (
        <>
                {!isReadonly ? <Guide type='GRID_DESIGNER' /> : null}
                {isReadonly ? undefined : <Box sx={{ position: "sticky", zIndex: 200, left: "20px", top: "30%" }}><NavigationButtons
                    shuffleCallback={() => {
                        setNoBackground(true);
                        setLayout(shuffle(layout));
                        setUnsavedChanges();
                        setNoBackground(false);
                    }}
                    enablePreview={() => {
                        if (props.parentId) {
                            const layoutBox = document.querySelector(`#${props.parentId}`);
                            if (layoutBox) {
                                layoutBox.scrollTop = 0;
                            }
                        }
                        setToolbarItem(null);
                        setToolbarAnchorEl(null);
                        setEditorPreview(true)
                    }}
                    onSave={() => {
                        clearUnsavedChanges();
                        onSave(toBoardModel(), boardProperties.background)
                    }}
                    addNewItemCallback={addNewItem}
                    onModalShowCallback={() => { setToolbarItem(null); setToolbarAnchorEl(null) }}
                    boardProperties={boardProperties}
                    updateBoardProperties={(updatedProps) => {
                        setBoardProperties({ ...updatedProps });
                    }} /></Box>}
            <Box>
            {editorPreview ? 
                <Box sx={{ position: "absolute", top: "25px", left: "50%", 
                transform: "translate(-50%, -50%)", ml: 0, mr: 0, zIndex: 23000 }}>
                    <Button onClick={() => {
                        setStatementSeen(false);
                        setEditorPreview(false);
                    }} sx={{  
                        borderWidth: "2px", borderStyle: "dashed", borderColor: "#C0C0C0", color: "#C0C0C0", transition: "0.3s all ease",
                        "&:hover": {
                            borderColor: "#A0A0A0", color: "#A0A0A0", backgroundColor: "#fff"
                        }
                     }}>Close preview</Button>
                </Box>
                : null}
                {isReadonly && statement && boardProperties.skipStatement !== true ?
                    <Fade timeout={!statementSeen ? 0 : 1000} in={!statementSeen}>
                        <Box>
                            <StatementPreview onStartCallback={() => { 
                                setStatementSeen(true);
                                updateOverflow("scroll");
                            }} statement={statement} />
                        </Box>
                    </Fade> : null}
            </Box>
            {boardProperties.backgroundAnimation && !noBackground ? <Particles
                id="tsparticles-editor"
                loaded={particlesLoaded}
                height={"100%"}
                init={particlesInit} options={{ ...MAPPINGS[boardProperties.backgroundAnimation], fullScreen: { enable: false, zIndex: 3 } }} /> : null}
            <ResponsiveGridLayout
                onDragStart={isReadonly ? undefined : (layout, oldItem, newItem, placeholder, event, element) => {
                    if (toolbarItem && !element.classList.contains(`drag-element-${toolbarItem.layout.i}`)) {
                        setToolbarItem(null);
                    }
                }}
                onDrag={isReadonly ? undefined : (layout, oldItem, newItem, placeholder, event, element) => {
                    if (toolbarItem && !element.classList.contains(`drag-element-${toolbarItem.layout.i}`)) {
                        setToolbarItem(null);
                    }
                    if (oldItem.w !== newItem.w || oldItem.h !== newItem.h || oldItem.x !== newItem.x || oldItem.y !== newItem.y) {
                        setToolbarItem(null);
                    }
                }}
                isDraggable={!isReadonly && toolbarAnchorEl === null}
                isDroppable={!isReadonly}
                isResizable={!isReadonly}
                allowOverlap={boardProperties.allowOverflow}
                className="layout"
                breakpoints={definitions.reduce((props, def) => ({ ...props, [def.name]: def.definition.width }), {})}
                cols={definitions.reduce((props, def) => ({ ...props, [def.name]: def.definition.columns }), {})}
                onLayoutChange={isReadonly ? undefined : (currentLayout, allLayouts) => {
                    // @ts-ignore
                    const updatedLayout: LayoutItem[] = currentLayout.map((l) => {
                        const item = layout.find((currentLayoutItem) => currentLayoutItem.layout.i === l.i);
                        if (item) {
                            return {
                                layout: l,
                                type: item.type,
                                value: item.value,
                                styles: item.styles
                            } as LayoutItem
                        }
                        return null
                    }).filter((l) => l !== null);
                    setLayout(updatedLayout);

                    // This a little hack as there is no event that component is initialized
                    if (initializedRef.current) {
                        setUnsavedChanges();
                    } else {
                        initializedRef.current = true;
                    }

                    // lists template to persist
                    // console.log(JSON.stringify(updatedLayout.map((l) => ({
                    //     x: l.layout.x,
                    //     y: l.layout.y,
                    //     h: l.layout.h,
                    //     w: l.layout.w,
                    //     name: l.type === "name",
                    // }))));
                }}
                containerPadding={boardProperties.boardPadding}
                margin={boardProperties.elementMargin}
                style={{
                    backgroundColor: boardProperties.background.type === "color" ? boardProperties.background.value as string : undefined,
                    backgroundImage: mapBoardBackgroundImage(boardProperties.background),
                    backgroundRepeat: boardProperties.background.type === "image" ? "no-repeat" : undefined,
                    backgroundSize: boardProperties.background.type === "image" ? "cover" : undefined,
                    border: viewMode ? undefined : "1px dashed #aabbcc",
                    zIndex: 1,
                    minHeight: viewMode ? "100%" : undefined,
                }}
            >
                {layout.map((item: LayoutItem) => {
                    const borderStyles = mapBorderStyles(boardProperties, item.styles);
                    const isImage = item.type === 'sticker' || item.type === 'illustration';
                    const isTextWithTransparentBackground = isTextItem(item.type) && item.styles?.background?.type === "transparent";
                    const shouldAddClass = borderStyles.className && !(isImage || isTextWithTransparentBackground);
                    // TODO - move it to separated class and try to add 3d effect on hover
                    return (
                    <Box
                        className={`drag-element-${item.layout.i} ${shouldAddClass ? borderStyles.className : ""}`}
                        onDoubleClick={isReadonly ? undefined : (e) => {
                            setToolbarAnchorEl(e.currentTarget);
                            setToolbarItem(item);
                        }}
                        onTouchStart={mobileMenu && !isReadonly ? (e) => {
                            const currentTime = new Date().getTime();
                            if (currentTime - doubleTouchRef.current < 300) {
                                setToolbarAnchorEl(e.currentTarget);
                                setToolbarItem(item);
                            } else {
                                doubleTouchRef.current = currentTime;
                            }
                        } : undefined}
                        onClick={() => {
                            if (toolbarAnchorEl && toolbarItem && toolbarItem.layout.i !== item.layout.i) {
                                setToolbarItem(null); setToolbarAnchorEl(null)
                            } 
                        }}
                        data-grid={item.layout} key={item.layout.i}
                        sx={{
                            zIndex: 10,
                            backgroundColor: item.type === "sticker" || item.type=== "illustration" || (item.styles && item.styles.background && item.styles.background.type === "transparent") ? undefined : isTextItem(item.type) && item.styles?.background?.type === "color" ? item.styles?.background?.value as string : "#fff",
                            borderRadius: item.styles && item.styles.noBorder ? undefined : boardProperties.roundedCorners ? "20px" : undefined,
                            borderColor: item.styles && item.styles.noBorder ? undefined : item.styles && item.styles.borderColor ? item.styles.borderColor : boardProperties.borderColor,
                            borderWidth: item.styles && item.styles.noBorder ? "0px" : item.styles && item.styles.borderSize !== undefined ? item.styles.borderSize : boardProperties.borderSize,
                            ...(borderStyles.className === "rainbow-border-1" || borderStyles.className === "rainbow-border-2" ? { "--borderWidth": item.styles && item.styles.noBorder ? "0px" : item.styles && item.styles.borderSize !== undefined ? `${item.styles.borderSize}px !important` : `${boardProperties.borderSize}px !important` } : {}),
                            ...(borderStyles.className === undefined ? borderStyles : {}),
                            fontSize: isTextItem(item.type) ? `${item.styles?.textStyles?.size}px` : undefined,
                            backgroundSize: isTextItem(item.type) && item.styles?.background?.type === "image" ? "cover" : undefined,
                            backgroundImage: isTextItem(item.type) && item.styles?.background?.type === "image" ? `url(${item.styles?.background?.value as string})` : undefined,
                        }}>
                        <HtmlTooltip sx={{
                            "&.MuiPopper-root": {
                                zIndex: 1000,
                            },
                            zIndex: 1000
                        }} PopperProps={{
                            anchorEl: toolbarAnchorEl ? toolbarAnchorEl : undefined,
                            sx: {
                                zIndex: 1300,
                                ".MuiTooltip-tooltip": {
                                    backgroundColor: "#fff !important",
                                    ".MuiToggleButton-root": {
                                        border: "unset !important",
                                        color: "#000 !important",
                                    }
                                },
                            }
                        }} onClick={isReadonly ? undefined : (e) => e.preventDefault()} placement='top' arrow open={toolbarItem ? toolbarItem.layout.i === item.layout.i : false} title={
                            <Box sx={{ backgroundColor: "#fff" }}>
                                <GridItemToolbox
                                    boardProperties={boardProperties}
                                    item={item}
                                    updateItem={(item) => {
                                        updateItem(item);
                                    }}
                                    moveDown={moveDown}
                                    moveUp={moveUp}
                                    onClose={() => { setToolbarItem(null); setToolbarAnchorEl(null) }}
                                    updateLayoutItemStyles={updateLayoutItemStyles}
                                    onDelete={canDeleteItem(item) ? () => setLayout([...layout.filter((i) => i.layout.i !== item.layout.i)]) : undefined}
                                />
                            </Box>
                        }>
                            <Box sx={{
                                userSelect: isReadonly ? "none" : undefined,
                                height: "100%", display: "flex", alignItems: "center",
                                justifyContent: isTextItem(item.type) ? item.styles?.textStyles?.textAlign === "justify" ? "space-evenly" : item.styles?.textStyles?.textAlign : undefined,
                                fontFamily: isTextItem(item.type) ? item.styles?.textStyles?.font : "Lato",
                                fontWeight: isTextItem(item.type) && item.styles?.textStyles?.bold ? "700" : undefined,
                                fontStyle: isTextItem(item.type) && item.styles?.textStyles?.italic ? "italic" : undefined,
                                textDecoration: isTextItem(item.type) && item.styles?.textStyles?.underline ? "underline" : undefined,
                                color: isTextItem(item.type) ? item.styles?.textStyles?.color : undefined,
                                ".item-text": {
                                    transform: isTextItem(item.type) ? `rotate(${item.styles?.textStyles?.textRotate}deg)` : undefined,
                                },
                                cursor: "pointer",
                                overflow: "hidden",
                                backgroundColor: isTextItem(item.type) && item.styles?.background?.type === "color" ? item.styles?.background?.value as string : item.styles?.background?.type === "image" ? "#fff" : undefined,
                                backgroundSize: isTextItem(item.type) && item.styles?.background?.type === "image" ? "cover" : undefined,
                                backgroundImage: isTextItem(item.type) && item.styles?.background?.type === "image" ? `url(${item.styles?.background?.value as string})` : undefined,
                            }}>
                                {/* TODO think about justify text how to handle it properly */}
                                <Item 
                                    isDraft={boardStatus === BoardStatus.DRAFT} 
                                    boardId={boardId} item={item} 
                                    preview={isReadonly} 
                                    viewMode={isReadonly} 
                                    refreshGoalsCallback={refreshGoals} 
                                    goals={props.goals} />
                            </Box>
                        </HtmlTooltip>
                    </Box>
                )
                })}
            </ResponsiveGridLayout>
            {welcomeModal && initialModel === null && !isReadonly ? <WelcomeModal onClose={() => setWelcomeModal(false)} /> : null}
            {viewMode ? 
            <Box sx={{ zIndex: 10, position: "absolute", top: "30px", left: "50%", transform: "translate(-50%, -50%)", ml: 0, mr: 0 }}>
                <Tooltip title="Close vision board">
                    <IconButton sx={{ background: "rgba(255,255,255,0.2)", "&:hover": { background: "rgba(255,255,255,0.8)" } }} onClick={onClose}><CancelPresentationIcon /></IconButton>
                </Tooltip>
            </Box> : null}
            {viewMode && boardStatus === BoardStatus.ARCHIVED ? <Box sx={{ zIndex: 10, p: "10px", position: "absolute", bottom: "30px", right: "30px", backgroundColor: "rgba(255,255,255,0.7)", fontSize: "20px", fontWeight: 500, fontStyle: "italic", fontFamily: "Lato, sans-serif"}}>Archived board</Box> : null}
        </>
    )
};
