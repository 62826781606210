import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Board } from "../model/board";
import { boardApi } from "../api/BoardApi";
import useFetch from "../hooks/useFetch";
import { Masonry } from "./screensaver/Masonry";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components/Loader";

export const BoardScreensaver = () => {
  const [board, setBoard] = useState<Board | null>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const { boardId } = useParams();
  const navigate = useNavigate();

  const { callApi, loading } = useFetch();

  useEffect(() => {
    const queryBoard = async () => {
      if (boardId) {
        try {
          const board: Board = await callApi(boardApi.getBoard(boardId));
          setBoard(board);
        } catch (e) {
          console.log("notification");
        }
      }
    };
    queryBoard();
  }, [boardId]);

  const mobileMenu = useMediaQuery("(max-width:630px)");
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Loader />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "start",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography sx={{ fontStyle: "italic" }}>
            {board ? board.name : null}
          </Typography>
        </Box>
        <Button
          variant="text"
          onClick={() => {
            setFullScreen(!fullScreen);
          }}
        >
          <FullscreenIcon />
          {!fullScreen ? "Enter full screen" : "Exit full screen"}
        </Button>
        <Button
          variant="text"
          onClick={() => navigate("/app/screensaver")}
        >
          See all boards
        </Button>
      </Box>
      <Box>
        {board ? (
          <Masonry
            exitFullScreenCallback={() => setFullScreen(false)}
            fullScreen={fullScreen}
            board={board}
          />
        ) : null}
      </Box>
    </Box>
  );
};
