import { Box, Fade, Button, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import StaggerText from "react-stagger-text";

export const StatementPreview = ({ statement, onStartCallback }: { statement: string; onStartCallback: () => void }) => {

    const [swaggerCompleted, setSwaggerCompleted] = useState<boolean>(false);
    const mobileMenu = useMediaQuery("(max-width:800px)");

    useEffect(() => {
        setTimeout(() => {
            if (!swaggerCompleted) {
                setSwaggerCompleted(true);
            }
        }, 5000) // fallback if stagger fails for some reason
    }, [])

    return (
        <Box sx={{
            zIndex: 100,
            top: 0,
            left: 0,
            overflow: "hidden",
            textShadow: "0 0 1px rgba(0,0,0, 0.9), 0 0 1px rgba(0,0,0, 0.9), 0 0 1px rgba(0,0,0, 0.9), 0 0 1px rgba(0,0,0, 0.9)",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            backgroundColor: "rgba(200,200,200, 0.92)"
        }}>
            <Box sx={{ width: "60%", fontSize: mobileMenu ? "26px": "46px", color: "white", fontWeight: 700, fontFamily: "Handlee, cursive", textAlign: "center" }}>
                <StaggerText staggerDuration={1} onStaggerComplete={() => setSwaggerCompleted(true)}>
                    {statement}
                </StaggerText>
            </Box>
            <Fade in={swaggerCompleted} timeout={500}>
                <Button onClick={onStartCallback} sx={{
                    mt: "20px",
                    fontSize: mobileMenu ? "20px" : "30px",
                    fontStyle: "italic",
                    fontWeight: "bold",
                    width: "600px",
                    color: "#fff",
                    borderRadius: "20px",
                    paddingLeft: "30px",
                    paddingRIght: "30px",
                    backgroundColor: mobileMenu ? "rgba(255,255,255,0.2)" : "unset",
                    fontFamily: "Handlee, cursive",
                    borderColor: "white",
                    borderSize: "2px",
                    height: "70px",
                    textShadow: "0 0 1px rgba(0,0,0, 0.9), 0 0 1px rgba(0,0,0, 0.9), 0 0 1px rgba(0,0,0, 0.9), 0 0 1px rgba(0,0,0, 0.9)",
                    transition: "1s all ease",
                    "&:hover": {
                        borderColor: "#6C5959",
                        backgroundColor: "rgba(200,200,200, 0.96)",
                        textShadow: "0 0 2px rgba(0,0,0, 0.9), 0 0 2px rgba(0,0,0, 0.9), 0 0 2px rgba(0,0,0, 0.9), 0 0 2px rgba(0,0,0, 0.9)",
                    }
                }}
                    variant='outlined'>
                    <Box sx={{ pt: "4px" }}>It's time to visualise</Box>
                    <Box sx={{ ml: "65px", mt: "3px" }}>
                        <div className="arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Box>
                </Button>
            </Fade>
        </Box>
    )
}