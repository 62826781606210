import { Box, Typography, useMediaQuery } from "@mui/material";
import { AppMenu } from "./AppMenu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Route,
  Routes,
  useLocation,
  Location,
  useNavigate,
} from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Boards } from "./pages/Boards";
import { NavigationTracker } from "./components/NavigationTracker";
import HomeIcon from "@mui/icons-material/Home";
import { ComingSoon } from "./pages/ComingSoon";
import { observer } from "mobx-react-lite";
import { ViewBoard } from "./pages/ViewBoard";
import { t } from "i18next";
import { NotificationContainer } from "./firebase/NotificationContainer";
import { Settings } from "./pages/settings/Settings";
import { SignedIn, UserButton } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import useFetch from "./hooks/useFetch";
import { AccountStatus, StatusResponse, userApi } from "./api/UserApi";
import { TrialModal, shouldPresentTrialModal } from "./components/TrialModal";
import { useStore } from "./state/RootStore";
import { SignedInPageNotFound } from "./pages/SignedInPageNotFound";
import { getErrorState } from "./state/ErrorState";
import { SubscriptionStatusPage } from "./pages/SubscriptionStatusPage";
import { SubscriptionRenewalModal } from "./components/SubscriptionRenewalModal";
import { AccountCreationInProgress } from "./components/AccountCreationInProgress";
import { Screensaver } from "./pages/Screensaver";
import { BoardScreensaver } from "./pages/BoardScreensaver";
import { Visualization } from "./pages/Visualization";
import { BasicVisualizator } from "./pages/visualize/BasicVisualizator";
import { VisualizationType } from "./pages/visualize/VisualizatorSettings";
import { HtmlTooltip } from "./components/HtmlTooltip";
import { Tutorials } from "./pages/tutorials/Tutorials";
import { TutorialContents } from "./pages/tutorials/TutorialContent";
import { Tutorial } from "./pages/tutorials/Tutorial";
import { guideApi } from "./api/GuideApi";
import { GUIDE_TYPE } from "./model/guides";
import { isPwaApplication } from "./utils/pwa";
import { VersionResponse, versionApi } from "./api/VersionApi";
import { config } from "./config";
import { initGallery } from "./assets/gallery/gallery-config";

const APP_PATH_PREFIX = "/app/";

const NO_BREADCRUMBS_REGEX = /boards_(.*)+|screensaver_(.*)+|visualization_(.*)+|tutorials_view_(.*)+/;

const SUBSCRIPTION_REDIRECT_PAGES = [
  "/app/subscriptions/success",
  "/app/subscriptions/cancel",
];

const resolveBreadcrumb = (location: Location) => {
  const path = location.pathname
    .replace(APP_PATH_PREFIX, "")
    .replaceAll("/", "_");
  if (NO_BREADCRUMBS_REGEX.test(path)) {
    if (path.startsWith("screensaver")) {
      return t("screensaver");
    } else if (path.startsWith("visualization")) {
      return t("visualization");
    } else if (path.startsWith("tutorials_view")) {
      const tutorial = TutorialContents.find((tutorial) => location.pathname.includes(tutorial.path));
      if (tutorial) {
        return tutorial.title;
      } else {
        return "Tutorial";
      }
    }
    return "";
  } else {
    return t(path);
  }
};

const Header = () => {
  const location = useLocation();
  const mobileMenu = useMediaQuery("(max-width:760px)");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        mb: "30px",
        mt: mobileMenu ? "50px" : "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          color: "#243040",
        }}
      >
        <HomeIcon sx={{ color: "#243040" }} />
        <Typography sx={{ fontSize: "22px" }}>
          {resolveBreadcrumb(location)}
        </Typography>
      </Box>
      {!mobileMenu ? (
        <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <HtmlTooltip title="No notifications"><NotificationsIcon /></HtmlTooltip>
          <UserButton afterSignOutUrl="/" />
        </Box>
      ) : null}
    </Box>
  );
};

export const AuthorizedApp = observer(() => {
  const mobileMenu = useMediaQuery("(max-width:760px)");
  const { callApi } = useFetch();
  const [trialModal, setTrialModal] = useState<boolean>(false);
  const [remainingDays, setRemainingDays] = useState<number>(0);
  const [trialExpired, setTrialExpired] = useState<boolean>(false);
  const store = useStore();
  const errorState = getErrorState();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getStatus = async () => {
    const statusResponse: StatusResponse = await callApi(userApi.status());
    if (statusResponse) {
      store.userId = statusResponse.userId;
      store.accountStatus = statusResponse.status;
      if (
        statusResponse.status === AccountStatus.TRIAL &&
        !SUBSCRIPTION_REDIRECT_PAGES.includes(pathname)
      ) {
        if (statusResponse.trialExpired) {
          setTrialExpired(true);
          setTrialModal(true);
        } else {
          const modalDetails = shouldPresentTrialModal(
            statusResponse.trialExpirationDate,
            statusResponse.userId
          );
          if (modalDetails.show) {
            setRemainingDays(modalDetails.remainingDays || 14);
            setTrialModal(true);
          }
        }
      }
      try {
        const res: any = await callApi(guideApi.getAll());
        store.viewedGuides = res.types as GUIDE_TYPE[];
      } catch (e) {
        //
      }
    }
  };

  useEffect(() => {
    getStatus();
    if (isPwaApplication() && mobileMenu) {
      window.addEventListener("visibilitychange", function () {
        if (document.visibilityState === "visible") {
          callApi<VersionResponse>(versionApi.version()).then((res) => {
            if (res.uiVersion !== config.version) {
              window.location.reload();
            }
          })
        }
      });
    }
    initGallery();
  }, []);

  return (
    <SignedIn>
      {trialModal || errorState.trialExpiredModal ? (
        <TrialModal
          remainingDays={remainingDays}
          expired={trialExpired}
          onClose={() => {
            setTrialModal(false);
            if (errorState.trialExpiredModal) {
              errorState.trialExpiredModal = false;
            }
          }}
        />
      ) : null}
      {errorState.subscriptionCanceledModal ? (
        <SubscriptionRenewalModal
          onSubscribe={async () => {
            errorState.subscriptionCanceledModal = false;
            navigate("/app/settings#subscription");
          }}
          onClose={() => (errorState.subscriptionCanceledModal = false)}
        />
      ) : null}
      {errorState.accountUnderCreationModal ? (<AccountCreationInProgress />) : null}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#fff",
          borderRadius: "15px",
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gap: mobileMenu ? "0px" : "30px",
        }}
      >
        <script src="https://js.stripe.com/v3/" async></script>
        <NotificationContainer />
        <AppMenu />
        <Box id="route-box" sx={{ display: "flex", flexDirection: "column", p: "30px" }}>
          <Header />
          <Box sx={{ height: "100%", display: "flex" }}>
            <NavigationTracker>
              <Routes>
                <Route index element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/boards" element={<Boards />} />
                <Route path="/boards/:boardId" element={<ViewBoard />} />
                <Route path="/screensaver" element={<Screensaver />} />
                <Route path="/screensaver/:boardId" element={<BoardScreensaver />} />
                <Route path="/visualization" element={<Visualization />} />
                <Route path="/visualization/1/:boardId" element={<BasicVisualizator type={VisualizationType.BOARD_BACKGROUND} />} />
                <Route path="/visualization/2/:boardId" element={<BasicVisualizator type={VisualizationType.ZOOM_IN_ZOOM_OUT} />} />
                <Route path="/visualization/3/:boardId" element={<BasicVisualizator type={VisualizationType.CLOUDS} />} />
                <Route path="/visualization/4/:boardId" element={<BasicVisualizator type={VisualizationType.HALO} />} />
                <Route path="/visualization/5/:boardId" element={<BasicVisualizator type={VisualizationType.CELLS} />} />
                <Route path="/journal" element={<ComingSoon />} />
                <Route path="/workflows" element={<ComingSoon />} />
                <Route path="/backlog" element={<ComingSoon />} />
                <Route path="/tutorials" element={<Tutorials />} />
                {TutorialContents.map((tutorial) => (<Route key={tutorial.path} path={`/tutorials/view/${tutorial.path}`} element={<Tutorial tutorial={tutorial} />} />))}
                <Route path="/community" element={<ComingSoon />} />
                <Route path="/settings" element={<Settings />} />
                <Route
                  path="/subscriptions/success"
                  element={<SubscriptionStatusPage success={true} />}
                />
                <Route
                  path="/subscriptions/cancel"
                  element={<SubscriptionStatusPage success={false} />}
                />
                <Route path="*" element={<SignedInPageNotFound />} />
              </Routes>
            </NavigationTracker>
          </Box>
        </Box>
      </Box>
    </SignedIn>
  );
});
