import { Box, Chip, Stack, TextField, useMediaQuery } from "@mui/material";
import { withFadeIn } from "../../../hoc/withFadeIn";
import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { observer } from "mobx-react-lite";
import { WizardStep, getWizardState } from "../state/wizzardState";
import { useUser } from "@clerk/clerk-react";

const currentYear = new Date().getFullYear();

const exampleNames = (username: string) => [
  "Life goals",
  `Life goals ${currentYear}`,
  `${username} life goals`,
  "Career development",
  "Travel plans",
  `Books to read in ${currentYear}`,
  "Mental and health",
  "Welth and propsperity",
  "Family",
  "Love",
];

export const NameYourVisionBoardStep = withFadeIn(
  observer(() => {
    const state = getWizardState();

    useEffect(() => {
      state.step = WizardStep.SET_UP_NAME;
    }, []);

    const { user } = useUser();

    const isWideScreen = useMediaQuery("(min-width:1600px)");

    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          pb: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Lato, sans-serif",
            width: "calc(80%)",
            pt: "20px",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "block",
                fontSize: isWideScreen ? "24px" : "18px",
                textAlign: "justify",
              }}
            >
              You can have multiple vision boards for different purposes. Let's
              start with defining a{" "}
              <strong style={{ paddingLeft: "3px", paddingRight: "3px" }}>
                name{" "}
              </strong>{" "}
              for your vision board
            </Box>
            <TextField
              sx={{ width: "calc(50%)", minWidth: "300px" }}
              inputProps={{ style: { textAlign: "center", fontSize: "18px" } }}
              value={state.boardName}
              onChange={(e) => (state.boardName = e.currentTarget.value)}
            />
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: "wrap",
                width: "calc(75%)",
                rowGap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {exampleNames(user?.firstName || "").map((name, index) => (
                <Chip
                  key={`exampleName${index}`}
                  label={name}
                  size={isWideScreen ? "medium" : "small"}
                  variant="outlined"
                  onClick={() => (state.boardName = name)}
                />
              ))}
            </Stack>
            <Box
              sx={{
                display: "block",
                fontSize: isWideScreen ? "24px" : "18px",
                pt: "15px",
                textAlign: "justify",
              }}
            >
              Every vision board should have a{" "}
              <strong style={{ paddingLeft: "3px", paddingRight: "3px" }}>
                statement
              </strong>{" "}
              that describes your vision board and what would you like to
              achieve. It could be a motto, a quote or simply a few words that
              matters to you and will help you motivate yourself
            </Box>
            <TextField
              sx={{ width: "calc(75%)", minWidth: "300px" }}
              inputProps={{
                style: {
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "italic",
                },
              }}
              value={state.boardStatement}
              onChange={(e) => (state.boardStatement = e.currentTarget.value)}
            />
            <Box
              sx={{
                display: "block",
                fontSize: isWideScreen ? "24px" : "18px",
                pt: "15px",
                textAlign: "justify",
              }}
            >
              And finally, each vision board can have a specific{" "}
              <strong style={{ paddingLeft: "3px", paddingRight: "3px" }}>
                end date
              </strong>{" "}
              that will allow you to better move in a specific direction. Within
              specific goals, you will be able to define specific milestones
            </Box>
            <DatePicker
              value={state.endDate}
              sx={{ minWidth: "300px" }}
              onChange={(date) => (state.endDate = date)}
            />
          </Box>
        </Box>
      </Box>
    );
  }),
  {
    fullWidth: true,
    center: true,
    overflowY: true,
  }
);
