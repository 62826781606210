import { Label } from '../labels/Label';
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import StaggerText from "react-stagger-text";
import { TypeAnimation } from "react-type-animation";
import { boardApi } from "../../../../api/BoardApi";
import { Goal, getPhotoUrl } from "../../../../model/goal";
import { GoalModal } from "../../../board/GoalModal";
import { TextStyles } from "../toolbox/TextToolbox";
import { LayoutItemStyles, PowerfulStatement, LayoutItem, DEFAULT_TEXT_STYLES, Label as LabelItem, } from "./model";
import { FILTER_STYLES } from "./utils";
import correct from "../../../../assets/correct.svg";
import useFetch from '../../../../hooks/useFetch';

const GoalItem = ({ boardId, goalId, goals, styles, preview, viewMode, refreshGoalsCallback, isDraft }: { boardId: string; goalId: string, goals: Goal[], styles?: LayoutItemStyles; preview?: boolean; viewMode?: boolean; refreshGoalsCallback: () => Promise<void>; isDraft: boolean  }) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const [goalModal, setGoalModal] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const { callApi } = useFetch();

    // TODO in the next versions add some animation to present description, status, etc or more elements like likes, stars in enterprise version

    const handleUpdate = async (goal: Goal) => {
        await callApi(boardApi.updateGoal(boardId, goalId, goal));
        await refreshGoalsCallback();
    }

    const goal = goals.find((g) => g.id === goalId);

    useEffect(() => {
        const getImageUrl = async () => {
            if (goal) {
                const url = await getPhotoUrl(goal, "regular", callApi);
                setImageUrl(url);
            }
        }
        getImageUrl();
    }, []);

    if (goal) {
        return (
            <Box
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={viewMode && !goalModal ? () => {
                    setHovered(false);
                    setGoalModal(true);
                } : undefined}
                sx={{
                    display: "flex", overflow: "hidden",
                    alignItems: "center", justifyContent: "center",
                    transition: "0.5s all ease",
                    width: "100%",
                    cursor: preview || viewMode ? "pointer" : undefined,
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        opacity: hovered ? 0.25 : 1,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // TODO - post-MVP implement a proper cropping mechanism
                        backgroundPosition: styles && styles.backgroundPosition ? `${styles.backgroundPosition.x} ${styles.backgroundPosition.y}` : undefined,
                        transition: "0.2s all ease",
                        filter: styles && styles.filter && styles.filter !== "none" ? FILTER_STYLES[styles.filter] : undefined
                    }
                }}>
                    {goal.accomplished ? <Box sx={{ position: "absolute", right: "10px", top: "10px" }}><Box component="img" sx={{ width: "40px" }} src={correct} /></Box> : null}
                    {goalModal && viewMode ? <GoalModal refreshGoals={refreshGoalsCallback} isDraft={isDraft} goal={goal} boardId={boardId} onCancel={() => setGoalModal(false)} updateCallback={handleUpdate} /> : null}
                <Box sx={{ fontSize: "45px", userSelect: "none", textAlign: "center", color: "black", fontFamily: "Lato, sans-serif", fontWeight: 400, fontStyle: "italic", zIndex: 2, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {hovered && <StaggerText>
                        {goal.name}
                    </StaggerText>}
                </Box>
            </Box>
        )
    } else {
        return null;
    }
}

const TextItem = ({ label }: { label: string }) => <Typography className="item-text">{label}</Typography>

const PowerfulStatementItem = ({ statement, styles }: { statement: PowerfulStatement; styles: TextStyles }) => {
    return (
        <Box>
            <TypeAnimation
                key={`powerfulStatementKey-${Object.entries(styles).map((e) => `${e[0]}-${e[1]}`).join("-")}`}
                sequence={statement.parts.flatMap((part => [part, 1000]))}
                speed={50}
                // @ts-ignore
                style={{
                    fontSize: `${styles.size}px`,
                    fontFamily: styles.font,
                    display: "block",
                    fontWeight: styles.bold ? "bold" : undefined,
                    fontStyle: styles.italic ? "italic" : undefined,
                    textDecoration: styles.underline ? "undefined" : undefined,
                    transform: `rotate(${styles.textRotate})deg`,
                    textShadow: styles.outline ? "0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black" : undefined,
                    ...styles
                }}
                className='item-text'
                repeat={Infinity}
            />
        </Box>
    )
}

export const Item = ({ boardId, item, preview, viewMode, goals, refreshGoalsCallback, isDraft }: { boardId: string; item: LayoutItem; preview?: boolean; viewMode?: boolean; goals: Goal[]; isDraft: boolean; refreshGoalsCallback: () => Promise<void> }) => {
    switch (item.type) {
        case 'goal':
            return <GoalItem isDraft={isDraft} boardId={boardId} goalId={item.value as string} goals={goals} styles={item.styles} preview={preview} viewMode={viewMode} refreshGoalsCallback={refreshGoalsCallback} />;
        case 'name':
            return <TextItem label={item.value as string} />;
        case 'sticker':
            return <Box><img style={{ objectFit: "cover", height: "calc(100%)", width: "calc(100%)" }} src={item.value as string} /></Box>
        case 'illustration':
            return <Box><img style={{ objectFit: "cover", height: "calc(100%)", width: "calc(100%)" }} src={item.value as string} /></Box>
        case 'powerful-statement':
            return <PowerfulStatementItem statement={item.value as PowerfulStatement} styles={item.styles?.textStyles as TextStyles} />
        case 'label': {
            const { style, text, variant } = item.value as LabelItem;
            return <Label key={`label-${style}-${new Date().getTime()}`} style={style} text={text} textStyles={(item.styles && item.styles.textStyles) ?? DEFAULT_TEXT_STYLES} variant={variant} />
        }
        default:
            return null;
    }
}