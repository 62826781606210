import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Board, BoardModel } from "../../model/board";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { MAPPINGS } from "../wizzard/layouts/grid/BackgroundAnimationModal";
import { GridBoard } from "../wizzard/layouts/grid/model";
import { mapBoardBackgroundImage } from "../wizzard/layouts/grid/utils";
import { BasicVisualizatorProps } from "./BasicVisualizator";

export const boardBackgroundVisualizatorContainerStyles = (board: Board) => {
  const boardModel: BoardModel = board.model as BoardModel;
  const gridModel: GridBoard = boardModel.model as GridBoard;
  return {
    height: "60vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:
      gridModel.props.background.type === "color"
        ? (gridModel.props.background.value as string)
        : undefined,
    backgroundImage: mapBoardBackgroundImage(gridModel.props.background),
    backgroundRepeat:
      gridModel.props.background.type === "image" ? "no-repeat" : undefined,
    backgroundSize:
      gridModel.props.background.type === "image" ? "cover" : undefined,
  };
};

export const BoardBackgroundVisualizator = ({
  board,
  fullscreen,
  loop,
  slides,
  onFinishCallback
}: {
  board: Board;
} & BasicVisualizatorProps) => {
  const [visibleIndex, setVisibleIndex] = useState<number>(0);
  const [gridModel, setGridModel] = useState<GridBoard | null>(null);
  const [recorded, setRecorded] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:630px)");

  useEffect(() => {
    if (visibleIndex === slides.length - 1 && !recorded) {
      onFinishCallback().then(() => setRecorded(true));
    }
  }, [visibleIndex, recorded]);
  
  const particlesInit = async (engine: any) => {
    await loadSlim(engine);
  };

  useEffect(() => {
    const boardModel: BoardModel = board.model as BoardModel;
    const gridModel: GridBoard = boardModel.model as GridBoard;
    setGridModel(gridModel);
  }, []);

  return (
    <>
      {gridModel && gridModel.props.backgroundAnimation ? (
        <Particles
          id="tsparticles-editor"
          height={"100%"}
          width="100%"
          style={{ position: "absolute", top: 0 }}
          init={particlesInit}
          options={{
            ...MAPPINGS[gridModel.props.backgroundAnimation],
            fullScreen: { enable: false, zIndex: 3 },
          }}
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Splide
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onAutoplayPause={(splide) => {
            const { Autoplay } = splide.Components;
            Autoplay.play();
          }}
          onMoved={(splide, index) => setVisibleIndex(index)}
          options={{
            start: 0,
            autoWidth: true,
            type: "fade",
            rewind: loop,
            autoplay: true,
            arrows: false,
            interval: 5000,
            pagination: false,
          }}
        >
          {slides.map((slide, index) => (
            <SplideSlide
              key={`slide-${index}-${fullscreen}`}
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                className={
                  slide.type === "goal" && visibleIndex === index
                    ? "zoom-in-out-box"
                    : undefined
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  height: fullscreen ? isMobile ? "70vh" : `700px` : isMobile ? "40vh" : "500px",
                  maxWidth: isMobile && slide.type === "goal" ? fullscreen ? "70vw" : "50vw" : "unset",
                  fontSize:
                    slide.type === "name"
                      ? fullscreen
                        ? isMobile ? "18px" : "46px"
                        : isMobile ? "15px" : "30px"
                      : fullscreen
                      ? isMobile ? "16px" : "40px"
                      : isMobile ? "14px" : "26px",
                  fontWeight: slide.type === "name" ? "bold" : undefined,
                  fontStyle: slide.type === "statement" ? "italic" : undefined,
                  fontFamily: '"Titillium Web", sans-serif',
                  backgroundImage:
                    slide.type === "goal"
                      ? `url(${slide.background})`
                      : undefined,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  opacity: 0.9,
                }}
              >
                {slide.type !== "goal" ? (
                  <Box
                    sx={{
                      p: "20px 40px",
                      backgroundColor: "rgba(255,255,255,0.7)",
                      borderRadius: "15px",
                      width: undefined,
                      position: undefined,
                      bottom: undefined,
                      textAlign: "center",
                    }}
                  >
                    {slide.name}
                  </Box>
                ) : null}
              </Box>
              {slide.type === "goal" ? (
                <Box
                  sx={{
                    fontSize: fullscreen ? isMobile ? "18px" : "40px" : isMobile ? "14px" : "18px",
                    fontStyle: "italic",
                    fontWeight: "400",
                    fontFamily: '"Titillium Web", sans-serif',
                    position: "absolute",
                    bottom: 0,
                    p: "20px 60px",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    backgroundColor: "rgba(255,255,255,0.7)",
                  }}
                >
                  {slide.name}
                </Box>
              ) : null}
            </SplideSlide>
          ))}
        </Splide>
      </Box>
    </>
  );
};
