import { Box, SxProps, Theme } from "@mui/material";

export const CustomTabPanel = (props: {
    children?: React.ReactNode;
    index: number;
    value: number;
    sx?: SxProps<Theme>;
    wrapperSx?: SxProps<Theme>;
}) => {
    const { children, value, index, sx, wrapperSx } = props;

    return (
        <Box sx={wrapperSx} hidden={value !== index}>
            {value === index && (
                <Box sx={{ p: 3, ...sx }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}