import { Box, Button, Fade, IconButton, Modal, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '0px',
    boxShadow: "0px 0px 15px 0px rgba(66, 68, 90, 1)",
    p: 4,
};

interface CustomModalProps {
    open: boolean;
    onOk?: (() => Promise<void>) | (() => void);
    onCancel?: () => void;
    title?: string,
    sx?: SxProps<Theme>;
    childrenWrapperSx?: SxProps<Theme>;
    okLabel?: string;
    cancelIcon?: boolean;
    noPadding?: boolean;
    cancelIconSx?: SxProps<Theme>;
    id?: string;
    alert?: boolean;
    modalSx?: SxProps<Theme>;
}

export const CustomModal = ({ open, onOk, okLabel, onCancel, title, id, children, sx, childrenWrapperSx, modalSx, alert = false, cancelIcon = false, noPadding = false, cancelIconSx }: CustomModalProps & PropsWithChildren) => {

    const [loading, setLoading] = useState<boolean>(false);
    const mobile = useMediaQuery("(max-width:760px)");

    return (
        <Box>
            <Modal
                open={open}
                disableEnforceFocus
                sx={{ outline: "none", ...modalSx }}
            >
                <Fade in={open}>
                    <Box id={id} sx={{ ...style, ...(mobile ? { width: "90%" } : {}), p: noPadding ? "0px" : 4, outline: "none", overflowY: "scroll", ...sx }}>
                        {onCancel && cancelIcon ? <IconButton sx={{ position: "absolute", top: "10px", right: "10px", ...cancelIconSx, zIndex: 100 }} onClick={onCancel}><CloseIcon /></IconButton> : null}
                        {title ?
                            <Typography variant="h6" component="h2">
                                {title}
                            </Typography> : null}
                        <Box sx={{ pt: cancelIcon && !noPadding ? "15px" : undefined, height: "100%", ...childrenWrapperSx }}>
                            {children}
                        </Box>
                        {
                            onOk || (onCancel && !cancelIcon) ?
                                <Box sx={{ display: "flex", justifyContent: "center", pt: "20px", gap: "20px" }}>
                                    {onOk ? <LoadingButton loading={loading} sx={{ backgroundColor: alert ? "#BD3636" : undefined }} variant="contained" onClick={async () => { setLoading(true); await onOk(); setLoading(false) }}>{okLabel ? okLabel : "Ok"}</LoadingButton> : null}
                                    {onCancel && !cancelIcon ? <Button variant="outlined" onClick={onCancel}>Cancel</Button> : null}
                                </Box>
                                : null
                        }
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}