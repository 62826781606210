import { action, makeAutoObservable } from "mobx";
import { Goal } from "../../../model/goal";
import { Background } from "../layouts/grid/model";
import { Board, BoardModel } from "../../../model/board";
import { BoardRequestDto } from "../../../api/BoardApi";

export interface PreviousSessionBoard {
    boardId: string;
    name: string;
}

const IN_PROGRESS_LOCALSTORAGE_KEY = "__wizzard_draft";

export enum WizardStep {
    WELCOME = "WELCOME",
    SET_UP_NAME = "SET_UP_NAME",
    GOALS = "GOALS",
    DESIGNER = "DESIGNER",
    SUMMARY = "SUMMARY"
}

export const MIN_GOALS_REQUIRED = 3;

export const getPreviousSessionBoardId = (): PreviousSessionBoard | null => {
    try {
        const serialized = localStorage.getItem(IN_PROGRESS_LOCALSTORAGE_KEY);
        return serialized ? JSON.parse(serialized) : null;
    } catch(e) {
        localStorage.removeItem(IN_PROGRESS_LOCALSTORAGE_KEY);
    }
    return null;
}
export const clearPreviousSessionBoardId = () => localStorage.removeItem(IN_PROGRESS_LOCALSTORAGE_KEY);

class WizardState {

    private _step: WizardStep = WizardStep.WELCOME;
    private _boardId: string | null = "";
    private _boardName: string = "";
    private _boardStatement: string = "";
    private _endDate: Date | null = null;
    private _goals: Goal[] = [];
    private _nextStepAvailable: boolean = true;
    private _boardModel: BoardModel | null = null;
    private _aiAssistant: boolean = false;
    private _aiAssistantInput: { boardName: string, userInput?: string } | null = null;
    private _aiAssistantRefresh: boolean = false;

    constructor() {
        makeAutoObservable(this, {
            reset: action,
            restoreBoard: action
        });
    }

    public get nextStepAvailable(): boolean {
        return this._nextStepAvailable;
    }

    public get boardId() {
        return this._boardId;
    }

    public set boardId(value: string | null) {
        this._boardId = value;
        if (value) {
            localStorage.setItem(IN_PROGRESS_LOCALSTORAGE_KEY, JSON.stringify({
                boardId: value,
                name: this._boardName
            } as PreviousSessionBoard));
        } else {
            localStorage.removeItem(IN_PROGRESS_LOCALSTORAGE_KEY);
        }
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public get goals(): Goal[] {
        return this._goals;
    }

    public set goals(value: Goal[]) {
        this._goals = value;
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public get endDate(): Date | null {
        return this._endDate;
    }

    public set endDate(value: Date | null) {
        this._endDate = value;
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public get boardStatement(): string {
        return this._boardStatement;
    }

    public set boardStatement(value: string) {
        this._boardStatement = value;
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public get boardName(): string {
        return this._boardName;
    }

    public set boardName(value: string) {
        this._boardName = value;
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public get step(): WizardStep {
        return this._step;
    }

    public set step(value: WizardStep) {
        this._step = value;
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public set boardModel(value: BoardModel | null) {
        this._boardModel = value;
        this._nextStepAvailable = this.calculateNextStepAvailable();
    }

    public get boardModel(): BoardModel | null {
        return this._boardModel;
    }

    get aiAssistant(): boolean {
        return this._aiAssistant;
    }
    
    set aiAssistant(value: boolean) {
        this._aiAssistant = value;
    }

    get aiAssistantInput() {
        return this._aiAssistantInput;
    }

    set aiAssistantInput(value) {
        this._aiAssistantInput = value;
    }

    get aiAssistantRefresh(): boolean {
        return this._aiAssistantRefresh;
    }
    set aiAssistantRefresh(value: boolean) {
        this._aiAssistantRefresh = value;
    }

    reset() {
        localStorage.removeItem(IN_PROGRESS_LOCALSTORAGE_KEY);
        this._step = WizardStep.WELCOME;
        this._boardName = "";
        this._boardStatement = "";
        this._endDate = null;
        this._goals = [];
        this._boardModel = null;
        this._boardId = null;
    }

    private calculateNextStepAvailable(): boolean {
        switch (this._step) {
            case WizardStep.WELCOME:
                return true;
            case WizardStep.SET_UP_NAME: {
                return this._boardName.length > 0; //and add date validation later
            }
            case WizardStep.GOALS: {
                return this._goals.length >= MIN_GOALS_REQUIRED;
            }
            case WizardStep.DESIGNER: {
                return this._boardModel !== null && this._boardModel !== undefined /*&& isCompleteGridBoard(this._boardModel)*/;
            }
            default:
                return false;
        }
    }

    public toBoardRequest(background?: Background): BoardRequestDto {
        return {
            endDate: this._endDate,
            name: this._boardName,
            background,
            model: this._boardModel,
            statement: this._boardStatement
        }
    }

    public restoreBoard(board: Board) {
        this._boardId = board.id;
        localStorage.setItem(IN_PROGRESS_LOCALSTORAGE_KEY, JSON.stringify({
            boardId: board.id,
            name: board.name,
        } as PreviousSessionBoard));
        this._boardName = board.name;
        this._boardStatement = board.statement;
        this._boardModel = board.model;
        this._goals = board.goals;
        this._step = board.goals.length ? WizardStep.GOALS : WizardStep.SET_UP_NAME;
        this._nextStepAvailable = true;
    }
}

const wizardState = new WizardState();

export const getWizardState = () => wizardState;