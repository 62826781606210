import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

export const getUserTimezone = () => dayjs.tz.guess();

export const getUtcNotificationTime = (date: Dayjs): number => { 
    const utcDate = date.utc();
    return parseInt(`${utcDate.hour()}${utcDate.minute() < 10 ? "0" + utcDate.minute() : utcDate.minute()}`);
}

export const convertNotificationUtcTimeToDate = (notificationTime: number): Dayjs => {
    let hour, minutes;
    const notificationTimeString = notificationTime.toString();
    if (notificationTimeString.length === 3) {
        hour = notificationTimeString.charAt(0);
        minutes = notificationTimeString.substring(1);
    } else {
        hour = notificationTimeString.substring(0, 2);
        minutes = notificationTimeString.substring(2, 4);
    }
    return dayjs.utc().set("hour", parseInt(hour)).set("minutes", parseInt(minutes)).tz(getUserTimezone());
}