import { Box, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { LogoText } from "../components/LogoText";
import { useAuth } from "@clerk/clerk-react";
import { Loader } from "../components/Loader";
import { getQuote } from "./quotes";
import dawn from "../assets/dawn.jpg";

export const ClerkPublicWrapperPage = ({
  Component,
  internal = false,
}: {
  Component: React.ReactNode;
  internal?: boolean;
}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (auth.isLoaded) {
      if (auth.isSignedIn) {
        navigate("/app/dashboard");
      } else {
        setLoading(false);
      }
    }
  }, [auth.isLoaded, auth.isSignedIn]);

  const isSmallDevice = useMediaQuery("(max-width:400px)");
  const isQuoteVisible = useMediaQuery("(min-width:850px)");

  if (loading) {
    return <Loader fullScreen />;
  }

  const quote = getQuote();

  return (
    <Box sx={{ width: "100vw", display: "flex" }}>
      <Box
        className="public-form"
        sx={() => ({
          width: isQuoteVisible ? "70%" : "100%",
          height: "100%",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          pt: "40px",
          pb: isSmallDevice ? "40px" : "0px",
          backgroundColor: "#243040",
          gap: internal ? "20px" : "0px",
        })}
      >
        <LogoText full width={isSmallDevice ? "200px": "300px"} />
        <Box sx={{ height: "20px" }}></Box>
        {Component}
      </Box>
      <Box
        sx={{
          minHeight: "100vh",
          width: isSmallDevice ? 0 : "30%",
          display: isQuoteVisible ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column", 
          gap: "20px",
          p: "40px",
          backgroundImage: `url(${dawn})`,
          backgroundSize: "cover",
          filter: "grayscale(100%)"
        }}
      >
        <Typography sx={{ fontSize: "35px", fontWeight: 700, fontStyle: "italic" }}>"{quote.value}"</Typography>
        <Typography sx={{ fontSize: "20px" }}>- {quote.author}</Typography>
        <Typography sx={{ position: "absolute", bottom: 10, right: 10, fontSize: "8px" }} component={"span"}>Image by <a href="https://pixabay.com/users/jplenio-7645255/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=3804124">Joe</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=3804124">Pixabay</a></Typography>
      </Box>
    </Box>
  );
};
