import { Typography } from "@mui/material"
import { TextStyles } from "../toolbox/TextToolbox";
import { mapStylesToSx } from "./Label";

const WAVE = `<svg width="639" height="100" xmlns="http://www.w3.org/2000/svg">
 <g class="layer">
  <g fill="COLOR_REPLACEMENT">
   <path d="m0,16c26.61,0 53.23,-16 79.85,-16l0,0c26.61,0 53.23,16 79.84,16l0,0c26.62,0 53.23,-16 79.85,-16l0,0c26.61,0 53.23,16 79.84,16l0,0c26.62,0 53.23,-16 79.85,-16l0,0c26.62,0 53.23,16 79.85,16l0,0c26.61,0 53.23,-16 79.84,-16l0,0c26.62,0 53.23,16 79.85,16l0,23.16l0,0c-26.62,0 -53.23,-16 -79.85,-16l0,0c-26.61,0.01 -53.23,16 -79.84,16l-0.01,0c-26.61,0 -53.23,-16 -79.84,-16l0,0c-26.62,0.01 -53.23,16 -79.85,16l0,0c-26.61,0 -53.23,-16 -79.84,-16l0,0c-26.62,0.01 -53.23,16 -79.85,16l0,0c-26.61,0 -53.23,-16 -79.84,-16l-0.01,0c-26.61,0.01 -53.23,16 -79.84,16l0,-23.16" id="svg_12"/>
  </g>
 </g>
</svg>`

// TODO add more colors from https://htmlcolorcodes.com/color-names/
export enum WaveColor {
    SteelBlue = "SteelBlue",
    CadetBlue = "CadetBlue",
    SkyBlue = "SkyBlue",
    SeaGreen = "SeaGreen",
    Khaki = "Khaki"
}

const COLOR_MAPPINGS: { [key in WaveColor]: string } = {
    [WaveColor.SteelBlue]: "#4682B4",
    [WaveColor.CadetBlue]: "#5F9EA0",
    [WaveColor.SkyBlue]: "#87CEEB",
    [WaveColor.SeaGreen]: "#2E8B57",
    [WaveColor.Khaki]: "#F0E68C"
}

export const LabelWave = ({ text, styles, variant }: { text: string; styles: TextStyles; variant: WaveColor }) => {

    const backgroundImage = `url('data:image/svg+xml;base64,${btoa(WAVE.replace("COLOR_REPLACEMENT", COLOR_MAPPINGS[variant]))}')`;

    return (
        <Typography sx={{ 
            ...mapStylesToSx(styles),
            position: "relative",
            "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-0.5rem",
                left: "-0.5rem",
                right: "-0.5rem",
                height: "0.55rem",
                zIndex: -1,
                backgroundSize: "contain",
                backgroundImage,
            },
            textDecoration: undefined,
        }}>{text}</Typography>
    )
}