import { PresignedUrl, attachmentApi } from "../api/AttachmentApi";
import { Photo } from "../api/UnsplashApi";
import { CallApi } from "../hooks/useFetch";

export interface GoalPhoto {
    type: "unsplash" | "attachment";
    value: Photo | string
}

export interface Goal {
    id: string;
    name: string;
    description: string;
    tags: string[];
    photo: GoalPhoto; 
    attachments: Attachment[]
    subgoals?: Goal[];
    accomplished: boolean;
}

export interface JournalEntry {
    id: string;
    body?: string;
    title: string;
    attachments: Attachment[];
    creationDate: number;
}

export enum AttachmentType {
    FILE = "FILE",
    IMAGE = "IMAGE",
}

export interface Attachment {
    id: string,
    type: AttachmentType;
    name: string;
    timestamp: number;
    description?: string;
}

export interface GoalGalleryAttachment {
    id: string;
    name: string;
    url: string;
}

interface GoalBacklog {
    id: string;

}

interface GoalBacklogEntry {
    id: string;
    name: string;
    link?: any // TODO link to general journal entry
}

export const getPhotoUrl = async (goal: Goal, size: "big" | "regular" | "small", callApi: CallApi): Promise<string> => {
    if (goal.photo.type === "unsplash") {
        const photo = goal.photo.value as Photo;
        return size === "small" ? photo.urls.small : size === "regular" ? photo.urls.regular : photo.urls.full ?? photo.urls.large
    } else if (goal.photo.type === "attachment") {
        const attachmentUrl: PresignedUrl = await callApi(attachmentApi.getDownloadAttachmentParameters(goal.photo.value as string));
        return attachmentUrl.params.url;
    } else {
        return "";
    }
}

export interface GoalRelatedElement {
    boardId: string; goalId: string
}