import { Fade, Box } from "@mui/material";
import { useState, useEffect } from "react";

export const withFadeIn = (
  Component: any,
  props?: {
    timeout?: number;
    fullWidth?: boolean;
    center?: boolean;
    overflowY?: boolean;
    postFadeIn?: () => void;
  }
) => {
  // @ts-ignore
  return (ComponentProps) => {
    const [welcomeFade, setWelcomeFade] = useState<boolean>(false);

    useEffect(() => {
      setWelcomeFade(false);
      setTimeout(
        () => setWelcomeFade(true),
        props && props.timeout ? props.timeout : 200
      );
    }, []);
    return (
      <Fade in={welcomeFade} timeout={200}>
        <Box
          sx={{
            width: props && props.fullWidth ? "100%" : undefined,
            height: props && props.center ? "100%" : undefined,
            paddingTop: props && props.center ? "50px" : undefined,
            overflowY: props && props.overflowY ? "auto" : undefined
          }}
        >
          <Component {...ComponentProps} />
        </Box>
      </Fade>
    );
  };
};
