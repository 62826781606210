import { Typography } from "@mui/material"
import { TextStyles } from "../toolbox/TextToolbox";
import { mapStylesToSx } from "./Label";

export enum TextShadowColor {
    "Gray" = "Gray",
    "Blue" = "Blue",
    "Orange" = "Orange",
    "Green" = "Green"
}

export const TextShadowLabel = ({ text, styles, variant }: { text: string; styles: TextStyles, variant: TextShadowColor  }) => {
    return (
        <Typography className={`${variant ? variant.toLocaleLowerCase() : TextShadowColor.Gray.toLocaleLowerCase()}-shadow-label`} sx={{ 
            ...mapStylesToSx(styles),
        }}>{text}</Typography>
    )
}