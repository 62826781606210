import { Typography, useMediaQuery } from "@mui/material";
import { CustomModal } from "./CustomModal"

export const YesNoModal = ({ open, onNo, onYes, label, alert }: { open: boolean, onYes: () => void; onNo: () => void, label?: string, alert?: boolean }) => {
    const isWideScreen = useMediaQuery("(min-width:1600px)");
    
    return (
        <CustomModal alert={alert} sx={{ width: "min(500px, 90%)", fontFamily: "Lato, sans-serif", fontSize: isWideScreen ? "24px" : undefined, fontStyle: "italic" }} open={open} onCancel={onNo} onOk={onYes} okLabel="Yes">
            <Typography sx={{ textAlign: "center", color: alert ? "#BD3636" : undefined }}>{label ? label : "Are you sure you want to confirm"}</Typography>
        </CustomModal>)
}