import { Typography } from "@mui/material";
import { TextStyles } from "../toolbox/TextToolbox";
import { Label1, TextShadowStyle } from "./Label1";
import {
  DEFAULT_TEXT_STYLES,
  Label as LabelItem,
  LabelStyle,
} from "../grid/model";
import { LabelWave, WaveColor } from "./LabelWave";
import { AnimatedLabel } from "./AnimatedLabel";
import { TextShadowColor, TextShadowLabel } from "./TextShadowLabel";
import {
  AnimatedGradientLabel,
  AnimatedGradientStyle,
} from "./AnimatedGradientLabel";
import {
  GradientUnderlineColor,
  GradientUnderlineLabel,
} from "./GradientUnderlineLabel";

export interface LabelProperties extends LabelItem {
  textStyles: TextStyles;
}

export const mapStylesToSx = (styles: TextStyles) => ({
  textAlign: styles.textAlign,
  fontFamily: styles.font,
  fontWeight: styles.bold ? "700" : undefined,
  fontStyle: styles.italic ? "italic" : undefined,
  textDecoration: styles.underline ? "underline" : undefined,
  fontSize: `${styles.size}px`,
  transform: `rotate(${styles.textRotate}deg)`,
  textShadow: styles.outline
    ? "0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black"
    : undefined,
  color: styles.color,
});

export const getVariants = (style: LabelStyle): any[] => {
  switch (style) {
    case LabelStyle.WAVE:
      return Object.keys(WaveColor);
    case LabelStyle.LABEL1:
      return Object.keys(TextShadowStyle);
    case LabelStyle.TEXT_SHADOW:
      return Object.keys(TextShadowColor);
    case LabelStyle.ANIMATED1:
      return [];
    case LabelStyle.ANIMATED_GRADIENT:
      return Object.keys(AnimatedGradientStyle);
    case LabelStyle.GRADIENT_UNDERLINE:
      return Object.keys(GradientUnderlineColor);
    default:
      return [];
  }
};

export const Label = ({
  text,
  style,
  textStyles,
  variant,
}: LabelProperties) => {
  switch (style) {
    case LabelStyle.LABEL1:
      return (
        <Label1
          text={text}
          styles={textStyles}
          variant={variant ?? TextShadowStyle.Regular}
        />
      );
    case LabelStyle.WAVE:
      return (
        <LabelWave
          text={text}
          styles={textStyles}
          variant={variant ?? WaveColor.CadetBlue}
        />
      );
    case LabelStyle.ANIMATED1:
      return <AnimatedLabel text={text} styles={textStyles} />;
    case LabelStyle.TEXT_SHADOW:
      return (
        <TextShadowLabel
          styles={textStyles}
          text={text}
          variant={variant ?? TextShadowColor.Gray}
        />
      );
    case LabelStyle.ANIMATED_GRADIENT:
      return (
        <AnimatedGradientLabel
          styles={textStyles}
          text={text}
          variant={variant ?? AnimatedGradientStyle.Neon}
        />
      );
    case LabelStyle.GRADIENT_UNDERLINE:
      return (
        <GradientUnderlineLabel
          styles={textStyles}
          text={text}
          variant={variant ?? GradientUnderlineColor.Blue}
        />
      );
    case LabelStyle.NONE:
    default:
      return (
        <Typography
          sx={{
            ...mapStylesToSx(textStyles || DEFAULT_TEXT_STYLES),
          }}
        >
          {text}
        </Typography>
      );
  }
};
