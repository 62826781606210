import {
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Box,
  Tooltip,
  TextField,
  SxProps,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { CustomSelect } from "../../../../components/CustomSelect";
import { HtmlTooltip } from "../../../../components/HtmlTooltip";
import {
  BorderStyle,
  FilterType,
  LayoutItem,
  LayoutItemStyles,
} from "../grid/model";
import FilterIcon from "@mui/icons-material/Filter";
import CropIcon from "@mui/icons-material/Crop";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { SketchPicker } from "react-color";
import { getInputValue } from "../../utils/numbers";
import { useTranslation } from "react-i18next";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

const FilterSelect = ({
  imageFilter,
  imageFilterCallback,
}: {
  imageFilter: FilterType;
  imageFilterCallback: (value: FilterType) => void;
}) => {
  return (
    <Box>
      <CustomSelect
        selectSx={{ height: "40px", backgroundColor: "#fff" }}
        onChange={(value) => imageFilterCallback(value as FilterType)}
        value={imageFilter}
        options={[
          {
            label: "None",
            value: "none",
          },
          {
            label: "Sepia",
            value: "sepia",
          },
          {
            label: "Grayscale",
            value: "grayscale",
          },
          {
            label: "Inverted",
            value: "inverted",
          },
          {
            label: "Contrast",
            value: "contrast",
          },
        ]}
      />
    </Box>
  );
};

export const BorderStyleSelect = ({
  borderStyle,
  borderStyleCallback,
  label,
  sx,
}: {
  borderStyle: BorderStyle;
  borderStyleCallback: (value: BorderStyle) => void;
  label?: string;
  sx?: SxProps;
}) => {
  const { t } = useTranslation();

  return (
    <CustomSelect
      fullWidth
      label={label}
      selectSx={sx ? sx : { height: "40px", backgroundColor: "#fff", minWidth: "150px" }}
      onChange={(value) => borderStyleCallback(value as BorderStyle)}
      value={borderStyle}
      options={[
        {
          label: t("border.style.normal"),
          value: "normal" as BorderStyle,
        },
        {
          label: t("border.style.dashed"),
          value: "dashed" as BorderStyle,
        },
        {
          label: t("border.style.dotted"),
          value: "dotted" as BorderStyle,
        },
        {
          label: t("border.style.double"),
          value: "double" as BorderStyle,
        },
        {
          label: t("border.style.outset"),
          value: "outset" as BorderStyle,
        },
        {
          label: t("border.style.special-dashed-1"),
          value: "special-dashed-1" as BorderStyle,
        },
        {
          label: t("border.style.special-dotted-1"),
          value: "special-dotted-1" as BorderStyle,
        },
        {
          label: t("border.style.special-normal-1"),
          value: "special-normal-1" as BorderStyle,
        },
        {
          label: t("border.style.animated-rainbow-1"),
          value: "animated-rainbow-1" as BorderStyle,
        },
        {
            label: t("border.style.animated-rainbow-2"),
            value: "animated-rainbow-2" as BorderStyle,
          },
      ]}
    />
  );
};

const ImagePositionAlignment = ({
  styles,
  updateStyles,
}: {
  updateStyles: (props: Partial<LayoutItemStyles>) => void;
  styles: LayoutItemStyles;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1px" }}
      >
        <Box sx={{ width: "40px" }}></Box>
        <IconButton
          sx={{ width: "40px" }}
          onClick={() =>
            updateStyles({
              backgroundPosition: {
                y: "top",
                x:
                  styles && styles.backgroundPosition
                    ? styles.backgroundPosition.x
                    : "left",
              },
            })
          }
        >
          <NorthIcon fontSize="small" />
        </IconButton>
        <Box sx={{ width: "40px" }}></Box>
        <IconButton
          sx={{ width: "40px" }}
          onClick={() =>
            updateStyles({
              backgroundPosition: {
                y:
                  styles && styles.backgroundPosition
                    ? styles.backgroundPosition.y
                    : "top",
                x: "left",
              },
            })
          }
        >
          <WestIcon fontSize="small" />
        </IconButton>
        <IconButton
          sx={{ width: "40px" }}
          onClick={() =>
            updateStyles({
              backgroundPosition: {
                y: "center",
                x: "center",
              },
            })
          }
        >
          <CenterFocusStrongIcon fontSize="small" />
        </IconButton>
        <IconButton
          sx={{ width: "40px" }}
          onClick={() =>
            updateStyles({
              backgroundPosition: {
                y:
                  styles && styles.backgroundPosition
                    ? styles.backgroundPosition.y
                    : "top",
                x: "right",
              },
            })
          }
        >
          <EastIcon fontSize="small" />
        </IconButton>
        <Box sx={{ width: "40px" }}></Box>
        <IconButton
          sx={{ width: "40px" }}
          onClick={() =>
            updateStyles({
              backgroundPosition: {
                y: "bottom",
                x:
                  styles && styles.backgroundPosition
                    ? styles.backgroundPosition.x
                    : "left",
              },
            })
          }
        >
          <SouthIcon fontSize="small" />
        </IconButton>
        <Box sx={{ width: "40px" }}></Box>
      </Box>
      <Button
        variant="text"
        onClick={() =>
          updateStyles({
            backgroundPosition: {
              y: "top",
              x: "left",
            },
          })
        }
      >
        Reset
      </Button>
    </Box>
  );
};

export const ElementToolbox = ({
  styles,
  updateStyles,
  moveDownCallback,
  moveUpCallback,
  orderIcons = false,
  item,
  onClose,
}: {
  moveUpCallback: () => void;
  moveDownCallback: () => void;
  updateStyles: (props: Partial<LayoutItemStyles>) => void;
  styles: LayoutItemStyles;
  orderIcons?: boolean;
  onClose?: () => void;
  item: LayoutItem;
}) => {
  const [imageFilterTooltip, setImageFilterTooltip] = useState<boolean>(false);
  const [colorPicker, setColorPicker] = useState<boolean>(false);
  const [borderSize, setBorderSize] = useState<boolean>(false);
  const [borderStyle, setBorderStyle] = useState<boolean>(false);
  const [cropImage, setCropImage] = useState<boolean>(false);

  return (
    <>
      {item.type === "goal" ? (
        <>
          <ToggleButtonGroup size="small">
            <HtmlTooltip
              open={imageFilterTooltip}
              title={
                <FilterSelect
                  imageFilter={styles.filter || "none"}
                  imageFilterCallback={(filter) => updateStyles({ filter })}
                />
              }
            >
              <ToggleButton
                selected={styles.filter !== "none"}
                value="image-filter"
                onClick={() => {
                  setImageFilterTooltip(!imageFilterTooltip);
                  setColorPicker(false);
                  setBorderSize(false);
                  setBorderStyle(false);
                  setCropImage(false);
                }}
              >
                <Tooltip title="Image filter" arrow placement="top">
                  <FilterIcon />
                </Tooltip>
              </ToggleButton>
            </HtmlTooltip>
            <HtmlTooltip
              open={cropImage}
              title={
                <ImagePositionAlignment
                  styles={styles}
                  updateStyles={updateStyles}
                />
              }
            >
              <ToggleButton
                value="image-crop"
                onClick={() => {
                  setImageFilterTooltip(false);
                  setColorPicker(false);
                  setBorderSize(false);
                  setBorderStyle(false);
                  setCropImage(!cropImage);
                }}
              >
                <Tooltip title="Adjust image position" arrow placement="top">
                  <CropIcon />
                </Tooltip>
              </ToggleButton>
            </HtmlTooltip>
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        </>
      ) : null}
      {orderIcons ? (
        <>
          <ToggleButtonGroup size="small">
            <Tooltip title="Move element up" arrow placement="top">
              <ToggleButton value="move-up" onClick={moveUpCallback}>
                <ArrowUpwardIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Move element down" arrow placement="top">
              <ToggleButton value="move-down" onClick={moveDownCallback}>
                <ArrowDownwardIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        </>
      ) : null}
      {/* common icons */}
      <ToggleButtonGroup size="small">
        <Tooltip title="Remove border" arrow placement="top">
          <ToggleButton
            selected={styles.noBorder ?? false}
            value="remove-border"
            onClick={() => updateStyles({ noBorder: !styles.noBorder })}
          >
            <BorderClearIcon />
          </ToggleButton>
        </Tooltip>
        <HtmlTooltip
          open={colorPicker}
          title={
            <Box>
              <SketchPicker
                color={styles.borderColor ?? "rgba(0,0,0,1)"}
                onChangeComplete={(color) =>
                  updateStyles({
                    borderColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                  })
                }
              />
            </Box>
          }
        >
          <ToggleButton
            value="border-color"
            onClick={() => {
              setColorPicker(!colorPicker);
              setImageFilterTooltip(false);
              setBorderSize(false);
              setBorderStyle(false);
              setCropImage(false);
            }}
          >
            <Tooltip title="Border color" arrow placement="top">
              <BorderColorIcon />
            </Tooltip>
          </ToggleButton>
        </HtmlTooltip>
        <HtmlTooltip
          open={borderSize}
          title={
            <Box>
              <TextField
                onChange={(e) =>
                  updateStyles({ borderSize: getInputValue(e, 0, 20) })
                }
                type="number"
                InputProps={{ style: { height: "40px", width: "100px" } }}
                value={styles.borderSize ?? 4}
                sx={{ height: "40px", width: "100px", backgroundColor: "#fff" }}
              />
            </Box>
          }
        >
          <ToggleButton
            value="border-color"
            onClick={() => {
              setBorderSize(!borderSize);
              setColorPicker(false);
              setImageFilterTooltip(false);
              setBorderStyle(false);
              setCropImage(false);
            }}
          >
            <Tooltip title="Border size" arrow placement="top">
              <LineWeightIcon />
            </Tooltip>
          </ToggleButton>
        </HtmlTooltip>
        <HtmlTooltip
          open={borderStyle}
          title={
            <Box>
              <BorderStyleSelect
                borderStyle={styles.borderStyle ?? "normal"}
                borderStyleCallback={(borderStyle) =>
                  updateStyles({ borderStyle })
                }
              />
            </Box>
          }
        >
          <ToggleButton
            value="border-style"
            onClick={() => {
              setBorderStyle(!borderStyle);
              setBorderSize(false);
              setColorPicker(false);
              setImageFilterTooltip(false);
            }}
          >
            <Tooltip title="Border style" arrow placement="top">
              <BorderStyleIcon />
            </Tooltip>
          </ToggleButton>
        </HtmlTooltip>
        <Tooltip arrow placement="top" title="Restore to board settings">
          <ToggleButton
            value="border-color"
            onClick={() =>
              updateStyles({
                noBorder: false,
                borderColor: undefined,
                borderSize: undefined,
                borderStyle: undefined,
              })
            }
          >
            <ClearAllIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
      {onClose ? (
        <>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <ToggleButtonGroup size="small">
            <ToggleButton value="close" onClick={onClose}>
              <CloseIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      ) : null}
    </>
  );
};
