import { Box, TextField, Tooltip, IconButton, FormGroup, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { CustomModal } from "../../../../components/CustomModal";
import { getInputValue } from "../../utils/numbers";
import { BorderStyle, GridBoardProperties } from "./model";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { HtmlTooltip } from "../../../../components/HtmlTooltip";
import { SketchPicker } from "react-color";
import { BorderStyleSelect } from "../toolbox/ElementToolbox";
import { CustomSwitch } from "../../../../components/CustomSwitch";

const SIZE_MIN_VALUE = 0;
const SIZE_MAX_VALUE = 100;
const sizeTextFieldStyles = { height: "40px", maxWidth: "150px" }

export const BoardPropertiesModal = (
    { initialProperties, onCancel, onOk }:
        {
            initialProperties: GridBoardProperties;
            onCancel: () => void;
            onOk: (properties: GridBoardProperties) => void;
        }
) => {

    const [boardPadding, setBoardPadding] = useState<[number, number]>(initialProperties.boardPadding);
    const [elementMargin, setElementMargin] = useState<[number, number]>(initialProperties.elementMargin);
    const [paddingKeepRatio, setPaddingKeepRatio] = useState<boolean>(initialProperties.boardPadding[0] === initialProperties.boardPadding[1])
    const [marginKeepRatio, setMarginKeepRatio] = useState<boolean>(initialProperties.elementMargin[0] === initialProperties.elementMargin[1])
    const [overflow, setOverflow] = useState<boolean>(initialProperties.allowOverflow);
    const [roundedCorners, setRoundedCorners] = useState<boolean>(initialProperties.roundedCorners);
    const [skipStatement, setSkipStatement] = useState<boolean>(initialProperties.skipStatement ?? false);
    const [picker, setPicker] = useState<boolean>(false);
    const [borderSize, setBorderSize] = useState<number>(initialProperties.borderSize);
    const [borderColor, setBorderColor] = useState<string>(initialProperties.borderColor);
    const [borderStyle, setBorderStyle] = useState<BorderStyle>(initialProperties.borderStyle);

    return (
        <CustomModal sx={{ width: "min(500px, 90%)" }} open={true} cancelIcon okLabel='Save' onCancel={onCancel} onOk={() => onOk({
            ...initialProperties,
            boardPadding,
            borderSize,
            borderColor,
            borderStyle,
            elementMargin,
            allowOverflow: overflow,
            roundedCorners,
            skipStatement: skipStatement
        })}>
            <Box sx={{ fontSize: "16px", display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap", flexDirection: "row" }}>
                <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", flexWrap: "wrap", flexDirection: "column", width: "100%" }}>
                    <Box sx={{ width: "100%", fontSize: "20px" }}>Board properties</Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <TextField onChange={(e) => setBoardPadding([getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE), paddingKeepRatio ? getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE) : boardPadding[1]])} label="Board padding (left)" type="number" InputProps={{ style: sizeTextFieldStyles }} value={boardPadding[0]} sx={sizeTextFieldStyles} />
                        <TextField onChange={(e) => setBoardPadding([paddingKeepRatio ? getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE) : boardPadding[0], getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE)])} label="Board padding (top)" type="number" InputProps={{ style: sizeTextFieldStyles }} value={boardPadding[1]} sx={sizeTextFieldStyles} />
                        <Tooltip arrow placement='top' title={paddingKeepRatio ? "Unlock ratio" : "Lock ratio"} >
                            <IconButton sx={{ border: paddingKeepRatio ? "1px solid #544961" : "1px solid white", color: marginKeepRatio ? "#243040" : undefined }} onClick={() => setPaddingKeepRatio(!paddingKeepRatio)}><AspectRatioIcon /></IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", pt: "15px" }}>
                        <TextField onChange={(e) => setElementMargin([getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE), marginKeepRatio ? getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE) : elementMargin[1]])} label="Element padding (left)" type="number" InputProps={{ style: sizeTextFieldStyles }} value={elementMargin[0]} sx={sizeTextFieldStyles} />
                        <TextField onChange={(e) => setElementMargin([marginKeepRatio ? getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE) : elementMargin[0], getInputValue(e, SIZE_MIN_VALUE, SIZE_MAX_VALUE)])} label="Element padding (top)" type="number" InputProps={{ style: sizeTextFieldStyles }} value={elementMargin[1]} sx={sizeTextFieldStyles} />
                        <Tooltip arrow placement='top' title={marginKeepRatio ? "Unlock ratio" : "Lock ratio"} >
                            <IconButton sx={{ border: marginKeepRatio ? "1px solid #544961" : "1px solid white", color: marginKeepRatio ? "#243040" : undefined }} onClick={() => setMarginKeepRatio(!marginKeepRatio)}><AspectRatioIcon /></IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", pt: "15px" }}>
                        <FormGroup>
                            <FormControlLabel control={<CustomSwitch defaultChecked={overflow} value={overflow} onChange={(e, checked) => setOverflow(checked)} />} label="Allow elements overflow" />
                            <FormControlLabel control={<CustomSwitch defaultChecked={roundedCorners} value={roundedCorners} onChange={(e, checked) => setRoundedCorners(checked)} />} label="Rounded corners" />
                            <FormControlLabel control={<CustomSwitch defaultChecked={skipStatement} value={skipStatement} onChange={(e, checked) => setSkipStatement(checked)} />} label="Skip statement" />
                        </FormGroup>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "15px" }}>
                        <Box sx={{ width: "130px" }}>
                            <TextField onChange={(e) => setBorderSize(getInputValue(e, 0, 20))} label="Border size" type="number" InputProps={{ style: { height: "40px", width: "100%" } }} value={borderSize} sx={{ height: "40px", width: "100%" }} />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", width: "50%", gap: "10px", justifyContent: "flex-end" }}>
                            <Box>Border color</Box>
                            <HtmlTooltip open={picker} title={
                                <Box>
                                    <SketchPicker color={borderColor} onChangeComplete={(color) => setBorderColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)} />
                                </Box>
                            }>
                                <Box onClick={() => setPicker(!picker)} sx={{ cursor: "pointer", border: "1px solid black", backgroundColor: borderColor, height: "40px", width: "50px", borderRadius: "10px" }}></Box>
                            </HtmlTooltip>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <BorderStyleSelect sx={{ width: "100%" }} label="Border style" borderStyle={borderStyle} borderStyleCallback={(style) => setBorderStyle(style)} />
                    </Box>
                    {/* <Box sx={{ pt: "15px", display: "flex", alignItems: "center", gap: "10px" }}>
                        <MusicNoteIcon />
                        <CustomSelect label="Background music" value={"none"} options={[{ "label": "None", value: "none" }, { "label": "Todo", value: "todo" }]} onChange={() => { }} fullWidth />
                    </Box> */}
                </Box>
            </Box>
        </CustomModal>
    )
}