import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Snidely.otf";
import "./fonts/HipHoray.otf";
import "./fonts/ArshineUnfocusDemoRegular-MAVPw.otf";
import "./fonts/DimsumWeek-8OOan.otf";
import "./fonts/Nicolast-G333Z.otf";
import "./fonts/Ohyou-lxgXw.otf";
import "./fonts/GingerCat-xRRXr.otf";
import "./fonts/Dinofiles-5yy48.otf";
import "./fonts/SnackBox-XGGmK.otf";
import "./fonts/QuickyStory-8Oa82.otf";
import "./fonts/DodolSabang-2vE7l.otf";
import "./fonts/RusticStory-1jDPL.otf";
import "./fonts/DinoCare-BL0BV.otf";
import "./fonts/Porcinilead-R9px3.otf";
import "./fonts/MangabeyRegular-rgqVO.otf";
import "./fonts/Grillin-ywlG2.otf";
import "./fonts/Browncat-8OMZB.otf";
import "react-18-image-lightbox/style.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
