import { CustomModal } from "../../../components/CustomModal"
import { BoardModel, BoardStatus } from "../../../model/board";
import { Goal } from "../../../model/goal";
import { GridLayoutDesigner } from "./grid/GridLayoutDesigner";
import { Background } from "./grid/model";

export enum LayoutType {
    "GRID" = "GRID",
    "EDITOR" = "EDITOR"
}

export interface DesignerProps {
    boardId: string;
    goals: Goal[];
    name: string;
    statement?: string;
    onClose: () => void;
    onSave: (model: BoardModel, background?: Background) => void;
    refreshGoals: () => Promise<void>;
    initialModel: BoardModel | null
    preview?: boolean;
    viewMode?: boolean;
    parentId?: string
    boardStatus: BoardStatus;
}

export const FullScreenDesigner = ({ open, type, DesignerProps }: { open: boolean, type: LayoutType, DesignerProps: DesignerProps }) => {
    return (
        <CustomModal
            id="fullScreenDesigner"
            open={open}
            sx={{ height: `calc(100% - ${DesignerProps.viewMode ? "0" : "20"}px)`, width: `calc(100% - ${DesignerProps.viewMode ? "0" : "20"}px)`, overflow: "auto !important" }}
            noPadding={DesignerProps.viewMode}
            cancelIcon={!DesignerProps.viewMode}
            childrenWrapperSx={{
                height: "unset",
                minHeight: "100%",
            }}
            onCancel={!DesignerProps.viewMode ? () => DesignerProps.onClose() : undefined} >
            {type === LayoutType.GRID ? <GridLayoutDesigner parentId="fullScreenDesigner" {...DesignerProps} /> : null}
        </CustomModal>
    )
};

export const ViewModeFullScreenDesigner = (props: { type: LayoutType; refreshBoard: () => Promise<void>; DesignerProps: Pick<DesignerProps, "boardId" | "boardStatus" | "goals" | "name" | "statement" | "initialModel" | "onClose"> }) => {
    const { type, refreshBoard, DesignerProps } = props;

    return (
        <FullScreenDesigner type={type} open={true} DesignerProps={{
            ...DesignerProps,
            viewMode: true,
            onSave: () => { },
            refreshGoals: refreshBoard,
        }} />
    )
}