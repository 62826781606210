import { Box, Typography, Button, useMediaQuery } from "@mui/material"
import { Link } from "react-router-dom"
import { CustomModal } from "../../../components/CustomModal"
import mindMapImage from "../../../assets/mindmap.jpg";

export const GoalIntroModal = ({ open, onCancel }: { open: boolean, onCancel: () => void }) => {
    const mobile = useMediaQuery("(max-width:920px)");

    return (
        <CustomModal open={open} onCancel={onCancel} cancelIcon sx={{ border: "undefined", boxShadow: "0px 0px 15px 0px rgba(66, 68, 90, 1)", borderRadius: "15px", width: mobile ? "90%" : "70%" }}>
            <Box sx={{ display: "flex", flexDirection: mobile ? "column" : "row", alignItems: "center", justifyContent: "center", gap: "30px", overflowY: mobile ? "auto" : undefined }}>
                <Box component={"img"} src={mindMapImage} sx={{ width: mobile ? "100%" : "50%" }} />
                <Box sx={{ fontFamily: "Lato, sans-serif", fontSize: mobile ? "14px" : "18px", fontStyle: "italic", textAlign: "justify" }}>
                    To start working with your goals, we recommend asking yourself simple questions like
                    <ul>
                        <li>What kind of life do I want?</li>
                        <li>Where is my life going?</li>
                        <li>Five years from today - where do I want to be?</li>
                        <li>What is the coolest thing that could happen?</li>
                    </ul>
                    Another popular way to gather ideas for you vision board would be using a technique called mind map with your name in the middle.
                    <Typography sx={{ fontFamily: "Lato, sans-serif", fontSize: mobile ? "14px" : "18px", fontStyle: "italic", pt: "10px" }}>
                        You can find more tips in our blog and <Link to={"/app/tutorials"}>tutorial</Link> section.
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", pt: "30px" }}>
                        <Button variant="contained" onClick={onCancel}> I'm ready to start!</Button>
                    </Box>
                </Box>
            </Box>
        </CustomModal >
    )
}