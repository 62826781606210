import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Board } from "../../model/board";
import useFetch, { CallApi } from "../../hooks/useFetch";
import { boardApi } from "../../api/BoardApi";
import { Goal, getPhotoUrl } from "../../model/goal";
import { Loader } from "../../components/Loader";
import { VizualizationControls } from "./VisualizationControls";
import { useParams } from "react-router-dom";
import { useStore } from "../../state/RootStore";
import { observer } from "mobx-react-lite";
import {
  VisualizationType,
  getSerializedVisualizatorSettings,
} from "./VisualizatorSettings";
import { ZoomInZoomOutVisualizator } from "./ZoomInZoomOutVisualizator";
import {
  BoardBackgroundVisualizator,
  boardBackgroundVisualizatorContainerStyles,
} from "./BoardBackgroundVisualizator";
import { VantaVisualizator } from "./VantaVisualizator";
import { PwaCloseFullscreenButton } from "../screensaver/Masonry";
import { isPwaApplication } from "../../utils/pwa";

export interface Slide {
  type: "name" | "statement" | "goal";
  background: string | null;
  name: string;
}

export interface BasicVisualizatorProps {
  slides: Slide[];
  fullscreen: boolean;
  loop: boolean;
  onFinishCallback: () => Promise<void>;
}

const mapGoals = async (goals: Goal[], callApi: CallApi): Promise<Slide[]> => {
  const slides: Slide[] = [];
  for (const goal of goals) {
    const url = await getPhotoUrl(goal, "regular", callApi);
    slides.push({
      type: "goal",
      background: url,
      name: goal.name,
    });
  }
  return slides;
};

const renderVisualization = (
  type: VisualizationType,
  board: Board,
  slides: Slide[],
  fullscreen: boolean,
  loop: boolean,
  onFinishCallback: () => Promise<void>
) => {
  if (type === VisualizationType.ZOOM_IN_ZOOM_OUT) {
    return (
      <ZoomInZoomOutVisualizator
        fullscreen={fullscreen}
        loop={loop}
        slides={slides}
        onFinishCallback={onFinishCallback}
      />
    );
  } else if (type === VisualizationType.BOARD_BACKGROUND) {
    return (
      <BoardBackgroundVisualizator
        board={board}
        fullscreen={fullscreen}
        loop={loop}
        slides={slides}
        onFinishCallback={onFinishCallback}
      />
    );
  } else if (type === VisualizationType.CELLS) {
    return (
      <VantaVisualizator
        effect={"cells"}
        fullscreen={fullscreen}
        loop={loop}
        slides={slides}
        onFinishCallback={onFinishCallback}
      />
    );
  } else if (type === VisualizationType.CLOUDS) {
    return (
      <VantaVisualizator
        effect={"clouds"}
        fullscreen={fullscreen}
        loop={loop}
        slides={slides}
        onFinishCallback={onFinishCallback}
      />
    );
  } else if (type === VisualizationType.HALO) {
    return (
      <VantaVisualizator
        effect={"halo"}
        fullscreen={fullscreen}
        loop={loop}
        slides={slides}
        onFinishCallback={onFinishCallback}
      />
    );
  }
  return null;
};

export const BasicVisualizator = observer(
  ({ type }: { type: VisualizationType }) => {
    const audioRef = useRef<any>(null);

    const [slides, setSlides] = useState<Slide[]>([]);
    const { callApi } = useFetch();
    const [loop, setLoop] = useState<boolean>(false);
    const [music, setMusic] = useState<boolean>(false);
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [board, setBoard] = useState<Board | null>(null);
    const { boardId } = useParams();
    const isMobile = useMediaQuery("(max-width:630px)");

    const store = useStore();

    const recordVisualization = async () => {
      if (boardId) {
        try {
          await callApi(boardApi.recordVisualization(boardId, type));
        } catch (e) {
          console.log("Exception", e);
        }
      }
    };

    useEffect(() => {
      const queryBoard = async () => {
        if (boardId) {
          try {
            const board: Board = await callApi(boardApi.getBoard(boardId));
            setBoard(board);
            const mappedSlides: Slide[] = [];
            mappedSlides.push({
              background: null,
              name: board.name,
              type: "name",
            });
            if (board.statement) {
              mappedSlides.push({
                background: null,
                name: board.statement,
                type: "statement",
              });
            }
            const mappedGoals = await mapGoals(board.goals, callApi);
            mappedSlides.push(...mappedGoals);
            mappedSlides.push({
              name: "Thank you",
              type: "statement",
              background: null,
            });
            setSlides(mappedSlides);
          } catch (e) {
            console.log("notification");
          }
        }
      };
      queryBoard();
    }, [boardId]);

    useEffect(() => {
      if (slides.length) {
        const audio = new Audio(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/music/better-day-186374.mp3`);
        audioRef.current = audio;
        if (store.isInitialRoute) {
          setMusic(false);
        } else if (music) {
          audio.play();
        }
      }
    }, [slides]);

    useEffect(() => {
      if (audioRef.current) {
        if (music) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
      }
    }, [music]);

    const exitedFullScreenCallback = () => {
      if (!document.fullscreenElement) {
        setFullscreen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("fullscreenchange", exitedFullScreenCallback);

      try {
        const serializedSettings = getSerializedVisualizatorSettings();
        setMusic(serializedSettings.withAudio);
        setLoop(serializedSettings.withLoop);
      } catch (e) {
        //
      }

      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        document.removeEventListener(
          "fullscreenchange",
          exitedFullScreenCallback
        );
      };
    }, []);
    if (!slides.length) {
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography>Building your visualization...</Typography>
          <Loader />
        </Box>
      );
    }
    const isPwaApp = isPwaApplication();

    return (
      <Box
        className="visualizator-box"
        sx={{
          ...(type === VisualizationType.BOARD_BACKGROUND && board
            ? boardBackgroundVisualizatorContainerStyles(board)
            : {
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }),
          width: isMobile ? "85vw" : "100%",
          ...(isMobile
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }
            : {}),
          ...(isPwaApp && fullscreen
            ? {
                width: "100vw",
                height: "100vh",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1200,
              }
            : {}),
        }}
      >
        <VizualizationControls
          elementClassName="visualizator-box"
          fullscreen={fullscreen}
          loop={loop}
          music={music}
          toggleFullscreen={(value) => {
            setFullscreen(value);
            setLoop(true);
          }}
          toggleLoop={(value) => {
            setLoop(value);
          }}
          toggleMusic={(value) => {
            setMusic(value);
          }}
        />
        {isPwaApp && fullscreen ? (
          <PwaCloseFullscreenButton callback={exitedFullScreenCallback} />
        ) : null}
        {renderVisualization(
          type,
          board as Board,
          slides,
          fullscreen,
          loop,
          recordVisualization
        )}
      </Box>
    );
  }
);
