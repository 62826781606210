import {
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
  Icon,
} from "@mui/material";
import { useState } from "react";
import { BackgroundPropertiesModal } from "./BackgroundPropertiesModal";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";
import PreviewIcon from "@mui/icons-material/Preview";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GridViewIcon from "@mui/icons-material/GridView";
import { BoardPropertiesModal } from "./BoardPropertiesModal";
import { GridBoardProperties, LayoutItemType } from "./model";
import AddIcon from "@mui/icons-material/Add";
import LabelIcon from "@mui/icons-material/Label";
import AnimationIcon from "@mui/icons-material/Animation";
import { IllustrationModal } from "./IllustrationsModal";
import BoltIcon from "@mui/icons-material/Bolt";
import { PowerfulStatementModal } from "./PowerfulStatementModal";
import { LabelModal } from "./LabelModal";
import stickerIcon from "../../../../assets/icons/add-sticker.svg";
import illustrationIcon from "../../../../assets/icons/add-illustration.svg";
import {
  BackgroundAnimation,
  BackgroundAnimationModal,
} from "./BackgroundAnimationModal";
import { StickersModal } from "./StickersModal";

const AddStickerIcon = () => {
  return (
    <Icon sx={{ textAlign: "center" }}>
      <img
        style={{ display: "flex", height: "inherit", width: "inherit", opacity: 0.54 }}
        src={stickerIcon}
      />
    </Icon>
  );
};

const AddIllustrationIcon = () => {
  return (
    <Icon sx={{ textAlign: "center" }}>
      <img
        style={{ display: "flex", height: "inherit", width: "inherit", opacity: 0.54 }}
        src={illustrationIcon}
      />
    </Icon>
  );
};

export const NavigationButtons = ({
  boardProperties,
  updateBoardProperties,
  onModalShowCallback,
  addNewItemCallback,
  shuffleCallback,
  onSave,
  enablePreview,
}: {
  boardProperties: GridBoardProperties;
  updateBoardProperties: (props: GridBoardProperties) => void;
  onModalShowCallback: () => void;
  addNewItemCallback: (type: LayoutItemType, value: any) => void;
  shuffleCallback: () => void;
  onSave: () => void;
  enablePreview: () => void;
}) => {
  const [editorMenu, setEditorMenu] = useState<boolean>(false);
  const [addMenu, setAddMenu] = useState<boolean>(false);
  const [boardModal, setBoardModal] = useState<boolean>(false);
  const [backgroundModal, setBackgroundModal] = useState<boolean>(false);
  const [illustrationModal, setIllustrationsModal] = useState<boolean>(false);
  const [stickerModal, setStickerModal] = useState<boolean>(false);
  const [powerfulModal, setPowerfulModal] = useState<boolean>(false);
  const [labelModal, setLabelModal] = useState<boolean>(false);
  const [animationModal, setAnimationModal] = useState<boolean>(false);
  const mobileMenu = useMediaQuery("(max-width:800px)");

  return (
    <>
      {animationModal ? (
        <BackgroundAnimationModal
          initialAnimation={
            boardProperties.backgroundAnimation ?? BackgroundAnimation.NONE
          }
          background={boardProperties.background}
          onCancel={() => setAnimationModal(false)}
          onOk={(animation) => {
            updateBoardProperties({
              ...boardProperties,
              backgroundAnimation: animation,
            });
            setAnimationModal(false);
          }}
        />
      ) : null}
      {labelModal ? (
        <LabelModal
          onCancel={() => setLabelModal(false)}
          onOk={(label) => {
            addNewItemCallback("label", label);
            setLabelModal(false);
          }}
        />
      ) : null}
      {powerfulModal ? (
        <PowerfulStatementModal
          onCancel={() => setPowerfulModal(false)}
          onOk={(statement) => {
            addNewItemCallback("powerful-statement", statement);
            setPowerfulModal(false);
          }}
        />
      ) : null}
      {illustrationModal ? (
        <IllustrationModal
          onOk={(value) => {
            addNewItemCallback("illustration", value);
            setIllustrationsModal(false);
          }}
          onCancel={() => setIllustrationsModal(false)}
        />
      ) : null}
      {stickerModal ? (
        <StickersModal
          onOk={(value) => {
            addNewItemCallback("sticker", value);
            setStickerModal(false);
          }}
          onCancel={() => setStickerModal(false)}
        />
      ) : null}
      {backgroundModal ? (
        <BackgroundPropertiesModal
          initialBackground={boardProperties.background}
          onOk={(background) => {
            updateBoardProperties({
              ...boardProperties,
              background,
            });
            setBackgroundModal(false);
          }}
          onCancel={() => setBackgroundModal(false)}
        />
      ) : null}
      {boardModal ? (
        <BoardPropertiesModal
          onOk={(properties) => {
            updateBoardProperties(properties);
            setBoardModal(false);
          }}
          initialProperties={boardProperties}
          onCancel={() => setBoardModal(false)}
        />
      ) : null}
      <Box
        sx={{
          backgroundColor: "#fff",
          opacity: mobileMenu ? 1 : 0.4,
          p: "10px",
          boxShadow: "0px 0px 7px 0px rgba(66, 68, 90, 1)",
          flexDirection: "column",
          gap: "10px",
          position: "absolute",
          // top: "30vh",
          left: mobileMenu ? "10px" : "-20px",
          // transform: "translate(-50%, -50%)",
          display: "flex",
          width: "fit-content",
          zIndex: "999",
          "&:hover": {
            opacity: 1,
            transition: "0.5s all ease",
          },
        }}
      >
        <Box
          id="new-elements-group"
          sx={{
            backgroundColor: "#fff",
            border: addMenu ? "1px solid rgba(0,0,0,0.5)" : "1px solid #fff",
            borderRadius: addMenu ? "5px" : "50%",
            maxHeight: !addMenu ? "51px" : undefined,
            width: "fit-content",
            transition: "0.2s all ease",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid grey",
            },
          }}
        >
          {addMenu ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "62px",
                display: "flex",
                backgroundColor: "#fff",
                p: "10px",
              }}
            >
              <Tooltip title="Add powerful statement" arrow placement="top">
                <IconButton onClick={() => setPowerfulModal(true)}>
                  <BoltIcon />
                </IconButton>
              </Tooltip>
              {/* takie https://www.ocjanssen.nl/labelmaker/ */}
              <Tooltip title="Add label" arrow placement="top">
                <IconButton onClick={() => setLabelModal(true)}>
                  <LabelIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add illustrations" arrow placement="top">
                <IconButton
                  onClick={() => {
                    onModalShowCallback();
                    setIllustrationsModal(true);
                  }}
                >
                  <AddIllustrationIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add sticker" arrow placement="top">
                <IconButton
                  onClick={() => {
                    onModalShowCallback();
                    setStickerModal(true);
                  }}
                >
                  <AddStickerIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
          <Tooltip title="Add" arrow disableInteractive placement="top">
            <IconButton onClick={() => setAddMenu(!addMenu)}>
              {addMenu ? (
                <CloseIcon fontSize="medium" />
              ) : (
                <AddIcon
                  sx={{
                    color: "#000",
                    "&:hover": { color: "rgba(0, 0, 0, 0.54)" },
                  }}
                  fontSize="medium"
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          id="customize-board-group"
          sx={{
            backgroundColor: "#fff",
            border: editorMenu ? "1px solid rgba(0,0,0,0.5)" : "1px solid #fff",
            borderRadius: editorMenu ? "5px" : "50%",
            width: "fit-content",
            maxHeight: !editorMenu ? "51px" : undefined,
            transition: "0.2s all ease",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid grey",
            },
          }}
        >
          {editorMenu ? (
            <Box
              sx={{
                position: "absolute",
                top: "52px",
                left: "62px",
                display: "flex",
                backgroundColor: "#fff",
                p: "10px",
              }}
            >
              <Tooltip title="Help" arrow placement="top">
                <IconButton>
                  <HelpCenterIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Shuffle layout" arrow placement="top">
                <IconButton onClick={shuffleCallback}>
                  <GridViewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Vision Board properties" arrow placement="top">
                <IconButton
                  onClick={() => {
                    onModalShowCallback();
                    setBoardModal(true);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Background effects" arrow placement="top">
                <IconButton onClick={() => setAnimationModal(true)}>
                  <AnimationIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Background properties" arrow placement="top">
                <IconButton
                  onClick={() => {
                    onModalShowCallback();
                    setBackgroundModal(true);
                  }}
                >
                  <WallpaperIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
          <Tooltip
            title="Customize board"
            disableInteractive
            arrow
            placement="top"
          >
            <IconButton onClick={() => setEditorMenu(!editorMenu)}>
              {editorMenu ? (
                <CloseIcon fontSize="medium" />
              ) : (
                <EditIcon
                  fontSize="medium"
                  sx={{
                    color: "#000",
                    "&:hover": { color: "rgba(0, 0, 0, 0.54)" },
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          id="preview-group"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            maxHeight: "51px",
            width: "fit-content",
            transition: "0.2s all ease",
            border: "1px solid #fff",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid grey",
            },
          }}
        >
          {/* // TODO change icons to gradient!! */}
          <Tooltip title="Preview" disableInteractive arrow placement="top">
            <IconButton onClick={enablePreview}>
              <PreviewIcon
                sx={{
                  color: "#000",
                  "&:hover": { color: "rgba(0, 0, 0, 0.54)" },
                }}
                fontSize="medium"
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            maxHeight: "51px",
            width: "fit-content",
            transition: "0.2s all ease",
            border: "1px solid #fff",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid grey",
            },
          }}
        >
          <Tooltip title="Save" disableInteractive arrow placement="top">
            <IconButton onClick={onSave}>
              <DoneIcon
                sx={{
                  color: "#000",
                  "&:hover": { color: "rgba(0, 0, 0, 0.54)" },
                }}
                fontSize="medium"
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};
