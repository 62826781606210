import { Box, ImageList, ImageListItem, ImageListItemBar, Button } from "@mui/material";
import { useState } from "react";
import { GalleryItem, getWallpapersGallery } from "../assets/gallery/gallery-config";

export const GallerySelectComponent = ({ onSelect, selectedImage }: { selectedImage?: GalleryItem; onSelect: (image: GalleryItem) => void }) => {

    const [hoveredImage, setHoveredImage] = useState<GalleryItem | undefined>(undefined);
    const [selectedGalleryItem, setSelectedGalleryItem] = useState<GalleryItem | undefined>(selectedImage);

    return (
        <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
            {selectedGalleryItem === undefined ?
                <ImageList sx={{ width: 500, height: 300 }}>
                    {getWallpapersGallery().map((item, index) => (
                        <ImageListItem key={`gallery${index}`}>
                            <img
                                onMouseEnter={() => setHoveredImage(item)}
                                onMouseLeave={(e) => {
                                    // @ts-ignore
                                    if (e.relatedTarget && e.relatedTarget.id === `select-${index}`) {
                                        return;
                                    }
                                    setHoveredImage(undefined)
                                }}
                                src={item.image}
                                loading="lazy"
                                style={{
                                    transition: "0.5s all ease",
                                }}
                                className='gallery-item'
                            />
                            <ImageListItemBar subtitle={item.credits} />
                            {
                                hoveredImage && hoveredImage.image === item.image ?
                                    <Box sx={{
                                        position: "absolute", top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        <Button id={`select-${index}`} variant="contained" onClick={() => {
                                            setSelectedGalleryItem(item);
                                            setHoveredImage(undefined);
                                            onSelect(item)
                                        }}>Select</Button>
                                    </Box> : null
                            }
                        </ImageListItem>
                    ))}
                </ImageList> : null}
            {selectedGalleryItem ?
                <Box>
                    <img

                        onMouseEnter={() => setHoveredImage(selectedGalleryItem)}
                        onMouseLeave={(e) => {
                            // @ts-ignore
                            if (e.relatedTarget && e.relatedTarget.id === `remove-selected`) {
                                return;
                            }
                            setHoveredImage(undefined)
                        }}
                        src={selectedGalleryItem.image}
                        loading="lazy"
                        style={{
                            transition: "0.5s all ease",
                            height: "300px"
                        }}
                        className='gallery-item'
                    />
                    {
                        hoveredImage && hoveredImage.image === selectedGalleryItem.image ?
                            <Box sx={{
                                position: "absolute", top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <Button id={`remove-selected`} variant="contained" onClick={() => {
                                    setSelectedGalleryItem(undefined);
                                    setHoveredImage(undefined);
                                }}>Remove</Button>
                            </Box> : null
                    }
                </Box>
                : null}
        </Box>
    )
}