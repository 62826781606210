import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { PresignedUrl, attachmentApi } from "../api/AttachmentApi";
import useFetch from "../hooks/useFetch";

export const UploadedPhoto = ({ attachmentId }: { attachmentId: string }) => {

    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const { callApi } = useFetch();

    useEffect(() => {
        const queryAttachment = async () => {
            // FIXME
            const result: PresignedUrl = await callApi(attachmentApi.getDownloadAttachmentParameters(attachmentId));
            setImageUrl(result.params.url)
        }
        queryAttachment();
    }, []);

    return (<Box sx={{
        maxHeight: "300px",
        transition: "0.5s all ease",
        "&:hover": {
            opacity: "0.5"
        }
    }} component={"img"} src={imageUrl ?? ""} />)
}