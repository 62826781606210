import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import clouds from "vanta/dist/vanta.clouds.min";
import clouds2 from "vanta/dist/vanta.clouds2.min";
import halo from "vanta/dist/vanta.halo.min";
import cells from "vanta/dist/vanta.cells.min";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { BasicVisualizatorProps } from "./BasicVisualizator";

export type VantaEffect = "clouds" | "clouds2" | "halo" | "cells";
const VANTA_EFFECTS: { [key in VantaEffect]: any } = {
  cells,
  clouds,
  clouds2,
  halo,
};

export const VantaVisualizator = ({
  effect,
  fullscreen,
  loop,
  slides,
  onFinishCallback,
}: { effect: VantaEffect } & BasicVisualizatorProps) => {
  const ref = useRef();
  const [vantaEffect, setVantaEffect] = useState<any>(0);
  const [visibleIndex, setVisibleIndex] = useState<number>(0);
  const [recorded, setRecorded] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:630px)");

  useEffect(() => {
    if (visibleIndex === slides.length - 1 && !recorded) {
      onFinishCallback().then(() => setRecorded(true));
    }
  }, [visibleIndex, recorded]);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        VANTA_EFFECTS[effect]({
          el: ref.current,
          backgroundColor: "#243040",
          size: 1.15,
        })
      );
    }
  }, [vantaEffect]);

  useEffect(() => {
    if (isMobile) {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
      setVantaEffect(
        VANTA_EFFECTS[effect]({
          el: ref.current,
          backgroundColor: "#243040",
          size: 1.15,
        })
      );
    }
  }, [fullscreen])

  useEffect(() => {
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          height: fullscreen ? isMobile ? "100vh" : "100%" : isMobile ? "50vh" : "calc(100% - 300px)",
          width: fullscreen ? isMobile ? "100vh" : "100%" : isMobile ? "85vw" : "calc(100% - 600px)",
          position: "absolute",
          opacity: 0.8,
        }}
        ref={ref}
      ></Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: fullscreen ? "calc(100% - 300px)" : "calc(100% - 600px)",
          height: fullscreen ? "calc(100% - 100px)" : "calc(100% - 300px)",
        }}
      >
        <Splide
          style={{ width: fullscreen ? "80%" : "600px" }}
          onAutoplayPause={(splide) => {
            const { Autoplay } = splide.Components;
            Autoplay.play();
          }}
          onMoved={(splide, index) => setVisibleIndex(index)}
          options={{
            start: 0,
            autoWidth: true,
            type: "fade",
            rewind: loop,
            autoplay: true,
            arrows: false,
            interval: 5000,
            pagination: false,
          }}
        >
          {slides.map((slide, index) => (
            <SplideSlide
              key={`slide-${index}-${fullscreen}`}
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  borderRadius: "15px",

                  height: fullscreen
                    ? isMobile
                      ? "70vh"
                      : "600px"
                    : isMobile
                    ? "40vh"
                    : "300px",
                  maxWidth:
                    isMobile && slide.type === "goal" ? "70vw" : "unset",
                  fontSize:
                    slide.type === "name"
                      ? fullscreen
                        ? isMobile
                          ? "18px"
                          : "46px"
                        : isMobile
                        ? "15px"
                        : "30px"
                      : fullscreen
                      ? isMobile
                        ? "16px"
                        : "40px"
                      : isMobile
                      ? "14px"
                      : "26px",
                  fontWeight: slide.type === "name" ? "bold" : undefined,
                  fontStyle: slide.type === "statement" ? "italic" : undefined,
                  fontFamily: '"Titillium Web", sans-serif',
                  backgroundImage:
                    slide.type === "goal"
                      ? `url(${slide.background})`
                      : undefined,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  opacity: 0.9,
                }}
              >
                {slide.type !== "goal" ? (
                  <Box
                    sx={{
                      p: "20px 40px",
                      backgroundColor: "rgba(255,255,255,0.7)",
                      borderRadius: "15px",
                      width: undefined,
                      position: undefined,
                      bottom: undefined,
                      textAlign: "center",
                    }}
                  >
                    {slide.name}
                  </Box>
                ) : null}
              </Box>
              {slide.type === "goal" ? (
                <Box
                  sx={{
                    fontSize: fullscreen ? isMobile ? "18px" : "30px" : isMobile ? "14px" : "18px",
                    fontStyle: "italic",
                    fontWeight: "400",
                    fontFamily: '"Titillium Web", sans-serif',
                    position: "absolute",
                    bottom: 0,
                    left: isMobile ? "unset" : 0,
                    p: "10px",
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTopRightRadius: "15px",
                    borderBottomLeftRadius: "15px",
                  }}
                >
                  {slide.name}
                </Box>
              ) : null}
            </SplideSlide>
          ))}
        </Splide>
      </Box>
    </>
  );
};
