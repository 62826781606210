import { Box, Divider, Typography } from "@mui/material";
import { config } from "../../config";

const ChangeLogEntry = ({ date, description, version, header }: { version: string; date: string; header: string; description: string[] }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>{version}</Typography>
        <Typography sx={{ fontSize: "16px", color: "grey" }}>{date}</Typography>
      </Box>
      <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>{header}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {description.map((desc) => (<Typography>{desc}</Typography>))}
      </Box>
    </Box>
  )
}

export const ChangeLog = () => {

  return (
    <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Typography>Current version: {config.version}</Typography>
        <Divider />
        <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>Change log</Typography>
        <ChangeLogEntry date="01.05.2024" version="1.0.0" header={"MVP release"} description={["Big release. Initial application version"]} />
    </Box>
  );
};
