import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Board, BoardStatus } from "../model/board";
import { boardApi } from "../api/BoardApi";
import useFetch from "../hooks/useFetch";
import { CustomSelect } from "../components/CustomSelect";
import { VisualizationType, VisualizatorSettingsContainer, mapVisualizationPath } from "./visualize/VisualizatorSettings";
import { useNavigate } from "react-router-dom";
import { Guide } from "../components/Guide";
import { IPhoneModals } from "../components/IPhponeModals";
import { NoBoardsAvailable } from "./Screensaver";

export const Visualization = () => {
  const [boards, setBoards] = useState<Board[]>([]);
  const [boardId, setBoardId] = useState<string | null>(null);

  const { callApi, loading } = useFetch();

  const queryBoards = async (statuses: BoardStatus[]) => {
    const result: Board[] = await callApi(boardApi.getBoards(statuses));
    setBoards(result);
  };

  useEffect(() => {
    queryBoards([BoardStatus.READY]);
  }, []);

  const navigate = useNavigate();

  const mobileMenu = useMediaQuery("(max-width:630px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        gap: "20px",
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: mobileMenu ? "10px" : "40px",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <IPhoneModals name="visualization" />
        <Box sx={{ maxWidth: "500px", width: "100%" }}>
          <Guide type="VISUALIZATIONS" />
          <CustomSelect
            selectId="visualization-select"
            label="Active Vision Board"
            value={boardId || ""}
            fullWidth
            options={boards.map((board) => ({
              label: board.name,
              value: board.id,
            }))}
            onChange={(value) => {
              setBoardId(value);
            }}
            selectSx={{ minWidth: mobileMenu ? "300px" : undefined, width: mobileMenu ? "100%" : undefined }}
          />
        </Box>
      </Box>
      {!boardId && boards.length === 0 && !loading ? (
          <NoBoardsAvailable />
        ) : null}
      <Box sx={{ maxWidth: "500px", width: "100%" }}>
        {boardId ? (
          <VisualizatorSettingsContainer withLabel={false} onOK={(type) => {
            navigate(mapVisualizationPath(type, boardId))
          }} />
        ) : null}
      </Box>
    </Box>
  );
};
