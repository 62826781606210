import { Box, IconButton, useMediaQuery } from "@mui/material";
import { enterFullscreen } from "../../utils/fullscreen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RepeatIcon from "@mui/icons-material/Repeat";
import RepeatOneIcon from "@mui/icons-material/RepeatOne";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";

export const VizualizationControls = ({
  elementClassName,
  fullscreen,
  loop,
  music,
  toggleFullscreen,
  toggleLoop,
  toggleMusic,
}: {
  elementClassName: string;
  loop: boolean;
  music: boolean;
  fullscreen: boolean;
  toggleLoop: (value: boolean) => void;
  toggleMusic: (value: boolean) => void;
  toggleFullscreen: (value: boolean) => void;
}) => {
  const isMobile = useMediaQuery("(max-width:630px)");

  return (
    <Box
      sx={{
        position: "fixed",
        top: fullscreen ? 10 : isMobile ? 130 : 100,
        p: "10px",
        display: "flex",
        backgroundColor: "#fff",
        borderRadius: "15px",
        zIndex: 300,
        opacity: fullscreen ? 0.3 : 1,
        transition: "0.5s all ease",
        "&:hover": {
          opacity: 1,
        },
      }}
    >
      <IconButton onClick={() => toggleMusic(!music)}>
        {music ? <MusicNoteIcon /> : <MusicOffIcon />}
      </IconButton>
      <IconButton onClick={() => toggleLoop(!loop)}>
        {loop ? <RepeatIcon /> : <RepeatOneIcon />}
      </IconButton>
      <IconButton
        onClick={async () => {
          if (!fullscreen) {
            const element = document.querySelector(`.${elementClassName}`);
            if (element) {
              enterFullscreen(element as HTMLElement);
            }
          } else {
            if (document.fullscreenElement) {
              await document.exitFullscreen();
            }
          }
          toggleFullscreen(!fullscreen);
        }}
      >
        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
    </Box>
  );
};
