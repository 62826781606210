import { CustomModal } from "./CustomModal";
import { Box, useMediaQuery } from "@mui/material";
import { LogoText } from "./LogoText";
import { SubscriptionDetailsBox } from "./SubscriptionDetails";

export const SubscriptionRenewalModal = ({
  onClose,
  onSubscribe
}: {
  onClose: () => void;
  onSubscribe: (yearly: boolean) => Promise<void>
}) => {
  const mobileMenu = useMediaQuery("(max-width:500px)");

  return (
    <CustomModal
      noPadding
      open={true}
      onCancel={onClose}
      cancelIcon={true}
      cancelIconSx={{ color: "#ffe" }}
      sx={{ maxWidth: "550px", minWidth: "350px", width: "unset" }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#243040",
              width: "100%",
              p: "30px",
              pt: "55px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LogoText small={mobileMenu} />
          </Box>
          <SubscriptionDetailsBox
            variant={"contained"}
            callback={async (yearly) => {
              await onSubscribe(yearly);
            }}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};
