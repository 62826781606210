import { ToggleButtonGroup, ToggleButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import DeleteIcon from "@mui/icons-material/Delete";
import { HelpModal } from "./HelpModal";
import { useState } from "react";
import { LayoutItemType } from "../grid/model";

export const SystemToolbox = ({
  onClose,
  onDelete,
  type
}: {
  onClose: () => void;
  onDelete?: () => void;
  type: LayoutItemType;
}) => {
  const [helpModal, setHelpModal] = useState<boolean>(false);

  return (
    <>
      <Box sx={{ position: "absolute" }}>
        {helpModal ? <HelpModal type={type} onClose={() => setHelpModal(false)} /> : null}
      </Box>
      <ToggleButtonGroup size="small">
        <ToggleButton value="close" onClick={() => setHelpModal(true)}>
          <HelpCenterIcon />
        </ToggleButton>
        {onDelete ? (
          <ToggleButton
            value="close"
            onClick={() => {
              onDelete();
              onClose();
            }}
          >
            <DeleteIcon />
          </ToggleButton>
        ) : null}
        <ToggleButton value="close" onClick={onClose}>
          <CloseIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
