import { Typography } from "@mui/material"
import { TextStyles } from "../toolbox/TextToolbox";
import { mapStylesToSx } from "./Label";

export enum AnimatedGradientStyle {
    "Neon" = "Neon",
    "Vivid" = "Vivid",
    "Dark" = "Dark"
}

export const AnimatedGradientLabel = ({ text, styles, variant }: { text: string; styles: TextStyles, variant: AnimatedGradientStyle  }) => {
    return (
        <Typography className={`${variant ? variant.toLocaleLowerCase() : AnimatedGradientStyle.Neon.toLocaleLowerCase()}-animated-gradient-text`} sx={{ 
            ...mapStylesToSx(styles),
            color: undefined
        }}>{text}</Typography>
    )
}