import { makeAutoObservable } from "mobx";

class ErrorState {
  private _trialExpiredModal: boolean = false;
  private _subscriptionCanceledModal: boolean = false;
  private _accountUnderCreationModal: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get trialExpiredModal() {
    return this._trialExpiredModal;
  }

  set trialExpiredModal(value: boolean) {
    this._trialExpiredModal = value;
  }

  get subscriptionCanceledModal() {
    return this._subscriptionCanceledModal;
  }

  set subscriptionCanceledModal(value: boolean) {
    this._subscriptionCanceledModal = value;
  }

  get accountUnderCreationModal() {
    return this._accountUnderCreationModal;
  }

  set accountUnderCreationModal(value: boolean) {
    this._accountUnderCreationModal = value;
  }
}

const errorState = new ErrorState();

export const getErrorState = () => errorState;
