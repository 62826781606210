import { Box, TextField, Chip, Autocomplete, Tabs, Tab, useMediaQuery } from "@mui/material";
import { CustomModal } from "../../../components/CustomModal";
import { useEffect, useRef, useState } from "react";
import { CustomTabPanel } from "../../../components/CustomTabPanel";
import { Photo } from "../../../api/UnsplashApi";
import { UnsplashSelectComponent } from "../../../components/UnsplashSelectComponent";
import { GoalPhoto } from "../../../model/goal";
import { RichTextEditor } from "../../../components/RichTextEditor";
import { UploadImage } from "../../../components/UploadImage";
import { UploadedPhoto } from "../../../components/UploadedPhoto";

export type NewGoalProps = {
    name: string;
    description: string;
    photo: GoalPhoto;
    tags: string[];
}

export const NewGoalModal = ({ editingGoal, onCancel, onSave }: { editingGoal?: NewGoalProps; onCancel: () => void; onSave: (goal: NewGoalProps) => void }) => {

    const [name, setName] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [tagValue, setTagValue] = useState<string>("");
    const [errors, setErrors] = useState<("name" | "photo")[]>([]);
    const editorRef = useRef(null);
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };
    const [selectedPhoto, setSelectedPhoto] = useState<Photo | undefined>(undefined);
    const [uploadedPhotoId, setUploadedPhotoId] = useState<string | null>(null);

    useEffect(() => {
        setSelectedPhoto(editingGoal && editingGoal.photo.type === "unsplash" ? (editingGoal.photo.value as Photo) : undefined);
        setUploadedPhotoId(editingGoal && editingGoal.photo.type === "attachment" ? editingGoal.photo.value as string : null);
        setTabIndex(editingGoal && editingGoal.photo.type === "unsplash" ? 0 : 1);
        setTags(editingGoal ? editingGoal.tags : []);
        setName(editingGoal ? editingGoal.name : "");
        setTagValue("");
    }, []);

    const mobile = useMediaQuery("(max-width:920px)");

    const validate = () => {
        const val: ("name" | "photo")[] = [];
        let validationResult = true;
        if (name.length === 0) {
            val.push("name");
            validationResult = false;
        }
        if (!selectedPhoto && !uploadedPhotoId) {
            val.push("photo");
            validationResult = false;
        }
        setErrors(val);
        return validationResult;
    }

    const handleSave = () => {
        const unsplashPhoto = (selectedPhoto && tabIndex === 0);
        const uploadedPhoto = (uploadedPhotoId && tabIndex === 1);
        if (validate() && (unsplashPhoto || uploadedPhoto)) {
            onSave({
                name,
                // @ts-ignore
                description: editorRef.current ? editorRef.current.getContent() : "",
                photo: unsplashPhoto ? {
                    type: "unsplash",
                    value: selectedPhoto as Photo,
                } : {
                    type: "attachment",
                    value: uploadedPhotoId as string
                },
                tags
            })
        }
    }

    return (
        <CustomModal open={true} onCancel={onCancel} onOk={handleSave} okLabel={editingGoal ? "Update" : "Add goal"} cancelIcon sx={{
            maxHeight: "calc(100% - 50px)", border: "undefined", boxShadow: "0px 0px 15px 0px rgba(66, 68, 90, 1)",
            borderRadius: "15px", minWidth: mobile ? "400px" : "900px", maxWidth: "calc(100% - 50px)", width: mobile ? "calc(100% - 100px)" : "auto"
        }}>
            <Box sx={{ pt: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ display: "block", fontFamily: "Lato, sans-serif", fontWeight: 400, fontSize: "16px" }}>It's very important to have a clear vision about what you would like to achieve so please define a miningful <strong style={{ paddingLeft: "2px", paddingRight: "2px" }}>name </strong> for your goal</Box>
                <TextField
                    helperText={errors.includes("name") ? "Missing goal name" : null}
                    error={errors.includes("name")}
                    placeholder={"Goal name"}
                    sx={{ width: "70%", minWidth: "300px", mt: "10px" }}
                    inputProps={{ style: { textAlign: "center", fontSize: "18px" } }}
                    value={name}
                    onChange={(e) => { 
                        setName(e.currentTarget.value);
                        setErrors([...errors.filter(e => e !== "name")])
                    }} />
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: mobile ? "1fr" : "1fr 1fr", gap: mobile ? "0px" : "30px", pt: "20px", fontFamily: "Lato, sans-serif" }}>
                <Box sx={{ overflow: "hidden", border: errors.includes("photo") ? "1px dotted red" : undefined }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <Tab label="Unsplash" id="define-goal-tab-1" />
                            <Tab label="Upload photo" id="define-goal-tab-2" />
                            {/* Post-MVP add a link to photo */}
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabIndex} index={0} sx={{ pl: "0px", pr: "0px" }}>
                        <UnsplashSelectComponent selectedPhoto={selectedPhoto} columns={1} onSelect={(photo: Photo) => {
                            setSelectedPhoto(photo);
                            setErrors([...errors.filter(e => e !== "photo")])
                        }} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={1}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "20px" }}>
                            {uploadedPhotoId ? <Box><UploadedPhoto attachmentId={uploadedPhotoId} /></Box> : 
                            <UploadImage uploadCompleted={(attachmentId) => {
                                setUploadedPhotoId(attachmentId);
                            }} />}
                        </Box>
                    </CustomTabPanel>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", alignItems: "center", pt: mobile ? "0px" : "15px", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        <Box sx={{ display: "block", fontFamily: "Lato, sans-serif", fontStyle: "italic", fontSize: "14px" }}>
                            Optionally you could add some more details about your goal in <strong style={{ paddingLeft: "2px", paddingRight: "2px" }}>description </strong> field
                            or add <strong style={{ paddingLeft: "2px", paddingRight: "2px" }}>tags</strong> for further convenience
                        </Box>
                        <RichTextEditor ref={editorRef} initialValue={editingGoal ? editingGoal.description : ""} />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", width: "100%", gap: "5px" }}>
                        <Autocomplete
                            freeSolo
                            options={[]} //async control
                            sx={{ width: "100%" }}
                            clearIcon={null}
                            value={tagValue || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Enter tag name"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            paddingTop: "5px",
                                            paddingBottom: "5px"
                                        }
                                    }}
                                    value={tagValue || ""}
                                    onChange={(e) => setTagValue(e.currentTarget.value || "")}
                                    onMouseDown={() => { }}
                                    onKeyDown={(e) => {
                                        if (e.code === "Enter" && tagValue.length) {
                                            setTags([...tags, tagValue]);
                                            setTagValue("");
                                        }
                                    }}
                                />
                            )}
                        />
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                            {tags.map((tag) => (<Chip key={`chip${tag}`} label={tag} variant="outlined" onDelete={() => setTags([...tags.filter(t => t !== tag)])} />))}
                        </Box>
                    </Box>
                </Box>
            </Box >
        </CustomModal >
    )
}