import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { CustomModal } from "../../components/CustomModal";
import { Goal, getPhotoUrl } from "../../model/goal";
import { DatePicker } from "@mui/x-date-pickers";
import { CustomTabPanel } from "../../components/CustomTabPanel";
import { useCallback, useEffect, useState } from "react";
import PagesIcon from "@mui/icons-material/Pages";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import TimelineIcon from "@mui/icons-material/Timeline";
import CollectionsIcon from "@mui/icons-material/Collections";
// import FenceIcon from '@mui/icons-material/Fence';
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { MilestoneList } from "./MilestoneList";
import { GoalJournalModal } from "./GoalJournalModal";
import { NewGoalModal } from "../wizzard/steps/NewGoalModal";
import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";
import { boardApi } from "../../api/BoardApi";
import { Loader } from "../../components/Loader";
import { GoalGallery } from "./GoalGallery";
import useFetch from "../../hooks/useFetch";

const TabStyle = {
  "&.MuiTab-root": {
    pr: "0px",
    pl: "0px",
    pt: "5px",
    pb: "5px",
    minWidth: "50px",
  },
};

const ModalIcons = ({
  onCancel,
  goal,
  updateCallback,
}: {
  onCancel: () => void;
  goal: Goal;
  updateCallback: (goal: Goal) => Promise<void>;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        transition: "0.5s all ease",
        display: "flex",
        gap: "5px",
        borderRadius: "5px",
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: hovered
          ? "rgba(255,255,255,0.9)"
          : "rgba(255,255,255,0.5)",
      }}
    >
      {editModal ? (
        <NewGoalModal
          onCancel={() => setEditModal(false)}
          onSave={async (updatedProps) => {
            await updateCallback({
              ...goal,
              ...updatedProps,
            });
            setEditModal(false);
          }}
          editingGoal={{
            description: goal.description,
            name: goal.name,
            photo: goal.photo,
            tags: goal.tags,
          }}
        />
      ) : null}
      <IconButton onClick={() => setEditModal(true)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={onCancel}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export const GoalModal = ({
  boardId,
  goal,
  onCancel,
  refreshGoals,
  updateCallback,
  isDraft,
}: {
  boardId: string;
  goal: Goal;
  refreshGoals: () => Promise<void>;
  onCancel: () => void;
  updateCallback: (goal: Goal) => Promise<void>;
  isDraft: boolean;
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { loading, callApi } = useFetch();

  useEffect(() => {
    const getImageUrl = async () => {
      const url = await getPhotoUrl(goal, "regular", callApi);
      setImageUrl(url);
    };
    getImageUrl();
  }, []);

  const toggleAccomplishment = async (e: any) => {
    await callApi(boardApi.toggleGoalAccomplishment(boardId, goal.id));
    await refreshGoals();
  };

  const particlesInit = useCallback(async (engine: any) => {
    await loadConfettiPreset(engine);
  }, []);

  const isWideScreen = useMediaQuery("(min-width:1600px)");

  return (
    <CustomModal
      open={true}
      noPadding
      childrenWrapperSx={{
        display: "flex",
        height: "90vh",
        flexDirection: "column",
        width: "100%",
      }}
      sx={{
        maxHeight: "90%",
        minHeight: "90%",
        ...(isWideScreen ? { width: "1000px" } : {}),
      }}
      cancelIconSx={{ color: "white", mixBlendMode: "difference" }}
    >
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <ModalIcons
          onCancel={onCancel}
          goal={goal}
          updateCallback={updateCallback}
        />
        {imageUrl ? (
          <Box
            component="img"
            src={imageUrl}
            sx={{ height: "35vh", width: "100%", objectFit: "cover" }}
          />
        ) : null}
        {goal.accomplished ? (
          <Particles
            id="tsparticles-goal-accomplished"
            height="70vh"
            style={{
              height: "70vh",
              position: "absolute",
              zIndex: 1000,
            }}
            init={particlesInit}
            params={{
              particles: { particleCount: 100, spread: 70, origin: { y: 0.6 } },
              particleCount: 100,
              spread: 70,
              origin: { y: 0.6 },
            }}
            options={{
              preset: "confetti",
              duration: 5,
              fullScreen: { enable: false, zIndex: 3 },
            }}
          />
        ) : null}
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "#fff",
                borderRadius: "20px",
                p: "5px 20px",
                marginTop: "-40px",
                height: "80px",
                fontFamily: "Lato, sans-serif",
                fontWeight: 300,
                fontStyle: "italic",
                fontSize: "46px",
                boxShadow: "0px 0px 5px 0px rgba(66, 68, 90, 1)",
              }}
            >
              {goal.name}
            </Box>
          </Box>
          <Box
            sx={{ height: "100%", pt: "70px", flexGrow: 1, display: "flex" }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "#fff", zIndex: 10 }}>
              <Tabs
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                value={tabIndex}
                onChange={handleTabChange}
                orientation="vertical"
                sx={{
                  "&.MuiTabs-root": {
                    height: "100%",
                    justifyContent: "space-between",
                  },
                  ".MuiTabs-flexContainer": {
                    justifyContent: "space-between",
                    height: "100%",
                    maxHeight: "30vh",
                  },
                  pr: "0px",
                  borderRight: 1,
                  borderColor: "divider",
                  width: "50px",
                }}
              >
                <Tab
                  sx={TabStyle}
                  label={
                    <Tooltip arrow placement="left" title="Description">
                      <PagesIcon
                        fontSize={isWideScreen ? "large" : undefined}
                      />
                    </Tooltip>
                  }
                />
                <Tab
                  sx={TabStyle}
                  label={
                    <Tooltip arrow placement="left" title="Journal / notes">
                      <MenuBookIcon
                        fontSize={isWideScreen ? "large" : undefined}
                      />
                    </Tooltip>
                  }
                />
                <Tab
                  sx={TabStyle}
                  label={
                    <Tooltip arrow placement="left" title="Milestones">
                      <TimelineIcon
                        fontSize={isWideScreen ? "large" : undefined}
                      />
                    </Tooltip>
                  }
                />
                {/* <Tab sx={TabStyle} label={<Tooltip arrow placement="left" title="Obstacles"><FenceIcon /></Tooltip>} /> */}
                <Tab
                  sx={TabStyle}
                  label={
                    <Tooltip arrow placement="left" title="Gallery">
                      <CollectionsIcon
                        fontSize={isWideScreen ? "large" : undefined}
                      />
                    </Tooltip>
                  }
                />
              </Tabs>
            </Box>
            <CustomTabPanel
              value={tabIndex}
              index={0}
              sx={{ height: "100%" }}
              wrapperSx={{
                width: "100%",
                marginLeft: "-50px",
                overflow: "scroll",
              }}
            >
              <Box
                sx={{
                  pl: "50px",
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {goal.description === "" ? (
                  <Box
                    sx={{
                      fontStyle: "italic",
                      flexDirection: "column",
                      textAlign: "center",
                      fontFamily: "Lato, sans-serif",
                      gap: "30px",
                      fontSize: "16px",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    Ups, it looks like there is no goal description yet. Please
                    add it. Visualization techniques works best when you have a
                    clear understanding what you would like to achieve
                  </Box>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "16px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: goal.description,
                    }}
                  />
                )}
                <Divider sx={{ width: "100%", m: "20px" }} />
                <Box>
                  <DatePicker label="Deadline" />
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel
              value={tabIndex}
              index={1}
              sx={{ pt: 0, height: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  p: "15px",
                  pt: 0,
                  height: "100%",
                }}
              >
                <GoalJournalModal goalId={goal.id} boardId={boardId} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel
              value={tabIndex}
              index={2}
              wrapperSx={{ width: "100%" }}
              sx={{ pt: 0, height: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  p: "15px",
                  pt: 0,
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <MilestoneList goalId={goal.id} boardId={boardId} />
              </Box>
            </CustomTabPanel>
            {/* <CustomTabPanel value={tabIndex} index={3} wrapperSx={{ pt: "30px", width: "100%" }}>
                            <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", pt: "15px", height: "100%", textAlign: "center" }}>
                                Obstacles TODO
                            </Box>
                        </CustomTabPanel> */}
            <CustomTabPanel
              value={tabIndex}
              index={3}
              wrapperSx={{ width: "100%" }}
              sx={{ pt: 0, height: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  p: "15px",
                  pt: 0,
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <GoalGallery goalId={goal.id} boardId={boardId} />
              </Box>
            </CustomTabPanel>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: "20px",
              pb: "15px",
              pr: "15px",
              pl: "15px",
              gap: "10px",
            }}
          >
            <Box>
              <FormGroup>
                {loading ? (
                  <Loader />
                ) : (
                  <FormControlLabel
                    disabled={isDraft}
                    control={
                      <Switch
                        checked={goal.accomplished}
                        onChange={toggleAccomplishment}
                      />
                    }
                    label="Goal accomplished"
                  />
                )}
              </FormGroup>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {goal.tags.map((tag) => (
                <Chip key={`chip${tag}`} label={tag} variant="outlined" />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};
