import { Box, CircularProgress, SxProps } from "@mui/material";

const fullScreenStyles = {
    height: "100vh",
    width: "100vw",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

const defaultStyles = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

export const Loader = ({ fullScreen, sx }: { fullScreen?: boolean; sx?: SxProps }) => {
    return (
        <Box sx={{ ...(fullScreen ? fullScreenStyles : defaultStyles), ...sx }}>
            <CircularProgress />
        </Box>
    )
}