import { Box, Typography } from "@mui/material";
import { IOSSwitch } from "./IOSSwitch";
import MoodIcon from "@mui/icons-material/Mood";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

const SubscriptionFeature = ({ feature }: { feature: string }) => (
  <Box sx={{ display: "flex", gap: "20px" }}>
    <MoodIcon sx={{ color: "#243040" }} />
    <Typography>{feature}</Typography>
  </Box>
);

export const SubscriptionDetailsBox = ({ callback, variant, label, loading }: { callback: (yearly: boolean) => void; variant?: "contained" | "outlined"; label?: string; loading?: boolean }) => {
  const [yearly, setYearly] = useState<boolean>(false);

  const { search } = useLocation();

  useEffect(() => {
    if (search && search.includes("yearly")) {
      setYearly(true);
    }
  }, []);

  return (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Subscription
      </Typography>
      <Typography sx={{ fontWeight: 400, textAlign: "center" }}>
        Become a member of VividBoard.app family with one of the
        following subscription plans
      </Typography>
      <Box>
        <SubscriptionFeature feature="All platforms" />
        <SubscriptionFeature feature="All features" />
        <SubscriptionFeature feature="All updates" />
        <SubscriptionFeature feature="AI Assistant" />
        <SubscriptionFeature feature="Support" />
      </Box>
      <Box sx={{ display: "flex", gap: "30px" }}>
        <Box sx={{ color: "#243040", opacity: !yearly ? 1 : 0.5 }}>
          billed monthly
        </Box>
        <IOSSwitch checked={yearly} onChange={() => setYearly(!yearly)} />
        <Box sx={{ color: "#243040", opacity: yearly ? 1 : 0.5 }}>
          billed yearly
        </Box>
      </Box>
      <Typography variant="h6">
        {yearly ? "XX$ / year" : "X$ / month"}
      </Typography>
      <LoadingButton loading={loading} variant={variant ? variant : "contained"} onClick={() => callback(yearly)}>
        {label ? label : `Get ${yearly ? "yearly" : "monthly"}`}
      </LoadingButton>
      <Typography sx={{ fontSize: "12px", color: "#aabbcc" }}>
        billed {yearly ? "yearly" : "monthly"}, cancel anytime
      </Typography>
    </Box>
  );
};
