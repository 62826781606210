import { Box, Typography } from "@mui/material";
import Joyride from "react-joyride";
import { GUIDES, GUIDE_TYPE } from "../model/guides";
import { observer } from "mobx-react-lite";
import { useStore } from "../state/RootStore";
import useFetch from "../hooks/useFetch";
import { guideApi } from "../api/GuideApi";

export const Guide = observer(({ type }: { type: GUIDE_TYPE }) => {

  const store = useStore();
  const { callApi } = useFetch();

  if (store.viewedGuides === null || store.viewedGuides.includes(type)) {
    return null;
  }

  return (
    <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 15000 }}>
      <Joyride
        styles={{

          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            overlayColor: "rgb(5 9 17 / 40%)",
            primaryColor: "#ff9600",
            textColor: "#202229",
            width: 400,
            zIndex: 15000,
          },
          buttonClose: {
            color: "#202229",
          },
          buttonSkip: {
            color: "#202229",
          },
          buttonBack: {
            color: "#202229",
          },
          overlay: {
            height: `${document.body.scrollHeight + 300}px`,
            zIndex: 15000,
          }
        }}
        continuous
        hideCloseButton
        run
        showProgress
        showSkipButton
        callback={(data) => {
          if (data.type === "tour:end" && data.action !== "skip") {
            callApi(guideApi.record(type)).then((res: any) => {
              store.viewedGuides = res.types as GUIDE_TYPE[];
            })
          }
        }}
        steps={GUIDES[type].map((step) => {
          return {
            ...step,
            content: Array.isArray(step.content) ? step.content.map((c, index) => (<Typography key={`guideStep${index}`} sx={{ textAlign: "justify", pb: "5px" }}>{c}</Typography>)) : step.content
          }
        })}
      />
    </Box>
  );
});
