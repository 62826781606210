import { Box, Tabs, Tab } from "@mui/material";
import { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { CustomModal } from "../../../../components/CustomModal";
import { CustomTabPanel } from "../../../../components/CustomTabPanel";
import { Background } from "./model";
import { UnsplashSelectComponent } from "../../../../components/UnsplashSelectComponent";
import { Photo } from "../../../../api/UnsplashApi";
import { GallerySelectComponent } from "../../../../components/GallerySelectComponent";
import { GalleryItem, getWallpapersGallery } from "../../../../assets/gallery/gallery-config";

export const BackgroundPropertiesModal = ({ initialBackground, onCancel, onOk }: { initialBackground: Background; onCancel: () => void; onOk: (background: Background) => void }) => {

    const [background, setBackground] = useState<Background>(initialBackground);
    const [picker, setPicker] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedGalleryItem, setSelectedGalleryItem] = useState<GalleryItem | undefined>(undefined);
    const [selectedPhoto, setSelectedPhoto] = useState<Photo | undefined>(undefined);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        if (initialBackground.type === "color") {
            setTabIndex(0);
        } else if (initialBackground.type === "image" && initialBackground.source === "internal") {
            setTabIndex(1);
            setSelectedGalleryItem(getWallpapersGallery().find((item) => item.image === (initialBackground.value as GalleryItem).image))
        } else if (initialBackground.type === "image" && initialBackground.source === "external") {
            setTabIndex(2);
            setSelectedPhoto(initialBackground.value as Photo)
        }
    }, []);

    return (
        <CustomModal open={true} cancelIcon okLabel='Save' onCancel={onCancel} onOk={() => onOk(background)}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ width: "100%", fontSize: "20px", pb: "20px" }}>Background properties</Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Color" />
                        <Tab label="Gallery" />
                        <Tab label="Unsplash" />
                        {/* FIXME POST-MVP */}
                        {/* <Tab label="Upload" /> */}
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabIndex} index={0}>
                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", width: "100%", alignItems: "center", gap: "20px" }}>
                            <Box>Background color</Box>
                            <Box onClick={() => setPicker(!picker)} sx={{ cursor: "pointer", border: "1px solid black", backgroundColor: background.type === "color" ? background.value as string : "#fff", height: "40px", width: "50%", borderRadius: "10px" }}></Box>
                        </Box>
                        {picker ? <Box sx={{ pt: "20px" }}>
                            <SketchPicker color={background.type === "color" ? background.value as string : "#fff"} onChangeComplete={(color) => {
                                setBackground({
                                    type: "color",
                                    value: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                                })
                            }} />
                        </Box> : null}
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={1}>
                    <GallerySelectComponent selectedImage={selectedGalleryItem} onSelect={(image) => {
                         setBackground({
                            type: 'image',
                            value: image,
                            source: 'internal'
                        })
                        setSelectedGalleryItem(image);
                    }} />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={2}>
                    <UnsplashSelectComponent orientation="landscape" selectedPhoto={selectedPhoto} columns={2} onSelect={(photo) => {
                         setBackground({
                            type: 'image',
                            value: photo,
                            source: 'external'
                        })
                        setSelectedPhoto(photo);
                    }} />
                </CustomTabPanel>
                {/* <CustomTabPanel value={tabIndex} index={3}>
                    Item Three
                </CustomTabPanel> */}
            </Box>
        </CustomModal>
    )
}