import { Box } from "@mui/material";
import basicLogo from "../assets/brand/logo-main-basic.png";
import fullLogo from "../assets/brand/logo-main.png";
import symbol from "../assets/brand/logo-symbol.svg";

export const LogoText = ({
  small,
  full,
  width,
}: {
  size?: number;
  outlined?: boolean;
  outlineDark?: boolean;
  small?: boolean;
  full?: boolean;
  width?: string;
}) => {
  return (
    <Box
      sx={() => ({
        display: "flex",
        alignItems: "center"
      })}
    >
      {small ? (
        <Box component="img" sx={{ width: "35px" }} src={symbol} />
      ) : full ? (
        <Box component="img" sx={{ width: width ? width : "200px" }} src={fullLogo} />
      ) : (
        <Box component="img" sx={{ width: "200px" }} src={basicLogo} />
      )}
    </Box>
  );
};
