import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HtmlTooltip } from "../../components/HtmlTooltip";
import { TutorialContent, TutorialContents } from "./TutorialContent";
import { scrollToTopApp } from "../../utils/common";

const TutorialCard = ({ tutorial }: { tutorial: TutorialContent }) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: 250,
        maxHeight: 250,
        height: "fit-content",
        cursor: "pointer",
        position: "relative",
        overflow: "inherit",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <HtmlTooltip arrow placement="top" title="Open tutorial">
        <CardActionArea onClick={() => {
          navigate(`/app/tutorials/view/${tutorial.path}`);
          scrollToTopApp();
        }}>
          <CardMedia
            component="img"
            height="140"
            image={tutorial.image}
            alt={tutorial.title}
            sx={{
              opacity: hovered ? "1.0" : "0.5",
              transition: "0.5s all ease",
            }}
          />
          <CardContent>
            <Typography
              sx={{
                fontFamily: "Lato, sans-serif",
                fontSize: "18px",
                fontStyle: "italic",
                fontWeight: 300,
                textAlign: "center",
              }}
              gutterBottom
              variant="h6"
              component="div"
            >
              {tutorial.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </HtmlTooltip>
    </Card>
  );
};

export const Tutorials = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: "18px", fontStyle: "italic" }}>The page offers a collection of tutorials on motivation and visualization techniques to help you achieve your goals and stay inspired.</Typography>
      <Box sx={{ justifyContent: "center", display: "flex", flexWrap: "wrap", gap: "30px" }}>{TutorialContents.map((tutorial) => (<TutorialCard key={`tutorial${tutorial.path}`} tutorial={tutorial} />))}</Box>
    </Box>
  );
};
