import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const SignedInPageNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <Typography sx={{ fontSize: "18px", color: "#243040" }}>
        Requested page does not exist ;-(
      </Typography>
      <Button
        component={Link}
        to={"/"}
        sx={{ width: "250px" }}
        variant="outlined"
      >
        Go to main page
      </Button>
    </Box>
  );
};
