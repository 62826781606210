import {
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Box,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Icon,
} from "@mui/material";
import { CustomSelect } from "../../../../components/CustomSelect";
import { FONTS } from "../../utils/fonts";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import HideImageIcon from "@mui/icons-material/HideImage";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { getInputValue } from "../../utils/numbers";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { HtmlTooltip } from "../../../../components/HtmlTooltip";
import { Background, Label, LabelStyle } from "../grid/model";
import { getTextBackgroundsGallery } from "../../../../assets/gallery/gallery-config";
import effectIcon from "../../../../assets/icons/font-effects.svg";
import { LabelModal } from "../grid/LabelModal";

export const TextEffectIcon = () => {
  return (
    <Icon sx={{ textAlign: "center" }}>
      <img
        style={{ display: "flex", height: "inherit", width: "inherit" }}
        src={effectIcon}
      />
    </Icon>
  );
};

export interface TextStyles {
  textAlign: "left" | "center" | "right" | "justify";
  color: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  outline: boolean;
  size: number;
  font: string;
  textRotate: number;
}

export const TextToolbox = ({
  styles,
  background,
  updateStyles,
  updateBackground,
  label,
  updateLabel,
  onClose,
}: {
  styles: TextStyles;
  background: Background;
  updateStyles: (updatedValues: TextStyles) => void;
  updateBackground: (updatedBackground: Background) => void;
  label?: Label;
  updateLabel?: (label: Label) => void;
  onClose?: () => void;
}) => {
  const [colorPicker, setColorPicker] = useState<
    "color" | "background-color" | null
  >(null);
  const [backgroundImage, setBackgroundImage] = useState<boolean>(false);
  const [labelModal, setLabelModal] = useState<boolean>(false);
  return (
    <>
      {labelModal && label && updateLabel ? (
        <Box sx={{ position: "absolute" }}>
          <LabelModal
            initialLabel={label}
            onCancel={() => setLabelModal(false)}
            onOk={(label) => {
              updateLabel(label);
              setLabelModal(false);
            }}
          />
        </Box>
      ) : null}
      <ToggleButtonGroup
        size="small"
        value={styles.textAlign}
        exclusive
        onChange={(e, value) =>
          updateStyles({
            ...styles,
            textAlign: value,
          })
        }
      >
        <ToggleButton value="left">
          <FormatAlignLeftIcon />
        </ToggleButton>
        <ToggleButton value="center">
          <FormatAlignCenterIcon />
        </ToggleButton>
        <ToggleButton value="right">
          <FormatAlignRightIcon />
        </ToggleButton>
        <ToggleButton value="justify">
          <FormatAlignJustifyIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <ToggleButtonGroup
        size="small"
        value={[
          styles.bold ? "bold" : null,
          styles.italic ? "italic" : null,
          styles.underline ? "underline" : null,
          styles.outline ? "outline" : null,
        ].filter((i) => i !== null)}
        onChange={(e, format: string[]) =>
          updateStyles({
            ...styles,
            bold: format.some((val) => val === "bold"),
            italic: format.some((val) => val === "italic"),
            underline: format.some((val) => val === "underline"),
            outline: format.some((val) => val === "outline"),
          })
        }
      >
        <ToggleButton value="bold">
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton value="italic">
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton value="underline">
          <FormatUnderlinedIcon />
        </ToggleButton>
        <ToggleButton value="outline">
          <Tooltip arrow placement="top" title="Outlined text">
            <TitleOutlinedIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <ToggleButtonGroup size="small">
        <HtmlTooltip
          open={colorPicker === "color"}
          title={
            <Box>
              <SketchPicker
                color={styles.color}
                onChangeComplete={(color) =>
                  updateStyles({
                    ...styles,
                    color: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                  })
                }
              />
            </Box>
          }
        >
          <ToggleButton
            value="color"
            onClick={() => {
              setBackgroundImage(false);
              setColorPicker(colorPicker === "color" ? null : "color");
            }}
          >
            {/* TODO handle it somehow and use color */}
            <FormatColorTextIcon />
          </ToggleButton>
        </HtmlTooltip>
        <HtmlTooltip
          open={colorPicker === "background-color"}
          title={
            <Box>
              <SketchPicker
                color={
                  background.type === "color"
                    ? (background.value as string)
                    : "#fff"
                }
                onChangeComplete={(color) =>
                  updateBackground({
                    type: "color",
                    value: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                  })
                }
              />
            </Box>
          }
        >
          <ToggleButton
            value="background-color"
            onClick={() => {
              setBackgroundImage(false);
              setColorPicker(
                colorPicker === "background-color" ? null : "background-color"
              );
            }}
          >
            <FormatColorFillIcon />
          </ToggleButton>
        </HtmlTooltip>
        <HtmlTooltip
          open={backgroundImage}
          title={
            <Box>
              <Box>
                <ImageList sx={{ width: 200, height: 200 }} cols={1}>
                  {getTextBackgroundsGallery().map((item, index) => (
                    <ImageListItem key={`backgroundImage${index}`}>
                      <img
                        onClick={() => {
                          updateBackground({
                            type: "image",
                            value: item.image,
                          });
                          setBackgroundImage(false);
                        }}
                        src={item.image}
                        alt={item.credits}
                        loading="lazy"
                      />
                      <ImageListItemBar subtitle={item.credits} />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            </Box>
          }
        >
          <ToggleButton
            value="background-image"
            onClick={() => {
              setColorPicker(null);
              setBackgroundImage(!backgroundImage);
            }}
          >
            <ImageIcon />
          </ToggleButton>
        </HtmlTooltip>
        <Tooltip arrow placement="top" title="Transparent background">
          <ToggleButton
            selected={background.type === "transparent"}
            value="transparent-image"
            onClick={() =>
              updateBackground({ type: "transparent", value: undefined })
            }
          >
            <HideImageIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <ToggleButtonGroup size="small">
        <Tooltip arrow placement="top" title="Rotate left">
          <ToggleButton
            value="rotate-left"
            onClick={() =>
              updateStyles({
                ...styles,
                textRotate:
                  styles.textRotate === -345 ? 0 : styles.textRotate - 15,
              })
            }
          >
            <RotateLeftIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip arrow placement="top" title="Rotate right">
          <ToggleButton
            value="rotate-right"
            onClick={() =>
              updateStyles({
                ...styles,
                textRotate:
                  styles.textRotate === 345 ? 0 : styles.textRotate + 15,
              })
            }
          >
            <RotateRightIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      {/* TODO - labelka - mozna jej zmieniac efekty. Dla elementu name dodać jakies fajne tła, jakies chmurki, firewerki itp. element name bardziej ich powinien uzywac. sciagnac je z jakiegos freepika, itp */}
      {/* TODO2 - dorobic help */}
      {label ? (
        <>
          <ToggleButtonGroup size="small">
            <Tooltip arrow placement="top" title="Label style">
              <ToggleButton
                value="text-effect"
                onClick={() => setLabelModal(true)}
              >
                <TextEffectIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        </>
      ) : null}
      <Box>
        <CustomSelect
          onChange={(value) => updateStyles({ ...styles, font: value })}
          options={FONTS.map((font) => ({
            label: font,
            value: font,
            sx: { fontFamily: font },
          }))}
          value={styles.font}
          selectSx={{ width: "150px", height: "40px", backgroundColor: "#fff" }}
        />
        <TextField
          onChange={(e) =>
            updateStyles({ ...styles, size: getInputValue(e, 10, 100) })
          }
          type="number"
          InputProps={{ style: { height: "40px" } }}
          value={styles.size}
          sx={{
            height: "40px",
            width: "70px",
            ml: "5px",
            backgroundColor: "#fff",
          }}
        />
      </Box>
      {onClose ? (
        <>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <ToggleButtonGroup size="small">
            <ToggleButton value="close" onClick={onClose}>
              <CloseIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      ) : null}
    </>
  );
};
