import { Box, LinearProgress, Typography } from "@mui/material";
import { CustomModal } from "./CustomModal";
import useFetch from "../hooks/useFetch";
import { useEffect, useRef } from "react";
import { userApi } from "../api/UserApi";
import { getErrorState } from "../state/ErrorState";

export const AccountCreationInProgress = () => {
  const { callApi } = useFetch();
  const errorState = getErrorState();
  const intervalRef = useRef<any>();

    const queryStatus = async () => {
        try {
            await callApi(userApi.status());
            errorState.accountUnderCreationModal = false;
        } catch (e) {
            
        }
    }

  useEffect(() => {
    intervalRef.current = setInterval(() => queryStatus(), 5000);
  }, []);

  useEffect(() => {
    return () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };
  }, []);

  return (
    <CustomModal open={true}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <LinearProgress />
        <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
          Your account is being created. Please give us a few more minutes :)
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          In case you are unable to access your account please contact our
          support via support@vividboard.app
        </Typography>
      </Box>
    </CustomModal>
  );
};
