import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { withFadeIn } from "../../../hoc/withFadeIn";
import { useCallback, useEffect } from "react";
import {
  clearPreviousSessionBoardId,
  getWizardState,
  WizardStep,
} from "../state/wizzardState";
import { observer } from "mobx-react-lite";
import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";
import tree1 from "../../../assets/sapling-1.svg";
import tree2 from "../../../assets/sapling-2.svg";
import tree3 from "../../../assets/sapling-3.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { boardApi } from "../../../api/BoardApi";
import { BoardStatus } from "../../../model/board";
import useFetch from "../../../hooks/useFetch";

const TreeAnimation = withFadeIn(
  () => {
    const mobileMenu = useMediaQuery("(max-width:800px)");
    const isWideScreen = useMediaQuery("(min-width:1600px)");

    return (
      <Splide
        style={{ width: isWideScreen ? "400px" : "200px" }}
        options={{
          start: 0,
          autoWidth: true,
          type: "fade",
          rewind: true,
          autoplay: true,
          arrows: false,
          interval: 1500,
          pagination: false,
        }}
      >
        {[tree1, tree2, tree3].map((t, index) => (
          <SplideSlide
            key={`tree-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              height: mobileMenu ? "130px" : isWideScreen ? "550px" : "300px",
              width: isWideScreen ? "400px" : "200px",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${t})`,
                backgroundSize: "cover",
                width: isWideScreen ? "400px" : "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                height: mobileMenu ? "130px" : isWideScreen ? "550px" : "300px",
                borderRadius: "15px",
              }}
            ></Box>
          </SplideSlide>
        ))}
      </Splide>
    );
  },
  {
    timeout: 800,
  }
);

export const SummaryStep = observer(
  withFadeIn(
    ({ onClose }: { onClose: () => void }) => {
      const { loading, callApi } = useFetch();

      const state = getWizardState();
      useEffect(() => {
        state.step = WizardStep.SUMMARY;
      }, []);

      const particlesInit = useCallback(async (engine: any) => {
        await loadConfettiPreset(engine);
      }, []);

      const handleCompleteBoard = async () => {
        await callApi(
          boardApi.progressWithStatus(
            state.boardId as string,
            BoardStatus.READY
          )
        );
        clearPreviousSessionBoardId();
        onClose();
      };

      const mobileMenu = useMediaQuery("(max-width:800px)");
      const isWideScreen = useMediaQuery("(min-width:1600px)");

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Lato, sans-serif",
            fontWeight: 300,
            fontStyle: "italic",
            ".splide.is-overflow": { width: isWideScreen ? "400px" : "200px" },
          }}
        >
          <Particles
            id="tsparticles-summary"
            height={mobileMenu ? "130px" : isWideScreen ? "550px" : "300px"}
            init={particlesInit}
            params={{ particles: {} }}
            options={{
              preset: "confetti",
              duration: 5,
              fullScreen: { enable: false, zIndex: 3 },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TreeAnimation />
          </Box>
          <Typography
            sx={{
              pt: "50px",
              fontFamily: "Lato, sans-serif",
              fontSize: mobileMenu ? "16px" : isWideScreen ? "30px" : "24px",
              fontWeight: 300,
            }}
          >
            Congratulations. Your vision board is ready to be created! Once
            created, you can start visualising and achieving your goals.
          </Typography>
          <Typography
            sx={{
              pt: "15px",
              pb: "25px",
              fontFamily: "Lato, sans-serif",
              fontSize: mobileMenu ? "14px" : isWideScreen ? "24px" : "20px",
              fontWeight: 300,
            }}
          >
            Please remember that it's a long process to achieve your goals and
            dreams so be patient and let's start!
          </Typography>
          <Button
            disabled={loading}
            onClick={handleCompleteBoard}
            sx={{ mb: "25px" }}
            variant="contained"
          >
            Create vision board
          </Button>
        </Box>
      );
    },
    {
      fullWidth: true,
      center: true,
      overflowY: true,
    }
  )
);
