const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export interface PersistedResult {
  id: string;
}

export type ApiParams = {
  path: string;
  method: string,
  body?: any;
};

export abstract class Api {
  private path(relativePath: string) { return `${API_ENDPOINT}/${relativePath}` }

  protected get(path: string): ApiParams {
    return {
      method: "GET",
      path: this.path(path),
    }
  }

  protected post(path: string, body: any): ApiParams {
    return {
      method: "POST",
      path: this.path(path),
      body,
    }
  }

  protected put(path: string, body: any): ApiParams {
    return {
      method: "PUT",
      body,
      path: this.path(path),
    }
  }

  protected delete(path: string): ApiParams {
    return {
      method: "DELETE",
      path: this.path(path),
    }
  }
}
