import { Box, Typography, CircularProgress } from "@mui/material";
import aiAssistantRobot from "../../assets/ai-assistant-robot.jpg";

export const AIAssistantLoadingScreen = ({ message }: { message: string }) => {
    return (
      <Box
        sx={{
          zIndex: 20,
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px",
          backgroundColor: "rgba(255,255,255,1)",
          p: "30px",
        }}
      >
        <Box component={"img"} src={aiAssistantRobot} sx={{ width: "100px" }} />
        <Typography sx={{ textAlign: "center" }}>
          {message}
        </Typography>
        <CircularProgress />
      </Box>
    );
  };