import { Box, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { NotificationSelection } from "../../components/NotificationSelection";
import { CustomSwitch } from "../../components/CustomSwitch";
import { useEffect, useState } from "react";
import {
  RepeatType,
  notificationApi,
  NotificationSettingsDto,
} from "../../api/NotificationApi";
import { getUserTimezone } from "../../utils/dates";
import useFetch from "../../hooks/useFetch";

const DEFAULT_SETTINGS: NotificationSettingsDto = {
  emailEnabled: false,
  executionTime: 1100,
  productUpdatesEnabled: true,
  pushEnabled: true,
  reminderEnabled: true,
  repeatType: RepeatType.EVERYDAY,
};

export const NotificationsSettings = () => {
  const { t } = useTranslation();
  const [settings, setSettings] =
    useState<NotificationSettingsDto>(DEFAULT_SETTINGS);

  const { loading, callApi } = useFetch();

  const getSettings = async () => {
    try {
      const userSettings: NotificationSettingsDto = await callApi(
        notificationApi.getSettings()
      );
      setSettings(userSettings);
    } catch (e) {
      console.log("TODO handle");
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const updateSettings = async () => {
    const updatedSettings: NotificationSettingsDto = await callApi(
      notificationApi.updateSettings({
        ...settings,
        timezone: getUserTimezone(),
      })
    );
    setSettings(updatedSettings);
  };

  return (
    <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <Typography variant="h5" gutterBottom>
        {t("settings.notifications.types.label")}
      </Typography>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    pushEnabled: !settings.pushEnabled,
                  })
                }
                checked={settings.pushEnabled}
              />
            }
            label={t("settings.notifications.WEB.enabled")}
          />
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    emailEnabled: !settings.emailEnabled,
                  })
                }
                checked={settings.emailEnabled}
              />
            }
            label={t("settings.notifications.EMAIL.enabled")}
          />
        </FormGroup>
      </Box>
      <Typography variant="h5" gutterBottom>
        {t("settings.notifications.visualisation.label")}
      </Typography>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    reminderEnabled: !settings.reminderEnabled,
                  })
                }
                checked={settings.reminderEnabled}
              />
            }
            label={t("settings.notifications.visualisation.enabled")}
          />
        </FormGroup>
      </Box>
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <NotificationSelection
          settings={settings}
          onChange={(settings) => setSettings(settings)}
        />
      </Box>
      <Typography variant="h5" gutterBottom>
        {t("settings.notifications.updates.label")}
      </Typography>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    productUpdatesEnabled: !settings.productUpdatesEnabled,
                  })
                }
                checked={settings.productUpdatesEnabled}
              />
            }
            label={t("settings.notifications.updates.releases")}
          />
        </FormGroup>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <LoadingButton
          loading={loading}
          sx={{ fontFamily: "Lato, sans-serif", fontSize: "12px" }}
          variant="outlined"
          onClick={updateSettings}
        >
          {t("save")}
        </LoadingButton>
      </Box>
    </Box>
  );
};
