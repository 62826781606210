import { Box, Button, Paper, Typography, useMediaQuery } from "@mui/material";
import achieveTargetImage from "../assets/dashboard-woman.png";
// TODO https://icons8.com/illustrations/illustration/shine-multitasking-young-woman-meditating-1
import lastVisualizationBoardIcon from "../assets/dashboard-last-visualization.png";
import trendIcon from "../assets/dashboard-trend.png";
import loveIcon from "../assets/dashboard-love.png";
import targetIcon from "../assets/dashboard-target.png";
import noResultsIcon from "../assets/dashboard-noresults.png";
import { AffirmationWidget } from "./AffirmationWidget";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import {
  DashboardVisualizationStatistics,
  dashboardApi,
} from "../api/DashboardApi";
import useFetch from "../hooks/useFetch";
import { HtmlTooltip } from "../components/HtmlTooltip";
import { Loader } from "../components/Loader";
import { NewsletterResponse, newsletterApi } from "../api/NewsletterApi";
import { Guide } from "../components/Guide";
import { getErrorState } from "../state/ErrorState";

const Tutorials = ({ mobile }: { mobile: boolean }) => {
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery("(min-width:2000px)");

  return (
    <Box
      sx={{
        width: mobile ? "100%" : "35%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Typography sx={{ fontSize: isWideScreen ? "18px" : "14px" }}>
        You can learn more about visualization techniques in our Tutorials
        section
      </Typography>
      <Button onClick={() => navigate("/app/tutorials")} variant="contained">
        Tutorials
      </Button>
    </Box>
  );
};

const SignToNewsletter = ({
  mobile,
  callback,
}: {
  mobile: boolean;
  callback: () => Promise<void>;
}) => {
  const isWideScreen = useMediaQuery("(min-width:2000px)");

  return (
    <Box
      sx={{
        width: mobile ? "100%" : "35%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        maxHeight: isWideScreen ? "350px" : undefined,
      }}
    >
      <Typography sx={{ fontSize: isWideScreen ? "18px" : "14px", textAlign: "center" }}>
        Don't want to miss new updates and great articles? Just sign up to our
        newsletter
      </Typography>
      <Button onClick={callback} variant="contained">
        Sign to newsletter
      </Button>
    </Box>
  );
};

const StatElement = ({
  mobile,
  noBackground = false,
  fullWidth = false,
  withElevation = true,
  icon,
  title,
  value,
  secondaryValue,
  tooltip,
}: {
  mobile: boolean;
  noBackground?: boolean;
  fullWidth?: boolean;
  withElevation?: boolean;
  icon: string;
  title: string;
  value: string | null;
  secondaryValue?: string;
  tooltip?: string;
}) => {
  const isWideScreen = useMediaQuery("(min-width:2000px)");

  return (
    <Paper
      elevation={withElevation ? 1 : 0}
      sx={{
        display: "flex",
        flexDirection: !mobile ? "row" : "column",
        backgroundColor: noBackground ? undefined : "#D8E2EF",
        padding: "5px",
        justifyContent: "start",
        alignItems: "start",
        width: mobile || fullWidth ? "100%" : "20%",
      }}
    >
      <HtmlTooltip title={tooltip ? tooltip : null} arrow placement="top">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box sx={{ width: "28px" }} component={"img"} src={icon} />
          <Typography
            sx={{ fontWeight: 500, fontSize: isWideScreen ? "26px" : "18px", textAlign: "center" }}
          >
            {title}
          </Typography>
          {value === null ? (
            <>
              <Box
                component="img"
                src={noResultsIcon}
                sx={{ width: "36px" }}
              ></Box>
              <Typography
                sx={{
                  fontSize: isWideScreen ? "18px" : "14px",
                  color: "grey",
                  textAlign: "center",
                  fontStyle: "italic",
                }}
              >
                No Data
              </Typography>
            </>
          ) : null}
          {secondaryValue ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", fontWeight: 700, textAlign: "center" }}
              >
                {value}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", color: "grey", textAlign: "center" }}
              >
                {secondaryValue}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{ fontSize: "22px", fontWeight: 700, textAlign: "center" }}
              >
                {value}
              </Typography>
            </Box>
          )}
        </Box>
      </HtmlTooltip>
    </Paper>
  );
};

const Statistics = ({
  mobile,
  stats,
}: {
  mobile: boolean;
  stats: DashboardVisualizationStatistics;
}) => {
  return (
    <Box
      id="statistics"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        flexDirection: mobile ? "column" : "row",
        gap: mobile ? "5px" : "0px",
      }}
    >
      <StatElement
        noBackground
        withElevation={false}
        // fullWidth
        mobile={mobile}
        icon={lastVisualizationBoardIcon}
        title="Last visualization"
        value={
          stats.lastVisualization ? stats.lastVisualization.boardName : null
        }
        secondaryValue={
          stats.lastVisualization ? stats.lastVisualization.date : undefined
        }
        tooltip="Last 30 days"
      />
      <StatElement
        noBackground
        mobile={mobile}
        icon={trendIcon}
        withElevation={false}
        title="Visualization streak"
        value={stats.visualizationDaysInRow + ""}
        secondaryValue={"days in row"}
        tooltip="Last 30 days"
      />
      <StatElement
        noBackground
        mobile={mobile}
        withElevation={false}
        icon={loveIcon}
        title="Most visualized board"
        value={stats.mostVisualizedBoard}
        tooltip="Last 30 days"
      />
      <StatElement
        noBackground
        mobile={mobile}
        withElevation={false}
        icon={targetIcon}
        title="Accomplished goals"
        value={stats.accomplishedGoals + ""}
      />
    </Box>
  );
};

export const Dashboard = () => {
  const { user } = useUser();
  const mobile = useMediaQuery("(max-width:660px)");
  const isWideScreen = useMediaQuery("(min-width:2000px)");
  const { callApi } = useFetch();
  const [stats, setStats] = useState<DashboardVisualizationStatistics | null>(
    null
  );
  const [newsletterLoading, setNewsletterLoading] = useState<boolean>(true);
  const [newsletter, setNewsletter] = useState<boolean>(false);

  const errorState = getErrorState();

  const getStatistics = async () => {
    try {
      const stats: DashboardVisualizationStatistics = await callApi(
        dashboardApi.getStatistics()
      );
      setStats(stats);
    } catch (e) {
      setStats({
        accomplishedGoals: 0,
        lastVisualization: null,
        mostVisualizedBoard: null,
        visualizationDaysInRow: 0,
      });
    }
  };

  const checkNewsletterStatus = async () => {
    try {
      setNewsletterLoading(true);
      const result: NewsletterResponse = await callApi(
        newsletterApi.hasSignedUp()
      );
      setNewsletter(result.result);
      setNewsletterLoading(false);
    } catch (e) {
      setNewsletter(false);
      setNewsletterLoading(true);
    }
  };

  const signUpNewsletter = async () => {
    try {
      setNewsletterLoading(true);
      const result: NewsletterResponse = await callApi(newsletterApi.signUp());
      setNewsletter(result.result);
      setNewsletterLoading(false);
    } catch (e) {
      console.log("Enable tu subscribe");
    }
  };

  useEffect(() => {
    if (errorState.accountUnderCreationModal) {
      getStatistics();
      checkNewsletterStatus();
    }
  }, []);

  useEffect(() => {
    if (!errorState.accountUnderCreationModal) {
      getStatistics();
      checkNewsletterStatus();
    }
  }, [errorState, errorState.accountUnderCreationModal]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "30px",
        flexWrap: "wrap",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <Guide type="DASHBOARD" />
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: !mobile ? "row" : "column",
          backgroundColor: "#D8E2EF",
          width: mobile ? "100%" : "60%",
          padding: "30px",
          color: "#ffe",
          justifyContent: "center",
          maxHeight: isWideScreen ? "350px" : undefined,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            height: isWideScreen ? "300px" : undefined,
            maxHeight: mobile ? undefined : "350px",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: isWideScreen ? "24px" : "20px" }}>
              Welcome back {user?.firstName} 👋
            </Typography>
          </Box>
          <Box sx={{ mt: "5px" }}>
            <Typography sx={{ fontSize: isWideScreen ? "18px" : "14px" }}>
              Create powerful and vivid Vision Boards and start achieving your
              goals
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "5px",
              display: "flex",
              justifyContent: mobile ? "center" : "flex-start",
            }}
          >
            <Button
              id="view-boards-btn"
              component={Link}
              to={"/app/boards"}
              variant="contained"
            >
              View boards
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: isWideScreen ? "300px" : "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: isWideScreen ? "300px" : "200px" }}
            src={achieveTargetImage}
          />
        </Box>
      </Paper>
      {mobile ? (
        <>
          {stats ? (
            <Statistics mobile={mobile} stats={stats} />
          ) : (
            <Loader sx={{ width: "100%", height: "100px" }} />
          )}
          <AffirmationWidget />
          {newsletterLoading ? (
            <Loader
              sx={{
                width: mobile ? "100%" : "35%",
                height: "100px",
                display: "flex",
                alignItems: "center",
              }}
            />
          ) : newsletter ? (
            <Tutorials mobile={mobile} />
          ) : (
            <SignToNewsletter callback={signUpNewsletter} mobile={mobile} />
          )}
        </>
      ) : (
        <>
          {newsletterLoading ? (
            <Loader
              sx={{
                width: mobile ? "100%" : "35%",
                height: "100px",
                display: "flex",
                alignItems: "center",
              }}
            />
          ) : newsletter ? (
            <Tutorials mobile={mobile} />
          ) : (
            <SignToNewsletter callback={signUpNewsletter} mobile={mobile} />
          )}
          {stats ? <Statistics mobile={mobile} stats={stats} /> : <Loader />}
          <AffirmationWidget />
        </>
      )}
    </Box>
  );
};
