import {
  Badge,
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  linearProgressClasses,
  styled,
  useMediaQuery,
} from "@mui/material";
import useFetch from "../../hooks/useFetch";
import { SubscriptionDetailsBox } from "../../components/SubscriptionDetails";
import stripeIcon from "../../assets/stripe.svg";
import {
  Payment,
  SubscriptionDetails,
  SubscriptionPeriod,
  subscriptionApi,
} from "../../api/SubscriptionApi";

import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { YesNoModal } from "../../components/YesNoModal";
import { SubscriptionRenewalModal } from "../../components/SubscriptionRenewalModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#243040" : "#308fe8",
  },
}));

const PaymentHistory = ({ payments }: { payments: Payment[] }) => {
  const { callApi, loading } = useFetch();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: "#91baf1" }}>
          <TableRow
            sx={{
              th: { pt: "12px", pb: "12px", color: "#fff", fontWeight: 600 },
            }}
          >
            <TableCell sx={{ height: "20px" }}>Payment date</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Currency</TableCell>
            <TableCell align="center">Invoice</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "td, th": { pt: "10px", pb: "10px" } }}>
          {payments.map((payment) => (
            <TableRow
              key={payment.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {dayjs(payment.creationDate).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="center">{payment.amount}</TableCell>
              <TableCell align="center">
                {payment.currency.toUpperCase()}
              </TableCell>
              <TableCell align="center">
                <LoadingButton
                  loading={loading}
                  sx={{ pt: "2px", pb: "2px" }}
                  onClick={async () => {
                    try {
                      const res: any = await callApi(
                        subscriptionApi.getPaymentInvoiceLink(payment.id)
                      );
                      const hiddenElement = document.createElement("a");
                      hiddenElement.href = res.url;
                      hiddenElement.target = "_blank";
                      hiddenElement.click();
                    } catch (e) {
                      console.log("Handle error");
                    }
                  }}
                  variant="text"
                >
                  PDF
                </LoadingButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SubscriptionDetailsContainer = ({
  details,
  subscribeCallback,
  cancelSubscriptionCallback,
}: {
  details: SubscriptionDetails;
  subscribeCallback: (yearly: boolean) => Promise<void>;
  cancelSubscriptionCallback: () => Promise<void>;
}) => {
  const [cancelSubscriptionModal, setCancelSubscriptionModal] =
    useState<boolean>(false);
  const [renewalSubscriptionModal, setRenewalSubscriptionModal] =
    useState<boolean>(false);
  const daysRemaining = details
    ? dayjs(details.currentPeriodEnd).diff(dayjs(), "days")
    : 0;
  const daysDiff = details
    ? dayjs(details.currentPeriodEnd).diff(
        dayjs(details.currentPeriodStart),
        "days"
      )
    : -1;

  const mobileMenu = useMediaQuery("(max-width:900px)");

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: mobileMenu ? "1fr" : "1fr 1fr",
          width: "100%",
          gap: "50px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography component="h3" sx={{ fontWeight: 600 }}>
            Your current plan
          </Typography>
          <Typography
            sx={{ color: details.status !== "active" ? "#BD3636" : undefined }}
          >
            {details.period === SubscriptionPeriod.MONTHLY
              ? "Monthly subscription"
              : "Yearly subscription"}
            {details.status !== "active"
              ? ` (Subscription status:  ${details.status.replaceAll(
                  "_",
                  " "
                )})`
              : ""}
          </Typography>
          <Typography
            component="h3"
            sx={{
              fontWeight: 600,
              pt: "20px",
              color: details.status !== "active" ? "#BD3636" : undefined,
            }}
          >
            Active until{" "}
            {dayjs(details.currentPeriodEnd).format("HH:mm MMM DD, YYYY")}
          </Typography>
          {details.status === "active" ? (
            <Typography>
              We will send you a notification upon Subscription expiration
            </Typography>
          ) : (
            <Typography sx={{ color: "#BD3636" }}>
              You do not have an active subscription at the moment. Please take
              a proper actions to continue using VividBoard.app
            </Typography>
          )}
          <Typography
            component="h3"
            sx={{
              fontWeight: 600,
              pt: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {details.period === SubscriptionPeriod.MONTHLY ? (
              <>
                4 {details.currency.toUpperCase()} Per Month
                <Badge
                  badgeContent={"Most flexible"}
                  sx={{
                    // backgroundColor: "#73b9ee",
                    color: "#fff",
                    ".MuiBadge-badge": {
                      fontSize: "12px",
                      textAlign: "center",
                      backgroundColor: "#73b9ee",
                      color: "#fff",
                      width: "100px",
                      position: "relative",
                      transform: "unset",
                      ml: "5px",
                    },
                  }}
                />
              </>
            ) : (
              <>
                35 {details.currency.toUpperCase()} Per Year
                <Badge
                  badgeContent={"Best option"}
                  sx={{
                    // backgroundColor: "#73b9ee",
                    color: "#fff",
                    ".MuiBadge-badge": {
                      fontSize: "12px",
                      textAlign: "center",
                      backgroundColor: "#73b9ee",
                      color: "#fff",
                      width: "100px",
                      position: "relative",
                      transform: "unset",
                      ml: "5px",
                    },
                  }}
                />
              </>
            )}
          </Typography>
          <Box sx={{ mt: "20px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>Days</Box>
              <Box>
                {daysDiff - daysRemaining} of {daysDiff} days
              </Box>
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={((daysDiff - daysRemaining) / daysDiff) * 100}
            />
            <Box sx={{ fontSize: "12px" }}>
              {daysRemaining} days remaining until subscription renewal
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              flex: 1,
            }}
          >
            {details.status === "active" ? (
              <LoadingButton
                variant="outlined"
                sx={{
                  color: "#BD3636",
                  borderColor: "#BD3636",
                  width: "200px",
                  mt: "20px",
                  height: "40px",
                }}
                onClick={() => setCancelSubscriptionModal(true)}
              >
                Cancel subscription
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                onClick={() => setRenewalSubscriptionModal(true)}
              >
                Renew subscription
              </LoadingButton>
            )}
          </Box>
        </Box>
        <Box>
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600, mb: "10px" }}>
              Payment History
            </Typography>
            <PaymentHistory payments={details.payments} />
          </Box>
        </Box>
      </Box>
      {cancelSubscriptionModal ? (
        <Box sx={{ position: "absolute" }}>
          <YesNoModal
            label="Are you sure you want to cancel your subscription? When current period ends you will no longer have access to all features."
            open={true}
            alert
            onNo={() => setCancelSubscriptionModal(false)}
            onYes={async () => {
              await cancelSubscriptionCallback();
              setCancelSubscriptionModal(false);
            }}
          />
        </Box>
      ) : null}
      {renewalSubscriptionModal ? (
        <Box>
          <SubscriptionRenewalModal
            onClose={() => setRenewalSubscriptionModal(false)}
            onSubscribe={subscribeCallback}
          />
        </Box>
      ) : null}
    </>
  );
};

export const SubscriptionSettings = () => {
  const [details, setDetails] = useState<SubscriptionDetails | null>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [stripeRedirectLoading, setStripeRedirectLoading] =
    useState<boolean>(false);
  const { callApi } = useFetch();

  const subscribeCallback = async (yearly: boolean) => {
    setStripeRedirectLoading(true);
    const stripeCheckoutResponse: any = await callApi(
      subscriptionApi.subscribe(
        yearly ? SubscriptionPeriod.YEARLY : SubscriptionPeriod.MONTHLY
      )
    );
    const stripe = await stripePromise;
    stripe?.redirectToCheckout({
      sessionId: stripeCheckoutResponse.id,
    });
  };

  const getSubscription = async () => {
    try {
      const result = await callApi(subscriptionApi.getSubscription());
      setDetails(result as SubscriptionDetails);
      setPageLoading(false);
    } catch (e) {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {details ? (
          <SubscriptionDetailsContainer
            details={details}
            subscribeCallback={subscribeCallback}
            cancelSubscriptionCallback={async () => {
              try {
                await callApi(subscriptionApi.cancelSubscription());
                await getSubscription();
              } catch (e) {
                console.log("Unable to cancel subscription");
              }
            }}
          />
        ) : null}
        {/* TODO Trial information */}
        {!details ? (
          <SubscriptionDetailsBox
            callback={subscribeCallback}
            loading={stripeRedirectLoading}
            label="Continue to Payment"
          />
        ) : null}
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box component={"span"} sx={{ fontSize: "10px" }}>
            {" "}
            Payments are safely processed and invoiced by
          </Box>
          <a href="https://stripe.com" target="_blank">
            <Box sx={{ width: "100px" }} component={"img"} src={stripeIcon} />
          </a>
        </Box>
      </Box>
    </Box>
  );
};
