import { Box } from "@mui/material";
import { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import aiAssistantRobot from "../../assets/ai-assistant-robot.jpg";

export const AssistantMessage = ({
    message,
    secondMessage,
    onFinish,
  }: {
    message: string;
    secondMessage?: string;
    onFinish: () => void;
  }) => {
    const [showSecondMessage, setShowSecondMessage] = useState<boolean>(false);
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            p: "10px",
          }}
        >
          <Box
            component={"img"}
            src={aiAssistantRobot}
            sx={{ height: "100px" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center" }}>
            <TypeAnimation
              sequence={[
                message,
                secondMessage ? () => setShowSecondMessage(true) : onFinish,
              ]}
              speed={85}
              repeat={0}
              style={{
                fontSize: "14px",
              }}
              cursor={false}
            />
            {secondMessage && showSecondMessage ? (
              <TypeAnimation
                sequence={[secondMessage, onFinish]}
                speed={85}
                repeat={0}
                style={{
                  fontSize: "14px",
                }}
                cursor={false}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  };