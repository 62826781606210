import { Box, Typography, Button } from "@mui/material"
import { CustomModal } from "../../../../components/CustomModal";
import welcomeAnimation from "../../../../assets/grid-layout-animation.gif"

export const WelcomeModal = ({ onClose }: { onClose: () => void }) => {
    return (
        <CustomModal open={true} cancelIcon onCancel={onClose} modalSx={{ zIndex: 16000 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{ textAlign: "center" }}>
                Welcome to template-based Vision Board designer. It's easy-to-use drag & drop editor to that allows you to build your dream board with predefined elements in grid template
                </Typography>
                <Box component={"img"} src={welcomeAnimation} />
                <Box>
                    <Button variant="contained" onClick={onClose}>I understand, let's start!</Button>
                </Box>
            </Box>
        </CustomModal>
    )
}