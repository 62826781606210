import dayjs from "dayjs";
import { CustomModal } from "./CustomModal";
import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import { LogoText } from "./LogoText";
import { useNavigate } from "react-router-dom";
import { getValue, setValue } from "../utils/localStorage";
import { SubscriptionDetailsBox } from "./SubscriptionDetails";
import { useTranslation } from "react-i18next";

const TRIAL_MODAL_KEY = "__tm"; // keeps date when modal was last shown

export interface TrialModalDetails {
  show: boolean;
  remainingDays?: number;
}

export const shouldPresentTrialModal = (
  trialExpirationDate: Date,
  userId: string
): TrialModalDetails => {
  const remainingDays = dayjs(trialExpirationDate).diff(
    dayjs().startOf("day"),
    "day"
  );
  const lastModalShownDays = getValue(TRIAL_MODAL_KEY, userId);
  if (lastModalShownDays) {
    const lastShownDayAgo = dayjs(lastModalShownDays)
      .add(1, "day")
      .isBefore(dayjs());
    if (lastShownDayAgo) {
      setValue(TRIAL_MODAL_KEY, userId, new Date().toString());
      return {
        show: true,
        remainingDays,
      };
    } else {
      return {
        show: false,
      };
    }
  } else {
    setValue(TRIAL_MODAL_KEY, userId, new Date().toString());
    return {
      show: true,
      remainingDays,
    };
  }
};

export const TrialModal = ({
  onClose,
  remainingDays,
  expired,
}: {
  onClose: () => void;
  remainingDays?: number;
  expired?: boolean;
}) => {
  const mobileMenu = useMediaQuery("(max-width:500px)");
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CustomModal
      noPadding
      open={true}
      onCancel={onClose}
      cancelIcon={true}
      cancelIconSx={{ color: "#ffe" }}
      sx={{ maxWidth: "550px", minWidth: "350px", width: "unset" }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#243040",
              width: "100%",
              p: "30px",
              pt: "55px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LogoText small={mobileMenu} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              p: "20px",
              pb: "0px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, textAlign: "center" }}
            >
              {expired ? (
                <>{t("trial.ended")}</>
              ) : (
                <>
                  {t("trial.message", {
                    remainingDays,
                  })}
                </>
              )}
            </Typography>
            <Divider sx={{ width: "100%" }} />
          </Box>
          <SubscriptionDetailsBox
            variant={expired ? "contained" : "outlined"}
            callback={(yearly) => {
              onClose();
              navigate(`/app/settings${yearly ? "?yearly=" : ""}#subscription`);
            }}
          />
          {expired ? null : (
            <Button
              sx={{ mb: "20px" }}
              variant="contained"
              onClick={() => {
                navigate("/app/dashboard");
                onClose();
              }}
            >
              Continue Trial
            </Button>
          )}
        </Box>
      </Box>
    </CustomModal>
  );
};
