import { Box, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
  MIN_GOALS_REQUIRED,
  WizardStep,
  getWizardState,
} from "../../pages/wizzard/state/wizzardState";
import CloseIcon from "@mui/icons-material/Close";
import aiAssistantIcon from "../../assets/icons/ai-assistant.svg";
import { useEffect, useState } from "react";
import {
  AIAssistantAction,
  AIGenerationResponse,
  aiAssistantApi,
} from "../../api/AIAssistantApi";
import useFetch from "../../hooks/useFetch";
import { InitialScreen } from "./InitialScreen";
import { UserInputScreen } from "./UserInputScreen";
import { GeneratedResponseScreen } from "./GeneratedResponseScreen";
import { AIAssistantLoadingScreen } from "./AIAssistantLoadingScreen";
import { FinalScreen } from "./FinalScreen";

enum ScreenName {
  INITIAL_SCREEN = "INITIAL_SCREEN",
  USER_INPUT = "USER_INPUT",
  GENERATED_RESPONSE = "GENERATED_RESPONSE",
  ACTION_PERFORMED = "ACTION_PERFORMED",
}

export interface GenerateCallbackParams {
  boardName: string;
  userInput?: string;
}

const TopBarMenu = ({ onClose }: { onClose: () => void }) => {
  return (
    <Box
      sx={{
        borderBottom: "1px solid grey",
        backgroundColor: "#243040",
        display: "flex",
        p: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      }}
    >
      <Box sx={{ alignItems: "center", display: "flex", gap: "6px" }}>
        <Box
          component={"img"}
          src={aiAssistantIcon}
          sx={{ width: "30px", filter: "invert(1)" }}
        />
        <Typography sx={{ color: "#ffe", fontWeight: 700, fontSize: "20px" }}>
          Assistant
        </Typography>
      </Box>
      <IconButton sx={{ color: "#ffe" }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export const AIAssistantWindow = observer(() => {
  const state = getWizardState();
  const [actions, setActions] = useState<AIAssistantAction[]>([]);
  const [userInputStepAction, setUserInputStepAction] =
    useState<AIAssistantAction | null>(null);
  const [response, setResponse] = useState<AIGenerationResponse | null>(null);
  const [screenName, setScreenName] = useState<ScreenName>(
    ScreenName.INITIAL_SCREEN
  );
  const mobileMenu = useMediaQuery("(max-width:800px)");

  const { callApi, loading } = useFetch();

  const generateCallback = async (
    action: AIAssistantAction,
    params: GenerateCallbackParams
  ) => {
    try {
      const response: AIGenerationResponse = await callApi(
        aiAssistantApi.generate({
          boardName: params.boardName,
          action,
          userDescription:
            action === AIAssistantAction.GENERATE_GOALS_AND_STATEMENT
              ? params.userInput
              : undefined,
          goals:
            action === AIAssistantAction.GENERATE_GOAL
              ? state.goals.map((goal) => goal.name)
              : undefined,
        })
      );
      setResponse(response);
      setScreenName(ScreenName.GENERATED_RESPONSE);
      state.aiAssistantInput = params;
    } catch (e) {
      console.log("Handle it");
    }
  };

  useEffect(() => {
    if (state.aiAssistant && !state.aiAssistantRefresh) {
      const availableActions = [];
      switch (state.step) {
        case WizardStep.WELCOME: {
          if (
            (!state.boardStatement || state.boardStatement.length === 0) &&
            state.goals.length === 0
          ) {
            availableActions.push(
              AIAssistantAction.GENERATE_GOALS_AND_STATEMENT
            );
          }
          if (state.boardName && state.boardName.length) {
            if (
              !state.boardStatement ||
              (state.boardStatement.length === 0 && state.goals.length > 0)
            ) {
              availableActions.push(AIAssistantAction.GENERATE_STATEMENT);
            }
            if (state.goals.length === 0) {
              availableActions.push(AIAssistantAction.GENERATE_GOALS);
            } else if (state.goals.length >= MIN_GOALS_REQUIRED) {
              availableActions.push(AIAssistantAction.GENERATE_GOAL);
            } else if (state.goals.length < MIN_GOALS_REQUIRED) {
              availableActions.push(AIAssistantAction.GENERATE_GOALS);
            }
          }
          break;
        }
        case WizardStep.SET_UP_NAME: {
          availableActions.push(AIAssistantAction.GENERATE_STATEMENT);
          break;
        }
        case WizardStep.GOALS: {
          if (state.goals.length === 0) {
            availableActions.push(AIAssistantAction.GENERATE_GOALS);
          } else if (state.goals.length >= MIN_GOALS_REQUIRED) {
            availableActions.push(AIAssistantAction.GENERATE_GOAL);
          } else if (state.goals.length < MIN_GOALS_REQUIRED) {
            availableActions.push(AIAssistantAction.GENERATE_GOALS);
          }
        }
      }
      setActions(availableActions);
    }
  }, [state.aiAssistant, state.step, state.aiAssistantRefresh]);

  const handleAction = async (
    action: AIAssistantAction,
    params?: GenerateCallbackParams
  ) => {
    if (action === AIAssistantAction.GENERATE_STATEMENT) {
      await generateCallback(action, {
        boardName: state.boardName,
      });
    } else if (action === AIAssistantAction.GENERATE_GOALS) {
      if (screenName === ScreenName.INITIAL_SCREEN) {
        setScreenName(ScreenName.USER_INPUT);
        setUserInputStepAction(action);
      } else if (params) {
        await generateCallback(action, params);
      }
    } else if (action === AIAssistantAction.GENERATE_GOAL) {
      await generateCallback(action, {
        boardName: state.boardName,
      });
    } else if (action === AIAssistantAction.GENERATE_GOALS_AND_STATEMENT) {
      if (screenName === ScreenName.INITIAL_SCREEN) {
        setScreenName(ScreenName.USER_INPUT);
        setUserInputStepAction(action);
      } else if (params) {
        await generateCallback(action, params);
      }
    }
  };

  useEffect(() => {
    return () => {
      setScreenName(ScreenName.INITIAL_SCREEN);
      setUserInputStepAction(null);
      setActions([]);
    };
  }, []);

  const onClose = () => {
    state.aiAssistant = false;
    setUserInputStepAction(null);
    state.aiAssistantInput = null;
    setScreenName(ScreenName.INITIAL_SCREEN);
    setResponse(null);
  };

  const onActionPerformed = () => {
    setScreenName(ScreenName.ACTION_PERFORMED);
    state.aiAssistantInput = null;
    setResponse(null);
  };

  return state.aiAssistant ? (
    <Paper
      elevation={4}
      sx={{
        position: "absolute",
        bottom: mobileMenu ? "85px" : "30px",
        right: mobileMenu ? "10px" : "30px",
        zIndex: 1400,
        width:
          screenName === ScreenName.USER_INPUT
            ? (mobileMenu ? "90vw" : "400px")
            : screenName === ScreenName.GENERATED_RESPONSE
            ? (mobileMenu ? "90vw" : "600px")
            : "300px",
        transition: "width 0.5s",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        maxHeight: "80vh",
      }}
    >
      {loading ? (
        <AIAssistantLoadingScreen
          message={"I'm on it! Please give me a few moments :)"}
        />
      ) : null}
      <TopBarMenu onClose={onClose} />
      {screenName === ScreenName.INITIAL_SCREEN ? (
        <InitialScreen actions={actions} handleAction={handleAction} />
      ) : null}
      {screenName === ScreenName.GENERATED_RESPONSE && response ? (
        <GeneratedResponseScreen
          response={response}
          onRegenerate={async () => {
            await generateCallback(response.action, {
              boardName: state.boardName,
            });
          }}
          onBack={() => {
            setScreenName(
              userInputStepAction !== null
                ? ScreenName.USER_INPUT
                : ScreenName.INITIAL_SCREEN
            );
            setResponse(null);
          }}
          onActionPerformed={onActionPerformed}
        />
      ) : null}
      {screenName === ScreenName.ACTION_PERFORMED ? (
        <FinalScreen
          performedAction={AIAssistantAction.GENERATE_GOAL /* TODO */}
          onClose={onClose}
          startAgainCallback={() => {
            setUserInputStepAction(null);
            setScreenName(ScreenName.INITIAL_SCREEN);
          }}
        />
      ) : null}
      {screenName === ScreenName.USER_INPUT && userInputStepAction !== null ? (
        <UserInputScreen
          userInputStepAction={userInputStepAction}
          onBack={() => {
            setScreenName(ScreenName.INITIAL_SCREEN);
          }}
          handleAction={handleAction}
        />
      ) : null}
    </Paper>
  ) : null;
});
