import { Box, Divider } from "@mui/material";
import { ElementToolbox } from "../toolbox/ElementToolbox";
import { SystemToolbox } from "../toolbox/SystemToolbox";
import { TextToolbox } from "../toolbox/TextToolbox";
import { LayoutItem, DEFAULT_BACKGROUND, DEFAULT_TEXT_STYLES, LayoutItemStyles, GridBoardProperties, Label } from "./model";
import { isTextItem } from "./utils";
import { forwardRef } from "react";

export const GridItemToolbox = forwardRef(({ item, moveDown, moveUp, boardProperties, updateLayoutItemStyles, updateItem, onClose, onDelete }: {
    item: LayoutItem,
    moveUp: (item: LayoutItem) => void,
    moveDown: (item: LayoutItem) => void;
    updateLayoutItemStyles: (item: LayoutItem, updatedStyles: LayoutItemStyles) => void;
    updateItem: (item: LayoutItem) => void;
    boardProperties: GridBoardProperties;
    onClose: () => void;
    onDelete?: () => void
 }, ref) => {
    return (
        <Box ref={ref} sx={{ display: "flex", flexWrap: "wrap", rowGap: "5px", backgroundColor: "#fff" }}>
            <ElementToolbox
                moveUpCallback={() => moveUp(item)}
                moveDownCallback={() => moveDown(item)}
                item={item}
                styles={{
                    borderColor: boardProperties.borderColor,
                    borderSize: boardProperties.borderSize,
                    filter: "none",
                    noBorder: false,
                    ...item.styles,
                }}
                updateStyles={(styles) => updateLayoutItemStyles(item, styles)}
                orderIcons={boardProperties.allowOverflow}
            />
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            {isTextItem(item.type) ?
                <>
                    <TextToolbox
                        label={item.type === "label" ? item.value as Label : undefined}
                        background={item.styles?.background || DEFAULT_BACKGROUND}
                        updateLabel={(label) => {
                            updateItem({
                                ...item,
                                value: label
                            })
                        }}
                        styles={item.styles?.textStyles || DEFAULT_TEXT_STYLES}
                        updateStyles={(styles) => updateLayoutItemStyles(item, { ...item.styles, textStyles: styles })}
                        updateBackground={(background) => updateLayoutItemStyles(item, { ...item.styles, background: background })} />
                    <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                </> : null}
            <SystemToolbox type={item.type} onClose={onClose} onDelete={onDelete} />
        </Box>
    )
});