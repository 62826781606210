import { Switch, alpha, styled } from "@mui/material";

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#544961",
      '&:hover': {
        backgroundColor: alpha("#544961", theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#544961",
    },
  }));
