import "./App.css";
import { Box, Button, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material/";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RootContext, store } from "./state/RootStore";
import { AuthorizedApp } from "./AuthorizedApp";
import {
  createBrowserRouter,
  Link,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { ClerkProvider, SignIn, SignUp } from "@clerk/clerk-react";
import { ClerkPublicWrapperPage } from "./public/ClerkPublicWrapperPage";
import { PageNotFound } from "./PageNotFound";

const theme = createTheme({
  typography: {
    fontFamily: "Lato, sans-serif",
    allVariants: {
      color: "#515154",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#243040",
          ":hover": {
            backgroundColor: "#4B6689",
          }
        },
        outlinedPrimary: {
          backgroundColor: "#fff",
          borderColor: "#243040",
          color: "#243040"
        },
        textPrimary: {
          color: "#243040"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#fff",
          color: "#243040",
          p: "5px",
          fontSize: "14px",
          border: "1px solid #243040 !important"
        },
        arrow: {
          color: "#fff",
          "&::before": {
            backgroundColor: "#243040"
          }
        },
        tooltipArrow: {
          border: "black"
        }
      }
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
  },
});

const localization = {
  signIn: {
    start: {
      title: "Login to your account",
      actionLink: "Register here"
    }
  },
  signUp: {
    start: {
      subtitle: "Start your free 14-days trial. No commitment. No credit card required.",
      actionLink: "Go to login page"
    }
  }
};

const RootLayout = () => {
  const navigate = useNavigate();
  const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
  }
  return (
    <ClerkProvider
      navigate={navigate}
      afterSignInUrl="/app/dashboard"
      localization={localization}
      appearance={{
        layout: {
          socialButtonsPlacement: "bottom",
          socialButtonsVariant: "iconButton",
          termsPageUrl: "https://ITS_DEV_ENV.com",
        },
      }}
      publishableKey={PUBLISHABLE_KEY}
    >
      <main>
        <Outlet />
      </main>
    </ClerkProvider>
  );
};

const IndexPageComponent = () => (
  <>
    <Button
      component={Link}
      to={"/sign-in"}
      sx={{ width: "250px", fontSize: "13px", backgroundColor: "rgba(0,0,0,0)", border: "1px solid #ffe", fontWeight: 600, color: "rgb(216, 226, 239)" }}
      variant="outlined"
    >
      Login
    </Button>
    <Button
      component={Link}
      to={"/sign-up"}
      sx={{ width: "250px", fontSize: "13px", backgroundColor: "rgba(0,0,0,0)", border: "1px solid #ffe", fontWeight: 600, color: "rgb(216, 226, 239)" }}
      variant="outlined"
    >
      Register
    </Button>
  </>
);

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <ClerkPublicWrapperPage internal Component={<IndexPageComponent />} />,
      },
      {
        path: "/sign-up",
        element: (
          <ClerkPublicWrapperPage
            Component={
              <SignUp signInUrl="/sign-in" afterSignUpUrl={"/app/dashboard"} />
            }
          />
        ),
      },
      {
        path: "/sign-in",
        element: (
          <ClerkPublicWrapperPage
            Component={
              <SignIn  signUpUrl="/sign-up" afterSignUpUrl={"/app/dashboard"} />
            }
          />
        ),
      },
      {
        path: "app/*",
        element: <AuthorizedApp />,
      },
      {
        path: "contact",
        element: <div>Contact!</div>,
      },
      {
        path: "privacy-policy",
        element: <div>Privacy policy!</div>,
      },
      {
        path: "terms",
        element: <div>Terms and conditions!</div>,
      },
      {
        path: "*",
        element: <PageNotFound />
      }
    ],
  },
]);

const DevEnvironment = () => process.env.REACT_APP_DEV_ENV === "true" ? <Box sx={{ position: "fixed", left: 0, bottom: 0, right: 0, backgroundColor: "red", color: "white", padding: "3px", zIndex: 34567, display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
  DEV ENVIRONMENT
  {process.env.REACT_APP_BUILD_DATE ? <Typography sx={{ fontSize: "10px" }}>{process.env.REACT_APP_BUILD_DATE}</Typography> : null}
  </Box> : null;


const Routes = () => (
  <Box sx={{ display: "flex", height: "calc(100vh)" }}>
    <RouterProvider router={router} />
    <DevEnvironment />
  </Box>
);

function App() {
  return (
    <RootContext.Provider value={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routes />
        </LocalizationProvider>
      </ThemeProvider>
    </RootContext.Provider>
  );
}

export default App;
