import { Box } from "@mui/material"
import { UserProfile } from "@clerk/clerk-react";
import "./AccountSettings.css"

export const AccountSettings = () => {
    return (
        <Box className="account-settings">
            <UserProfile>
                <UserProfile.Page label="account" />
                <UserProfile.Page label="security" />
            </UserProfile>
        </Box>)
}