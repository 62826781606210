import { Box } from "@mui/material";
import { SettingTabs } from "./SettingTabs";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentIcon from '@mui/icons-material/Payment';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { AccountSettings } from "./AccountSettings";
import { useTranslation } from "react-i18next";
import { NotificationsSettings } from "./NotificationsSettings";
import { SubscriptionSettings } from "./SubscriptionSettings";
import { ChangeLog } from "./ChangeLog";

export const Settings = () => {

    const { t } = useTranslation();

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            <SettingTabs tabs={[
                {
                    label: t("settings.account"),
                    icon: <AccountBoxIcon sx={{ color: "#243040" }} />,
                    Element: <AccountSettings />,
                    hash: "account"
                },
                {
                    label: t("settings.notifications"),
                    icon: <NotificationsIcon sx={{ color: "#243040" }} />,
                    Element: <NotificationsSettings />,
                    hash: "notifications"
                },
                {
                    label: t("settings.subscription"),
                    icon: <PaymentIcon sx={{ color: "#243040" }} />,
                    Element: <SubscriptionSettings />,
                    hash: "subscription"
                },
                {
                    label: t("settings.changelog"),
                    icon: <ChangeHistoryIcon sx={{ color: "#243040" }} />,
                    Element: <ChangeLog />,
                    hash: "changelog"
                }
            ]} />
        </Box>
    )
}