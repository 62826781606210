import { Box, useMediaQuery } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { DEFAULT_AFFIRMATIONS } from "../api/affirmations";
import { registerListener } from "../events/eventBus";
import { useEffect, useState } from "react";
import { generateRandom } from "../utils/numbers";

enum AffirmationWidgetMode {
  DEFAULT = "DEFAULT",
  OWN = "OWN",
  MIXED = "MIXED",
}

interface AffirmationWidgetConfiguration {
  count: number;
  mode: AffirmationWidgetMode;
}

const DEFAULT: AffirmationWidgetConfiguration = {
  count: 6,
  mode: AffirmationWidgetMode.DEFAULT,
};

const BACKGROUND_COUNTS = 8;

const getRandomArray = (maxNumber: number, arraySize: number): number[] =>
  Array.from({ length: maxNumber }, (x, i) => i)
    .sort(() => 0.5 - Math.random())
    .slice(0, arraySize);

/**
 * toggling menu size should force refresh this component
 * @returns
 */
export const AffirmationWidget = () => {
  /* TODO affirmation widget - nice backgrounds, on hover after a second a top tooltip w edit button will appear.
        you can edit: number of affirmations 1-5, type: predefined, own, mixed. list of own affirmations that user can add */

  //   const [edit, setEdit] = useState<boolean>(false);
  const mobile = useMediaQuery("(max-width:660px)");
  const [version, setVersion] = useState<number>(1);

  const bumpVersion = () => {
    setVersion(generateRandom(0, 1000));
  };

  useEffect(() => {
    registerListener({
      callback: bumpVersion,
      type: "menu_size_change",
    });
  }, []);

  const affirmationIndexes = Array.from(
    { length: DEFAULT_AFFIRMATIONS.length },
    (x, i) => i
  )
    .sort(() => 0.5 - Math.random())
    .slice(0, DEFAULT.count);

    const isWideScreen = useMediaQuery("(min-width:1600px)");


  return (
    // <Tooltip
    //   arrow
    //   placement="top"
    //   title={edit || 1 * 1 === 1 ? null : "Double-click to edit widget"}
    //   TransitionProps={{ timeout: 500 }}
    //   enterDelay={1000}
    // >
    <Box
      // onDoubleClick={(e) => setEdit(true)}
      id="affirmations-widget"
      key={`affirmations-widget${version}`}
      sx={{
        borderRadius: "15px",
        width: mobile ? "calc(100vw - 50px)" : "100%",
        height: "300px",
        display: "flex",
        "&>div.splide": {
            ...(mobile ? { width: "calc(100vw - 50px)" } : {})
        }
      }}
    >
      {/* {edit ? (
          <Box
            sx={{
              position: "absolute",
              zIndex: 100,
              p: "10px",
              display: "flex",
              gap: "10px",
            }}
          >
            <Button variant="contained">Settings</Button>
            <Button variant="contained" onClick={() => setEdit(false)}>
              Close
            </Button>
          </Box>
        ) : null} */}
      <Splide
        key={`affirmation-splide-${version}`}
        style={{
          borderRadius: "15px",
          height: "100%",
          width: mobile ? "calc(100vw - 50px)" : "100%",
        }}
        options={{
          rewind: true,
          autoplay: true,
        }}
      >
        {getRandomArray(BACKGROUND_COUNTS, DEFAULT.count).map(
          (index, count) => (
            <SplideSlide
              key={`slide${index}`}
              style={{
                height: "100%",
                width: mobile ? "calc(100vw - 50px)" : "100%",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${process.env.REACT_APP_PUBLIC_ASSETS_URL}/affirmations/affirmation-${index}.jpg)`,
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: mobile ? "calc(100vw - 50px)" : "100%",
                  flex: 1,
                  height: "300px",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    minWidth: "75%",
                    color: "rgba(0,0,0,0.5)",
                    borderRadius: "15px",
                    fontWeight: "bold",
                    fontSize: mobile ? "15px" : isWideScreen ? "26px" : "18px",
                    padding: "15px",
                    m: mobile ? "20px" : undefined,
                    backgroundColor: "rgba(255,255,255,0.9)",
                    border: "1px solid rgba(255,255,255,0.8)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  {DEFAULT_AFFIRMATIONS[affirmationIndexes[count]]}
                </Box>
              </Box>
            </SplideSlide>
          )
        )}
      </Splide>
    </Box>
    // </Tooltip>
  );
};
