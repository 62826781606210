import { Api } from "./Api";

export interface User {
  email: string;
  firstName: string;
  lastName: string;
}

export enum AccountStatus {
  TRIAL = "TRIAL",
  ACTIVE = "ACTIVE",
  DUE_PAYMENT = "DUE_PAYMENT",
  SUSPENDED = "SUSPENDED"
}

export interface StatusResponse {
  userId: string,
  status: AccountStatus;
  trialExpirationDate: Date;
  trialExpired?: boolean;
}

class UserApi extends Api {
  me() {
    return this.get("me");
  }

  status() {
    return this.get("status");
  }
}

export const userApi = new UserApi();
