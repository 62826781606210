import { Box, Button, TextField, List, ListItem, IconButton, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material";
import { useState, ChangeEvent, KeyboardEvent, useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { MilestoneDto, boardApi } from "../../api/BoardApi";
import { Loader } from "../../components/Loader";
import useFetch from "../../hooks/useFetch";

export const MilestoneList = ({ boardId, goalId }: { boardId: string; goalId: string }) => {
    const [milestones, setMilestones] = useState<MilestoneDto[]>([]);
    const [firstMilestone, setFirstMilestone] = useState<boolean>(false);
    const [milestoneText, setMilestoneText] = useState<string>("");
    const [milestoneTextError, setMilestoneTextError] = useState<boolean>(false);
    const [milestoneLoading, setMilestoneLoading] = useState<string | null>(null);
    const [addLoading, setAddLoading] = useState<boolean>(false);
    const { loading, callApi } = useFetch();


    const queryMilestones = async () => {
        const results: MilestoneDto[] = await callApi(boardApi.getGoalMilestones(boardId, goalId));
        setMilestones(results);
    }

    useEffect(() => {
        queryMilestones();
    }, []);

    const addCallback = async () => {
        if (milestoneText.length) {
            setAddLoading(true);
            await callApi(boardApi.createGoalMilestone(boardId, goalId, {
                name: milestoneText
            }));
            await queryMilestones();
            setFirstMilestone(false);
            setMilestoneText("");
            setAddLoading(false);
        } else {
            setMilestoneTextError(true)
        }
    }

    const deleteCallback = async (id: string) => {
        setMilestoneLoading(id);
        await callApi(boardApi.deleteGoalMilestone(boardId, goalId, id));
        await queryMilestones();
        setMilestoneLoading(null);
    }

    const toggleCallback = async (id: string) => {
        setMilestoneLoading(id);
        await callApi(boardApi.toggleGoalMilestone(boardId, goalId, id));
        await queryMilestones();
        setMilestoneLoading(null);
    }

    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMilestoneText(e.currentTarget.value);
        if (milestoneTextError) {
            setMilestoneTextError(false)
        }
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            addCallback();
        }
    }

    if (loading) {
        return <Loader />
    }

    if (firstMilestone === false && milestones.length === 0) {
        return (<Box sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            fontStyle: "italic",
            flexDirection: "column",
            textAlign: "center",
            fontFamily: "Lato, sans-serif",
            gap: "30px"
        }}> You have no milestones defined.<br /><br />Milestones are optional part of each goal that might help you achieve your goal. Once you reach a milestone you can mark it as completed.
            <Button onClick={() => setFirstMilestone(true)} variant="outlined">Add milestone</Button>
        </Box>);
    } else if (firstMilestone) {
        return (<Box sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            fontStyle: "italic",
            flexDirection: "column",
            gap: "30px"
        }}
        >
            <TextField disabled={addLoading} error={milestoneTextError} onChange={onTextChange} onKeyDown={onKeyDown} sx={{ width: "80%" }} value={milestoneText} placeholder="Milestone" />
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", gap: "20px" }}>
                <Button disabled={addLoading} onClick={addCallback} variant="contained">Add</Button>
                <Button disabled={addLoading} onClick={() => {
                    setFirstMilestone(false);
                    setMilestoneText("");
                    setMilestoneTextError(false);
                }} variant="outlined">Cancel</Button>
            </Box>
        </Box>);
    }

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {milestones.map((value) => {
                return (
                    <ListItem sx={{ height: "40px", ".MuiButtonBase-root": { height: "40px" } }} key={`milestone-${value.id}`} secondaryAction={
                        <IconButton disabled={milestoneLoading === value.id} onClick={async () => await deleteCallback(value.id)} edge="end">
                            <DeleteIcon />
                        </IconButton>
                    } disablePadding>
                        <ListItemButton disabled={milestoneLoading === value.id} role={undefined} onClick={() => { }} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={value.accomplished}
                                    tabIndex={-1}
                                    onClick={async () => await toggleCallback(value.id)}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={value.name} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
            <ListItem key="add-item" secondaryAction={
                <IconButton disabled={addLoading} sx={{ mt: "10px" }} edge="end" onClick={addCallback}>
                    <AddBoxIcon />
                </IconButton>
            } disablePadding>
                <ListItemIcon>
                    <Box />
                </ListItemIcon>
                <TextField disabled={addLoading} error={milestoneTextError} onKeyDown={onKeyDown} onChange={onTextChange} value={milestoneText} InputProps={{
                    sx: { height: "30px", fontSize: "12px", width: "100%" }
                }} sx={{ width: "calc(100%)", mr: "60px", mt: "10px" }} placeholder="New milestone" />
            </ListItem>
        </List>
    )
}