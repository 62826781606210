import { Photo } from "../../../../api/UnsplashApi";
import { TextStyles } from "../toolbox/TextToolbox";
import { Layout } from "react-grid-layout";
import { BackgroundAnimation } from "./BackgroundAnimationModal";
import { GalleryItem } from "../../../../assets/gallery/gallery-config";

type LayoutBreakingPointName = "lg" | "md" | "sm" | "xs" | "xxs"

export interface LayoutDefinition {
    columns: number;
    width: number;
    name: LayoutBreakingPointName;
}

export const DEFINITIONS: LayoutDefinition[] = [
    {
        columns: 12,
        width: 1200,
        name: 'lg'
    },
    {
        columns: 8,
        width: 996,
        name: 'md',
    },
    {
        columns: 4,
        width: 768,
        name: 'sm',
    },
    {
        columns: 1,
        width: 480,
        name: 'xs',
    },
    {
        columns: 1,
        width: 0,
        name: 'xxs'
    },
]

export type LayoutItemType = "goal" | "name" | "statement" | "label" | "sticker" | "illustration" | "powerful-statement";
export type FilterType = "sepia" | "grayscale" | "none" | "contrast" | "inverted";
export type BorderStyle = "normal" | "dashed" | "dotted" | "double" | "outset" | "special-dashed-1" | "special-dotted-1" | "special-normal-1" | "gradient-1" | "gradient-2" | "animated-rainbow-1" | "animated-rainbow-2"
//TODO
// border-gradient https://codepen.io/chriscoyier/pen/PXNPRq/ - z 2 warianty
// TODO animated rainbow z dwa warianty

export interface BackgroundPosition {
    x: "left" | "center" | "right";
    y: "top" | "center" | "bottom";
}

export interface LayoutItemStyles {
    filter?: FilterType;
    textStyles?: TextStyles;
    background?: Background;
    backgroundPosition?: BackgroundPosition;
    noBorder?: boolean;
    borderColor?: string;
    borderSize?: number;
    borderStyle?: BorderStyle;
}

export interface PowerfulStatement {
    parts: string[]
}

export enum LabelStyle {
    NONE = "NONE",
    LABEL1 = "LABEL1",
    WAVE = "WAVE",
    ANIMATED1 = "ANIMATED1",
    ANIMATED_GRADIENT = "ANIMATED_GRADIENT",
    TEXT_SHADOW = "TEXT_SHADOW",
    GRADIENT_UNDERLINE = "GRADIENT_UNDERLINE"
    // UNDERLINE = "UNDERLINE"; https://codepen.io/erikmartinjordan/pen/MVPaNW
    // LINE_BEHIND = "LINE_BEHIND"; https://codepen.io/ericrasch/pen/kWWzzk
    // NEON = "NEON" -> https://www.npmjs.com/package/react-novacancy
}

export interface Label {
    text: string;
    style: LabelStyle;
    variant?: any;
}

export interface LayoutItem {
    layout: Layout
    type: LayoutItemType,
    value: string | PowerfulStatement | Label,
    styles?: LayoutItemStyles
}

export interface Background {
    type: "color" | "image" | "transparent",
    source?: "external" | "internal"
    value?: string | Photo | GalleryItem;
}

export interface GridBoardProperties {
    borderColor: string;
    borderSize: number;
    borderStyle: BorderStyle;
    background: Background,
    backgroundAnimation?: BackgroundAnimation;
    boardPadding: [number, number];
    roundedCorners: boolean;
    skipStatement?: boolean;
    allowOverflow: boolean;
    elementMargin: [number, number];
    mode: "none" | "light" | "dark" | "auto",
}

export const DEFAULT_TEXT_STYLES: TextStyles = {
    bold: false,
    color: "#000",
    font: "Lato",
    italic: false,
    outline: false,
    underline: false,
    size: 28,
    textAlign: "center",
    textRotate: 0
}

export const DEFAULT_BACKGROUND: Background = {
    type: "color",
    value: "#fff"
}

export const DEFAULT_BOARD_PROPERTIES: GridBoardProperties = {
    background: {
        type: "color",
        value: "#fff"
    },
    borderColor: "#aabbcc",
    borderSize: 4,
    borderStyle: "normal",
    mode: "none",
    allowOverflow: false,
    roundedCorners: false,
    boardPadding: [30, 30],
    elementMargin: [30, 30],
}

export interface GridBoard {
    props: GridBoardProperties;
    layout: LayoutItem[];
    definition: LayoutDefinition;
}

export const isCompleteGridBoard = (board: GridBoard): boolean => {

    return true;
}