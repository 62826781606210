import { forwardRef } from "react";
import { Editor } from '@tinymce/tinymce-react';

export const RichTextEditor = forwardRef(({ initialValue }: { initialValue?: string }, ref) => {
    return (
        <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            // @ts-ignore
            onInit={(evt, editor) => ref ? ref.current = editor : {}}
            initialValue={initialValue ?? ""}
            init={{
                height: 300,
                width: "100%",
                menubar: false,
                statusbar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'charmap',
                    'anchor', 'searchreplace', 'visualblocks', 'code',
                    'insertdatetime', 'media', 'table'
                ],
                toolbar: 'undo redo | formatselect | bold italic color backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | anchor | table tabledelete | media',
                content_style: 'body { font-family:Lato,Helvetica,Arial,sans-serif; font-size:14px }'
            }}
        />
    )
});