import { Box, Typography } from "@mui/material";
import { CustomModal } from "../../../../components/CustomModal";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { LayoutItemType } from "../grid/model";
import FilterIcon from "@mui/icons-material/Filter";
import CropIcon from "@mui/icons-material/Crop";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import HideImageIcon from "@mui/icons-material/HideImage";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ImageIcon from "@mui/icons-material/Image";
import { TextEffectIcon } from "./TextToolbox";

const IconDefinition = ({
  IconComponent,
  description,
}: {
  IconComponent: React.ReactNode;
  description: string;
}) => (
  <Box sx={{ display: "flex", gap: "20px" }}>
    {IconComponent}
    {description}
  </Box>
);

export const HelpModal = ({
  onClose,
  type,
}: {
  type: LayoutItemType;
  onClose: () => void;
}) => {
  return (
    <CustomModal open cancelIcon onCancel={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          maxHeight: 400,
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>General options</Typography>
        <IconDefinition
          IconComponent={<DeleteIcon />}
          description="Delete an element from board. Please note that board name and goals cannot be removed"
        />
        <IconDefinition
          IconComponent={<BorderClearIcon />}
          description="Remove border from element"
        />
        <IconDefinition
          IconComponent={<BorderColorIcon />}
          description="Change border color"
        />
        <IconDefinition
          IconComponent={<LineWeightIcon />}
          description="Change border size"
        />
        <IconDefinition
          IconComponent={<BorderStyleIcon />}
          description="Apply one of available border styles"
        />
        <IconDefinition
          IconComponent={<ClearAllIcon />}
          description="Restore to board settings"
        />
        {type === "goal" ? (
          <>
            <Typography sx={{ fontWeight: 700 }}>Goal element</Typography>
            <IconDefinition
              IconComponent={<FilterIcon />}
              description="Apply filter to goal image"
            />
            <IconDefinition
              IconComponent={<CropIcon />}
              description="Change position of goal immage. Depend of goal element shape a different position might be needed"
            />
          </>
        ) : null}
        {type === "label" ? (
          <>
            <Typography sx={{ fontWeight: 700 }}>Label element</Typography>
            <IconDefinition
              IconComponent={<TextEffectIcon />}
              description="Change label effect"
            />
          </>
        ) : null}
        {type === "name" || type === "label" ? (
          <>
            <Typography sx={{ fontWeight: 700 }}>Text element</Typography>
            <IconDefinition
              IconComponent={<TitleOutlinedIcon />}
              description="Apply text outline"
            />
            <IconDefinition
              IconComponent={<FormatColorTextIcon />}
              description="Font color"
            />
            <IconDefinition
              IconComponent={<FormatColorFillIcon />}
              description="Element background color"
            />
            <IconDefinition
              IconComponent={<ImageIcon />}
              description="Select element background image"
            />
            <IconDefinition
              IconComponent={<HideImageIcon />}
              description="Transparent background"
            />
            <IconDefinition
              IconComponent={<RotateLeftIcon />}
              description="Rotate text left"
            />
            <IconDefinition
              IconComponent={<RotateRightIcon />}
              description="Rotate text right"
            />
          </>
        ) : null}
      </Box>
    </CustomModal>
  );
};
